import React from 'react';

const EmsHubFlags = (props) => {
    return (<>
    <div style={{display: 'flex'}}>
        {props?.account?.flags?.length >= 1 && 
        (<div>
            <h4>Ems Flags</h4>
            <div>
                <ul>
                    {props?.account?.flags?.map(f => <small key={f}><li>{f}</li></small>)}
                </ul>
            </div>
        </div>)}
        
        {props?.account?.hub?.featureFlags?.length > 1 && 
        (<div>
            <h4>Hub Flags</h4>
            <div>
                <ul style={{columns: 2}}>
                    {props?.account?.hub?.featureFlags?.map(f => <small key={f}><li>{f}</li></small>)}
                </ul>
            </div>
        </div>
        )}
    </div>
</>);
}

export default EmsHubFlags;