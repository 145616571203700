import { faCheckCircle, faMinusCircle, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TableRow } from '@hai/ui-react';
import React from 'react';


const FeatureFlagSubRow = (props) => {


    return (<TableRow
checkbox={false} style={{ gridTemplateColumns: '0.3fr 2fr 2fr 2fr 0.5fr', color: 'white', textalign: 'center' }}>
                        <div></div>
                        <div>{props.sub.id}</div>
                        <div>{props.sub.name}</div>
                        <div><FontAwesomeIcon
                            size="lg"
                            style={{ cursor: 'pointer' }}
                            icon={props.sub.flag ? faCheckCircle : faMinusCircle}
                            color={props.sub.flag ? "green" : "red"}
                            onClick={() => props.openSubDialog(props.sub, props.ff)} /></div>
                    </TableRow>)}

export default FeatureFlagSubRow;                    