import {
  Animation,
  List,
  ListHeader,
  TableRow,
  OverlayTrigger,
  Icon,
  ProgressBar, 
  Spinner,
  Notification} from "@hai/ui-react";
import React, { Component, Fragment } from "react";
import { Collapse } from "reactstrap";
import axios from "../../../infra/axios";
import moment from "moment";

class HaivisionLicenseRequestItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      licenseRequestItems: null,
      licenseRequestId: this.props.licenseRequestId,
      licenseActionRunning: false,
      showErrorNotification: false
    };
  }

  componentDidMount() {
    this.getLicenseRequestItems();
  }

  getProductBySku(sku) {
    return axios
      .get(process.env.REACT_APP_LIC_API_BASE_URL + "/Products/sku/" + sku)
      .then((response) => {
        return response.data;
      });
  }

  getGetProductOptionDataWithDefaultValue(sku, version) {
    if (sku) {
      return axios
        .get(
          process.env.REACT_APP_LIC_API_BASE_URL +
            "/Products/options/Sku/" +
            sku +
            "/version/" +
            version
        )
        .then((response) => {
          return response.data;
        });
    } else {
      this.setState({showErrorNotification: true});
      return null;
    }
  }

  getLicenseRequestItems() {
    axios
      .get(
        process.env.REACT_APP_LIC_API_BASE_URL +
          "/LicenseRequests/" +
          this.state.licenseRequestId +
          "/LicenseRequestItems"
      )
      .then((response) => {
        this.setState((prev) => {
          prev.licenseRequestItems = response.data;
          prev.loading = false;
          return prev;
        });
      });
  }

  handleActionSpinner() {
    this.setState((prev) => {
      prev.licenseActionRunning = !prev.licenseActionRunning;
      return prev;
    });
  }

  hideCopyNotification() {
    this.setState((prev) => {
      prev.showErrorNotification = false;
      prev.licenseActionRunning = !prev.licenseActionRunning;
      return prev;
    });
  }

  async handleCreateAndGetLicenseText(item) {
    this.handleActionSpinner();
    var options = await this.getGetProductOptionDataWithDefaultValue(
      item.licensedProductSku,
      item.requestedVersion
    );

    if (!options || (options != null && options.length <= 0)) {
      return;
    }

    var sn = item.serialNumber.indexOf("HAI-") >= 0 ? item.serialNumber : "HAI-" + item.serialNumber;

    var newLicense = {
      sku: item.licensedProductSku,
      serialNumber:
        item.recordTypeName.indexOf("Makito") == -1
          ? ""
          : sn,
      instanceUuid: item.instanceUuid,
      cpuId: item.cpuId,
      licenseRequestNumber: item.name,
      productVersionNumber: item.requestedVersion,
      macAddresses: Array.from(
        new Set(
          item.serverMacAddresses.split(";").map((m) => {
            return { value: m };
          })
        )
      ),
      licenseOptionDatas: [],

      productType: options.productTypeName,
    };
    options.map((o) => {
      if (o.licenseSectionItem == "expdate") {
        newLicense.licenseOptionDatas.push({
          item: "expdate",
          value: moment(item.expirationDate).format("YYYY-MM-DD"),
        });
      } else if (o.licenseSectionItem == "expiration_date") {
        newLicense.licenseOptionDatas.push({
          item: "expiration_date",
          value: moment(item.expirationDate).format("YYYYMMDD"),
        });
      } else {
        newLicense.licenseOptionDatas.push({
          item: o.licenseSectionItem,
          value: o.defaultValue,
        });
      }
    });

    var response = await this.PostCreateAndGetLicenseText(newLicense);
    this.handleActionSpinner();
    return response;
  }

  async PostCreateAndGetLicenseText(licenseDto) {
    return axios
      .post(
        process.env.REACT_APP_LIC_API_BASE_URL +
          "/LicenseText/createAndGetLicenseText",
        licenseDto
      )
      .then((response) => {
        return response;
      })
      .finally(() => {});
  }

  async handleCreateAndDownload(item) {
    var response = await this.handleCreateAndGetLicenseText(item);
    this.handleWiiteFileToLocal(response, item);
  }

  handleWiiteFileToLocal(response, item) {
    this.handleActionSpinner();
    const yourDate = new Date();
    var datastr = yourDate.toISOString().split("T")[0];
    console.log(item);
    var fileName =
      item.licensedProductSku +
      "_" +
      item.serialNumber +
      "_" +
      item.serverMacAddresses +
      "_" +
      datastr +
      ".lic";

    const type = response.headers["content-type"];
    const blob = new Blob([response.data], {
      type: type,
      encoding: "UTF-8",
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
    this.handleActionSpinner();
  }

  handleDownload(item) {
    this.handleActionSpinner();
    var licenseUrl = "";
    if (item.recordTypeName.indexOf("makito") != -1) {
      licenseUrl =
        process.env.REACT_APP_LIC_API_BASE_URL +
        "/LicenseText/MacAddress/" +
        item.macAddresses +
        "/merge?instanceUuid=" +
        item.instanceUuid;
    } else {
      licenseUrl =
        process.env.REACT_APP_LIC_API_BASE_URL +
        "/LicenseText/SerialNumber/HAI-" +
        item.serialNumber +
        "/merge";
    }
    axios
      .get(licenseUrl)
      .then((response) => {
        const yourDate = new Date();
        var datastr = yourDate.toISOString().split("T")[0];
        console.log(item);
        var fileName =
          item.licensedProductSku +
          "_" +
          item.serialNumber +
          "_" +
          item.serverMacAddresses +
          "_" +
          datastr +
          ".lic";

        const type = response.headers["content-type"];
        const blob = new Blob([response.data], {
          type: type,
          encoding: "UTF-8",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
      })
      .finally(() => {
        this.handleActionSpinner();
      });
  }

  getFileName(item) {

    var fileName = "";
    var todayDate = new Date().toISOString().slice(0, 10);
    if (item.recordTypeName.indexOf("Makito") == -1)
    {
      fileName = item.licensedProductSku + "-" + item.requestedVersion.replaceAll(".", "-") + "-" + item.serverMacAddresses + "-" + item.name + "-" + todayDate.replaceAll("-", "") + ".lic";
    }
    else
    {
      fileName = item.serialNumber + "-" + item.licensedProductSku + "-" + item.name + "-" + todayDate.replaceAll("-", "") + ".lic";
    }
    return fileName;
  }

  async handleUpload(item) {
    var response = await this.handleCreateAndGetLicenseText(item);
    if (response) {
      var licenseText = response.data;
      this.handleActionSpinner();
      var fileName = this.getFileName(item);
      var uploadUrl =
        process.env.REACT_APP_LIC_API_BASE_URL +
        "/LicenseRequestItems/" +
        item.id +
        "/License/Upload?serialNumber=" +
        "&filename=" +
        fileName;
      axios.post(uploadUrl, JSON.stringify(licenseText)).then((response) => {
        this.handleActionSpinner();
      });
    }
  }

  render() {
    return (
      <Fragment>
      <Collapse isOpen={true}>
        <div
          style={{
            padding: "1%",
            border: "1px #007bff groove",
            borderRadius: "5px",
            marginTop: "5px",
          }}
        >
          {this.state.loading && <ProgressBar  leftLabel="Loading: "
                barColors={{
                  background: '#599bff',
                  right: '#599bff',
                  left: '#2fccd3',
                }}
                hideRight={true}
                animate={1}
              />}
          {!this.state.loading && (
            <List>
              <ListHeader
                checkbox={false}
                style={{
                  gridTemplateColumns:
                    "0.1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr ",
                }}
              >
                <div></div>
                <div>Name</div>
                <div>Sku</div>
                <div>Type</div>
                <div>Version</div>
                <div>Serial Number</div>
                <div>Mac Address</div>
                <div>UUID</div>
                <div>CPU Id</div>
                <div>Expiration Date</div>
                <div>Is Version Updrade?</div>
                <div>Actions</div>
              </ListHeader>
              {this.state.licenseRequestItems &&
                this.state.licenseRequestItems.map((i) => {
                  return (
                    <TableRow
                      checkbox={false}
                      key={i.id}
                      style={{
                        gridTemplateColumns:
                          "0.1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr",
                      }}
                    >
                      <div></div>
                      <div>{i.name}</div>
                      <div>{i.licensedProductSku}</div>
                      <div>{i.recordTypeName}</div>
                      <div>{i.requestedVersion}</div>
                      <div>{i.serialNumber}</div>
                      <div>{i.serverMacAddresses}</div>
                      <div>{i.instanceUuid}</div>
                      <div>{i.cpuId}</div>
                      <div>{i.expirationDate}</div>
                      <div>{i.versionUpgrade}</div>
                      <div style={{ display: "inline-flex" }}>
                        {this.state.licenseActionRunning && (
                         <Spinner/>
                        )}
                        {!this.state.licenseActionRunning && (
                          <>
                            {/* <OverlayTrigger
                              popoverContent={"Create License"}
                              style={{
                                cursor: "pointer",
                                paddingRight: "15px",
                              }}
                              onClick={() => this.handleCreate(i)}
                            >
                              <Icon
                                style={{ fill: "#007bff" }}
                                
                                iconname="AddRed"
                              />
                            </OverlayTrigger> */}
                            <OverlayTrigger
                              popoverContent={"Upload to Salesforce"}
                              style={{
                                cursor: "pointer",
                                paddingRight: "15px",
                              }}
                              onClick={() => this.handleUpload(i)}
                            >
              
                              <Icon
                                style={{ fill: "#007bff" }}                               
                                iconname="Upload"
                              />
                            </OverlayTrigger>

                            <OverlayTrigger
                              popoverContent={"Download"}
                              style={{
                                cursor: "pointer",
                                paddingRight: "15px",
                              }}
                              onClick={() => this.handleCreateAndDownload(i)}
                            >
                              <Icon
                                style={{ fill: "#007bff" }}
                                
                                iconname="Download"
                              />
                            </OverlayTrigger>
                          </>
                        )}
                      </div>
                    </TableRow>
                  );
                })}
            </List>
          )}
        </div>
      </Collapse>
      <Notification
          style={{ top: "1rem", right: "1rem" }}
          show={this.state.showErrorNotification}
          onDismiss={() => this.hideCopyNotification()}
          description={"No SKU for this Item."}
          variant="Error"
        ></Notification>
      </Fragment>
    );
  }
}

export default HaivisionLicenseRequestItem;
