
import React, { useState } from 'react';
import StepStatusRow from '../../common/StepStatusRow';
import PropTypes from 'prop-types';

const HubProvisionStatusProgress = props => {

    const [stateStepMap] = useState(
        new Map([
          ['not_started', 0],
          ['serviceprincipal_created', 1],
          ['subscription_found', 2],
          ['serviceprincipal_role_assigned', 3],
          ['sf_asset_created', 4],
          ['precreated_hub', 5],
          ['claimed', 6],
          ['added_features', 7],
          ['completed', 8],
        ])
      )

      let status = stateStepMap.get(props.runningProvision.stepStatus);
      let isRunning = props.runningProvision.provisionInProgress;
      let retrying = props.retrying || false;

    return (
        <>
      <StepStatusRow 
        text={status < 1 ? "Creating Service principal..." : "Service Principal Created."}
        type={status < 1 ? "StatusStarting" : "StatusOK"}
        isFailed={(status < 1 && props.runningProvision.failMessage != null && !isRunning) && !retrying}>
        </StepStatusRow>

      <StepStatusRow text={status < 2 ? "Finding Azure Subscription..." : "Subscription found."}
      type={status < 2 ? "StatusStarting" : "StatusOK"}
      isFailed={(status < 2 && props.runningProvision.failMessage != null && !isRunning) && !retrying}>
        </StepStatusRow>

        <StepStatusRow text={status < 3 ? "Assigning roles..." : "Role assigned."}
      type={status < 3 ? "StatusStarting" : "StatusOK"}
      isFailed={(status < 3 && props.runningProvision.failMessage != null && !isRunning) && !retrying}>
        </StepStatusRow>

        <StepStatusRow text={status < 4 ? "Creating asset..." : "Asset created."}
      type={status < 4 ? "StatusStarting" : "StatusOK"}
      isFailed={(status < 4 && props.runningProvision.failMessage != null && !isRunning) && !retrying}>
        </StepStatusRow>

        <StepStatusRow text={status < 5 ? "Pre creating in Hub..." : "Hub subscription pre created."}
      type={status < 5 ? "StatusStarting" : "StatusOK"}
      isFailed={(status < 5 && props.runningProvision.failMessage != null && !isRunning) && !retrying}>
        </StepStatusRow>
    </>
    )
}

HubProvisionStatusProgress.propTypes = {
    runningProvision: PropTypes.object.isRequired
}

export default HubProvisionStatusProgress;