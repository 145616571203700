import React, { Component } from "react";
import {
  FormControl,
  FormGroup,
  FormLabel,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Button,
  SelectOption,
  Spinner,
  Animation,
 ProgressBar } from "@hai/ui-react";
import axios from "../../../infra/axios";

class HaivisionLicenseOptionDetailModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      show: this.props.show,
      license: this.props.license,
      productLicenseOptions: null,
      selectedProductOption: null,
      selectedLicenseOption: null,
      showOption: false,
    };
  }

  componentDidMount() {}

  componentWillReceiveProps(nextProps) {
    this.setState((prev) => {
      prev.show = nextProps.show;
      prev.license = nextProps.license;
      return prev;
    });
    this.getProductLicenseOptions();
  }

  getProductLicenseOptions = () => {
    axios
      .get(
        process.env.REACT_APP_LIC_API_BASE_URL +
          "/Products/ProductVersions/" +
          this.state.license.productVersionId +
          "/ProductLicenseOptions"
      )
      .then((response) => {
       
        if(response.data.length > 0){
          this.setState((prev) => {
              prev.productLicenseOptions = response.data;
              prev.selectedProductOption = response.data[0];
              prev.selectedLicenseOption = prev.license.licenseOptionDatas.filter(
                (x) => x.item === prev.selectedProductOption.licenseSectionItem
              )[0];
  
              if (prev.selectedLicenseOption == null) {
                prev.selectedLicenseOption = {
                  item: prev.selectedProductOption.licenseSectionItem,
                  value: prev.selectedProductOption.value,
                };
              }
    
              prev.showOption = this.isShowOption(
                prev.selectedProductOption.availableValues
              );
          
              prev.loading = false;
              return prev;
          });        
        }
        else{
          this.setState({
            loading :false
          });
          // alert('Can not get ProductLicenseOptions, maybe the product or version has been deleted!');
        }
 
      });
  }

  isShowOption(availableValues) {
    if (
      availableValues.substring(0, 1) === "^" &&
      availableValues.substring(availableValues.length - 1) === "$"
    ) {
      return false;
    } else {
      return true;
    }
  }

  handleSubmit() {
    if (this.state.selectedLicenseOption.id == null) {
      axios
        .post(
          process.env.REACT_APP_LIC_API_BASE_URL +
            "/Licenses/" +
            this.state.license.id +
            "/LicenseOptionDatas",
          this.state.selectedLicenseOption
        )
        .then((response) => {
          this.props.onClose();
        });
    } else {
      axios
        .put(
          process.env.REACT_APP_LIC_API_BASE_URL +
            "/Licenses/" +
            this.state.license.id +
            "/LicenseOptionDatas/" +
            this.state.selectedLicenseOption.id,
          this.state.selectedLicenseOption
        )
        .then((response) => {
          this.handleClose();
        });
    }
  }

  handleClose() {
    this.props.onClose();
  }

  onSelectOption(e) {
    var text = e.target.value;
    var tmpSelectedProductOption = this.state.productLicenseOptions.filter(
      (x) => x.description.toLowerCase() === text.toLowerCase()
    )[0];
    var tmpSelectedLicenseOption = this.state.license.licenseOptionDatas.filter(
      (x) =>
        x.item.toLowerCase() ===
        tmpSelectedProductOption.licenseSectionItem.toLowerCase()
    )[0];

    if (tmpSelectedLicenseOption == null) {
      if (
        tmpSelectedProductOption?.availableValues &&
        !tmpSelectedProductOption?.availableValues.startsWith("^")
      ) {
        var availables =
          tmpSelectedProductOption?.availableValues.split(";")[0];
        tmpSelectedLicenseOption = {
          item: tmpSelectedProductOption.licenseSectionItem,
          value: availables.length > 0 ? availables.split("!")[0] : "",
        };
      } else {
        tmpSelectedLicenseOption = {
          item: tmpSelectedProductOption.licenseSectionItem,
          value: "",
        };
      }
    }

    var showOption = this.isShowOption(
      tmpSelectedProductOption.availableValues
    );

    this.setState((prev) => {
      prev.selectedProductOption = tmpSelectedProductOption;
      prev.selectedLicenseOption = tmpSelectedLicenseOption;
      prev.showOption = showOption;
      return prev;
    });
  }

  showValueComponent = () => {
    return (
      <div>
        {!this.state.showOption && (
          <FormControl
          value={this.state.selectedLicenseOption?.value}
            onChange={(e) => {
              var text = e.target.value;
              this.setState((prev) => {
                prev.selectedLicenseOption.value = text;
                return prev;
              });
            }}
          ></FormControl>
        )}
        {this.state.showOption && (
          <FormControl
            as="select"
            defaultValue={this.state.selectedLicenseOption.value?.toLowerCase()}
            onChange={(e) => {
              var text = e.target.value;
              this.setState((prev) => {
                prev.selectedLicenseOption.value = text;
                return prev;
              });
            }}
          >
            {this.state.productLicenseOptions
              .filter(
                (x) =>
                  x.description === this.state.selectedProductOption.description
              )[0]
              .availableValues.split(";")
              .map((o) => {
                var value = o.split("!")[0];
                return <SelectOption key={value} value={value.toLowerCase()}>{value}</SelectOption>;
              })}
          </FormControl>
        )}
      </div>
    );
  };

  render() {
    return (
      <div>
        <Modal show={this.state.show} size="lg">
          <ModalHeader>
            <ModalTitle>Add/Update License Option</ModalTitle>
          </ModalHeader>
          <ModalBody>
            {this.state.loading && <Spinner />}
            {!this.state.loading && (
              <>
                <FormGroup>
                  <FormLabel>License Option</FormLabel>
                  <FormControl
                    as="select"
                    defaultValue={this.state.selectedProductOption?.description}
                    onChange={(e) => this.onSelectOption(e)}
                  >
                    {this.state.productLicenseOptions?.map((p) => {
                      return (
                        <SelectOption key={p.licenseSectionItem} value={p.description}>
                          {p.description}
                        </SelectOption>
                      );
                    })}
                  </FormControl>
                  <FormLabel>License Option Value</FormLabel>
                  {this.showValueComponent()}
                  {/*this.state.selectedProductOption.availableValues.substring(
                    0,
                    1
                  ) === "^" &&
                  this.state.selectedProductOption.availableValues.substring(
                    this.state.selectedProductOption.availableValues.length - 1
                  ) === "$" ? (
                    <FormControl
                      value={this.state.selectedLicenseOption.value}
                      onChange={(e) => {
                        var text = e.target.value;
                        this.setState((prev) => {
                          prev.selectedLicenseOption.value = text;
                          return prev;
                        });
                      }}
                    ></FormControl>
                  ) : (
                    <FormControl
                      as="select"
                      value={this.state.selectedLicenseOption.value}
                      onChange={(e) => {
                        var text = e.target.value;
                        this.setState((prev) => {
                          prev.selectedLicenseOption.value = text;
                          return prev;
                        });
                      }}
                    >
                      {this.state.productLicenseOptions
                        .filter(
                          (x) =>
                            x.description ===
                            this.state.selectedProductOption.description
                        )[0]
                        .availableValues.split(";")
                        .map((o) => {
                          var value = o.split("!")[0];
                          return <SelectOption key={value}>{value}</SelectOption>;
                        })}
                    </FormControl>
                  )*/}
                  {!this.state.selectedLicenseOption?.id && (
                    <FormLabel>
                      * this value will be added to current license
                    </FormLabel>
                  )}
                </FormGroup>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <Button variant="primary" onClick={() => this.handleSubmit()}>
              Submit
            </Button>{" "}
            <Button onClick={() => this.handleClose()}>Cancel</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default HaivisionLicenseOptionDetailModal;
