import { DataTable, FormLabel } from "@hai/ui-react";
import React from "react";
import { Col, Row } from "reactstrap";

const AwAirSeriesDataTable = props => {

    return (<>
        <Row>
            <Col><p style={{ fontSize: '1.4rem', fontStyle: 'italic' }}>Air Series</p></Col>
        </Row>
        <Row>
            <Col><FormLabel> <span style={{ fontStyle: 'italic' }}>UUID:</span> <p>{props.air?.uuid}</p></FormLabel> </Col>
            <Col><FormLabel> <span style={{ fontStyle: 'italic' }}>Mac Address:</span> <p>{props.air?.macAddress}</p></FormLabel> </Col>
            <Col><FormLabel> <span style={{ fontStyle: 'italic' }}>Product name:</span> <p>{props.air?.productName}</p></FormLabel> </Col>
            <Col><FormLabel> <span style={{ fontStyle: 'italic' }}>Maupiti version:</span> <p>{props.air?.maupitiVersion}</p></FormLabel> </Col>
            <Col><FormLabel> <span style={{ fontStyle: 'italic' }}>Version:</span> <p>{props.air?.version}</p></FormLabel> </Col>
        </Row>
        <Row>
            <Col><FormLabel> <span style={{ fontStyle: 'italic' }}>Tahiti Id:</span> <p>{props.air?.idTahiti}</p></FormLabel> </Col>
            <Col><FormLabel> <span style={{ fontStyle: 'italic' }}>Palma Id:</span> <p>{props.air?.idPalma}</p></FormLabel> </Col>
            <Col><FormLabel> <span style={{ fontStyle: 'italic' }}>Bull Id:</span> <p>{props.air?.idBull}</p></FormLabel> </Col>
            <Col><FormLabel> <span style={{ fontStyle: 'italic' }}>Oleron Id:</span> <p>{props.air?.idOleron}</p></FormLabel> </Col>
            <Col><FormLabel> <span style={{ fontStyle: 'italic' }}>Mercury Id:</span> <p>{props.air?.idMercury}</p></FormLabel> </Col>
        </Row>

        <hr />
    </>
    )
}

export default AwAirSeriesDataTable;