import React from "react";
import OrganizationTable from "./OrganizationTable";
import { Button, Header , ProgressBar } from "@hai/ui-react";
import { Row, Col, Container, CustomInput } from "reactstrap";
import JellyHeader from "../../common/Utils/JellyHeader";

class Organization extends React.Component {
  constructor(props) {
    super(props);
    console.log("subs env", process.env.REACT_APP_HUB_API_BASE_URL);
  }

  state = {
    organizations: [],
    subsInGrid: false,
  };

  toggleSubsInGrid = () => {
    this.setState({ subsInGrid: !this.state.subsInGrid });
  };

  render() {
    if (!sessionStorage.getItem("user.authState")) {
      return <div className="white-text">auth required...</div>;
    } else {
      return (
        <Container className="themed-container" fluid={true}>
          <Header
            title="Organizations"
            style={{ marginBottom: "1%", color: "#007bff" }}
          >
            <Col className={"float-right"} md="2" xl="2">
              <CustomInput
                type="switch"
                label="Subs in grid"
                defaultValue={this.state.subsInGrid}
                disabled={false}
                id="subsInGrid"
                name="subsInGrid"
                className={"float-right"}
                onChange={() => this.toggleSubsInGrid()}
              />
            </Col>
          </Header>
          <OrganizationTable
            subsViewGrid={this.state.subsInGrid}
          ></OrganizationTable>
        </Container>
      );
    }
  }
}

export default Organization;
