import {
  Animation,
  Dialog,
  FormLabel,
  LabelValue,
  ProgressBar } from "@hai/ui-react";
import React from "react";
import { useState } from "react";
import axios from "../../../infra/axios";
import ContractProvisionSuccessModal from "./ContractProvisionSuccessModal";

import PropTypes from "prop-types";
import cogoToast from "cogo-toast";
import HubProvisionStatusProgress from "./HubProvisionStatusProgress";
import { Col, Row } from "reactstrap";

const ContractNewProvisionDialog = (props) => {
  const [provision, setProvision] = useState(null);
  const [showDoneModal, setShowDoneModal] = useState(false);
  const [postingProvision, setPostingProvision] = useState(false);
  const [runningProvision, setRunningProvision] = useState(null);

  var intervalChecks = null;

  const getLastCustomerProvision = (onGoing) => {
    axios.get(
      process.env.REACT_APP_HUB_API_BASE_URL +
        "/Provisions/latest/contracts/" + props.contract.id
    ).then(r => {
      if (r.data) {
        setRunningProvision(r.data);
        if (onGoing) {
          enableInterval(true, () => getLastCustomerProvision());
        }
      }
    }).catch(e => {
      enableInterval(false);
    })
  }

  const enableInterval = async (flag, func) => {
    if (flag) {
      clearInterval(intervalChecks);
      intervalChecks = setInterval(func, 15000);
    } else {
      console.log("stop interval");
      clearInterval(intervalChecks);
    }
  };

  const postProvision = () => {
    if (validateForm()) {
      setPostingProvision(true);
      enableInterval(true, () => getLastCustomerProvision(true));
      var body = {
        name: props.contract.accountName,
        domain: props.contract.orgDomain,
        contractId: props.contract.id,
      };
      axios
        .post(
          process.env.REACT_APP_HUB_API_BASE_URL +
            "/Operations/client-subscription/provision",
          body
        )
        .then((response) => {
          setShowDoneModal(true);
          setProvision(response.data);
          setPostingProvision(false);
          enableInterval(false)
        })
        .catch((error) => {
          setPostingProvision(false);
          enableInterval(false);
          getLastCustomerProvision(false);
        });
    }
  };

  const validateForm = () => {
    var valid = true;
    if (
      props.contract.orgDomain === "" ||
      props.contract.orgDomain === undefined
    ) {
      cogoToast.error("Domain is required. Check Salesforce", {
        position: "top-right",
      });
      valid = false;
    }
    if (
      props.contract.accountName === "" ||
      props.contract.accountName === undefined
    ) {
      cogoToast.error("Company name is required. Check Salesforce", {
        position: "top-right",
      });
      valid = false;
    }
    return valid;
  };

  const hideDoneModal = () => {
    setShowDoneModal(false);
    props.close();
  };

  return (
    <>
      <Dialog
        title={"Do you want to provision a new Azure Subscription?"}
        show={props.show}
        content={
          <>
            <FormLabel>Company Name</FormLabel>
            <LabelValue>{props.contract.accountName}</LabelValue>

            <FormLabel>Domain</FormLabel>
            <LabelValue>{props.contract.orgDomain}</LabelValue>

            <FormLabel>Contract Id</FormLabel>
            <LabelValue>{props.contract.id}</LabelValue>

            {(postingProvision && !runningProvision) && (
              <ProgressBar
  leftLabel="Loading: "
  barColors={{
    background: '#599bff',
    right: '#599bff',
    left: '#2fccd3',
  }}
  hideRight={true}
  animate={1}
/>
            )}
            {runningProvision && <HubProvisionStatusProgress
            runningProvision={runningProvision}
            ></HubProvisionStatusProgress> }
            {runningProvision && runningProvision.failMessage != null && 
            <Row><Col>Provision for {runningProvision.claimCode} failed. Retry in Provision page</Col></Row>}
          </>
        }
        buttons={[
          {
            label: "Yes",
            primary: true,
            disabled: postingProvision || runningProvision,
            onClick: () => postProvision(),
          },
          { label: "No", onClick: () => props.close(), disabled: postingProvision },
        ]}
      ></Dialog>

      {showDoneModal && provision != null && (
        <ContractProvisionSuccessModal
          show={showDoneModal}
          contract={{
            contractName: props.contract.name,
            accountName: props.contract.accountName,
          }}
          provision={provision}
          hide={hideDoneModal}
        ></ContractProvisionSuccessModal>
      )}
    </>
  );
};

ContractNewProvisionDialog.propTypes = {
  close: PropTypes.func.isRequired,
  contract: PropTypes.object.isRequired,
};
export default ContractNewProvisionDialog;
