import React, { useEffect, useState } from "react"
import { Button, FormControl, Header, List, ProgressBar } from "@hai/ui-react"
import { FormLabel } from "react-bootstrap";
import axios from "../../infra/axios";
import { Col, Row } from "reactstrap";
import { useParams } from "react-router-dom";
import NavigationButtons from "../common/Utils/NavigationButtons";

const ToriiUsers = props => {
    const params = useParams();
    const [users, setUsers] = useState(null);
    const [loading, setLoading] = useState(true);
    const [notFound, setNotFound] = useState(false);
    const [expandContent, setExpandContent] = useState(null);
    const [visibleData, setVisibleData] = useState(null);
    const [pageData, setPageData] = useState({
        currentPage: params?.pageNum ? parseInt(params?.pageNum) : 1,
        pageSize: 10
    });
    const [userFilter, setUserFilter] = useState(params?.id);
    
    useEffect(() => {
        getUsers();
    }, []);

    useEffect(() => {
        search();
    }, [users])

    const search = () => {
        if (userFilter === '*') {
            setVisibleData(users);
        }
        else if (!userFilter) {
            setVisibleData(users?.slice(0, 10));
            setPageData({...pageData, currentPage: 1});
            window.history.replaceState('','', '/torii/users/page/1')
        } else {
            var data = users?.filter(a => a.id === userFilter || a.name.includes(userFilter));
            setVisibleData(data);
        }
    }

    const getUsers = () => {
        axios.get(`${process.env.REACT_APP_TORII_API_BASE_URL}/torii-users`)
            .then(res => {
                setUsers(res.data)
                let start = params?.pageNum != null ? ((params?.pageNum - 1) * pageData.pageSize) : 0;
            setVisibleData(res.data.slice(start, pageData.currentPage * pageData.pageSize));
            if (res.data.length < pageData.currentPage * pageData.pageSize) {
                console.log('invalid page');
                setVisibleData(res.data.slice(0, 1 * pageData.pageSize));
            }
                setLoading(false)
            })
            .catch(e => setLoading(false))
    }

    
    return (
        <>
            <Header title="Torii Users"></Header>
            <br />
            {loading && <ProgressBar leftLabel="Loading: "
            barColors={{
                background: '#599bff',
                right: '#599bff',
                left: '#2fccd3',
            }}
            hideRight={true}
            animate={1} />
            }

            {users &&
                <div style={{display: 'flex', alignItems: 'baseline'}}>
                    <FormLabel style={{marginRight: '1%'}}>User Id/Name</FormLabel>
                    <FormControl onChange={(e) => setUserFilter(e.target.value)} defaultValue={params?.id}></FormControl>
                    <Button onClick={search}>Search</Button>
                </div> }
                { visibleData && visibleData.map(a => {
                    return (

                        <List key={a.id}>
                            <List.Panel panelColor={a.enabled ? 'haiui-green-01' : 'haiui-red-01'}>
                                <List.PanelTitle title={a.name} />
                                <List.PanelDetail>
                                    <List.PanelDetailItem icon="Information" text={a.id} divider="vertical"></List.PanelDetailItem>
                                </List.PanelDetail>
                                <List.Actions>
                                    <List.ActionItem expandButton eventKey={"showDetails"} icon='View' title="View"
                                        onSelect={(eventKey, expanded) => {
                                            setExpandContent(expanded ? eventKey : '')
                                        }} />
                                    <List.ActionItem isDivider />
                                    {/* <List.ActionItem expandButton eventKey={"navigate"} icon='Export' title="Go"
                                        onSelect={(eventKey, expanded) => {
                                            window.open(`${window.location.origin}/torii/organizations/${a.id}/subscriptions`,'_blank')
                                        }} /> */}
                                </List.Actions>
                                <List.ExpandedPanel>
                                    <div>
                                        {expandContent == 'showDetails' &&
                                            <Row>
                                                <Col>
                                                    <FormLabel>Email: {a.email}</FormLabel>
                                                    <FormLabel>Role: {a.role}</FormLabel>
                                                    <FormLabel>Last sign in: {a.lastSignIn}</FormLabel>
                                                    <FormLabel>Status: {a.status}</FormLabel>
                                                </Col>

                                                <Col>
                                                    <FormLabel>Subscriptions</FormLabel>
                                                    
                                                </Col>

                                            </Row>}
                                    </div>
                                </List.ExpandedPanel>
                            </List.Panel>
                        </List>
                    )
                })
            }

        {users && visibleData &&
             <NavigationButtons
             baseUrl="/torii/users"
             setPageData={setPageData}
             pageData={pageData}
             setVisibleData={setVisibleData}
             visibleData={visibleData}
             data={users}
         ></NavigationButtons>
        }
    </>)
}

export default ToriiUsers;