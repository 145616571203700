import React, { useEffect, useState } from 'react'
import axios from '../../../infra/axios';
import { ProgressBar } from '@hai/ui-react';
import cogoToast from 'cogo-toast';
import PropTypes from "prop-types";
import DeviceByTenantTable from './DeviceByTenantTable';

const DeviceByAccountTable = (props) => {
    const [tenants, setTenants] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (props.selectedAccount === props.account.id) {
            getTenants();
        }
    }, [props.selectedAccount]);

    const getTenants = () => {
        axios.get(process.env.REACT_APP_HUB_API_BASE_URL + `/EmsApi/accounts/${props.account?.id}/tenants`)
        .then(
            res => {
                setTenants(res.data);
                setLoading(false);
                if (res.data?.length > 1) {   
                    throw 'Account has more than one tenant';   
                }
            }).catch(e => {
                cogoToast.info(e, {position: `top-right`})
                setLoading(false);
            })
    }



    return (<>
        {loading && <ProgressBar animate={1} hideRight={true}/>}
        {tenants && tenants.map(t => <DeviceByTenantTable 
        key={t.id} 
        accountId={props.account?.id} 
        tenantId={t.id} 
        pairingPasscode={t.pairingPasscode}
        getTenants={getTenants}/>)}
        </>);
}

DeviceByAccountTable.propTypes = {
    account: PropTypes.any.isRequired
}

export default DeviceByAccountTable;