import React from 'react';
import {Card, CardHeader, CardBody, CardFooter, Row, Col } from 'reactstrap';
import { faCloud, faCopy, faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, LabelValue, FormLabel} from '@hai/ui-react';

import moment from 'moment';
import cogoToast from 'cogo-toast';

const ResourceCard = (props) => {

    var copyToClipboard =async (id) => {
        var copyText = document.getElementById(id);
        copyText.select();
        copyText.setSelectionRange(0, 99999); /*For mobile devices*/
        await navigator.clipboard.writeText(copyText.value);
        cogoToast.success("Copied to clipboard");
    }


    return (
        <Col md="12">
    <Card body inverse style={{ backgroundColor: 'black', borderColor: '#747272', marginBottom: '2%' }}>
        
        <CardBody>
            <Row>
            <Col sm="2">
                <FormLabel  className={'topology-label-data'}>Id: </FormLabel>
                <LabelValue className={'topology-label-data'}>{props.r.id}</LabelValue>
            </Col>
            <Col sm="2">
                <FormLabel className={'topology-label-data'}>Hublet definition Id: </FormLabel>
                <LabelValue className={'topology-label-data'}>{props.r.hubletDefinitionId}</LabelValue>
            </Col>
            <Col sm="2">
                <FormLabel className={'topology-label-data'}>Hublet Id: </FormLabel>
                <LabelValue className={'topology-label-data'}>{props.r.hubletId}</LabelValue>
            </Col>
            <Col sm="2">
                <FormLabel className={'topology-label-data'}>Status: </FormLabel>
                <LabelValue className={'topology-label-data'}>{props.r.status}</LabelValue>
            </Col>
            <Col sm="2">
                <FormLabel className={'topology-label-data'}>External Status: </FormLabel>
                <LabelValue className={'topology-label-data'}>{props.r.externalStatus}</LabelValue>
            </Col>
            </Row>
            <Row>
                <Col sm="2">
                    <FormLabel className={'topology-label-data'}>Provision start: </FormLabel>
                    <LabelValue className={'topology-label-data'}>{moment(props.r.provisionStartTime).format("YYYY-MM-DD HH:mm:ss")}</LabelValue>
                </Col>

                <Col sm="2">
                    <FormLabel className={'topology-label-data'}>Provision end: </FormLabel>
                    <LabelValue className={'topology-label-data'}>{moment(props.r.provisionEndTime).format("YYYY-MM-DD HH:mm:ss")}</LabelValue>
                </Col>

                <Col sm="2">
                    <FormLabel className={'topology-label-data'}>Region: </FormLabel>
                    <LabelValue className={'topology-label-data'}>{props.r.region}</LabelValue>
                </Col>
            </Row>
        </CardBody>
        <CardFooter>
        <Row style={{marginBottom:"1%"}}>
            <Col>Errors found: {props.r.errors.length}</Col> 
            <Col>
                <Button variant="primary" className="float-right">
                        <a href={props.r.resourceLog} 
                        target="_blank" rel="noopener noreferrer"
                        className="link-to-resource"
                        >Go to Resource Log <FontAwesomeIcon icon={faCloud}></FontAwesomeIcon></a>
                    </Button>
                </Col>
        </Row>
            {props.r.errors.map((e, index) => {
                return(<div  key={index}>
                <Row>
                    <Col sm="12" style={{marginBottom:"1%"}}>
                        <FormLabel className={'topology-label-data'}>Message: </FormLabel>
                        <LabelValue className={'topology-label-data'}>{e.errorMessage}</LabelValue>
                    </Col>
                    <Col>
                        <FormLabel className={'topology-label-data'}>Is Unrecoverable: </FormLabel>
                        <LabelValue className={'topology-label-data'}><FontAwesomeIcon icon={e.isUnrecoverable ? faThumbsUp : faThumbsDown}></FontAwesomeIcon></LabelValue>
                    </Col>
                    <Col>
                        <FormLabel className={'topology-label-data'}>Provision state:</FormLabel>
                        <LabelValue className={'topology-label-data'}>{e.provisionState}</LabelValue>
                    </Col>
                    <Col>
                        <FormLabel className={'topology-label-data'}>Provision step state:</FormLabel>
                        <LabelValue className={'topology-label-data'}>{e.provisionStepState}</LabelValue>
                    </Col>
                    <Col>
                        <FormLabel className={'topology-label-data'}>Time:</FormLabel>
                        <LabelValue className={'topology-label-data'}>{moment(e.timeStamp).format("YYYY-MM-DD HH:mm:ss")}</LabelValue>
                    </Col>

                    <Col>
                        <FormLabel className={'topology-label-data'}>Stacktrace:</FormLabel>
                        <LabelValue className={'topology-label-data'}><input type="text" id={"trace"+index} style={{display: "none"}} defaultValue={e.stackTrace}/>
                    <FontAwesomeIcon icon={faCopy} onClick={() => copyToClipboard("trace"+index)}></FontAwesomeIcon></LabelValue>
                    </Col>
                </Row>
                <hr className="hr-divider"/> 
                </div>
                    )
            })}
        </CardFooter>
    </Card>
    </Col>)
}


export default ResourceCard;