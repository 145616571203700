import {
  Animation,
  Button,
  Icon,
  List,
  ListHeader,
  TableRow,
  FormGroup,
  FormLabel,
  FormControl, 
  Spinner,
  Header} from "@hai/ui-react";
import { Table, Row, Col } from "react-bootstrap";
import React, { Component, Fragment, useEffect, useState } from "react";
import axios from "../../../infra/axios";
import HaivisionLicenseOptionDetailModal from "./haivision-license-option-detail-modal";
import HaivisionLicenseDelete from "./haivision-license-delete";
import HaivisionLicenseOptionUpgradeModal from "./haivision-license-upgrade";
import { useParams } from "react-router-dom";

const HaivisionLicenseDetail = (props) => {
  let params = useParams();
    const [loading, setLoading] = useState(true);
    const [license, setLicense] = useState(null);
    const [isVm, setIsVm] = useState(false);
    const [isAzure, setIsAzure] = useState(false);
    const [licenseId, setLicenseId] = useState(props.licenseId ?? params?.licenseId);
    const [selectLicenseOptionData, setSelectLicenseOptionData] = useState(null);
    const [showOptionDetailModal, setShowOptionDetailModal] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [showUpgrade, setShowUpgrade] = useState(false);
    const [deleteLicense, setDeleteLicense] = useState(null);
    const [IsLoadingLs, setIsLoadingLs] = useState(false);

  useEffect(() => {
    getLicense();
  }, []);

  const getLicense = () => {
    axios
      .get(
        process.env.REACT_APP_LIC_API_BASE_URL +
          "/Licenses/" +
          licenseId
      )
      .then((response) => {
        setLicense(response.data);
        setLoading(false);
        
        response.data.licenseOptionDatas.forEach((op) => {
          setVmAndAzure(op.item, op.value);
        });
      });
  }

  const setVmAndAzure = (item, value) => {
    if (
      item === null ||
      item === undefined ||
      value === null ||
      value === undefined
    ) {
      return;
    }
    if (item.toLowerCase() === "vmware") {
      setIsVm(value.toLowerCase() === "on" ? true : false);
    }

    if (item.toLowerCase() === "licensetype") {
      setIsAzure(value.toLowerCase() === "azure byol" ? true : false)
    }
  }

  const handleAddLicenseOption = () => {
    handleLiceseOptionDetailModal();
  }

  const handleUpgradeLicense = () => {
    setShowUpgrade(!showUpgrade);
  }

  const handleLiceseOptionDetailModal = () => {
    setShowOptionDetailModal(!showOptionDetailModal);
    getLicense();
  }

  const handleDeleteLicenseOptionData = (opt) => {
    axios
      .delete(
        process.env.REACT_APP_LIC_API_BASE_URL +
          "/Licenses/" +
          license.id +
          "/LicenseOptionDatas/" +
          opt.id
      )
      .then((response) => {
        getLicense();
      });
  }

  const handleDownloadLicense = () => {
    setIsLoadingLs(true);
    axios
      .get(
        process.env.REACT_APP_LIC_API_BASE_URL +
          "/LicenseText/Licenses/" +
          license.id
      )
      .then((response) => {
        const yourDate = new Date();
        var datastr = yourDate.toISOString().split("T")[0];
        var fileName = license.serialNumber + "_" + datastr + ".lic";

        const type = response.headers["content-type"];
        const blob = new Blob([response.data], {
          type: type,
          encoding: "UTF-8",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
        setIsLoadingLs(false);
      });
  }

  const handleDeleteLicense = () => {
    setShowDelete(!showDelete);
    setDeleteLicense(deleteLicense == null ? license : null)
  }

  const getMacAddres = (license) => {
    if(license.macAddresses == null || license.macAddresses[0] == null){
      return "";
    }

    return license.macAddresses[0].value;
  }

  const showFeatures = () => {
    return (
      <FormGroup>
        <Row>
          {!isVm && (
            <Col sm={4}>
              <FormGroup>
                <FormLabel>Serial Number</FormLabel>
                <FormControl
                  defaultValue={license.serialNumber}
                  disabled={true}
                />
              </FormGroup>
            </Col>
          )}
          <Col sm={4}>
            <FormGroup>
              <FormLabel>Mac Address</FormLabel>
              <FormControl
                defaultValue={license.macAddresses == null?"":license.macAddresses
                  .map((x) => x.value)
                  .join(",")}
                disabled={true}
              />
            </FormGroup>
          </Col>
        </Row>
        {isVm && (
          <Row>
            <Col sm={4}>
              <FormGroup>
                <FormLabel>Instance UUID</FormLabel>
                <FormControl
                  defaultValue={license.instanceUuid}
                  disabled={true}
                />
              </FormGroup>
            </Col>
            <Col sm={4}>
              <FormGroup>
                <FormLabel>CPU ID</FormLabel>
                <FormControl
                  defaultValue={license.cpuId}
                  disabled={true}
                />
              </FormGroup>
            </Col>
          </Row>
        )}
        {isAzure && (
          <Row>
            <Col sm={4}>
              <FormGroup>
                <FormLabel>Resource ID</FormLabel>
                <FormControl
                  defaultValue={license.resourceId}
                  disabled={true}
                />
              </FormGroup>
            </Col>
            <Col sm={4}>
              <FormGroup>
                <FormLabel>License Request Number</FormLabel>
                <FormControl
                  defaultValue={license.licenseRequestNumber}
                  disabled={true}
                />
              </FormGroup>
            </Col>
          </Row>
        )}
      </FormGroup>
    );
  };

    if (loading) {
      return <Spinner />;
    } else {
      return (
        <div>
          <div>
            <Header
              title={
                license.serialNumber +
                " - " +
                getMacAddres(license)
              }
            >
            </Header>
            <h4>{license.sku}</h4>
            {" "}
              <Button
                style={{ display: "inline" }}
                onClick={() => (window.location = "license/licenses")}
              >
                Back
              </Button>
            {IsLoadingLs && <Spinner />}
            {!IsLoadingLs && (
              <div>
                <Button
                  variant="secondary"
                  size="long"
                  style={{ fontSize: "0.8rem" }}
                  onClick={() => handleAddLicenseOption()}
                >
                  <Icon
                    style={{ fill: "#007bff", padding: "3px" }}
                    
                    iconname="AddRed"
                  />
                  Update License Options
                </Button>
                <Button
                  variant="secondary"
                  size="long"
                  style={{ fontSize: "0.8rem" }}
                  onClick={() => handleUpgradeLicense()}
                >
                  <Icon
                    style={{ fill: "#007bff", padding: "3px" }}
                    
                    iconname="AddRed"
                  />
                  Upgrade License
                </Button>
                <Button
                  variant="secondary"
                  size="long"
                  style={{ fontSize: "0.8rem" }}
                  onClick={() => handleDownloadLicense()}
                >
                  <Icon
                    style={{ fill: "#007bff", padding: "2px" }}
                    
                    iconname="Download"
                  />
                  Download License
                </Button>
                <Button
                  variant="secondary"
                  size="long"
                  style={{ fontSize: "0.8rem" }}
                  onClick={() => handleDeleteLicense()}
                >
                  <Icon
                    style={{ fill: "#007bff", padding: "2px" }}
                    
                    iconname="Delete"
                  />
                  Delete License
                </Button>
              </div>
            )}
          </div>
          <hr />
          {showFeatures()}
          <hr />
          <div>
            <List>
              <ListHeader
                checkbox={false}
                style={{
                  gridTemplateColumns:
                    "minmax(0,0.1fr) minmax(0, 1fr) minmax(0, 1fr)",
                  fontWeight: "bold",
                  fontSize: "17",
                }}
              >
                <div></div>
                <div>License Option</div>
                <div>Value</div>
              </ListHeader>
              {license.licenseOptionDatas &&
                license.licenseOptionDatas.map((opt) => {
                  return (
                    <Fragment key={opt.id}>
                      <TableRow
                        checkbox={false}
                        style={{
                          gridTemplateColumns:
                            "minmax(0,0.1fr) minmax(0, 1fr) minmax(0, 1fr)",
                          fontWeight: "bold",
                          fontSize: "17",
                        }}
                        key={opt.id}
                      >
                        <div></div>
                        <div>{opt.productLicenseOption.description}</div>
                        <div>{opt.value}</div>
                        {/* <div style={{ display: "inline-flex" }}>
                          <Button
                            variant="secondary"
                            style={{
                              margin: "5px",
                            }}
                            onClick={(e) =>
                              this.handleDeleteLicenseOptionData(opt)
                            }
                          >
                            <Icon
                              style={{ fill: "#007bff", paddingRight: "5px" }}
                              
                              iconname="Delete"
                            />
                            Delete Option
                          </Button>
                        </div> */}
                      </TableRow>
                    </Fragment>
                  );
                })}
            </List>
          </div>
          <HaivisionLicenseOptionDetailModal
            show={showOptionDetailModal}
            license={license}
            onClose={handleLiceseOptionDetailModal}
            RefreshLicense={getLicense}
          ></HaivisionLicenseOptionDetailModal>
          <HaivisionLicenseDelete
            show={showDelete}
            license={deleteLicense}
            onHide={handleDeleteLicense}
            refresh={getLicense}
          />
          <HaivisionLicenseOptionUpgradeModal
            show={showUpgrade}
            license={license}
            onHide={handleUpgradeLicense}
            refresh={getLicense}
          />
        </div>
      );
    }
  }

export default HaivisionLicenseDetail;
