import React from "react";
import ProvisionTable from "./ProvisionsTable";
import { Row, Col, Container } from "reactstrap";
import JellyHeader from "../../common/Utils/JellyHeader";
import { Header } from "@hai/ui-react";

class Provision extends React.Component {
  constructor(props) {
    super(props);
    console.log("provision env", process.env.REACT_APP_HUB_API_BASE_URL);
  }

  render() {
    if (!sessionStorage.getItem("user.authState")) {
      return <div className="white-text">auth required...</div>;
    } else {
      return (
        <Container className="themed-container" fluid={true}>
            <Header style={{marginBottom: "1%"}} title="Provisions"></Header>

          <ProvisionTable></ProvisionTable>
        </Container>
      );
    }
  }
}

export default Provision;
