import axios from "axios";
import { handleError } from "../components/common/ErrorHandler";
import { getToken, storeToken, removeToken } from "./azure-aad-auth";

// Add a request interceptor
axios.interceptors.request.use(
  (config) => {
    config.headers["Authorization"] = "Bearer " + getToken();
    config.headers["Content-Type"] = "application/json";
    config.headers["Pragma"] = "no-cache";
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

//Add a response interceptor
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    console.log(error);
    //check if error was 401 and try one more time.
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      removeToken();
      await storeToken();
      axios.defaults.headers.common["Authorization"] = "Bearer " + getToken();
      return axios(originalRequest);
    } else if (
      error.response?.status === 403 &&
      error.response?.data?.message?.includes("User is not enabled")
    ) {
      // not good
      window.location.replace("/user-disabled");
    }

    handleError(error.response.data);
    return Promise.reject(error);
  }
);

export default axios;
