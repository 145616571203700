import React, { useEffect, useState } from "react"
import { Button, FormControl, Header, List, ProgressBar } from "@hai/ui-react"
import { FormLabel } from "react-bootstrap";
import axios from "../../infra/axios";
import { Col, Row } from "reactstrap";
import { useParams } from "react-router-dom";
import NavigationButtons from "../common/Utils/NavigationButtons";

const ToriiOrganizations = props => {

    const params = useParams();
    const [organizations, setOrganizations] = useState(null);
    const [loading, setLoading] = useState(true);
    const [expandContent, setExpandContent] = useState(null);
    const [pageData, setPageData] = useState({
        currentPage: params?.pageNum ? parseInt(params?.pageNum) : 1,
        pageSize: 10
    });
    const [visibleData, setVisibleData] = useState(null);
    const [userFilter, setUserFilter] = useState(params?.id);


    useEffect(() => {
        getOrganizations();
    }, []);

    useEffect(() => {
        search();
    }, [organizations])

    const search = () => {
        if (userFilter === '*') {
            setVisibleData(organizations);
        }
        else if (userFilter) {
            var data = organizations?.filter(a => a.id === userFilter || a.name.includes(userFilter));
            setVisibleData(data);
        } 
        else if (!userFilter && !params?.pageNum) {
            setVisibleData(organizations?.slice(0, 10));
            setPageData({...pageData, currentPage: 1});
            window.history.replaceState('','', '/torii/organizations/page/1')
        } else if (params?.pageNum) {
            setVisibleDataFromPageNum(organizations)   
        }
    }
    
    const getOrganizations = () => {
        axios.get(`${process.env.REACT_APP_TORII_API_BASE_URL}/organizations`)
            .then(res => {
                setOrganizations(res.data)
                setVisibleDataFromPageNum(res.data);
                setLoading(false)
            })
            .catch(e => setLoading(false))
    }

    const setVisibleDataFromPageNum = (data) => {
        if (data) {
            let start = params?.pageNum != null ? ((params?.pageNum - 1) * pageData.pageSize) : 0;
            setVisibleData(data.slice(start, pageData.currentPage * pageData.pageSize));
            if (data.length < pageData.currentPage * pageData.pageSize) {
                console.log('invalid page');
                setVisibleData(data.slice(0, 1 * pageData.pageSize));
            }
        }
    }


    return (<>
        <Header title="Torii Origanizations"></Header>
        <br/>
    
        {loading && <ProgressBar leftLabel="Loading: "
            barColors={{
                background: '#599bff',
                right: '#599bff',
                left: '#2fccd3',
            }}
            hideRight={true}
            animate={1} />
        }

        {!loading && 
        <div style={{display: 'flex', alignItems: 'baseline'}}>
            <FormLabel style={{marginRight: '1%'}}>Organization Id/Name</FormLabel>
            <FormControl onChange={(e) => setUserFilter(e.target.value)} defaultValue={params?.id}></FormControl>
            <Button onClick={search}>Search</Button>
        </div> }
            { visibleData && visibleData.map(a => {
            return (

                <List key={a.id}>
                    <List.Panel panelColor={a.enabled ? 'haiui-green-01' : 'haiui-red-01'}>
                        <List.PanelTitle title={a.name} />
                        <List.PanelDetail>
                            <List.PanelDetailItem icon="Information" text={a.id} divider="vertical"></List.PanelDetailItem>
                            <List.PanelDetailItem icon="HubNodes" text={a.alias} divider="vertical"></List.PanelDetailItem>
                        </List.PanelDetail>
                        <List.Actions>
                            <List.ActionItem expandButton eventKey={"showDetails"} icon='Devices' title="Devices"
                                onSelect={(eventKey, expanded) => {
                                    setExpandContent(expanded ? eventKey : '')
                                }} />
                            <List.ActionItem isDivider />
                            <List.ActionItem expandButton eventKey={"navigate"} icon='Export' title="Go"
                                onSelect={(eventKey, expanded) => {
                                    window.open(`${window.location.origin}/torii/organizations/${a.id}/subscriptions`,'_blank')
                                }} />
                        </List.Actions>
                        <List.ExpandedPanel>
                            <div>
                                {expandContent == 'showDetails' &&
                                    <Row>
                                        <Col>
                                            <FormLabel>Description</FormLabel>
                                            <FormControl disabled={true} defaultValue={a.description}/>

                                            <FormLabel>Tier</FormLabel>
                                            <FormControl disabled={true} defaultValue={a.tier} />
                                        </Col>

                                        <Col>
                                            <FormLabel>Currency</FormLabel>
                                            <FormControl disabled={true} defaultValue={a.currency}/>

                                            <FormLabel>Domain</FormLabel>
                                            <FormControl disabled={true} defaultValue={a.domain}/>
                                        </Col>

                                    </Row>}
                            </div>
                        </List.ExpandedPanel>
                    </List.Panel>
                </List>
            )
        })
        }

        {organizations &&
             <NavigationButtons
             baseUrl="/torii/organizations"
             setPageData={setPageData}
             pageData={pageData}
             setVisibleData={setVisibleData}
             visibleData={visibleData}
             data={organizations}
         ></NavigationButtons>
        }
    </>);
}

export default ToriiOrganizations;