import { Button, NotFound , ProgressBar } from "@hai/ui-react";
import React, { Component } from "react";
import SharkNotFound from "../../../static/404_shark.png";

class NotFound_Shark extends Component {
  render() {
    return (
      <NotFound
        backgroundImage={SharkNotFound}
        pageTitle="404 Not Found"
        subTitle="HTTP 404 - Page Not Found"
        notFoundMessage="The page you are looking for might have been removed, may be temporarily unavailable or was possibly eaten by a shark."
        homeButton={
          <Button variant="primary" onClick={() => (window.location = "/")}>
            GO BACK TO HOME
          </Button>
        }
      />
    );
  }
}

export default NotFound_Shark;
