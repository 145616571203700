import React from 'react';
import JellyfishLogo from '../static/jellyfish.svg';
import { authProvider } from "../infra/authProvider";
import { DynamicContainer, Icon, Login } from '@hai/ui-react';
import { ScreenInfoContext } from '@hai/ui-react/dist/screen-info-provider/ScreenInfoProvider';
import backgroundImage from '../static/jellyfishbg.jpeg';

const JellyfishLogin = () => {
  const { mobileMode } = React.useContext(ScreenInfoContext)
  const bgColor = `rgba(33, 36, 56, 0.6)`;
    const responsiveProps = mobileMode
    ? {
        containerHeight: '550px',
        logoSize: { height: '100px', width: '150px' },
      }
    : {
        containerHeight: '810px',
        logoSize: { height: '120px', width: '200px' },
      }

    return (  <div  style={{ backgroundImage: `url(${backgroundImage})`, backgroundRepeat: "repeat"}}>
      <DynamicContainer responsive vGap={12}>

      
    <div style={{ height: responsiveProps.containerHeight }}>
        <Login
        accentColor="#5a9bff"
        appLogoSrc={JellyfishLogo}
        appLogoStyle={responsiveProps.logoSize}

        productName="Jellyfish"
        useFullPageContainer={true}
        style={{backgroundColor: bgColor}}
      >    
        {/* Provide any custom content using Login.Content & Login.IconButton */}
        <Login.Header>
          <Icon src={JellyfishLogo} />
        </Login.Header>
        <Login.Content>
          <div style={{display: "flex", flexDirection: "column", gap: "16px", width: "100%"}}>
            <Login.IconButton
              onClick={authProvider.login}
              iconname="Microsoft"
              title="Haivision SSO"
              subtitle="Corporate"
            />        
          <p style={{
            fontSize: '10px',
            lineHeight: 'normal',
            color: '#868692'
          }}>
          This service currently has limited availability.  You will not be
          able to sign in if you haven’t already contacted IT to set up your permissions.
        </p>
            </div>
        </Login.Content>
      </Login>
    </div>
    </DynamicContainer>
    </div>);

}

export default JellyfishLogin;