import React, { useEffect, useState, version } from "react";
import { Col, Row, Spinner, Collapse, Card, CardFooter } from "reactstrap";
import axios from "../../../infra/axios";
import Select from "react-select";
import {
  Button,
  Animation,
  FormControl,
  FormGroup,
  FormLabel,
  List,
  ListHeader,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Switch,
  TableRow,
  Notification,
 ProgressBar } from "@hai/ui-react";
import RoleModal from "../../haivision-hub/role/RoleModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import AssetsTable from "../../haivision-hub/contracts/AssetsTable";
import SubscriptionFeatureSection from "./SubscriptionFeatureSection";
import HubDevicePairingCode from "../ems/HubDevicePairingCode";
import { useParams } from "react-router-dom";

const SubscriptionPage = (props) => {
  const [subscription, setSubscription] = useState(null);
  const [loadingSub, setLoadingSub] = useState(true);
  const [users, setUsers] = useState(null);
  const [showRoleModal, setShowRoleModal] = useState(false);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [userIdtoAdd, setUserIdtoAdd] = useState(null);
  const [userRoletoAdd, setUserRoletoAdd] = useState(null);
  const [allUsers, setAllUsers] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [userLoading, setUserLoading] = useState(false);

  //contract
  const [contract, setContract] = useState(null);
  const [loadingContract, setLoadingContract] = useState(true);
  //users
  const [collapseUsers, setCollapseUsers] = useState(false);
  const [showDevicePairCode, setShowDevicePairCode] = useState(false);
  var params = useParams();

  useEffect(() => {
    let subId = params.subId;
    let orgId = params.orgId;
    (async function () {
      await querySubscription(subId, orgId);
      await getUsers(subId, orgId);
      await getSFAssets(subId);
    })();
  }, []);

  const querySubscription = async (subId, orgId) => {
    setLoadingSub(true);
    await axios
      .get(
        process.env.REACT_APP_HUB_API_BASE_URL +
          `/SrtHub/organizations/${orgId}/subscriptions/${subId}`
      )
      .then((response) => {
        setSubscription(response.data);
        setLoadingSub(false);
      })
      .catch((error) => {
        setLoadingSub(false);
      });
  };

  const getSFAssets = async (subId) => {
    await axios
      .get(
        process.env.REACT_APP_HUB_API_BASE_URL +
          `/HubServiceContract/HubSubscription/${subId}`
      )
      .then(async (response) => {
        await axios
          .get(
            process.env.REACT_APP_HUB_API_BASE_URL +
              `/HubServiceContract/${response.data.id}/HubAssets`
          )
          .then((response_asset) => {
            response.data.assets = response_asset.data;
            setContract(response.data);
            setLoadingContract(false);
          });
      })
      .catch((err) => setLoadingContract(false));
  };

  const getUsers = async (subId, orgId) => {
    await axios
      .get(
        process.env.REACT_APP_HUB_API_BASE_URL +
          `/SrtHub/organizations/${orgId}/subscriptions/${subId}/users`
      )
      .then((response) => {
        setUsers(response.data);
      });
  };

  const hideRoleModal = () => {
    setShowRoleModal(false);
  };

  const getAllUsers = async () => {
    await axios
      .get(process.env.REACT_APP_HUB_API_BASE_URL + `/Support/hub/users`)
      .then((response) => {
        setAllUsers(
          response.data.map((u) => ({ label: u.userName, value: u.id }))
        );
      });
  };

  const addUserToSub = async (subscriptionId, userId, roleId) => {
    await axios
      .put(
        process.env.REACT_APP_HUB_API_BASE_URL +
          `/Support/subscriptions/${subscriptionId}/users/${userId}?roleId=${roleId}`
      )
      .then((response) => {
        setShowAddUserModal(false);
        setShowNotification(true);
        if (response.status == 200) {
          let subId = params.subId;
          let orgId = params.orgId;
          getUsers(subId, orgId);
        }
      })
      .then((response) => {
        setUserLoading(false);
      });
  };

  const removeUserFromSub = async (subscriptionId, userId) => {
    await axios
      .delete(
        process.env.REACT_APP_HUB_API_BASE_URL +
          `/Support/subscriptions/${subscriptionId}/users/${userId}`
      )
      .then((response) => {
        if (response.status == 200) {
          setShowNotification(true);
          let subId = params.subId;
          let orgId = params.orgId;
          getUsers(subId, orgId);
        }
      })
      .then((response) => {
        setUserLoading(false);
      });
  };

  const goToRoutePage = (subscriptionId) => {
    window.location = "/hub/subscriptions/" + subscriptionId + "/routes/";
  };

  return (
    <div>
      {loadingSub && <ProgressBar
  leftLabel="Loading: "
  barColors={{
    background: '#599bff',
    right: '#599bff',
    left: '#2fccd3',
  }}
  hideRight={true}
  animate={1}
/>}
      {!loadingSub && subscription && (
        <div>
          {/* Sub informations */}
          <Row>
            <Col>
              <h4 style={{ color: "#007bff" }}>Subscription</h4>
            </Col>
          </Row>
          <Row>
            <Col xl="12" md="12">
              <h5>
                {" "}
                {subscription.subscriptionName} - {subscription.subscriptionId}
              </h5>
            </Col>
            <Col>
              <small>{subscription.description}</small>
            </Col>
            <Col>
              <Button
                onClick={() => goToRoutePage(subscription.subscriptionId)}
              >
                View Routes
              </Button>
              <Button
                onClick={() => setShowDevicePairCode(true)}
              >
                Device pair code
              </Button>
            </Col>
          </Row>
          <hr />
          <SubscriptionFeatureSection
            subscriptionId={subscription.subscriptionId}
          />

          {/* Assets */}
          <Row>
            <Col>
              <h4 style={{ color: "#007bff" }}>Salesforce Assets</h4>
            </Col>
          </Row>

          <Row>
            {loadingContract && (
              <Col md="6">
                <ProgressBar
  leftLabel="Loading: "
  barColors={{
    background: '#599bff',
    right: '#599bff',
    left: '#2fccd3',
  }}
  hideRight={true}
  animate={1}
/>
              </Col>
            )}
            {!loadingContract && !contract && (
              <Col>
                <p>No asset found</p>
              </Col>
            )}
            {contract && (
              <Col md="12">
                <AssetsTable
                  contract={contract}
                  specificSub={params.subId}
                ></AssetsTable>
              </Col>
            )}
          </Row>
          <hr />

          {/* Users */}
          <Row>
            <Col>
              <h4
                style={{ color: "#007bff" }}
                onClick={() => setCollapseUsers(!collapseUsers)}
              >
                Users&nbsp;
                <FontAwesomeIcon
                  icon={collapseUsers ? faAngleUp : faAngleDown}
                ></FontAwesomeIcon>
              </h4>
            </Col>
            <Col>
              <Button onClick={() => setShowRoleModal(true)}>
                Invite user
              </Button>
              <Button
                onClick={() => {
                  getAllUsers();
                  setShowAddUserModal(true);
                }}
              >
                Add User
              </Button>
            </Col>
          </Row>
          <br />
          <Collapse isOpen={collapseUsers}>
            <Row>
              {userLoading && (
                <Col md="6">
                  <ProgressBar
  leftLabel="Loading: "
  barColors={{
    background: '#599bff',
    right: '#599bff',
    left: '#2fccd3',
  }}
  hideRight={true}
  animate={1}
/>
                </Col>
              )}
              {!userLoading && (
                <Col>
                  {users && (
                    <List>
                      <ListHeader
checkbox={false}
                        style={{
                          gridTemplateColumns: "0.1fr 2fr 2fr 2fr 2fr ",
                        }}
                      >
                        <div></div>
                        <div>Username</div>
                        <div>User Id</div>
                        <div>User role</div>
                        <div></div>
                      </ListHeader>
                      {users.map((u, index) => {
                        return (
                          <TableRow
checkbox={false}
                            key={index}
                            style={{
                              gridTemplateColumns: "0.1fr 2fr 2fr 2fr 2fr  ",
                              color: "white",
                            }}
                          >
                            <div></div>
                            <div>{u.userName}</div>
                            <div>{u.id}</div>
                            <div>{u.role.description}</div>
                            <div>
                              <Button
                                onClick={() => {
                                  setUserLoading(true);
                                  removeUserFromSub(
                                    params.subId,
                                    u.id
                                  );
                                }}
                              >
                                Remove
                              </Button>
                            </div>
                          </TableRow>
                        );
                      })}
                    </List>
                  )}
                </Col>
              )}
              
              <Modal
              show={showDevicePairCode}
              size={"md"}
              onHide={() => setShowDevicePairCode(false)}>
                <ModalHeader style={{color: "white"}}>Hub device pairing code</ModalHeader>
                <ModalBody>
                  <HubDevicePairingCode hubSubId={params.orgId}></HubDevicePairingCode>
                </ModalBody>
                <ModalFooter>
                  <Button onClick={() => setShowDevicePairCode(false)}>OK</Button>
                </ModalFooter>

              </Modal>

              {showRoleModal && (
                <RoleModal
                  show={showRoleModal}
                  onHide={hideRoleModal}
                  organizationId={params.orgId}
                  subscriptionId={params.subId}
                ></RoleModal>
              )}
              {showAddUserModal && (
                <Modal
                  show={showAddUserModal}
                  onHide={() => {
                    setUserIdtoAdd(null);
                    setUserRoletoAdd(null);
                    setShowAddUserModal(false);
                  }}
                >
                  <ModalHeader style={{ color: "white" }}>
                    Add User to Sub
                  </ModalHeader>
                  <ModalBody>
                    <FormGroup>
                      <FormLabel>User</FormLabel>
                      <Select
                        isSearchable
                        options={allUsers}
                        onChange={(value) => {
                          setUserIdtoAdd(value);
                        }}
                        onClick={() => {
                          getAllUsers();
                        }}
                      ></Select>
                    </FormGroup>
                    <FormGroup>
                      <FormLabel>Role</FormLabel>
                      <Select
                        options={[
                          { label: "admin", value: "admin" },
                          { label: "operator", value: "operator" },
                          { label: "viewer", value: "viewer" },
                        ]}
                        onChange={(value) => {
                          setUserRoletoAdd(value);
                        }}
                      ></Select>
                    </FormGroup>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      variant="primary"
                      onClick={() => {
                        setUserLoading(true);
                        addUserToSub(
                         params.subId,
                          userIdtoAdd.value,
                          userRoletoAdd.value
                        );
                      }}
                    >
                      Add
                    </Button>
                    <Button onClick={() => setShowAddUserModal(false)}>
                      Cancel
                    </Button>
                  </ModalFooter>
                </Modal>
              )}
              {showNotification && (
                <Notification
                  show={showNotification}
                  style={{ top: "0", right: "1rem" }}
                  message="Update Completed"
                  onDismiss={() => setShowNotification(false)}
                ></Notification>
              )}
            </Row>
          </Collapse>
        </div>
      )}
    </div>
  );
};

export default SubscriptionPage;
