import React, { Component, useEffect, useState } from "react";
import axios from "../../../infra/axios";
import {
  Header,
  Icon,
  List,
  ListHeader,
  TableRow,
  Button,
  FormGroup,
  FormControl,
  FormLabel,
  OverlayTrigger,
  Modal,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
  SelectOption,
 ProgressBar } from "@hai/ui-react";
import { Fragment } from "react";
import * as sortHelper from '../sortHelper';

import MakitoDefaultProductLicenseOptionUpdate from "./haivision-default-product-license-option-update";
import { useParams } from "react-router-dom";

const HaivisionProductDetail = (props) => {
  let {productIdParam} = useParams();
  const [productId, setProductId] = useState(props.productId || productIdParam);
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState(null);
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [showNoVersionWarnning, setshowNoVersionWarnning] = useState(false);
  const [showProductVersionAdd, setShowProductVersionAdd] = useState(false);
  const [showProductLicenseOptionAdd, setShowProductLicenseOptionAdd] = useState(false);
  const [showProductLicenseOptionUpdate, setShowProductLicenseOptionUpdate] = useState(false);
  const [selectedDefaultLicenseOption, setSelectedDefaultLicenseOption] = useState(null);
  const [updateProductLicenseOption, setUpdateProductLicenseOption] = useState(null);

 useEffect(() =>{
    getProduct();
  },[]);

  const getProduct = () => {
    axios
      .get(
        process.env.REACT_APP_LIC_API_BASE_URL +
          "/Products/" +
          productId
      )
      .then((response) => {
        response.data.productType.productVersions = response.data.productType.productVersions.filter((x)=> {return x.active});
        setProduct(sortByVersionDesc(response.data)); // check if sort worked.
        setSelectedVersion(response.data.productType.productVersions[0]);
        setshowNoVersionWarnning(response.data.productType.productVersions.length === 0);
        setLoading(false);
      });
  }

  const sortByVersionDesc = (product) => {
    if (product?.productType?.productVersions?.length >= 1) {
      sortHelper.sortByVersionDesc(product.productType.productVersions);
    }
    return product;
  };

  const handleProductLicenseOptionUpdate = (opt) => {
    setShowProductLicenseOptionUpdate(!showProductLicenseOptionUpdate);
    setUpdateProductLicenseOption(opt);
    setSelectedDefaultLicenseOption(product?.productDefaultOptions.find(
      (x) => x.productLicenseOptionId === opt?.id));
  }

  const handleDelete = (id) => {
    let defaultLicenseOption = product?.productDefaultOptions.find(
      (x) => x.productLicenseOptionId == id
    );
    axios
      .delete(
        process.env.REACT_APP_LIC_API_BASE_URL +
          "/Products/ProductDefaultOptions/" +
          defaultLicenseOption.id
      )
      .then((response) => {
        getProduct();
      });
  }

  const getDefaultLicenseOptionDate = (opt) => {
    let defaultLicenseOption = product?.productDefaultOptions.find(
      (x) => x.productLicenseOptionId == opt.id
    );
    return defaultLicenseOption == null
      ? ""
      : defaultLicenseOption.defaultValue;
  }

    if (loading) {
      return <ProgressBar
        leftLabel="Loading: "
        barColors={{
          background: '#599bff',
          right: '#599bff',
          left: '#2fccd3',
        }}
        hideRight={true}
        animate={1}
/>;
    } else {
      return (
        <div>
          <div>
            <Header 
             title={product.sku}/>
            <Button
                style={{ display: "inline" }}
                onClick={() => (window.location = "license/products  ")}
              >
                Back
              </Button>
          </div>
          <hr />
          <div style={{ display: "inline-flex" }}>
            <FormGroup>
              <FormLabel>Current Version</FormLabel>
              <FormControl
                as="select"
                style={{ width: "380px" }}
                defaultValue={
                  selectedVersion?.majorVersion +
                  "." +
                  selectedVersion?.minorVersion +
                  "." +
                  selectedVersion?.patchVersion
                }
                onChange={(e) => {
                  var text = e.target.value;
                  setSelectedVersion(product.productType.productVersions.filter(
                    (x) =>
                      x.majorVersion +
                        "." +
                        x.minorVersion +
                        "." +
                        x.patchVersion ===
                      text
                  )[0]);
                }}
              >
                {product.productType.productVersions &&
                  product?.productType?.productVersions.map(
                    (ver) => {
                      return (
                        <SelectOption key={ver.id} value={`${ver.majorVersion}.${ver.minorVersion}.${ver.patchVersion}`}>
                          {" "}
                          {ver.majorVersion}.{ver.minorVersion}.
                          {ver.patchVersion}
                        </SelectOption>
                      );
                    }
                  )}
              </FormControl>
            </FormGroup>
          </div>
          <hr />
          <List>
            <ListHeader
checkbox={false}
              alternateRowColors
              style={{
                gridTemplateColumns: "1fr 1fr 2fr 2fr 2fr 2fr 1fr 0.5fr",
              }}
            >
              <div>Section</div>
              <div>Item</div>
              <div>Value Name</div>
              <div>Available Values</div>
              <div>Default Value</div>
              <div>Description</div>
              <div>Actions</div>
            </ListHeader>
            {product &&
              selectedVersion?.productLicenseOptions?.map((opt) => {
                if(opt.active){
                return (
                  <Fragment key={opt.id}>
                    <TableRow
                      checkbox={false}
                      style={{
                        gridTemplateColumns:
                          "1fr 1fr 2fr 2fr 2fr 2fr 1fr 0.5fr",
                      }}
                    >
                      <div>{opt.licenseSection}</div>
                      <div>{opt.licenseSectionItem}</div>
                      <div>{opt.valueName}</div>
                      <div>{opt.availableValues}</div>
                        <b>{getDefaultLicenseOptionDate(opt)}</b>
                      <div>{opt.description}</div>
                      <div style={{ display: "inline-flex" }}>
                        <OverlayTrigger
                          popoverContent={"Update Default Value"}
                          style={{ cursor: "pointer", paddingRight: "10px" }}
                          onClick={() => handleProductLicenseOptionUpdate(opt)
                          }
                        >
                          <Icon
                            
                            iconname="View"
                            style={{ fill: "#007bff" }}
                          />
                        </OverlayTrigger>
                        <OverlayTrigger
                          popoverContent={"Disable"}
                          onClick={() => handleDelete(opt.id)}
                          style={{ cursor: "pointer", paddingRight: "10px" }}
                        >
                          <Icon
                            
                            iconname="TrashCan"
                            style={{ fill: "#dc3545" }}
                          />
                        </OverlayTrigger>
                      </div>
                    </TableRow>
                  </Fragment>
                );
                        }
              })}
          </List>

          <Modal show={showNoVersionWarnning} size="md" centered>
            <ModalHeader>
              <ModalTitle></ModalTitle>
            </ModalHeader>
            <ModalBody>No Product Version Found</ModalBody>
            <ModalFooter>
              <Button
                onClick={() => {
                  setshowNoVersionWarnning(false);
                }}
              >
                Close
              </Button>
            </ModalFooter>
          </Modal>
          <MakitoDefaultProductLicenseOptionUpdate
            show={showProductLicenseOptionUpdate}
            handleProductLicenseOptionUpdate={handleProductLicenseOptionUpdate}
            getProduct={getProduct}
            productLicenseOption={updateProductLicenseOption}
            productId={productId}
            selectedDefaultLicenseOption={
              selectedDefaultLicenseOption
            }
          />
        </div>
      );
    }
}

export default HaivisionProductDetail;
