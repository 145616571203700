import { faRecycle, faRedo, faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  FormControl,
  FormGroup,
  FormLabel,
  Animation,
  TabItem,
  Tabs,
 ProgressBar } from "@hai/ui-react";
import cogoToast from "cogo-toast";
import React, { useEffect, useState } from "react";
import {
  Col,
  Collapse,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import axios from "../../../infra/axios";
import JellyHeader from "../../common/Utils/JellyHeader";
import HubRoute from "../route/route";
import ClearModal from "./ClearModal";
import TopologyDetails from "./TopologyDetails";
import { useNavigate, useParams } from "react-router-dom";

const TopologySupport = () => {
  const [loading, setLoading] = useState(false);
  const [loadingTopology, setLoadingTopology] = useState(false);
  const [routes, setRoutes] = useState(null);
  const [form, setForm] = useState({topologyId: null, routeId: null, subscriptionId: null});
  const [route, setRoute] = useState(null);
  const [topologies, setTopologies] = useState([]);
  const [azure, setAzure] = useState(null);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [clearModalOpen, setClearModalOpen] = useState(false);
  const [searchType, setSearchType] = useState("byroute");
  const [refreshing, setRefreshing] = useState(false);
  const [showRoutesBySub, setShowRoutesBySub] = useState(false);
  const routeParams = useParams();
  const navigate = useNavigate();

  const toggleForm = () => setIsFormOpen(!isFormOpen);

  useEffect(() => {
    var formTemp = { ...form };
    if (routeParams) {
      formTemp.topologyId = routeParams.topologyId;
      formTemp.routeId = routeParams.routeId;
      formTemp.subscriptionId = routeParams.subscriptionId;
      setForm(formTemp);
        if (formTemp.topologyId) {
          setSearchType("bytopology");
        } else if (formTemp.routeId) {
          setSearchType("byroute");
        } else if (formTemp.subscriptionId) {
          setSearchType("bysubscription");
        }
        // search();
      }
  }, []);

  const handleInputChange = (event) => {
    if (event != null) {
      const target = event.target;
      const value = target.type === "checkbox" ? target.checked : target.value;
      const name = target.name;
      var tempForm = { ...form };
      tempForm[name] = value;
      setForm(tempForm);
    }
  };

  const getTopologiesById = async (topologies) => {
    if (topologies?.length > 0) {
      setLoading(true);
      setLoadingTopology(true);

      await axios
        .put(
          process.env.REACT_APP_HUB_API_BASE_URL + `/Support/topologies`,
          topologies
        )
        .then((response) => {
          let newArray = topologies;
          response.data
            .map((item) => item.topology)
            .forEach((topo) => newArray.push(topo));
          setRoute(response.data[0]?.route);
          setAzure(response.data[0]?.azureSubscription);
          setTopologies(newArray);
          setIsFormOpen(true);
          setLoading(false)
          setLoadingTopology(false);
        })
        .catch((err) => {
          setLoadingTopology(false);
          setLoading(false);
        });
    }
  }

  const search = async() => {
    if (
      topologies === null ||
      topologies.length === 0 ||
      searchType === "bysubscription"
    ) {
      await searchRequest();
    } else {
      setClearModalOpen(true);
    }
  }

  const searchRequest = async() => {
    if (validateForm()) {
      setRefreshing(true);
      setLoading(true);
      switch (searchType) {
        case "bytopology":
          setShowRoutesBySub(false);
          navigate(
            `/hub/support/routes/topology/${form.topologyId}`
          );
          await getTopologiesById([form.topologyId]);
          break;

        case "byroute":
          setShowRoutesBySub(false);
          navigate(
            `/hub/support/routes/route/${form.routeId}`
          );
          await searchByRouteId(form.routeId);
          break;

        case "bysubscription":
          setShowRoutesBySub(true);
          navigate(
            `/hub/support/routes/subscription/${form.subscriptionId}`
          );
          break;
      }
      setRefreshing(false);
      setLoading(false);
    }
  }

  const validateForm = () => {
    var valid = true;
    if (
      (searchType === "byroute" && !form.routeId) ||
      (searchType === "bytopology" && !form.topologyId) ||
      (searchType === "bysubscription" &&
        !form.subscriptionId)
    ) {
      cogoToast.warn("Please fill the field to search", {
        position: "top-right",
      });
      return false;
    }

    return valid;
  }

  const clearSearch = () => {
    setTopologies([]);
    setAzure(null);
    setRoute(null);
    setClearModalOpen(false);
    searchRequest();
  }

  const closeClearModalNo = () => {
    setClearModalOpen(false);
    searchRequest();
  }

  const searchByRouteId = async (routeId) => {
    setLoading(true);
    await axios
      .get(
        process.env.REACT_APP_HUB_API_BASE_URL +
          `/Support/topologies/routes/${routeId}`
      )
      .then(async (response) => {
        if (response.data.topologies && response.data.topologies.length > 0) {
          await getTopologiesById(response.data.topologies.map((t) => t.id));
          setLoading(false);
        } else {
          cogoToast.info("No topology was found for this route", {
            position: "top-right",
          });
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  const searchBySubId = () => {
    this.setState({ loading: true });
    axios
      .get(
        process.env.REACT_APP_HUB_API_BASE_URL +
          `/Support/subscriptions/${form.subscriptionId}/hublets/routes`
      )
      .then((response) => {
        setLoading(false);
        setRoutes(response.data);
      });
  }

  const formRender = () => {
    return (
      <div>
        <ClearModal
          show={clearModalOpen}
          clearResult={clearSearch}
          close={closeClearModalNo}
        ></ClearModal>

        <Row style={{ marginBottom: "1%" }}>
          <Col md="1">
            <h4 style={{ color: "#007bff" }} id="searchText">
              Search{" "}
            </h4>
          </Col>
          <Col md="3">
            <Tabs
              onSelect={(e) => {
                const { eventkey } = e.target;
                setSearchType(eventkey);}}
              selectedTab={searchType}
            >
              <TabItem caption="By route" eventkey="byroute"></TabItem>
              <TabItem caption="By topology" eventkey="bytopology"></TabItem>
              <TabItem
                caption="By subscription"
                eventkey="bysubscription"
              ></TabItem>
            </Tabs>
          </Col>

          <UncontrolledTooltip placement="right" target="searchText">
            Fill topology to direct search or fill the route id to search all
            topologies in it.
          </UncontrolledTooltip>
        </Row>

        <Row>
          <Col hidden={searchType !== "byroute"}>
            <FormGroup>
              <FormLabel required style={{ color: "#007bff" }}>
                {" "}
                by Route Id
              </FormLabel>
              <FormControl
                placeholder="abcdefg-hijk-lmnop-868d"
                name="routeId"
                defaultValue={form.routeId}
                onChange={handleInputChange}
              />
            </FormGroup>
          </Col>

          <Col hidden={searchType !== "bytopology"}>
            <FormGroup>
              <FormLabel required style={{ color: "#007bff" }}>
                by Topology Id
              </FormLabel>
              <FormControl
                placeholder="abcdefg-hijk-lmnop-868d"
                name="topologyId"
                defaultValue={form.topologyId}
                onChange={handleInputChange}
              />
            </FormGroup>
          </Col>

          <Col hidden={searchType !== "bysubscription"}>
            <FormGroup>
              <FormLabel required style={{ color: "#007bff" }}>
                by Subscription Id
              </FormLabel>
              <FormControl
                placeholder="abcdefg-hijk-lmnop-868d"
                name="subscriptionId"
                defaultValue={form.subscriptionId}
                onChange={handleInputChange}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col sm="12" className="clear-fix">
            <Button
              onClick={() => search()}
              className="float-right"
              disabled={loading}
            >
              Search <FontAwesomeIcon icon={faSearch} className="mr-2" />
            </Button>
          </Col>
        </Row>
      </div>
    );
  }

    if (!sessionStorage.getItem("user.authState")) {
      return <div className="white-text">Auth required...</div>;
    } else {
      return (
        <Container className="themed-container" fluid={true}>
          <Row
            style={{ alignItems: "baseline", justifyContent: "space-between" }}
          >
            <JellyHeader title="Support - Routes"></JellyHeader>
            <div>
              <Button
                className="float-right"
                color="primary"
                onClick={toggleForm}
                style={{ marginBottom: "1rem" }}
              >
                Toggle Form
              </Button>

              <Button
                disabled={refreshing}
                color="primary"
                style={{ marginBottom: "1rem" }}
                onClick={() => {
                  setTopologies([])
                  searchRequest();
                }}
              >
                Refresh{" "}
                <FontAwesomeIcon
                  icon={faRedo}
                  spin={refreshing}
                ></FontAwesomeIcon>
              </Button>
            </div>
          </Row>

          <hr />

          <Collapse isOpen={!isFormOpen}>{formRender()}</Collapse>

          {loading && (
            <Row>
              <ProgressBar
              leftLabel="Loading: "
              barColors={{
                background: '#599bff',
                right: '#599bff',
                left: '#2fccd3',
              }}
              hideRight={true}
              animate={1}
            />
            </Row>
          )}

          {route != null &&
            !loadingTopology &&
            !showRoutesBySub && (
              <TopologyDetails
                route={route}
                topologies={topologies}
                azure={azure}
              ></TopologyDetails>
            )}

          {showRoutesBySub && (
            <HubRoute
              subscriptionId={form.subscriptionId}
            ></HubRoute>
          )}
        </Container>
      );
    }
}

export default TopologySupport;
