import {
  faCheckCircle,
  faMinusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  TableRow,
  List,
  ListHeader,
  Button,
  Notification,
  Dialog,
 ProgressBar } from "@hai/ui-react";
import React, { Component } from "react";
import { Col, Row, Spinner, Collapse } from "reactstrap";
import axios from "../../../infra/axios";
import {
  enableBySubHttp,
  disableBySubHttp,
} from "../feature-flags/FeatureFlagService";
import FeatureFlagCard from "./FeatureFlagCard";

class SubscriptionFeatureSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingData: true,
      subscriptionFeatureFlags: null,
      subscriptionId: this.props.subscriptionId,
      flagListOpen: false,

      orchestratorV2Enabled: false,
      akamaiPresetEnabled: false,
      rttPresetEnabled: false,
      hubApplianceManagerEnabled: false,
      cloudRoutingEnabled: false,
      zannaHubletEnabled: false,
      hubnetTrailEnabled: false,

      switchingFF: false,
      showNotification: false,
      showFFDialog: false,
      dialogTitle: "",
      clickedFeature: null,
      showContractMessage: false,
      loadingRequirement: false,
      directSwitch: false,

      syncingFeatures: false
    };
  }

  componentDidMount() {
    this.setState((prevState) => {
      prevState.loadingData = true;
      return prevState;
    });
    this.loadFeatures();

    this.setState((prevState) => {
      prevState.loadingData = false;
      return prevState;
    });
  }

  loadFeatures() {
    this.loadFeatureFlags(this.state.subscriptionId).then(() => {
      this.checkFeatures();
    });
  }

  handleClose() {
    this.setState({ showFFDialog: false, directSwitch: false });
  }

  async loadFeatureFlags() {
    this.setState({ loadingRequirement: true });
    await axios
      .get(
        process.env.REACT_APP_HUB_API_BASE_URL +
          `/Support/subscriptions/${this.state.subscriptionId}/featureflags`,
        {
          headers: {
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
            Expires: "0",
          },
        }
      )
      .then((response) => {
        this.setState((prev) => {
          prev.subscriptionFeatureFlags = response.data;
          prev.loadingRequirement = false;
          return prev;
        });
      })
      .catch((err) => {
        this.setState({ loadingRequirement: false });
      });
  }

  handleFlagListToggle() {
    this.setState((prevState) => {
      prevState.flagListOpen = !prevState.flagListOpen;
      return prevState;
    });
  }

  async syncFeaturesWithSalesforce() {
    if (this.state.subscriptionId != null) {
      this.setState({syncingFeatures: true});
      axios.post( process.env.REACT_APP_HUB_API_BASE_URL + `/Operations/features/sync-salesforce/subscriptions/${this.state.subscriptionId}`)
      .then(r => this.setState({syncingFeatures: false}));   
    }
  } 

  checkFeatures() {
    // v2 with nodeip
    let orchestratorActive = this.state.subscriptionFeatureFlags.find(
      (f) => f.id === "orchestrator-v2-messages-ff"
    )?.flag;
    let infrastructureActive = this.state.subscriptionFeatureFlags.find(
      (f) => f.id === "infrastructure-hublets:require-2-or-more-outputs"
    )?.flag;
    let nodeIp =
      this.state.subscriptionFeatureFlags.find(
        (f) => f.id === "use-node-public-ip-flow-ff"
      )?.flag ?? false;

    // hubappliance
    let edge_nodes_ff = this.state.subscriptionFeatureFlags.find(
      (f) => f.id === "edge-nodes-ff"
    )?.flag;
    let makito_input_hublet_ff = this.state.subscriptionFeatureFlags.find(
      (f) => f.id === "dr-ems-makito-input-hublet-ff"
    )?.flag;
    let makito_output_hublet_ff = this.state.subscriptionFeatureFlags.find(
      (f) => f.id === "dr-ems-makito-output-hublet-ff"
    )?.flag;
    let makito_preset_creator = this.state.subscriptionFeatureFlags.find(
      (f) => f.id === "ems.hub.haivision.com:create-makito-preset"
    )?.flag;

    // akamai
    let akamaiActive =
      this.state.subscriptionFeatureFlags.find(
        (f) => f.id === "femto.hub.haivision.com:create-akamai-hublet"
      )?.flag ?? false;

    // rtt
    let RttPresetActive =
      this.state.subscriptionFeatureFlags.find(
        (f) => f.id === "femto.hub.haivision.com:create-kraken-hublet"
      )?.flag ?? false;

    // cloud routing
    let cloudRoutingActive =
      this.state.subscriptionFeatureFlags.find(
        (f) => f.id === "activate-cloud-routing"
      )?.flag ?? false;

    // zanna processing hublet
    let zannaHubletActive =
      this.state.subscriptionFeatureFlags.find(
        (f) => f.id === "zanna-processing-hublet-ff"
      )?.flag ?? false;

    // hubnetTrail
    let hubnetTrailActive =
      this.state.subscriptionFeatureFlags.find(
        (f) => f.id === "activate-hubnet-free-trial"
      )?.flag ?? false;

    this.setState({
      rttPresetEnabled: RttPresetActive,
      orchestratorV2Enabled:
        (orchestratorActive && !infrastructureActive && nodeIp) || false,
      hubApplianceManagerEnabled:
        (edge_nodes_ff &&
          makito_input_hublet_ff &&
          makito_output_hublet_ff &&
          makito_preset_creator) ||
        false,
      akamaiPresetEnabled: akamaiActive,
      cloudRoutingEnabled: cloudRoutingActive,
      zannaHubletEnabled: zannaHubletActive,
      hubnetTrailEnabled: hubnetTrailActive,
    });
  }

  setSwitchingFF(value) {
    this.setState((prev) => {
      prev.switchingFF = value;
      return prev;
    });
  }

  setShowNotification(value) {
    this.setState({ showNotification: value });
  }

  setShowFeatureDialog(value) {
    this.setState({ showFFDialog: value });
  }

  setDialogTitle(value) {
    this.setState({ dialogTitle: value });
  }

  async subscriptionHasRequirement(featureId) {
    let hasRequirement;
    await axios
      .get(
        process.env.REACT_APP_HUB_API_BASE_URL +
          `/Support/features/${featureId}/subscriptions/${this.state.subscriptionId}/hasRequirement`
      )
      .then((response) => (hasRequirement = response.data))
      .catch((error) => {
        hasRequirement = false;
      });
    return hasRequirement;
  }

  async enableOrDisableCustomFeatures(isEnabled, featureStateName, featureUri) {
    this.setSwitchingFF(true);
    this.setState({ [featureStateName]: isEnabled });

    let baseUrl =
      process.env.REACT_APP_HUB_API_BASE_URL +
      `/Support/features/${featureUri}/subscriptions/${this.state.subscriptionId}`;
    if (isEnabled) {
      baseUrl += "/deactivate";
    } else {
      baseUrl += "/activate";
    }
    await axios
      .put(baseUrl)
      .then((response) => {
        this.setSwitchingFF(false);
        this.setShowNotification(true);
      })
      .catch((err) => {
        this.setSwitchingFF(false);
        this.setState({ [featureStateName]: !isEnabled });
      });
  }

  openFFDialogWithDirect = async (ff) => {
    this.setState({ directSwitch: true });
    this.openFFDialog(ff);
  };
  openFFDialog = async (ff) => {
    this.setState({ clickedFeature: ff, loadingRequirement: true });
    if (ff.flag) {
      //if its enable, then disable
      this.setDialogTitle("Are you sure you want to disable the feature flag?");
    } else {
      this.setDialogTitle("Are you sure you want to enable the feature flag?");
    }

    let hasReq = await this.subscriptionHasRequirement(ff.id);
    if (!hasReq) {
      this.setState({ showContractMessage: true });
    }
    this.setShowFeatureDialog(true);
    this.setState({ loadingRequirement: false });
  };

  directEnableOrDisableFF = async () => {
    if (this.state.clickedFeature.flag) {
      await disableBySubHttp(
        this.state.clickedFeature.id,
        this.state.subscriptionId,
        (response) => {
          this.setShowNotification(true);
        }
      );
    } else {
      await enableBySubHttp(
        this.state.clickedFeature.id,
        this.state.subscriptionId,
        (response) => {
          this.setShowNotification(true);
        }
      );
    }
  };

  // if directSwitch is true, then it will enable/disable the ff directly in hub
  enableOrDisablebySub = async () => {
    if (this.state.directSwitch) {
      await this.directEnableOrDisableFF();
    } else {
      switch (this.state.clickedFeature?.id) {
        case "femto.hub.haivision.com:create-akamai-hublet":
          await this.enableOrDisableCustomFeatures(
            this.state.clickedFeature.flag,
            "akamaiPresetEnabled",
            "akamai-hublet-preset-creator"
          );
          break;

        case "femto.hub.haivision.com:create-kraken-hublet":
          await this.enableOrDisableCustomFeatures(
            this.state.clickedFeature.flag,
            "rttPresetEnabled",
            "rtt-preset-creator"
          );
          break;

        case "appliance-manager":
          await this.enableOrDisableCustomFeatures(
            this.state.clickedFeature.flag,
            "hubApplianceManagerEnabled",
            "appliance-manager"
          );
          break;

        case "orchestrator-v2-messages-ff":
          await this.enableOrDisableCustomFeatures(
            this.state.clickedFeature.flag,
            "orchestratorV2Enabled",
            "orchestrator-v2"
          );
          break;
        case "activate-cloud-routing":
          await this.enableOrDisableCustomFeatures(
            this.state.clickedFeature.flag,
            "cloudRoutingEnabled",
            "activate-cloud-routing"
          );
          break;
        case "zanna-processing-hublet-ff":
          await this.enableOrDisableCustomFeatures(
            this.state.clickedFeature.flag,
            "zannaHubletEnabled",
            "zanna-processing-hublet-ff"
          );
          break;
        case "activate-hubnet-free-trial":
          await this.enableOrDisableCustomFeatures(
            this.state.clickedFeature.flag,
            "hubnetTrailEnabled",
            "hubnet-trail"
          );
          break;

        default:
          await this.directEnableOrDisableFF();
          break;
      }
    }

    this.setShowFeatureDialog(false);
    this.setState({ clickedFeature: null, directSwitch: false });
    this.loadFeatures(); //calling this again because it check all features state again
  };

  dialogContent = () => {
    if (this.state.switchingFF) {
      return "Loading...";
    } else if (this.state.showContractMessage ) {
      return "This subscription doesn't have the contract line required for this feature."
    } else {
      return null;
    }
  };

  render() {
    return (
      <div>
        <div>
          <Row>
            <Col>
              <h4 style={{ color: "#007bff" }}>Features</h4>
            </Col>
          </Row>
          {this.state.loadingData && <Spinner />}
          {this.state.subscriptionFeatureFlags && (
            <>
              <Row>
                {/* ORCHESTRATOR V2 */}
                <Col sm="12" md="3" xl="2" style={{ marginBottom: "1%" }}>
                  <FeatureFlagCard
                    cardTitle="Orchestrator V2"
                    changes={
                      <ul style={{ alignContent: "unset" }}>
                        <li>
                          Disable
                          infrastructure-hublets:require-2-or-more-outputs
                        </li>
                        <li>enable orchestrator-v2-messages-ff</li>
                        <li>enable use-node-public-ip-flow-ff</li>
                        <li>
                          enable notify-resource-provision-state-changes-ff
                        </li>
                      </ul>
                    }
                    checked={this.state.orchestratorV2Enabled}
                    disabled={this.state.switchingFF}
                    featureId="orchestrator-v2-messages-ff"
                    loadingRequirement={this.state.loadingRequirement}
                    openFFDialog={this.openFFDialog}
                  ></FeatureFlagCard>
                </Col>

                {/* Appliance Manager */}
                <Col sm="12" md="3" xl="2" style={{ marginBottom: "1%" }}>
                  <FeatureFlagCard
                    cardTitle="Appliance Manager"
                    changes={
                      <ul>
                        <li>edge-nodes-ff</li>
                        <li>ems.hub.haivision.com:create-makito-preset</li>
                        <li>dr-ems-makito-input-hublet-ff</li>
                        <li>dr-ems-makito-output-hublet-ff</li>
                      </ul>
                    }
                    checked={this.state.hubApplianceManagerEnabled}
                    disabled={this.state.switchingFF}
                    featureId="appliance-manager"
                    loadingRequirement={this.state.loadingRequirement}
                    openFFDialog={this.openFFDialog}
                  ></FeatureFlagCard>
                </Col>

                {/* AKAMAI PRESET FF */}
                <Col sm="12" md="3" xl="2" style={{ marginBottom: "1%" }}>
                  <FeatureFlagCard
                    cardTitle="Akamai Preset creator"
                    changes={
                      <ul>
                        <li>
                          Changes femto.hub.haivision.com:create-akamai-hublet
                        </li>
                      </ul>
                    }
                    checked={this.state.akamaiPresetEnabled}
                    disabled={this.state.switchingFF}
                    featureId="femto.hub.haivision.com:create-akamai-hublet"
                    loadingRequirement={this.state.loadingRequirement}
                    openFFDialog={this.openFFDialog}
                  ></FeatureFlagCard>
                </Col>

                {/* RTT PRESET */}
                <Col sm="12" md="3" xl="2" style={{ marginBottom: "1%" }}>
                  <FeatureFlagCard
                    cardTitle="RTT Preset Creator"
                    changes={
                      <ul>
                        <li>
                          Changes femto.hub.haivision.com:create-kraken-hublet
                        </li>
                      </ul>
                    }
                    checked={this.state.rttPresetEnabled}
                    disabled={this.state.switchingFF}
                    featureId="femto.hub.haivision.com:create-kraken-hublet"
                    loadingRequirement={this.state.loadingRequirement}
                    openFFDialog={this.openFFDialog}
                  ></FeatureFlagCard>
                </Col>

                {/* Cloud Routing */}
                <Col sm="12" md="3" xl="2" style={{ marginBottom: "1%" }}>
                  <FeatureFlagCard
                    cardTitle="Cloud Routing"
                    changes={
                      <ul>
                        <li>Changes activate-cloud-routing</li>
                      </ul>
                    }
                    checked={this.state.cloudRoutingEnabled}
                    disabled={this.state.switchingFF}
                    featureId="activate-cloud-routing"
                    loadingRequirement={this.state.loadingRequirement}
                    openFFDialog={this.openFFDialog}
                  ></FeatureFlagCard>
                </Col>

                {/* Audio Hublet */}
                <Col sm="12" md="3" xl="2" style={{ marginBottom: "1%" }}>
                  <FeatureFlagCard
                    cardTitle="Zanna Processing Hublet"
                    changes={
                      <ul>
                        <li>Changes zanna-processing-hublet-ff</li>
                      </ul>
                    }
                    checked={this.state.zannaHubletEnabled}
                    disabled={this.state.switchingFF}
                    featureId="zanna-processing-hublet-ff"
                    loadingRequirement={this.state.loadingRequirement}
                    openFFDialog={this.openFFDialog}
                  ></FeatureFlagCard>
                </Col>

                {/* Hubnet Trail */}
                <Col sm="12" md="3" xl="2" style={{ marginBottom: "1%" }}>
                  <FeatureFlagCard
                    cardTitle="Hubnet Trail"
                    changes={
                      <ul>
                        <li>Changes activate-hubnet-free-trial</li>
                      </ul>
                    }
                    checked={this.state.hubnetTrailEnabled}
                    disabled={this.state.switchingFF}
                    featureId="activate-hubnet-free-trial"
                    loadingRequirement={this.state.loadingRequirement}
                    openFFDialog={this.openFFDialog}
                  ></FeatureFlagCard>
                </Col>
              </Row>
            </>
          )}

          {!this.state.loadingData && this.state.subscriptionFeatureFlags && (
            <div>
              <Button
                style={{ color: "#007bff" }}
                onClick={() => this.handleFlagListToggle()}
              >
                See All Flags
              </Button>

              <Button
                style={{ color: "#007bff" }}
                onClick={() => this.syncFeaturesWithSalesforce()}
                disabled={!this.state.subscriptionId}
              >
                Sync Features
              </Button>

              <Collapse isOpen={this.state.flagListOpen}>
                <List>
                  <ListHeader
checkbox={false}
                    style={{
                      gridTemplateColumns: "0.3fr 2fr 0.5fr 0.5fr 0.2fr",
                    }}
                    alternateRowColors="true"
                  >
                    <div></div>
                    <div>Feature Flag</div>
                    <div>Global Flag</div>
                    <div></div>
                  </ListHeader>
                  {this.state.subscriptionFeatureFlags.map((ff, index) => {
                    return (
                      <div key={index}>
                        <TableRow
checkbox={false}
                          style={{
                            gridTemplateColumns: "0.3fr 2fr 0.5fr 0.5fr 0.2fr",
                            color: "white",
                            textalign: "center",
                          }}
                        >
                          <div></div>
                          <div>{ff.id}</div>
                          <div>
                            {
                              <FontAwesomeIcon
                                style={{ cursor: "pointer" }}
                                icon={ff.flag ? faCheckCircle : faMinusCircle}
                                color={ff.flag ? "green" : "red"}
                                onClick={() => this.openFFDialogWithDirect(ff)}
                              />
                            }
                          </div>
                          <div></div>
                          <div></div>
                        </TableRow>
                      </div>
                    );
                  })}
                </List>
              </Collapse>
            </div>
          )}
        </div>
        <hr />
        <Notification
          style={{ top: "1rem", right: "1rem" }}
          show={this.state.showNotification}
          description="Success."
          message="Update succeeded."
          autohide={true}
          variant="Success"
          onDismiss={() => this.setShowNotification(false)}
        />

        <Dialog
          headline={this.state.dialogTitle}
          bodyText={this.dialogContent()}
          show={this.state.showFFDialog}
          buttons={[
            {
              label: "Yes",
              variant: 'primary',
              onClick: () => this.enableOrDisablebySub(),
              disabled: this.state.switchingFF,
            },
            { label: "No", onClick: () => this.handleClose() },
          ]}
        />
      </div>
    );
  }
}

export default SubscriptionFeatureSection;
