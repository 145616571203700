import { DataTable } from "@hai/ui-react";
import React from "react";

const AwFunctionalTestsDataTable = props => {

    return (
        <DataTable
            columnStructure={[{ fieldKey: 'uuid', title: 'UUID' },
            { fieldKey: 'nameTest', title: 'Name test' },
            { fieldKey: 'result', title: 'Result' },
            { fieldKey: 'details', title: 'Details' },
            { fieldKey: 'numberPass', title: 'Number pass' },
            { fieldKey: 'dateTest', title: 'Test Date' },
            { fieldKey: 'revision', title: 'Revision' }
            ]}
            compact={true}
            emptyStateText="No test!"
            selectable={false}
        >
            <DataTable.TitleBar
                title={"Functional Tests"}
                subtitle={'Product ' + props?.uuid}
                withSearch={false}
            />
            <DataTable.Header />

            {props?.functionalTests && props?.functionalTests.map((data, index) => {
                return <DataTable.Row key={data.id} rowData={data} />;
            })}
        </DataTable>
    )
}

export default AwFunctionalTestsDataTable;