import { FormControl, FormGroup, FormLabel, Header, SelectOption } from "@hai/ui-react";
import React, { useState } from "react"
import CreateAwProduct from "./CreateAwProduct";
import CreateAirProduct from "./CreateAirProduct";
import CreatePro4Product from "./CreatePro4Product";
import CreateProProduct from "./CreateProProduct";
import CreateAwFunctionalTests from "./CreateAWFunctionalTests";
import CreateAwModem from "./CreateAwModem";

const CreateAllProducts = props => {
    const [formType, setFormType] = useState('air');

    const handleChange = evt => {
        setFormType(evt.target.value)
    }

    return (<>
        <Header title={"Create AW Data"}></Header>

        <FormGroup>
            <FormLabel>Type</FormLabel>
            <FormControl
                name="select-type"
                as="select"
                defaultValue='air'
                value={formType}
            >
                <SelectOption key="air" value="air" onChange={handleChange}>
                    Air Series
                </SelectOption>
                <SelectOption key="pro" value="pro" onChange={handleChange}>
                    Pro Series
                </SelectOption>
                <SelectOption key="pro4" value="pro4" onChange={handleChange}>
                    Pro 4 Series
                </SelectOption>
                <SelectOption key="func" value="func" onChange={handleChange}>
                    Functional Test
                </SelectOption>
                <SelectOption key="modem" value="modem" onChange={handleChange}>
                    Modem
                </SelectOption>
            </FormControl>
        </FormGroup>

        {formType === 'air' && <CreateAirProduct />}
        {formType === 'pro' && <CreateProProduct />}
        {formType === 'pro4' && <CreatePro4Product />}
        {formType === 'func' && <CreateAwFunctionalTests />}
        {formType === 'modem' && <CreateAwModem />}

    </>);
}

export default CreateAllProducts;