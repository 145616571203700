import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Header,
  List,
  Modal,
  ModalBody,
  ModalHeader,
  ProgressBar,
  Form,
  FormGroup,
  FormLabel,
  FormControl,
  SelectOption,
  DropdownButton,
  DropdownMenuItem,
  Divider,
} from "@hai/ui-react";
import {
  getDevices,
  getBranches,
  getProducts,
  getAccounts,
  activateServicesDevice,
  suspendServicesDevice,
  setServiceDeviceBranch,
  setServiceDeviceProduct,
  createBranch,
  createServiceDeviceAssignmentAccount,
  createAccount,
} from "./webbingApi";
import { useFetch } from "../common/useFetch";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import cogoToast from "cogo-toast";

const WebbingDevices = (props) => {
  const params = useParams();
  const [showActivate, setShowActivate] = useState(false);
  const [showSuspend, setShowSuspend] = useState(false);

  const [activateBranchName, setActivateBranchName] = useState(null);
  const [activateProductId, setActivateProductId] = useState(null);
  const [activateAccountName, setActivateAccountName] = useState(null);
  const [creatingCustomBranch, setCreatingCustomBranch] = useState(false);
  const [creatingCustomAccount, setCreatingCustomAccount] = useState(false);

  const handleShowActivate = () => setShowActivate(true);
  const handleShowSuspend = () => setShowSuspend(true);
  const {
    data: devices,
    loading: devicesLoading,
    error: devicesError,
  } = useFetch(getDevices);
  const {
    data: branches,
    loading: branchesLoading,
    error: branchesError,
  } = useFetch(() => getBranches(`?pageNumber=1&pageSize=1000`));
  const {
    data: products,
    loading: productsLoading,
    error: productsError,
  } = useFetch(getProducts);
  const {
    data: accounts,
    loading: accountsLoading,
    error: accountsError,
  } = useFetch(() => getAccounts(`?pageNumber=1&pageSize=1000`));

  const [selectedBranch, setSelectedBranch] = useState("select branch");
  const [iccidFilter, setIccidFilter] = useState("");

  const filteredDevices = devices?.filter((device) => {
    if (iccidFilter === "") {
      return device.branchName?.includes(selectedBranch);
    } else {
      return (
        device.branchName?.includes(selectedBranch) &&
        device.iccid.includes(iccidFilter)
      );
    }
  });

  const handleSubmitActivate = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const branchName = activateBranchName;
    const productId = activateProductId;
    const accountName = activateAccountName;
    const iccids = formData.get("iccids").split(",");

    if (activateBranchName) {
      for (const iccid of iccids) {
        await activateServicesDevice({ iccid });

        const branch = branches.data.find((branch) => branch.name === branchName);
        var createdBranch;
        if (!branch) {
          createdBranch = (await createBranch({ name: branchName })).data;
          cogoToast.success(`Branch ${branchName} created.`, { position: "top-right" });
        } else {
          createdBranch = branch;
        }
        await setServiceDeviceBranch({ iccid, branchID: createdBranch.id });
        const product = products.find((product) => product.id === productId);
        await setServiceDeviceProduct({ iccid, productID: product.id });
        
        const account = accounts.data.find((account) => account.name === accountName);
        var createdaccount;
        if (!account) {
            createdaccount = (await createAccount({ branchID: createdBranch.id, name: accountName })).data;
        cogoToast.success(`Account ${accountName} created.`, { position: "top-right" });
        } else {
        createdaccount = account;
        }
        await createServiceDeviceAssignmentAccount({ iccid, accountID: createdaccount.id });
      }
      setActivateBranchName(null);
      setActivateProductId(null);
      setActivateAccountName(null);
      setShowActivate(false);
      cogoToast.success("Activated", { position: "top-right" });
    } else {
      cogoToast.error("Select a branch.", { position: "top-right" })
    }
  };

  const handleSubmitSuspend = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const iccids = formData.get("iccids").split(",");

    for (const iccid of iccids) {
      await suspendServicesDevice({ iccid });
    }

    setShowSuspend(false);
    cogoToast.success("ICCIDs suspended.", { position: "top-right" });
  };

  if (devicesLoading || branchesLoading || productsLoading || accountsLoading) {
    return (
      <ProgressBar
        leftLabel="Loading: "
        barColors={{
          background: "#599bff",
          right: "#599bff",
          left: "#2fccd3",
        }}
        hideRight={true}
        animate={1}
      />
    );
  }

  if (devicesError || branchesError || productsError || accountsError) {
    return (
      <div>
        An error has occurred:{" "}
        {devicesError?.message ||
          branchesError?.message ||
          productsError?.message ||
          accountsError?.message}
      </div>
    );
  }

  return (
    <>
      <Header title="Webbing Devices" style={{ marginTop: "1%" }} />
      <br />
      <div>
        <Button variant="primary" onClick={handleShowActivate}>
          Bulk Activate ICCIDs
        </Button>
        <Modal show={showActivate}>
          <ModalHeader closeButton={true}>
            <Header type="icon-header" title="Activate ICCIDs" />
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={handleSubmitActivate}>
              <FormGroup>
                <FormLabel>Branch Name {creatingCustomBranch && <small style={{ color: "yellow", fontSize: "80%" }}>Clicking Activate will create a new branch.</small>}</FormLabel>
                <AsyncTypeahead
                  id="search-typeahead-activebranch"
                  labelKey={"name"}
                  options={branches.data.map((b) => ({
                    id: b.id,
                    name: b.name,
                  }))
                  }
                  minLength={0}
                  allowNew={true}
                  maxResults={10}
                  onSearch={() => true}
                  onChange={(e) => {
                    if (e[0] && e[0].customOption) {
                      setCreatingCustomBranch(true);
                    } else {
                      setCreatingCustomBranch(false);
                    }

                    setActivateBranchName(e[0] ? e[0].name : null);
                  }}
                  placeholder="Select branch..."
                  newSelectionPrefix="Create new branch: "
                ></AsyncTypeahead>
              </FormGroup>
              <FormGroup>
                <FormLabel htmlFor="product">Product</FormLabel>
                <AsyncTypeahead
                  id="search-typeahead-activeproduct"
                  labelKey={"name"}
                  options={products.map((b) => ({
                    id: b.id,
                    name: b.name,
                  }))
                  }
                  minLength={0}
                  maxResults={10}
                  onSearch={() => true}
                  onChange={(e) => setActivateProductId(e[0] ? e[0].id : null)}
                  placeholder="Select product..."
                ></AsyncTypeahead>
              </FormGroup>
              <FormGroup>
                <FormLabel>Account Name{creatingCustomAccount && <small style={{ color: "yellow", fontSize: "80%" }}>Clicking Activate will create a new account.</small>}</FormLabel>
                <AsyncTypeahead
                    id="search-typeahead-activeaccount"
                    labelKey={"name"}
                    options={accounts.data.map((b) => ({
                        id: b.id,
                        name: b.name,
                    }))
                    }
                    minLength={0}
                    allowNew={true}
                    maxResults={10}
                    onSearch={() => true}
                    onChange={(e) => {
                    if (e[0] && e[0].customOption) {
                        setCreatingCustomAccount(true);
                    } else {
                        setCreatingCustomAccount(false);
                    }
                    setActivateAccountName(e[0] ? e[0].name : null)
                    }}
                    placeholder="Select account..."
                    newSelectionPrefix="Create new account: "
                ></AsyncTypeahead>
              </FormGroup>
              <FormGroup>
                <FormLabel htmlFor="iccids">List of ICCIDs</FormLabel>
                <FormControl as="textarea" name="iccids" />
              </FormGroup>
              <Button type="submit">Activate</Button>
              <Button type="button" onClick={() => {
                setShowActivate(false);
                setCreatingCustomBranch(false);
                setCreatingCustomAccount(false);
              }
              }>Cancel</Button>
            </Form>
          </ModalBody>
        </Modal>

        <Button variant="primary" onClick={handleShowSuspend}>
          Bulk Suspend ICCIDs
        </Button>

        <Modal show={showSuspend}>
          <ModalHeader closeButton={true}>
            <Header type="icon-header" title="Suspend ICCIDs" />
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={handleSubmitSuspend}>
              <FormGroup>
                <FormLabel htmlFor="iccids">List of ICCIDs</FormLabel>
                <FormControl as="textarea" name="iccids" />
              </FormGroup>
              <Button type="submit">Suspend</Button>
              <Button type="button" onClick={() => setShowSuspend(false)}>Cancel</Button>
            </Form>
          </ModalBody>
        </Modal>
      </div>
      <br />
      <div>
        <FormLabel>Branch</FormLabel>
        <AsyncTypeahead
          id="search-typeahead-branch"
          labelKey={"name"}
          options={branches.data.map((b) => ({
            id: b.id,
            name: b.name,
          }))
          }
          minLength={0}
          maxResults={10}
          onSearch={() => true}
          onChange={(e) => setSelectedBranch(e[0] ? e[0].name : null)}
          placeholder="Select branch..."

        ></AsyncTypeahead>

        <Divider />
        <FormControl
          placeholder={"Enter ICCID"}
          onChange={(e) => setIccidFilter(e.target.value)}
        />
      </div>
      <br />
      <div>
        {selectedBranch && filteredDevices &&
          filteredDevices.map((a, index) => (
            <List key={index}>
              <List.Panel
                panelColor={
                  a.statusID === 3
                    ? "haiui-green-01"
                    : a.statusID === 4
                      ? "haiui-red-01"
                      : ""
                }
              >
                <List.PanelTitle title={a.iccid ?? "No name"} />
                <List.PanelDetail>
                  <List.PanelDetailItem
                    icon="Information"
                    text={`Status: ${a.statusName}`}
                    divider="vertical"
                    label="Status"
                  />
                  <List.PanelDetailItem
                    icon="HubNodes"
                    text={`Account: ${a.accountName}`}
                    divider="vertical"
                    label="email"
                  />
                  <List.PanelDetailItem
                    icon="HubNodes"
                    text={`Branch: ${a.branchName}`}
                    divider="vertical"
                    label="email"
                  />
                  <List.PanelDetailItem
                    icon="HubNodes"
                    text={`Product: ${a.productName}`}
                    divider="vertical"
                    label="email"
                  />
                </List.PanelDetail>
              </List.Panel>
            </List>
          ))}
      </div>
    </>
  );
};

export default WebbingDevices;
