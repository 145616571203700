import React, { useState, useEffect } from 'react'
import {
  Header,
  FormControl,
  FormGroup,
  FormLabel,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Button,
  SelectOption,
  ProgressBar,
  List,
  ListHeader,
  TableRow,
} from "@hai/ui-react";

import { Col, Row } from "reactstrap";
import axios from "../../../infra/axios";
import Select from "react-select";

const WorkOrderAdd = (props) => {
  //const [modelFilter, setModelFilter] = useState([]);
  const [modelFilterStr, setModelFilterStr] = useState('');
  const [models, setModels] = useState([]);
  const [modelOptions, setModelOptions] = useState([]);
  const [selectedModel, SetSelectedModel] = useState('');
  const [locations, setLocations] = useState([]);
  const [selectedLocation, SetSelectedLocation] = useState('');
  const [year, setYear] = useState(parseInt(new Date().getFullYear().toString().substr(-2))); //format - 00
  const [week, setWeek] = useState(1); //format - 01~53
  const [lot, setLot] = useState(1);//format 01~99
  const [existingWO, setExistingWO] = useState([])//01~999
  const [count, setCount] = useState(50)//01~999
  const [comment, setComment] = useState('')
  const [loading, setLoading] = useState(false);
  const [userName, setUserName] = useState('');

  useEffect(() => {
    setUserName(sessionStorage.getItem("user.name"));
  }, [])
  
  useEffect(() => {
    getWorkOrderByWorkOrderNumber();
}, [selectedLocation, year, week]);

useEffect(() => {
  var week = getWeekNumber(new Date());
  setWeek(week);
}, []);

  const handleCreate = () => {

    var woDto = {
      workOrderNumber: '',
      workOrderFactoryName: '',
      orderCount: '',

      modelId: '',
      comments: ""
    }

    if (week < 1 || week > 53) {
      alert('Week should be a number from 1 to 53!');
      return;
    }

    if (lot < 1 || lot > 99) {
      alert('Lot should be a number from 1 to 99!');
      return;
    }

    if (count < 1 || count > 999) {
      alert('Count should be a number from 1 to 999!');
      return;
    }

    if (selectedLocation === '' || selectedLocation === undefined || selectedLocation === null) {
      alert('Please select a location!');
      return;
    }

    if (selectedModel === '' || selectedModel === undefined || selectedModel === null) {
      alert('Please select a model!');
      return;
    }

    if (comment && comment.length > 200) {
      alert('The max length of comments is 200!');
      return;
    }

    var code = String(locations.filter(x => x.name === selectedLocation)[0].code);
    woDto.workOrderNumber = `${code.padStart(2, '0')}${year.toString().substr(-2)}${week.toString().padStart(2, '0')}${lot.toString().padStart(2, '0')}`;
    woDto.workOrderFactoryName = selectedLocation;
    woDto.orderCount = count.toString().padStart(2, '0');
    woDto.modelId = models.filter(x => x.description === selectedModel)[0].id;
    woDto.comments = 'User : ' + userName + ', Model: ' + selectedModel + ', Comment : '  + comment;

    setLoading(true);
    axios
      .post(
        process.env.REACT_APP_PRODUCTION_API_BASE_URL + "/Legacy/WorkOrders",
        woDto
      )
      .then((reponse) => {
        handleHideModal(false);
      })
      .finally(() => {
        setLoading(false);
      });


  }

  const getWorkOrderByWorkOrderNumber = () => {
    
    var workOrderNumberWithoutLot = GetFilterWo();
    if (workOrderNumberWithoutLot === '') {
      return;
    }

    setLoading(true);
    axios
    .get(
    process.env.REACT_APP_PRODUCTION_API_BASE_URL +
        "/Legacy/WorkOrders/WorkOrderNumberWithoutLot/" + workOrderNumberWithoutLot
    )
    .then((response) => {
        if(response.data){
            const wo = response.data;
            setExistingWO(wo);

            if(wo.length >= 1){
              const maxItem = wo.reduce((max, c) => parseInt(c.workOrderNumber.slice(-2)) > parseInt(max.workOrderNumber.slice(-2)) ? c : max) // 'abx'
              setLot(parseInt(maxItem.workOrderNumber.slice(-2)) + 1);
            }
            else{
              setLot(1);
            }

        }
    })
    .finally(() => {
      setLoading(false);
    });   
};

const GetFilterWo = () => {
      
  if (selectedLocation === '' || selectedLocation === undefined || selectedLocation === null) {
    return '';
  } 

  if (week === '' || week === undefined || week === null) {
    return '';
  }

  var code = String(locations.filter(x => x.name === selectedLocation)[0].code);
  return `${code.padStart(2, '0')}${year.toString().substr(-2)}${week.toString().padStart(2, '0')}`;
}

  const handleHideModal = (isCancelled) => {

    //setModelFilter(models);
    setModelFilterStr('');
    //setModels([]);
    SetSelectedModel(models[1].description);
    //setLocations([]);
    SetSelectedLocation(locations[0].name);
    setYear(parseInt(new Date().getFullYear().toString().substr(-2))); //format - 00
    var week = getWeekNumber(new Date());
    setWeek(week);
    setLot(1);//format 01~99
    setCount(50)//01~999
    setLoading(false);

    props.onHide();
    if(!isCancelled){
      props.onLoadWorkOrders(1, 12);
    }  
  }


  useEffect(() => {
    GetModels();
    GetLocations();

  }, []);

  const GetModels = () => {
    setLoading(true);
    axios
      .get(
        process.env.REACT_APP_PRODUCTION_API_BASE_URL +
        "/Legacy/Models?PageNumber=1&PageSize=1000"
      )
      .then((response) => {
        if (response.data) {
          const models = response.data;
          setModels(models.data);
          let modelOptions = models.data.map(item => {
            return { value: item.description, label: item.description };
         });
         setModelOptions(modelOptions);

          //setModelFilter(models);
          //SetSelectedModel(models[1].description);
        }
        setLoading(false);
      });
  }

  const GetLocations = () => {
    setLoading(true);
    axios
      .get(
        process.env.REACT_APP_PRODUCTION_API_BASE_URL +
        "/Legacy/Locations"
      )
      .then((response) => {
        if (response.data) {
          const locations = response.data;
          setLocations(locations);
          SetSelectedLocation(locations[0].name);
        }
        setLoading(false);
      });
  }

  const filterModel = (filterStr) => {
    setModelFilterStr(filterStr);
    var filterdModels = models.filter(x => x.description.toLowerCase()===filterStr.toLowerCase());
    if (filterdModels.length > 0) {
      SetSelectedModel(filterdModels[0].description);
    }
  }

  const  getWeekNumber = (dateIn)  => {
    // Copy date so don't modify original
    dateIn = new Date(Date.UTC(dateIn.getFullYear(), dateIn.getMonth(), dateIn.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    dateIn.setUTCDate(dateIn.getUTCDate() + 4 - (dateIn.getUTCDay()||7));
    // Get first day of year
    var yearStart = new Date(Date.UTC(dateIn.getUTCFullYear(),0,1));
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil(( ( (dateIn - yearStart) / 86400000) + 1)/7);
    // Return array of year and week number
    return weekNo;
}

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: "#777782",
      background: "#33333c",
    }),
  };

  const inputStyle = {color: "white", background: "#33333c", height : "40px"};

  return (
    <div>      
      {
        <div>
          <Modal show={props.show} size="xl">
          {loading && <ProgressBar
        leftLabel="Loading: "
        barColors={{
          background: '#599bff',
          right: '#599bff',
          left: '#2fccd3',
        }}
        hideRight={true}
        animate={1}
      />}
            <ModalHeader>
              <ModalTitle>Create Work Order</ModalTitle>
            </ModalHeader>
            <ModalBody>
              <div>
                <Header type="sub-section" title="Product Model" />
                <Row>
                  <Col>
                    <FormGroup>
                      <Select
                      className="basic-single"
                      classNamePrefix="select"
                      defaultValue = {modelFilterStr!==''?modelFilterStr:''}
                      options={modelOptions}
                      label="Models"
                      isSearchable
                      styles={customStyles}
                      onChange={(e) => filterModel(e.value)}
                    />
                  </FormGroup>
                  </Col>
                </Row>
                <Header type="sub-section" title="Work Order Number" />
                <Row>
                  <Col>
                    <FormGroup>
                      <FormLabel>Location</FormLabel>
                      <FormControl
                        as="select"
                        defaultValue={selectedLocation}
                        onChange={(e) => {
                          var text = e.target.value;
                          SetSelectedLocation(text);
                        }}
                      >
                        {locations &&
                          locations.map((x) => {
                            return <SelectOption value={x.name} key={x.id} >{x.name}</SelectOption>;
                          })}
                      </FormControl>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                    <FormLabel>Year</FormLabel>
                    <input  style = {inputStyle} type="number" name="YearIndex" step="1" value = {year} onChange={(e) => setYear(e.target.value)}></input>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                    <FormLabel>Week</FormLabel>
                    <input  style = {inputStyle} type="number" name="weekIndex" step="1" value = {week} onChange={(e) => setWeek(e.target.value)}></input>
                    </FormGroup>
                  </Col>
                  <Col>
                  <FormGroup>
                    <FormLabel>Lot</FormLabel>
                    <input  style = {inputStyle} type="number" name="lotIndex" step="1" value = {lot} onChange={(e) => setLot(e.target.value)}></input>
                  </FormGroup>
                  </Col>
                </Row>
                <Header type="sub-section" title="Serial Number" />
                <Row>
                  <Col>
                  <FormGroup>
                    <FormLabel>Count</FormLabel>
                    <input  style = {inputStyle} type="number" name="YearIndex" step="1" value = {count} onChange={(e) => setCount(e.target.value)}></input>
                  </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                  <FormGroup>
                    <FormLabel>Comment (Max length 200 characters)</FormLabel>
                    <FormControl
                    as="textarea"
                    defaultValue=""
                    onChange={(e) => setComment(e.target.value)}>               
                    </FormControl>
                  </FormGroup>
                  </Col>
                </Row>
              
              <Header type="sub-section" title={'Filterd by : ' + GetFilterWo()} />
              <Row>              
                <List>
                  <ListHeader
                    checkbox={false}
                    style={{
                      gridTemplateColumns:
                        "1fr 1fr 2fr 2fr 1.5fr 2.5fr",
                    }}
                  >                   
                    <div>Factory</div>
                    <div>Work Number</div>
                    <div>Serial Number From</div>
                    <div>Serial Number To</div>
                    <div>Date Created</div>
                    <div>Comment</div>
                  </ListHeader>
                  {existingWO &&
                    existingWO.map((wo) => {
                      return (
                        <div key={wo.id}>
                          <TableRow
                            checkbox={false}
                            style={{
                              gridTemplateColumns:
                                "1fr 1fr 2fr 2fr 1.5fr 2.5fr",
                            }}
                          >                            
                            <div>{wo.workOrderFactoryName}</div>
                            <div>{wo.workOrderNumber}</div>
                            <div>{wo.workOrderFirstSerialNumber}</div>
                            <div>{wo.workOrderLastSerialNumber}</div>
                            <div>{new Date(wo.dateCreated).toUTCString()}</div>
                            <div>{wo.comments}</div>
                          </TableRow>
                        </div>
                      );
                    })}
                </List>
              </Row>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button variant="primary" onClick={() => handleCreate()}>
                Create
              </Button>
              <Button onClick={() => handleHideModal(true)}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </div>}
    </div>
  );
}
export default WorkOrderAdd;