import React, { useEffect, useState } from 'react'
import axios from '../../../infra/axios';
import { Button, CopyToClipboard, DataTable, Dialog, Icon, ProgressBar } from '@hai/ui-react';
import { HaiDataTableColumnType } from '@hai/ui-react/dist/types';
import cogoToast from 'cogo-toast';
import PropTypes from "prop-types";

const DeviceByTenantTable = props => {
    const [loading, setLoading] = useState(true);
    const [devices, setDevices] = useState([]);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showDeleteBulkDialog, setShowDeleteBulkDialog] = useState(false);
    const [selectedListToDelete, setSelectedListToDelete] = useState(null);
    const [showGeneratePairCode, setShowGeneratePairCode] = useState(false);
    const [showNewDeviceCode, setShowNewDeviceCode] = useState(false);
    const [newDeviceCode, setNewDeviceCode] = useState('')

    useEffect(()=> {
        getDevices();
    }, []);

    const getDevices = () => {
        if (props?.tenantId) {
            axios.get(process.env.REACT_APP_HUB_API_BASE_URL + `/EmsApi/accounts/${props?.accountId}/tenants/${props?.tenantId}/devices`)
            .then(res => {
                setDevices(res.data)
                setLoading(false);
                })
            .catch(e => {
                setLoading(false);
            })
        } else {
            cogoToast.info(`No tenant found.`, {position: `top-right`});
            setLoading(false)
        }
    }

    const deleteDevice = (id) => {
        return axios.delete(process.env.REACT_APP_HUB_API_BASE_URL + `/EmsApi/accounts/${props.accountId}/tenants/${props.tenantId}/devices/${id}`)
    }

    const deleteDeviceBulk = () => {
        if (selectedListToDelete) {
            var promises = selectedListToDelete.map(id => deleteDevice(id));
            Promise.all(promises).then(res => {
                cogoToast.success(`Devices deleted`);
                const remainingDevices = devices.filter(d => !selectedListToDelete.includes(d.id));
                setDevices(remainingDevices);
                setSelectedListToDelete(null);
            })
        }
    }

    const createNewPairCode = () => {
        axios.post(process.env.REACT_APP_HUB_API_BASE_URL + `/EmsApi/accounts/${props.accountId}/tenants/${props.tenantId}/passcode`)
        .then(res => {
            setNewDeviceCode(res.data.pairingPasscode);
            setShowNewDeviceCode(true)
        });
    }

    const bulkActions = selectedItems => 
    (<Button state='idle' size='small' onClick={() => {
        setSelectedListToDelete(selectedItems);
        setShowDeleteBulkDialog(true);
    } }>Delete selected</Button>)

    const onCheckAllEvent = nextCheckAllState => {
        const devicesSelected = devices.map(d => { return {...d, selected: nextCheckAllState}})
        setDevices(devicesSelected);
    }

    const deleteTenant = () => {
        axios.delete(process.env.REACT_APP_HUB_API_BASE_URL + `/EmsApi/accounts/${props.accountId}/tenants/${props.tenantId}`)
        .then(t => {
            props.getTenants();
            setShowDeleteDialog(false);
            cogoToast.success(`Tenant deleted.`, {position: `top-right`})
        })
    }


    return (<div>

        {loading && (<ProgressBar animate={1} hideRight={true}/>)}
        <br/>
        {!loading && (
            <DataTable
                columnStructure={[{ fieldKey: 'firmwareVersion', title: 'Version' },
                { fieldKey: 'modelNumber', title: 'Model' },
                { fieldKey: 'name', title: 'Name'},
                { fieldKey: 'status', title: 'Status' },
                { fieldKey: 'type', title: 'Type' },
                { fieldKey: 'serialNumber', title: 'Serial N#' },
                { fieldKey: '', title: '', type: HaiDataTableColumnType.ACTIONS }]}
                compact={true}
                emptyStateText="No devices!"
                selectable={true}
                onCheckAll={onCheckAllEvent}
            >
                <DataTable.TitleBar
                    title={"Tenant " + props?.tenantId}
                    subtitle='Devices'
                    withSearch={false}
                    rightComponent={
                    <div style={{display: `flex`, justifyContent: 'space-between', alignItems: 'center'}}>
                        <div style={{padding: '5px'}}><CopyToClipboard value={props?.pairingPasscode}><small>Pair code</small><Icon iconname="Copy" size="sm3" style={{ fill: 'white' }} title='copy existing pair code' /></CopyToClipboard></div>
                        <div style={{padding: '5px'}}><Icon iconname='Repeat' size="sm3" style={{ fill: 'yellow' }} title='Generate new pairing code' onClick={() => setShowGeneratePairCode(true)} /></div>
                        <div style={{padding: '5px'}}><Icon iconname='TrashCan' size="sm3" style={{ fill: 'red' }} title='delete tenant' onClick={() => setShowDeleteDialog(true)} /></div>
                    </div>}
                />
                <DataTable.Header bulkActions={bulkActions}/>
                    
    
                {devices && devices.map((data, index) => {
                    return <DataTable.Row key={data.id} rowData={data} actionItems={[ {
                        actionIcon: 'TrashCan',
                        iconColor: 'red',
                        onClick: () => console.log(`delete`, data.id)
                    }]} />;
                })}
            </DataTable>)}

            <Dialog show={showDeleteDialog}
                onClose={() => setShowDeleteDialog(false)}
                headline="Delete Tenant?"
                bodyText={`This action will delete the tenant ${props?.tenantId}.`}
                buttons={[
                    { label: 'CANCEL' },
                    { label: 'DELETE', variant: 'danger', onClick: () => deleteTenant() },
                  ]}
            ></Dialog>

            <Dialog show={showDeleteBulkDialog}
                onClose={() => setShowDeleteBulkDialog(false)}
                headline="Delete devices?"
                bodyText={`This action will delete all selected devices.`}
                buttons={[
                    { label: 'CANCEL' },
                    { label: 'DELETE', variant: 'danger', onClick: () => deleteDeviceBulk() },
                  ]}
            ></Dialog>

            <Dialog show={showGeneratePairCode}
                onClose={() => setShowGeneratePairCode(false)}
                headline="Generate new device pairing code?"
                buttons={[
                    { label: 'CANCEL' },
                    { label: 'CONFIRM', variant: 'primary', onClick: () => createNewPairCode() },
                  ]}
            ></Dialog>

        <Dialog
          headline="New device pairing code created."
          icon="StatusOK"
          bodyText={newDeviceCode}
          onClose={() => setShowNewDeviceCode(false)}
          show={showNewDeviceCode}
          buttons={[{ label: 'OK', variant: 'primary' }]}
        />
        </div>)
}

export default DeviceByTenantTable;