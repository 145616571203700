import {
  Button,
  FormControl,
  List,
  ListHeader,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Pagination,
  Notification,
  FormLabel,
  FormGroup,
  Form,
  ProgressBar,
  Header, Spinner
} from "@hai/ui-react";
import cogoToast from "cogo-toast";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import axios from "../../../infra/axios";
import { useParams } from "react-router-dom";
import { UserContext } from "../../common/userContext";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import ServicePrincipalRow from "./ServicePrincipalRow";
import Select from "react-select";
import SecretModal from "./SecretModal";

const ServicePrincipal = (props) => {
  const params = useParams();
  const [allSPs, setAllSPs] = useState(null);
  const [filteredSPs, setfilteredSPs] = useState(null);
  const [loadingSPs, setLoadingSPs] = useState(false);
  const [
    showUserStatusChangeNotification,
    setShowUserStatusChangeNotification,
  ] = useState(false);

  const [columnFilters, setColumnFilters] = useState([])
  const [showNewSPModal, setShowNewSPModal] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState("Updated");
  const [showSecretModal, setShowSecretModal] = useState(false);
  const [createdSP, setCreatedSP] = useState(null);
  const [loading, setLoading] = useState(false);
  const [spForm, setSpForm] = useState({
    tenantId: "",
    moduleId: "",
    name: "",
  });

  const [pageData, setPageData] = useState({
    currentPage: params?.pageNum ? parseInt(params?.pageNum) : 1,
    pageSize: 12
  });

  const firstTimeRender = useRef(true);

  useEffect(() => {
    (async function () {
      await getAllServicePrincipal(1, 12);
    })();
  }, []);

  useEffect(() => {
    firstTimeRender.current = false;
  }, []);


  const getAllServicePrincipal = async (page, pageSize) => {
    setLoadingSPs(true);
    await axios
      .get(process.env.REACT_APP_JELLYFISH_API_BASE_URL + `/azure-resource/service-principals?pageNumber=${page}&pageSize=${pageSize}&columnFilters=${JSON.stringify(columnFilters)}`, {
        headers: {
          "Cache-Control": "no-cache",
        },
      })
      .then((response) => {
        setAllSPs(response.data);
        setfilteredSPs(response.data);
        setLoadingSPs(false);
      })
      .catch((err) => setLoadingSPs(false));
  };

  const createNewServicePrincipal = () => {
    if (validateForm()) {
      setLoading(true);
      axios
        .post(process.env.REACT_APP_JELLYFISH_API_BASE_URL + "/azure-resource/service-principals", spForm)
        .then((res) => {
          setShowNewSPModal(false);
          setNotificationMsg("Service Principal created!");
          setShowUserStatusChangeNotification(true);
          setCreatedSP(res.data);
          setShowSecretModal(true);
          setLoading(false);
        })
        .catch((e) => setLoading(false));
    }
  };

  const validateForm = () => {
    let valid = true;
    if (!spForm?.tenantId) valid = false;
    if (!spForm?.name) valid = false;

    if (!valid) {
      cogoToast.warn("Please fill the required fields", {
        position: "top-right",
      });
    }
    return valid;
  };

  const handleChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    var temp = spForm;
    temp[name] = value;
    setSpForm(temp);
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted red",
      color: "red",
      background: "white",
    }),
  };

  return (
    <div>
      <UserContext.Consumer>
        {(user) => {
          if (user.roles?.map((r) => r.role).some((r) => r === "SYS_ADMIN")) {
            return (
              <>
                <Header
                  title="Service Principal Management"
                  style={{ marginBottom: "1%" }}
                />
                <Button
                  variant="primary"
                  onClick={() => setShowNewSPModal(true)}
                >
                  Create new
                </Button>
                <hr></hr>

                <Row style={{ alignItems: "flex-end" }}>
                  <Col>
                    <FormLabel>Filter by name</FormLabel>
                    <FormControl
                      name="nameFilter"
                      placeholder="Search by Name"
                      onChange={(e) => {
                        let tempSet = columnFilters.filter(f => f.id !== "name");
                        if (e.target.value !== "") {
                          tempSet.push({
                            id: 'name',
                            Value: e.target.value,
                            Comparator: 'Contains'
                          });
                          setColumnFilters(tempSet);
                        } else {
                          setColumnFilters(tempSet);
                        }
                      }
                      }
                    />
                  </Col>

                  <Col>
                    <FormLabel>Filter by tenant</FormLabel>
                    <FormControl
                      placeholder="Tenant Id..."
                      name="tenantFilter"
                      onChange={(e) => {
                        let tempSet = columnFilters.filter(f => f.id !== "tenantId");
                        if (e.target.value !== "") {
                          tempSet.push({
                            id: 'tenantId',
                            Value: e.target.value,
                            Comparator: 'Contains'
                          });
                          setColumnFilters(tempSet);
                        } else {
                          setColumnFilters(tempSet);
                        }
                      }
                      }
                    ></FormControl>
                  </Col>

                  <Col>
                    <FormLabel>Filter by App Id</FormLabel>
                    <FormControl
                      name="appIdFilter"
                      onChange={(e) => {
                        let tempSet = columnFilters.filter(f => f.id !== "applicationId");
                        if (e.target.value !== "") {
                          tempSet.push({
                            id: 'applicationId',
                            Value: e.target.value,
                            Comparator: 'Contains'
                          });
                          setColumnFilters(tempSet);
                        } else {
                          setColumnFilters(tempSet);
                        }
                      }}
                    ></FormControl>
                  </Col>

                  <Col>
                    <Button onClick={() => getAllServicePrincipal(1, 12)}>Search</Button>
                  </Col>
                </Row>

                <hr />
                <List>
                  <ListHeader
                    checkbox={false}
                    style={{
                      gridTemplateColumns:
                        "0.2fr 3fr 3fr 3fr 2fr 1fr 0.2fr 0.2fr",
                    }}
                  >
                    <div></div>
                    <div>Application Name</div>
                    <div>Application Id</div>
                    <div>Tenant Id</div>
                    <div>Created Date</div>
                    <div>Secrets</div>
                    <div></div>
                  </ListHeader>

                  {loadingSPs && (
                    <ProgressBar
                      leftLabel="Loading: "
                      barColors={{
                        background: '#599bff',
                        right: '#599bff',
                        left: '#2fccd3',
                      }}
                      hideRight={true}
                      animate={1}
                    />
                  )}
                  {/* .sort((a, b) => (a.name > b.name ? 1 : -1)) */}
                  {filteredSPs &&
                    filteredSPs.data?.map((sp, index) => (
                      <ServicePrincipalRow
                        sp={sp}
                        key={sp.applicationId}
                        index={index}
                        loadSps={getAllServicePrincipal}
                      ></ServicePrincipalRow>
                    ))}
                </List>
                <Pagination
                  pageCount={allSPs?.totalPages}
                  currentPage={pageData.currentPage}
                  onPageChange={(newPage, previousPage) => {
                    if (allSPs.hasNextPage || newPage <= allSPs?.totalPages) {
                      setPageData({ currentPage: newPage, pageSize: pageData.pageSize });
                      getAllServicePrincipal(newPage, pageData.pageSize);

                    } else {
                      cogoToast.warn("No next page", { position: 'top-right' });
                    }

                  }}
                />

                <Notification
                  style={{ top: "1rem", right: "1rem" }}
                  show={showUserStatusChangeNotification}
                  description="Success"
                  message={notificationMsg}
                  autohide={true}
                  variant="Success"
                />

                <Modal
                  size="md"
                  show={showNewSPModal}
                  onHide={() => setShowNewSPModal(false)}
                >
                  <ModalHeader>
                    <p>New Service Principal</p>
                  </ModalHeader>
                  <ModalBody>
                    <Form
                      formTitle="Create a service principal"
                    >
                      <FormGroup>
                        <FormLabel required>Tenant Id</FormLabel>
                        <FormControl
                          defaultValue={spForm.tenantId}
                          name="tenantId"
                          onChange={handleChange}
                        ></FormControl>
                      </FormGroup>

                      <FormGroup>
                        <FormLabel required>Name</FormLabel>
                        <FormControl
                          defaultValue={spForm.name}
                          name="name"
                          onChange={handleChange}
                        ></FormControl>
                      </FormGroup>
                    </Form>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      disabled={loading}
                      onClick={() => createNewServicePrincipal()}
                    >
                      Create
                    </Button>
                    {loading && <Spinner />}
                  </ModalFooter>
                </Modal>

                {showSecretModal && (
                  <SecretModal
                    sp={createdSP}
                    show={showSecretModal}
                    secret={createdSP.secrets?.[0]}
                    onClose={() => {
                      setCreatedSP(null);
                      setShowSecretModal(false);
                      getAllServicePrincipal();
                    }}
                  ></SecretModal>
                )}
              </>
            );
          } else {
            return <p>You don't have permission on this page</p>;
          }
        }}
      </UserContext.Consumer>
    </div>
  );
};

export default ServicePrincipal;
