import React, { Component } from "react";
import axios from "../../../infra/axios";
import {SelectOption,
  FormLabel,
  FormControl,
  Button,
  Modal,
  ModalHeader,
  ModalTitle,
  ModalFooter,
  FormGroup,
 } from "@hai/ui-react";
import { Row, Col, ModalBody } from "reactstrap";

class HaivisionProductAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showProductAdd: false,
      newProduct: {
        productTypeId: "",
        sku: "",
        description: "",
      },
      productTypes: null,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState((prev) => {
      prev.showProductAdd = nextProps.show;
      prev.productTypes = nextProps.productTypes;
      if (prev.productTypes.length > 0) {
        prev.productTypes = prev.productTypes.filter((x) => x.name !== "All");
        prev.newProduct.productTypeId = prev.productTypes[0].id;
      }
      return prev;
    });
  }

  submitProductAdd() {
    axios
      .post(
        process.env.REACT_APP_LIC_API_BASE_URL + "/Products",
        this.state.newProduct
      )
      .then((response) => {
        this.props.handleAddProduct();
        this.props.getProducts();
        this.setState((prev) => {
          prev.newProduct = {
            productTypeId: "",
            sku: "",
            description: "",
          };
          return prev;
        });
      });
  }

  render() {
    return (
      <Modal show={this.state.showProductAdd}>
        <ModalHeader>
          <ModalTitle>Add Product</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <FormLabel>Product Sku</FormLabel>
            <FormControl
              onChange={(e) => {
                var text = e.target.value;
                this.setState((prev) => {
                  prev.newProduct.sku = text;
                  return prev;
                });
              }}
            />
            <FormLabel>Product Type</FormLabel>
            <FormControl
              as="select"
              placeholder="Please select a product type"
              onChange={(e) => {
                const text = e.target.value;
                var productType = this.state.productTypes.find(
                  (x) => x.name === text
                );
                this.setState((prev) => {
                  prev.newProduct.productTypeId = productType.id;
                  return prev;
                });
              }}
            >
              {this.state.productTypes &&
                this.state.productTypes.map((t) => {
                  return <SelectOption key={t.id}>{t.name}</SelectOption>;
                })}
            </FormControl>
            <FormLabel>Product Description</FormLabel>
            <FormControl
              onChange={(e) => {
                var text = e.target.value;
                this.setState((prev) => {
                  prev.newProduct.description = text;
                  return prev;
                });
              }}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button variant="primary" onClick={() => this.submitProductAdd()}>
            Submit
          </Button>
          <Button onClick={() => this.props.handleAddProduct()}>Cancel</Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default HaivisionProductAdd;
