import { List, ListHeader, TableRow , ProgressBar } from "@hai/ui-react";
import React from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import RatesModal from "../contracts-details/RatesModal";

export default class ContractItemsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ratesModal: false,
      activeContractItem: null,
    };
  }

  modalComponent() {
    if (this.state.ratesModal) {
      return (
        <RatesModal
          show={this.state.ratesModal}
          rates={this.state.activeContractItem.rates}
          hide={() => this.hideRatesModal()}
        ></RatesModal>
      );
    } else {
      return;
    }
  }

  showModalClick(item) {
    this.setState({ ratesModal: true, activeContractItem: item });
  }

  hideRatesModal() {
    this.setState({ ratesModal: false, activeContractItem: null });
  }

  render = () => {
    return (
      <div
        style={{
          padding: "1%",
          border: "1px #ff99ff groove",
          borderRadius: "5px",
          marginTop: "5px",
          marginBottom: "3px"
        }}
      >
        <b>Contract Line Items</b>
        <br/>
        <List>
          <ListHeader
          checkbox={false}
            style={{
              gridTemplateColumns: "0.3fr 2.5fr 2.5fr 2.5fr 2.5fr 2.5fr 1fr",

              color: "white",
              textalign: "center",
            }}
            alternateRowColors="true"
          >
            <div></div>
            <div>Id</div>
            <div>Name</div>
            <div>Start Date</div>
            <div>End Date</div>
            <div>Status</div>
            <div>Rates</div>
          </ListHeader>

          {this.props.contractItems?.map((item, index) => {
            return (
              <div key={index}>
                <TableRow checkbox={false}
                  key={index}
                  className="table-item-sm"
                  alternateRowColors={index % 2 == 0}
                  style={{
                    gridTemplateColumns:
                      "0.01fr 2.5fr 2.5fr 2.5fr 2.5fr 2.5fr 1fr",
                    color: "white",
                  }}
                >
                  <div></div>
                  <div> {item.id} </div>
                  <div> {item.name} </div>
                  <div> {moment(item.startDate).format("MM/DD/YYYY")} </div>
                  <div> {moment(item.endDate).format("MM/DD/YYYY")} </div>
                  <div>{item.status}</div>
                  <div>
                    <FontAwesomeIcon
                      icon={faExternalLinkAlt}
                      className="mr-3"
                      onClick={() => this.showModalClick(item)}
                    />{" "}
                  </div>
                </TableRow>
                {this.modalComponent()}
              </div>
            );
          })}
        </List>

        {/* <EmsAccountInfo organizationId={this.props.organizationId}></EmsAccountInfo> */}
      </div>
    );
  };
}
