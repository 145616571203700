import axios from "../../infra/axios";
import React, { Component } from "react";

class KickerCaller extends Component {
  constructor(props) {
    super(props);
    this.state = { sessionId: null };
  }

  componentDidMount() {
    this.authenticate();
    this.getLicense();
  }

  authenticate() {
    axios
      .post(
        "https://install.chi.haivision.com/vfkicker/admin.php",
        "'username=&password=&submit=+Login+'"
      )
      .then((response) => {
        console.log(response);
        this.setState((prev) => {
          prev.sessionId = "";
          return prev;
        });
      });
  }

  getLicense() {
    axios
      .post(
        "https://install.chi.haivision.com/vfkicker/admin.php?function=generateLicenseRun&hvhostid=1",
        "product=calypso&basis=3_7&licensedMac=44%3AA8%3A42%3A35%3A02%3A18&lic_request_num=&expiration=on&expiration_date=20220831&edition_select=Enterprise+-+S-HMP2-ENT&edition=Enterprise&concurrent_recordings=999&concurrent_users=888&sources=777&epg=on&iptv=on&multicast_agent=on&remote_storage=on&sso=on&vod=on&mobile_contribution=on&klv=on&ecdn=on&hmg_cloud=on&layouts=on&live_review=on&appspace=on&p2p=on&custom_build_types=1&lic_build_type_VF_RELEASE=on&lic_build_type_VF_BETA=on&lic_build_type_VF_ALPHA=on&lic_build_type_VF_DEV=on"
      )
      .then((response) => {
        this.setState((prev) => {
          prev.licenseText = response.data;
          return prev;
        });
      });
  }

  render() {
    return <div></div>;
  }
}

export default KickerCaller;
