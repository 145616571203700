import { DataTable, FormLabel } from "@hai/ui-react";
import React from "react";
import { Col, Row } from "reactstrap";

const AwProSeriesDataTable = props => {

    return (<>
        <Row>
            <Col><p style={{ fontSize: '1.4rem', fontStyle: 'italic' }}>Pro Series</p></Col>
        </Row>
        <Row>
            <Col><FormLabel> <span style={{ fontStyle: 'italic' }}>UUID:</span> <p>{props.pro?.uuid}</p></FormLabel> </Col>
            <Col><FormLabel> <span style={{ fontStyle: 'italic' }}>Mac Address:</span> <p>{props.pro?.macAddress1}</p></FormLabel> </Col>
            <Col><FormLabel> <span style={{ fontStyle: 'italic' }}>Mac Address 2:</span> <p>{props.pro?.macAddress2}</p></FormLabel> </Col>
            <Col><FormLabel> <span style={{ fontStyle: 'italic' }}>Name:</span> <p>{props.pro?.productName}</p></FormLabel> </Col>
            <Col><FormLabel> <span style={{ fontStyle: 'italic' }}>Rosedo version:</span> <p>{props.pro?.rosedoVersion}</p></FormLabel> </Col>
        </Row>

        <Row>
            <Col><FormLabel> <span style={{ fontStyle: 'italic' }}>Version:</span> <p>{props.pro?.version}</p></FormLabel> </Col>
            <Col><FormLabel> <span style={{ fontStyle: 'italic' }}>Brehat Id:</span> <p>{props.pro?.idBrehat}</p></FormLabel> </Col>
            <Col><FormLabel> <span style={{ fontStyle: 'italic' }}>Tresco Id:</span> <p>{props.pro?.idTresco}</p></FormLabel> </Col>
            <Col><FormLabel> <span style={{ fontStyle: 'italic' }}>Mercury Id:</span> <p>{props.pro?.idMercury}</p></FormLabel> </Col>
        </Row>
    </>
    )
}

export default AwProSeriesDataTable;