import { Animation, Header , ProgressBar, Spinner } from "@hai/ui-react";
import PropTypes from "prop-types";
import React, { Component, useEffect, useState } from "react";
import HubRouteTable from "./routeTable";
import axios from "../../../infra/axios";
import { useParams } from "react-router-dom";

const HubRoute = (props) => {
  let {subscriptionIdParam} = useParams();
  const [routes, setRoutes] = useState(null);
  const [loading, setLoading] = useState(true);
  const [subscriptionId, setSubscriptionId] = useState(props.subscriptionId || subscriptionIdParam);

  useEffect(() => {
    refreshRoutes(subscriptionId);
  },[])

  const refreshRoutes = async (subscriptionId) => {
    await axios
      .get(
        process.env.REACT_APP_HUB_API_BASE_URL +
          "/Support/subscriptions/" +
          subscriptionId +
          "/hublets/routes"
      )
      .then((response) => {
        setRoutes(response.data.results.sort((a, b) =>
        a.createdOn < b.createdOn ? 1 : -1
      ));
      setLoading(false);
      });
  }

  if (loading) {
      return <Spinner />;
    } else {
      return (
        <div>
          <Header
            title="Subscription Routes"
            style={{ marginBottom: "1%", color: "#007bff" }}
          ></Header>
          <br />
          <HubRouteTable
            routes={routes}
            subscriptionId={subscriptionId}
          />
        </div>
      );
    }
  }

HubRoute.propTypes = {
  subscriptionId: PropTypes.string,
};

export default HubRoute;
