import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  faCheckCircle,
  faChevronCircleDown,
  faChevronCircleRight,
  faCog,
  faMinusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  List,
  ListHeader,
  OverlayTrigger,
  TableRow,
  Animation,
 ProgressBar } from "@hai/ui-react";
import { Col, Collapse, Row, } from "reactstrap";
import axios from "../../../infra/axios";
import "./OrganizationLine.scss";
import SubscriptionCardItem from "./SubscriptionCardItem";
import cogoToast from "cogo-toast";

const OrganizationLine = (props) => {
  const [collapse, setCollapse] = useState(false);
  const [loadingSubs, setLoadingSubs] = useState(false);
  const [subscriptions, setSubscriptions] = useState(null);
  const toggleCollapse = () => {
    setCollapse(!collapse);
    querySubscriptions(props.org.organizationId);
  };

  const querySubscriptions = (orgId) => {
    setLoadingSubs(true);
    axios
      .get(
        process.env.REACT_APP_HUB_API_BASE_URL +
          `/SrtHub/organizations/${orgId}/subscriptions`
      )
      .then((response) => {
        setSubscriptions(response.data);
        setLoadingSubs(false);
      })
      .catch((error) => setLoadingSubs(false));
  };

  let navigate = useNavigate();
  const goToSubscription = (subId) => {
    navigate(
      `/hub/organizations/${props.org.organizationId}/subscriptions/${subId}`
    );
  };

  const goToOrganization = (subId) => {
    let contractId;
    axios
      .get(
        process.env.REACT_APP_HUB_API_BASE_URL +
          `/Provisions/by-organizations/${props.org.organizationId}`
      )
      .then((response) => {
        contractId = response.data[0]?.contractId;
        if (contractId) {
          navigate(`/contracts/${contractId}`);
        } else {
          cogoToast.error("Contract not found", { position: "top-right" });
        }
      });
  };

  const renderSubs = () => {
    if (props.subsViewGrid) {
      return (
        <Row style={{ display: "flex", alignItems: "stretch" }}>
          {subscriptions.map((sub, index) => {
            return (
              <Col md="3" key={index} onClick={() => goToSubscription(sub.id)}>
                <SubscriptionCardItem
                  sub={sub}
                  key={index}
                ></SubscriptionCardItem>
              </Col>
            );
          })}
        </Row>
      );
    } else {
      return (
        <List style={{ boarder: "1px" }}>
          <ListHeader
checkbox={false}
            style={{ gridTemplateColumns: "0.3fr 2fr 2fr 2fr 0.2fr 0.2fr" }}
          >
            <div></div>
            <div>Sub Name</div>
            <div>Sub Id</div>
            <div>Status</div>
            <div></div>
          </ListHeader>
          <div>
            {subscriptions.map((sub, index) => {
              return (
                <TableRow
checkbox={false}
                  key={index}
                  style={{
                    gridTemplateColumns: "0.3fr 2fr 2fr 2fr 0.2fr 0.2fr",
                    color: "white",
                    textalign: "center",
                  }}
                >
                  <div></div>
                  <div>{sub.subscriptionName}</div>
                  <div>{sub.subscriptionId}</div>
                  <div>
                    {sub.isDisabled ? (
                      <FontAwesomeIcon icon={faMinusCircle} color="red" 
                      size="lg"/>
                    ) : (
                      <FontAwesomeIcon icon={faCheckCircle} color="green" 
                      size="lg"/>
                    )}
                  </div>
                  <div style={{ cursor: "pointer" }}>
                    <FontAwesomeIcon
                    size="lg"
                      icon={faCog}
                      color="#007bff"
                      onClick={() => goToSubscription(sub.subscriptionId)}
                    />
                  </div>
                </TableRow>
              );
            })}
          </div>
        </List>
      );
    }
  };

  return (
    <div>
      <TableRow
checkbox={false}
        alternateRowColors={props.index % 2 == 0}
        style={{
          gridTemplateColumns: "0.3fr 2fr 2fr 2fr 0.2fr 0.2fr",
          color: "white",
          textalign: "center",
        }}
      >
        <div></div>
        <div> {props.org.name} </div>
        <div> {props.org.domain} </div>
        <div> {props.org.organizationId}</div>
        <div style={{ cursor: "pointer" }}>
          <OverlayTrigger
            popoverContent={
              collapse ? "Hide subscriptions" : "Show subscriptions"
            }
            placement="top"
          >
            <FontAwesomeIcon
              icon={collapse ? faChevronCircleDown : faChevronCircleRight}
              size="lg"
              onClick={() => toggleCollapse()}
            />
          </OverlayTrigger>
        </div>
        <div style={{ cursor: "pointer" }}>
          <FontAwesomeIcon
          size="lg"
            icon={faCog}
            onClick={() => goToOrganization()}
          />
        </div>
      </TableRow>
      <Collapse isOpen={collapse}>
        <div
          style={{
            padding: "1%",
            border: "1px #007bff groove",
            borderRadius: "5px",
            marginTop: "5px",
          }}
        >
          {loadingSubs && <ProgressBar
  leftLabel="Loading: "
  barColors={{
    background: '#599bff',
    right: '#599bff',
    left: '#2fccd3',
  }}
  hideRight={true}
  animate={1}
/>}
          {subscriptions && renderSubs()}
        </div>
      </Collapse>
    </div>
  );
};

export default OrganizationLine;
