import { Button, Form, FormControl, FormGroup, FormLabel } from "@hai/ui-react";
import React, { useState } from "react";
import axios from "../../../infra/axios";
import SelectUUIDBox from "./SelectUUIDBox";
import cogoToast from "cogo-toast";
import { Col, Row } from "reactstrap";

const CreateAwModem = props => {

    const [isNewProduct, setIsNewProduct] = useState(true)
    const [form, setForm] = useState({
        serialNumber: null,
        uuid: null,
        type: null,
        macAddress: null,
        model: null,
        serial: null,
        revision: null,
        firmwareFull: null,
        details: null,
        skuId: null
    });

    const createAwModem = () => {
        if (form.uuid && form.serialNumber) {
            axios.post(`${process.env.REACT_APP_PRODUCTION_API_BASE_URL}/Legacy/AwProducts/Uuid/${form.uuid}/Modem`, form)
                .then(a => {
                    setForm({
                        serialNumber: null,
                        uuid: null,
                        type: null,
                        macAddress: null,
                        model: null,
                        serial: null,
                        revision: null,
                        firmwareFull: null,
                        details: null,
                        skuId: null
                    });
                    cogoToast.success('Modem created', { position: 'top-right' });
                })
                .catch(e => cogoToast.error("Failed to create Modem.", { position: 'top-right' }));
        } else {
            cogoToast.error('Select an UUID and Serial Number', { position: 'top-right' });
        }
    }

    const getProductBySerial = () => {
        if (form.serialNumber) {
            axios.get(`${process.env.REACT_APP_PRODUCTION_API_BASE_URL}/Legacy/AwProducts/SerialNumber/${form.serialNumber}`)
                .then(a => setIsNewProduct(false))
                .catch(e => setIsNewProduct(true));
        }
    }

    const onChangeHandler = evt => {
        const { name, value } = evt.target
        setForm({
            ...form,
            [name]: value,
        })
    }

    return (<>
        <Form>
            <Row>
                <Col md='3'>
                    <SelectUUIDBox label={'UUID'} onChange={a => {
                        if (a && a[0]) {
                            setForm({ ...form, uuid: a[0].uuid, serialNumber: a[0].serial });
                        }
                    }}
                        onInputChange={i => {
                            setForm({ ...form, uuid: i });
                        }}
                        onBlur={getProductBySerial}
                    ></SelectUUIDBox>
                </Col>
                {isNewProduct && <Col md='3'>
                    <FormGroup>
                        <FormLabel required>Serial Number</FormLabel>
                        <FormControl placeholder="AW..." name="serialNumber" onChange={onChangeHandler} defaultValue={form.serialNumber} />
                    </FormGroup>
                </Col>}
                <Col md='3'>
                    <FormGroup>
                        <FormLabel>Type</FormLabel>
                        <FormControl placeholder="Type" name="type" onChange={onChangeHandler} value={form.type} />
                    </FormGroup>
                </Col>
                <Col md='3'>
                    <FormGroup>
                        <FormLabel>Mac Address</FormLabel>
                        <FormControl placeholder="AB:CD:DE:FG..." name="macAddress" onChange={onChangeHandler} value={form.macAddress} />
                    </FormGroup>
                </Col>
            </Row>

            <Row>
                <Col md='4'>
                    <FormGroup>
                        <FormLabel>Model</FormLabel>
                        <FormControl placeholder="" name="model" onChange={onChangeHandler} value={form.model} />
                    </FormGroup>
                </Col>
                <Col md='4'>
                    <FormGroup>
                        <FormLabel>Serial</FormLabel>
                        <FormControl placeholder="" name="serial" onChange={onChangeHandler} value={form.serial} />
                    </FormGroup>
                </Col>
                <Col md='4'>
                    <FormGroup>
                        <FormLabel>Revision</FormLabel>
                        <FormControl placeholder="" name="revision" onChange={onChangeHandler} value={form.revision} />
                    </FormGroup>
                </Col>
            </Row>

            <Row>
                <Col md='4'>
                    <FormGroup>
                        <FormLabel>Details</FormLabel>
                        <FormControl placeholder="Details: something..." name="details" onChange={onChangeHandler} value={form.details} as="textarea" />
                    </FormGroup>
                </Col>
                <Col md='4'>

                    <FormGroup>
                        <FormLabel>Firmware full</FormLabel>
                        <FormControl placeholder="" name="firmwareFull" onChange={onChangeHandler} value={form.firmwareFull} />
                    </FormGroup>
                </Col>
                <Col md='4'>
                    <FormGroup>
                        <FormLabel>SKU Id</FormLabel>
                        <FormControl placeholder="" name="skuId" onChange={onChangeHandler} value={form.skuId} />
                    </FormGroup>
                </Col>
            </Row>
            <Button onClick={() => createAwModem()} type="primary">Create</Button>
        </Form>
    </>)
}

export default CreateAwModem;