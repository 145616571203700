import { NotFound , ProgressBar } from "@hai/ui-react";
import React, { Component } from "react";
import SharkNotFound from "../../../static/404_shark.png";

class NeedAuthenticate_Shark extends Component {
  render() {
    return (
      <NotFound
        backgroundImage={SharkNotFound}
        pageTitle="401 Not Found"
        subTitle="HTTP 401 - Need Authentication"
        notFoundMessage="Please log in first otherwise the shark can't eat you."
      />
    );
  }
}

export default NeedAuthenticate_Shark;
