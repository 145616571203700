import { CardContainer, Spinner , ProgressBar } from "@hai/ui-react";
import React from "react";
import { Col, Row } from "reactstrap";
import LineTo from "react-lineto";
import HubletsCard from "./HubletsCard";
import { useEffect } from "react";
import axios from "../../../infra/axios";
import { useState } from "react";

const HubletsView = (props) => {
  const [imageDefinitions, setImageDefinitions] = useState(null);

  useEffect(() => {
    getHubletsImages();
  }, []);

  const getHubletsImages = async () => {
    await axios
      .put(
        process.env.REACT_APP_HUB_API_BASE_URL +
          `/Support/hublet-definitions/image`,
        props.hublets.map((h) => h.definitionId)
      )
      .then((response) => setImageDefinitions(response.data));
  };

  return (
    <>
      {!imageDefinitions && <Spinner spinnerColor="red"></Spinner>}
      {
        <Row
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignContent: "center",
          }}
        >
          <Col>
            <CardContainer
              label="INPUT"
              variant="large"
              card={props.hublets
                .filter((h) => h.type === "input")
                .map((h, index) => (
                  <HubletsCard
                    h={h}
                    images={imageDefinitions}
                    key={index}
                  ></HubletsCard>
                ))}
            />
          </Col>

          <Col>
            <CardContainer
              label="PROCESSING"
              variant="large"
              card={props.hublets
                .filter((h) => h.type === "processing")
                .map((h, index) => (
                  <HubletsCard
                    h={h}
                    images={imageDefinitions}
                    key={index}
                  ></HubletsCard>
                ))}
            />
          </Col>

          <Col>
            <CardContainer
              label="OUTPUT"
              variant="large"
              card={props.hublets
                .filter((h) => h.type === "output")
                .map((h, index) => (
                  <HubletsCard
                    h={h}
                    images={imageDefinitions}
                    key={index}
                  ></HubletsCard>
                ))}
            />
          </Col>
        </Row>
      }

      {imageDefinitions &&
        /*Generate lines */
        props.hublets
          .filter((h) => h.outputs !== null && h.outputs.length > 0)
          .map((hublet) => {
            return hublet.outputs.map((out, index) => {
              return (
                <LineTo
                  key={index}
                  delay={500}
                  fromAnchor="right"
                  from={hublet.id}
                  to={out}
                  toAnchor="left"
                />
              );
            });
          })}
    </>
  );
};

export default HubletsView;
