import React, { useEffect, useState } from "react"
import axios from "../../infra/axios";
import { Col, Row } from "reactstrap";
import { DataTable, ProgressBar } from "@hai/ui-react";
import { HaiDataTableColumnType } from "@hai/ui-react/dist/types";

const ToriiSalesforceItems = props => {

    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (props.contractId) {
            axios.get(`${process.env.REACT_APP_TORII_API_BASE_URL}/service-contracts/${props?.contractId}/contract-line-items`)
            .then(res => {
                setItems(res.data);
                setLoading(false);
            }).catch(e => setLoading(false))
        } else {
            setLoading(false)
        }
    },[]);

    if (loading) {
        return (<ProgressBar leftLabel="Loading: "
        barColors={{
            background: '#599bff',
            right: '#599bff',
            left: '#2fccd3',
        }}
        hideRight={true}
        animate={1} />)
    }
    
    return (<>
        {!props.contractId && <p>No contract set to find items.</p>}
     <div style={{ marginTop: '2%' }}>
        <DataTable
          selectable={false}
          sortable={false}
          columnStructure={[
            { fieldKey: 'name', title: 'Name' },
          { fieldKey: 'description', title: 'Description' },
          { fieldKey: 'startDate', title: 'Start Date'},
          { fieldKey: 'endDate', title: 'End Date', type: HaiDataTableColumnType.CUSTOM },
          { fieldKey: 'status', title: 'Status' },
          { fieldKey: 'allowance', title: 'Allowance' }]}
          compact={true}
          emptyStateText="No item!"
        >
          <DataTable.Header />
          <DataTable.TitleBar
                    title={"Contract items"}
                />
                {items && items.map((data, index) => {
                    return <DataTable.Row key={data.id} rowData={data} />;
                })}
        </DataTable>
    </div>
    </>)
}

export default ToriiSalesforceItems;