import {
  Button, FormGroup,
  FormLabel, Modal,
  ModalBody,
  ModalFooter, ModalHeader, ModalTitle
, ProgressBar } from "@hai/ui-react";
import React from "react";
import { Col, Row } from "reactstrap";
import axios from "../../../infra/axios";
import HubProvisionStatusProgress from "../contracts/HubProvisionStatusProgress";

var intervalChecks = null;

class ProvisionDetailModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sp: null,
      retrying: false,
      provision: props.provision,
    }
  }


  componentDidMount() {
    this.getProvisionByClaimCode();
    if (this.state.provision.jellyServicePrincipalId) {
      axios.get(process.env.REACT_APP_JELLYFISH_API_BASE_URL +
        `/azure-resource/service-principals/by-db/${this.props.provision.jellyServicePrincipalId}`)
        .then(response => {
          this.setState({sp: response.data})
        })
    } 
  }

  getProvisionByClaimCode() {
    axios.get(process.env.REACT_APP_HUB_API_BASE_URL + `/Provisions/by-claim-code/${this.state.provision.claimCode}`)
    .then(res => this.setState({provision: res.data}));
  }

  retryProvision() {
    this.enableInterval(true, () => this.getProvisionByClaimCode());
    this.setState({retrying : true});
    axios.post(process.env.REACT_APP_HUB_API_BASE_URL + `/Operations/client-subscription/provision/${this.props.provision.id}/resume`)
    .then(res => {
      this.setState({retrying: false, provision: res.data});
      this.enableInterval(false);
    })
    .catch(e => {
      this.setState({retrying: false});
      this.enableInterval(false);
      this.getProvisionByClaimCode();
    })
  }

  async enableInterval(flag, func) {
    if (flag) {
      clearInterval(intervalChecks);
      intervalChecks = setInterval(func, 15000);
    } else {
      clearInterval(intervalChecks);
    }
  };

  render = () => {
    return (
      <Modal
        size="xl"
        show={this.props.show}
        onHide={this.props.hide}
        centered
        style={{ font: "0.8rem" }}
      >
        <ModalHeader>
          <ModalTitle>Provision code {this.state.provision.claimCode}</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <FormGroup>
                <FormLabel text="Domain" />
                <p>{this.state.provision.domain ?? ""} </p>
              </FormGroup>
            </Col>
            <Col>
            <FormGroup>
              <FormLabel text="Claim Code" />
              <p>{this.state.provision.claimCode ?? ""}</p>
            </FormGroup>
            </Col>

            <Col>            
            <FormGroup>
              <FormLabel text="Claimed Date" />
              <p>{this.state.provision.claimedDate ?? ""} </p>
            </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col> 
            <FormGroup>
              <FormLabel text="Claimed By" />
              <p>
                {this.state.provision.claimedUserEmail ?? ""}
              </p>
            </FormGroup>
            </Col>

            <Col>
            <FormGroup>
              <FormLabel text="Status" />
              <p>
              {this.state.provision.status} 
                </p>
            </FormGroup>
            </Col>

            <Col>
            <FormGroup>
              <FormLabel text="Hub Org Id" />
              <p>
              {this.state.provision.srtHubOrganizationId ?? ""}
                </p>
            </FormGroup>
            </Col>
          </Row>

          <Row>
          <Col>
            <FormGroup>
              <FormLabel text="Hub Sub Id" />
              <p>
              {this.state.provision.srtHubSubscriptionId ?? ""}
              </p>
            </FormGroup>
            </Col>

            <Col>
            <FormGroup>
              <FormLabel text="Azure Sub Name" />{" "}
              <p>
                {this.state.provision.subscriptionName}
              </p>
            </FormGroup>
            </Col>

            <Col>
            <FormGroup>
              <FormLabel text="Azure Sub Id" />{" "}
              <p>{this.state.provision.subscriptionId}
              </p>
            </FormGroup>
            </Col>
          </Row>
          <Row>

          <Col>
            <FormGroup>
              <FormLabel text="Azure SP Name" />
              <p>
                {this.state.sp?.name}
              </p>
            </FormGroup>
            </Col>

          <Col>
            <FormGroup>
              <FormLabel text="Azure SP Id" />
              <p>
              {this.state.sp?.applicationId}
            </p>
            </FormGroup>
          </Col>

          <Col>
            <FormGroup>
              <FormLabel text="Azure SP TenantId" />
              <p>
              {this.state.sp?.tenantId}
              </p>                
            </FormGroup>
          </Col>

          </Row>
          <Row>
          <Col>
            <FormGroup>
              <FormLabel text="Provision Status" />
              <p>
              {this.state.provision.stepStatus} 
                </p>
            </FormGroup>
          </Col>

        {this.state.provision.failMessage && <Col>
            <FormGroup>
              <FormLabel text="Error" />
              <p>
              {this.state.provision.failMessage} 
                </p>
            </FormGroup>
          </Col>
        }
          </Row>

        {
          this.state.provision &&
          <HubProvisionStatusProgress runningProvision={this.state.provision} retrying={this.state.retrying}></HubProvisionStatusProgress>
        }


        </ModalBody>
        <ModalFooter>
          <Row>
            <Col>
              <Button variant="secondary" onClick={this.props.hide}>
                Close
              </Button>
             {this.state.provision.failMessage 
             && (this.state.provision.provisionInProgress != null
             && !this.state.provision.provisionInProgress) 
             && <Button variant="primary" onClick={() => this.retryProvision()}>
                Retry
              </Button> }
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    );
  };
}

export default ProvisionDetailModal;
