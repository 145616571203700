import React from "react";
import { Button, Header , ProgressBar } from "@hai/ui-react";
import { Row, Col, Container, CustomInput } from "reactstrap";
import JellyHeader from "../../common/Utils/JellyHeader";
import FeatureFlagTable from "./FeatureFlagTable";

class FeatuerFlag extends React.Component {
  constructor(props) {
    super(props);
    console.log("subs env", process.env.REACT_APP_HUB_API_BASE_URL);
  }

  state = {
    featureflags: [],
  };

  render() {
    if (!sessionStorage.getItem("user.authState")) {
      return <div className="white-text">auth required...</div>;
    } else {
      return (
        <Container className="themed-container" fluid={true}>
          <Header
            title="Feature Flags"
            style={{ marginBottom: "1%", color: "#007bff" }}
          />
          <p>
            To view Subscription or Organization overrides, please click on the
            numbers.
          </p>
          <p>To change a flag, please click on the status icon.</p>
          <hr />

          <FeatureFlagTable></FeatureFlagTable>
        </Container>
      );
    }
  }
}

export default FeatuerFlag;
