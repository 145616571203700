import { Button, Form, FormControl, FormGroup, FormLabel } from "@hai/ui-react";
import React, { useState } from "react";
import axios from "../../../infra/axios";
import SelectUUIDBox from "./SelectUUIDBox";
import cogoToast from "cogo-toast";
import { Col, Row } from "reactstrap";

const CreateAwFunctionalTests = props => {

    const [isNewProduct, setIsNewProduct] = useState(true)
    const [form, setForm] = useState({
        serialNumber: null,
        uuid: null,
        nameTest: null,
        result: null,
        details: null,
        numberPass: null,
        dateTestOld: null,
        dateTest: null,
    });

    const createFunctionaltests = () => {
        if (form.uuid && form.serialNumber) {
            axios.post(`${process.env.REACT_APP_PRODUCTION_API_BASE_URL}/Legacy/AwProducts/Uuid/${form.uuid}/FunctionalTests`, form)
                .then(a => {
                    setForm({
                        serialNumber: null,
                        uuid: null,
                        nameTest: null,
                        result: null,
                        details: null,
                        numberPass: null,
                        dateTestOld: null,
                        dateTest: null,
                    });
                    cogoToast.success('Test created', { position: 'top-right' });
                })
                .catch(e => cogoToast.error("Failed to create Funcional Test.", { position: 'top-right' }));
        } else {
            cogoToast.error('Select an UUID and Serial Number', { position: 'top-right' });
        }
    }

    const getProductBySerial = () => {
        if (form.serialNumber) {
            axios.get(`${process.env.REACT_APP_PRODUCTION_API_BASE_URL}/Legacy/AwProducts/SerialNumber/${form.serialNumber}`)
                .then(a => setIsNewProduct(false))
                .catch(e => setIsNewProduct(true));
        }
    }

    const onChangeHandler = evt => {
        console.log(evt)
        const { name, value } = evt.target
        setForm({
            ...form,
            [name]: value,
        })
    }

    return (<>
        <Form>
            <Row>
                <Col>
                    <SelectUUIDBox label={'UUID'} onChange={a => {
                        if (a && a[0]) {
                            setForm({ ...form, uuid: a[0].uuid, serialNumber: a[0].serial });
                        }
                    }}
                        onInputChange={i => {
                            setForm({ ...form, uuid: i });
                        }}
                        onBlur={getProductBySerial}
                    ></SelectUUIDBox>
                </Col>
                {isNewProduct && <Col md='3'>
                    <FormGroup>
                        <FormLabel required>Serial Number</FormLabel>
                        <FormControl placeholder="AW..." name="serialNumber" onChange={onChangeHandler} defaultValue={form.serialNumber} />
                    </FormGroup>
                </Col>}
                <Col>
                    <FormGroup>
                        <FormLabel>Name test</FormLabel>
                        <FormControl placeholder="" name="nameTest" onChange={onChangeHandler} value={form.nameTest} />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <FormLabel>Result</FormLabel>
                        <FormControl placeholder="" name="result" onChange={onChangeHandler} value={form.result} />
                    </FormGroup>
                </Col>
            </Row>

            <Row>
                <Col>
                    <FormGroup>
                        <FormLabel>Details</FormLabel>
                        <FormControl placeholder="Details: something..." name="details" onChange={onChangeHandler} value={form.details} as="textarea" />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <FormLabel>Number pass</FormLabel>
                        <FormControl placeholder="" name="numberPass" onChange={onChangeHandler} value={form.numberPass} />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <FormLabel>Old test date</FormLabel>
                        <FormControl placeholder="Select date and time"
                            datetime={{
                                minimumDate: new Date("2000-01-01T00:00:00"),
                                offRangeCallback: range => alert('invalid range')
                            }}
                            as="select"
                            name="dateTestOld"
                            onChange={a => setForm({ ...form, dateTestOld: a })}
                            defaultValue={form.dateTestOld} />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <FormLabel>Test date</FormLabel>
                        <FormControl placeholder="Select date and time"
                            datetime={{
                                minimumDate: new Date("2000-01-01T00:00:00"),
                                offRangeCallback: range => alert('invalid range')
                            }}
                            as="select"
                            name="dateTest"
                            onChange={a => setForm({ ...form, dateTest: a })}
                            defaultValue={form.dateTest} />
                    </FormGroup>
                </Col>
            </Row>

            <Button onClick={() => createFunctionaltests()} type="primary">Create</Button>
        </Form>
    </>)
}

export default CreateAwFunctionalTests;