import { Button, Form, FormControl, FormLabel } from "@hai/ui-react";
import React, { useState } from "react";
import { Col, FormGroup, Row } from "reactstrap";
import { createAccount } from "./webbingApi";

const WebbingCreateAccount = props => {
    const [form, setForm] = useState({
        id: null,
        branchId: null,
        name: null,
        statusId: null,
        email: null,
        mobile: null,
        accountRef: null
    });

    const onChangeHandler = evt => {
        const { name, value } = evt.target
        setForm({
            ...form,
            [name]: value,
        })
    }

    return (<>
        <Form>
            <Row>
                {/*   <Col md='4'>
                     <FormGroup>
                        <FormLabel required>Branch Id</FormLabel>
                        <FormControl placeholder="Branch id" name="branchId" onChange={onChangeHandler} defaultValue={form.branchId} />
                    </FormGroup> 
                </Col>*/}

                <Col md='4'>
                    <FormGroup>
                        <FormLabel>Name</FormLabel>
                        <FormControl placeholder="" name="name" onChange={onChangeHandler} value={form.name} />
                    </FormGroup>
                </Col>
                <Col md='4'>
                    <FormGroup>
                        <FormLabel>Email</FormLabel>
                        <FormControl placeholder="haivision@haivision.com" name="email" onChange={onChangeHandler} value={form.email} />
                    </FormGroup>
                </Col>
                <Col md='4'>
                    <FormGroup>
                        <FormLabel>Status</FormLabel>
                        <FormControl placeholder="" name="statusId" onChange={onChangeHandler} value={form.statusId} />
                    </FormGroup>
                </Col>
            </Row>

            <Row>
                <Col><Button onClick={() => createAccount(form)} type="primary">Create</Button></Col>
                {props.close && <Col><Button onClick={() => props.close()} type="primary">Close</Button></Col>}
            </Row>

        </Form>
    </>)
}

export default WebbingCreateAccount;