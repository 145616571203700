import React, { Component } from "react";
import { Row, Col, Card, Collapse } from "reactstrap";
import { Button, LabelValue, FormLabel , ProgressBar } from "@hai/ui-react";
import moment from "moment";
import {
  faAngleDown,
  faAngleUp,
  faArrowDown,
  faCloud,
  faCopy,
  faExclamationTriangle,
  faThumbsDown,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ResourceCard from "./ResourceCard";
import cogoToast from "cogo-toast";

class TopologyCard extends Component {
  state = {
    collapse: false,
    startedByName: null,
    stoppedByName: null,
    collapseResources: false,
    collapseTopologyError: false,
  };

  toggle = () => this.setState({ collapse: !this.state.collapse });
  toggleResources = () =>
    this.setState({ collapseResources: !this.state.collapseResources });
  toggleTopology = () =>
    this.setState({ collapseTopologyError: !this.state.collapseTopologyError });

  buttonsRow = (t) => {
    return (
      <div className="float-right">
        <Button>
          <a
            href={t.applicationLogs}
            target="_blank"
            rel="noopener noreferrer"
            className="link-to-resource"
          >
            Go to Logs <FontAwesomeIcon icon={faCloud}></FontAwesomeIcon>
          </a>
        </Button>
      </div>
    );
  };

  copyToClipboard = async (id) => {
    var copyText = document.getElementById(id);
    copyText.select();
    copyText.setSelectionRange(0, 99999); /*For mobile devices*/
    await navigator.clipboard.writeText(copyText.value);
    cogoToast.success("Copied to clipboard");
  };

  componentDidMount() {
    this.getUserNames();
  }

  getUserNames() {
    let startedByName;
    let stoppedByName;
    this.props.users.find((user) => {
      if (this.props.topology.startedBy === user.id)
        startedByName = user.userName;

      if (this.props.topology.stoppedBy === user.id)
        stoppedByName = user.userName;
    });
    this.setState({
      startedByName: startedByName,
      stoppedByName: stoppedByName,
    });
  }

  render() {
    return (
      <Card
        body
        inverse
        style={{
          backgroundColor: "#0f0d0d",
          borderColor: "#afaca8",
          marginBottom: "1%",
          padding: "0.7rem",
        }}
      >
        <Col md="12">
          <Row
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          >
            <Col md="5" xl="5" sm="12" onClick={this.toggle}>
              <p style={{ margin: "auto" }}>
                Topology Id - {this.props.topology.id}{" "}
                <FontAwesomeIcon
                  icon={this.state.collapse ? faAngleUp : faAngleDown}
                ></FontAwesomeIcon>{" "}
                {(this.props.topology.errors?.length >= 1 ||
                  this.props.topology.resources?.some(
                    (r) => r.errors?.length >= 1
                  )) && (
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    color="yellow"
                    title="Has error"
                  ></FontAwesomeIcon>
                )}
              </p>
            </Col>

            <Col md="2" xl="2">
              <p style={{ margin: "auto" }}>
                Started:{" "}
                {this.props.topology.routeStartTime &&
                  moment(this.props.topology.routeStartTime).format(
                    "YYYY-MM-DD HH:mm:ss"
                  )}
              </p>
            </Col>
            <Col md="2" xl="2">
              <p style={{ margin: "auto" }}>
                Ended:{" "}
                {this.props.topology.routeEndTime &&
                  moment(this.props.topology.routeEndTime).format(
                    "YYYY-MM-DD HH:mm:ss"
                  )}
              </p>
            </Col>
            <Col md="2" xl="2">
              <p style={{ margin: "auto" }}>
                Status: {this.props.topology.state}
              </p>
            </Col>
            <Col md="2" xl="1">
              {this.buttonsRow(this.props.topology)}
            </Col>
          </Row>
          <Collapse isOpen={this.state.collapse}>
            <Row style={{ marginTop: "1%" }}>
              <Col sm="2">
                <FormLabel className={"topology-label-data"}>
                  Provision Start Time
                </FormLabel>
                <LabelValue className={"topology-label-data"}>
                  {moment(this.props.topology.provisionStartTime).format(
                    "YYYY-MM-DD HH:mm:ss"
                  )}
                </LabelValue>
              </Col>
              <Col sm="2">
                <FormLabel className={"topology-label-data"}>
                  Provision End Time
                </FormLabel>
                <LabelValue className={"topology-label-data"}>
                  {moment(this.props.topology.provisionEndTime).format(
                    "YYYY-MM-DD HH:mm:ss"
                  )}
                </LabelValue>
              </Col>
              <Col sm="2">
                <FormLabel className={"topology-label-data"}>
                  Started by
                </FormLabel>
                <LabelValue className={"topology-label-data"}>
                  {this.state.startedByName
                    ? this.state.startedByName
                    : this.props.topology.startedBy}
                </LabelValue>
              </Col>
              <Col sm="2">
                <FormLabel className={"topology-label-data"}>
                  Stopped by
                </FormLabel>
                <LabelValue className={"topology-label-data"}>
                  {this.state.stoppedByName
                    ? this.state.stoppedByName
                    : this.props.topology.stoppedBy}
                </LabelValue>
              </Col>
              <Col sm="2">
                <FormLabel className={"topology-label-data"}>
                  Topology State
                </FormLabel>
                <LabelValue className={"topology-label-data"}>
                  {this.props.topology.state}
                </LabelValue>
              </Col>
              <Col sm="2">
                <FormLabel className={"topology-label-data"}>
                  Total Resources
                </FormLabel>
                <LabelValue className={"topology-label-data"}>
                  {this.props.topology.totalResources}
                </LabelValue>
              </Col>
            </Row>

            <Row>
              <Col sm="12">
                <h5 style={{ marginTop: "1%" }} onClick={this.toggleResources}>
                  Resources ({this.props.topology.resources?.length}){" "}
                  <FontAwesomeIcon
                    icon={
                      this.state.collapseResources ? faAngleUp : faAngleDown
                    }
                  ></FontAwesomeIcon>
                </h5>
              </Col>
            </Row>

            {/* RESOURCES */}
            <Collapse isOpen={this.state.collapseResources}>
              <Row>
                {this.props.topology.resources?.map((r, index) => (
                  <ResourceCard r={r} key={index}></ResourceCard>
                ))}
              </Row>
            </Collapse>

            {/* TOPOLOGY ERRORS */}
            <Row>
              <Col sm="12">
                <h5 style={{ marginTop: "1%" }} onClick={this.toggleTopology}>
                  Topology errors ({this.props.topology.errors?.length}){" "}
                  <FontAwesomeIcon
                    icon={
                      this.state.collapseTopologyError ? faAngleUp : faAngleDown
                    }
                  ></FontAwesomeIcon>
                </h5>
              </Col>
            </Row>
            <Collapse isOpen={this.state.collapseTopologyError}>
              <Row>
                {this.props.topology.errors?.map((e, index) => {
                  return (
                    <div key={index}>
                      <Row>
                        <Col sm="12" style={{ marginBottom: "1%" }}>
                          <FormLabel className={"topology-label-data"}>
                            Message:{" "}
                          </FormLabel>
                          <LabelValue className={"topology-label-data"}>
                            {e.errorMessage}
                          </LabelValue>
                        </Col>
                        <Col>
                          <FormLabel className={"topology-label-data"}>
                            Is Unrecoverable:{" "}
                          </FormLabel>
                          <LabelValue className={"topology-label-data"}>
                            <FontAwesomeIcon
                              icon={
                                e.isUnrecoverable ? faThumbsUp : faThumbsDown
                              }
                            ></FontAwesomeIcon>
                          </LabelValue>
                        </Col>
                        <Col>
                          <FormLabel className={"topology-label-data"}>
                            Provision state:
                          </FormLabel>
                          <LabelValue className={"topology-label-data"}>
                            {e.provisionState}
                          </LabelValue>
                        </Col>
                        <Col>
                          <FormLabel className={"topology-label-data"}>
                            Provision step state:
                          </FormLabel>
                          <LabelValue className={"topology-label-data"}>
                            {e.provisionStepState}
                          </LabelValue>
                        </Col>
                        <Col>
                          <FormLabel className={"topology-label-data"}>
                            Time:
                          </FormLabel>
                          <LabelValue className={"topology-label-data"}>
                            {moment(e.timeStamp).format("YYYY-MM-DD HH:mm:ss")}
                          </LabelValue>
                        </Col>

                        <Col>
                          <FormLabel className={"topology-label-data"}>
                            Stacktrace:
                          </FormLabel>
                          <LabelValue className={"topology-label-data"}>
                            <input
                              type="text"
                              id={"trace" + index}
                              style={{ display: "none" }}
                              defaultValue={e.stackTrace}
                            />
                            <FontAwesomeIcon
                              icon={faCopy}
                              onClick={() =>
                                this.copyToClipboard("trace" + index)
                              }
                            ></FontAwesomeIcon>
                          </LabelValue>
                        </Col>
                      </Row>
                      <hr className="hr-divider" />
                    </div>
                  );
                })}
              </Row>
            </Collapse>
          </Collapse>
        </Col>
      </Card>
    );
  }
}

export default TopologyCard;
