import { DataTable, FormLabel, ProgressBar } from "@hai/ui-react";
import React, { useEffect, useState } from "react"
import { Col, Row } from "reactstrap";
import axios from "../../infra/axios";

const ToriiUsageBySubscription = props => {
    const [usage, setUsage] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (props.subscriptionId) {
            axios.get(`${process.env.REACT_APP_TORII_API_BASE_URL}/subscriptions/${props?.subscriptionId}/appliance/usage`)
            .then(res => {
                setUsage(res.data);
                setLoading(false);
            }).catch(e => setLoading(false))
        } else {
            setLoading(false);
        }
    },[]);


    if (loading) {
        return (<ProgressBar leftLabel="Loading: "
        barColors={{
            background: '#599bff',
            right: '#599bff',
            left: '#2fccd3',
        }}
        hideRight={true}
        animate={1} />)
    }
    
    return (<>
     <div style={{ marginTop: '2%' }}>
        <Row><Col><b>Current usage</b></Col></Row>
        {usage && 
            <div> 
                <Row>
                <Col>
                    <FormLabel>Peak: <p style={{color: 'white'}}>{usage.peak}</p></FormLabel>
                </Col>
                <Col>
                    <FormLabel>Previous Peak: <p style={{color: 'white'}}>{usage.peakPreviousPeriod}</p></FormLabel>
                </Col>    
                </Row>
                <Row>
                    <Col>
                        {usage?.details && 
                        <DataTable
                        selectable={false}
                        sortable={false}
                        columnStructure={[
                        { fieldKey: 'date', title: 'Date' },
                        { fieldKey: 'peak', title: 'Peak' },
                        { fieldKey: 'family', title: 'Family'}]}
                        compact={true}
                        emptyStateText="No usage data!"
                      >
                        <DataTable.Header />
                        <DataTable.TitleBar
                                  title={"Usage"}
                              />
                              {usage?.details && usage?.details.map((data, index) => {
                                  return <DataTable.Row key={data.id} rowData={data} />;
                              })}
                      </DataTable>
                        }
                    </Col>
                </Row>
            </div>}
    </div>
    </>)
}

export default ToriiUsageBySubscription;