import React from "react";
import propTypes from 'prop-types';
import { Animation , ProgressBar } from "@hai/ui-react";

const Loading = (props) => {

    if (props?.show) {
        return (<ProgressBar
  leftLabel="Loading: "
  barColors={{
    background: '#599bff',
    right: '#599bff',
    left: '#2fccd3',
  }}
  hideRight={true}
  animate={1}
/>);
    } else {return null;}
}

Loading.propTypes = {
    show: propTypes.bool.isRequired
}

export default Loading;