import {
  faCheckCircle,
  faChevronCircleDown,
  faChevronCircleRight,
  faMinusCircle,
  faPlaneArrival,
  faRedo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, TableRow } from "@hai/ui-react";
import axios from "axios";
import cogoToast from "cogo-toast";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Collapse from "reactstrap/lib/Collapse";
import HubUserSubscriptions from "./HubUserSubscriptions";
import PropTypes from "prop-types";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";

const HubUsersRow = (props) => {
  const [collapseSubs, setCollapseSubs] = useState(false);
  const [user, setUser] = useState(props.user);
  const [isUpdating, setIsUpdating] = useState(false);
  const [showDisableModal, setShowDisableModal] = useState(false);

  let navigate = useNavigate();
  const goToContractPage = (orgId) => {
    axios
      .get(
        process.env.REACT_APP_HUB_API_BASE_URL +
          "/Provisions/by-organizations/" +
          orgId
      )
      .then((response) => {
        if (response?.data[0]?.contractId) {
          navigate("/hub/contracts/" + response.data[0].contractId);
        } else {
          cogoToast.error("User organization is not linked to any contract", {
            position: "top-right",
          });
        }
      })
      .catch((error) =>
        cogoToast.error("Contract not found", { position: "top-right" })
      );
  };

  const enableOrDisableUser = () => {
    setIsUpdating(true);
    if (user.isEnabled) {
      axios
        .put(
          process.env.REACT_APP_HUB_API_BASE_URL +
            `/Support/hub/users/${user.id}/disable`
        )
        .then((response) => {
          cogoToast.success("User disabled", { position: "top-right" });
          let tempUser = { ...user };
          tempUser.isEnabled = false;
          setUser(tempUser);
          setIsUpdating(false);
        });
    } else {
      axios
        .put(
          process.env.REACT_APP_HUB_API_BASE_URL +
            `/Support/hub/users/${user.id}/enable`
        )
        .then((response) => {
          cogoToast.success("User enabled", { position: "top-right" });
          let tempUser = { ...user };
          tempUser.isEnabled = true;
          setUser(tempUser);
          setIsUpdating(false);
        });
    }
    setShowDisableModal(false);
  };

  return (
    <>
      <TableRow
checkbox={false}
        alternateRowColors={props.index % 2 === 0}
        style={{
          gridTemplateColumns: "0.2fr 3fr 3fr 3fr 1fr 0.3fr 0.3fr",
        }}
      >
        <div></div>
        <div>{props.user.userName}</div>
        <div>{props.user.email}</div>
        <div>{props.user.isApplication ? "Application" : "User"}</div>
        <div>
          {" "}
          {isUpdating ? (
            <FontAwesomeIcon
              icon={faRedo}
              spin={true}
              color={"red"}
            ></FontAwesomeIcon>
          ) : (
            <FontAwesomeIcon
              style={{ cursor: "pointer" }}
              icon={user.isEnabled ? faCheckCircle : faMinusCircle}
              color={user.isEnabled ? "green" : "red"}
              onClick={() => setShowDisableModal(true)}
            ></FontAwesomeIcon>
          )}
        </div>
        <div>
          <FontAwesomeIcon
            icon={collapseSubs ? faChevronCircleDown : faChevronCircleRight}
            style={{ cursor: "pointer" }}
            color={"#007bff"}
            onClick={() => setCollapseSubs(!collapseSubs)}
            title="Expand subs"
          />
        </div>
        <div>
          <FontAwesomeIcon
            icon={faPlaneArrival}
            style={{ cursor: "pointer" }}
            color={"#007bff"}
            title="Contract page"
            onClick={() => goToContractPage(props.user.organizationId)}
          />
        </div>
      </TableRow>

      <Collapse isOpen={collapseSubs}>
        {collapseSubs && (
          <HubUserSubscriptions userId={props.user.id}></HubUserSubscriptions>
        )}
      </Collapse>

      <Dialog
        content={
          <Row>
            <Col>
              Are you sure you want to {user.isEnabled ? "disable" : "enable"}{" "}
              the user below?
              <br />
              <br />
              <b>Name:</b> {user.userName} <br />
              <b>Email:</b> {user.email}
            </Col>
          </Row>
        }
        title={"Confirm"}
        show={showDisableModal}
        buttons={[
          { label: "Yes", primary: true, onClick: () => enableOrDisableUser() },
          { label: "No", onClick: () => setShowDisableModal(false) },
        ]}
      ></Dialog>
    </>
  );
};

HubUsersRow.propTypes = {
  user: PropTypes.object.isRequired,
  index: PropTypes.number,
};
export default HubUsersRow;
