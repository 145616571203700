import React, { Component } from "react";
import axios from "../../../infra/axios";
import {
  OverlayTrigger,
  List,
  ListHeader,
  TableRow,
  Animation,
  Icon,
  FormLabel,
  FormControl,
  Button,
  Modal,
  ModalHeader,
  ModalTitle,
  ModalFooter,
  FormGroup,
  SelectOption,
 ProgressBar } from "@hai/ui-react";
import { Row, Col, ModalBody } from "reactstrap";

class MakitoDefaultProductLicenseOptionUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showProductLicenseOptionUpdate: false,
      newProduceLicenseOption: this.props.productLicenseOption,
      newDefaultLicenseOption: {
        item: this.props.productLicenseOption?.licenseSectionItem,
        defaultValue: this.props.selectedDefaultLicenseOption?.defaultValue,
        productId: this.props.productId,
        productLicenseOptionId: this.props.productLicenseOption?.id,
      },
      formError: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState((prev) => {
      prev.showProductLicenseOptionUpdate = nextProps.show;
      prev.newProduceLicenseOption = nextProps.productLicenseOption;
      prev.newDefaultLicenseOption.item =
        prev.newProduceLicenseOption?.licenseSectionItem;
      prev.newDefaultLicenseOption.productId = nextProps.productId;
      prev.newDefaultLicenseOption.productLicenseOptionId =
        prev.newProduceLicenseOption?.id;
      if (
        nextProps.selectedDefaultLicenseOption &&
        nextProps.selectedDefaultLicenseOption.defaultValue
      ) {
        prev.newDefaultLicenseOption.defaultValue =
          nextProps.selectedDefaultLicenseOption.defaultValue;
      } else {
        if (
          nextProps.productLicenseOption?.availableValues &&
          !nextProps.productLicenseOption?.availableValues.startsWith("^")
        ) {
          var availables =
            nextProps.productLicenseOption?.availableValues.split(";")[0];
          prev.newDefaultLicenseOption.defaultValue =
            availables.length > 0 ? availables.split("!")[0] : "";
        }
      }

      return prev;
    });
  }

  submitProductLicenseOptionUpdate() {
    //check data
    //this.validateForm();

    if (this.state.formError !== "") {
      return;
    }

    axios
      .post(
        process.env.REACT_APP_LIC_API_BASE_URL +
          "/Products/" +
          this.state.newDefaultLicenseOption.productId +
          "/ProductDefaultOptions/" +
          this.state.newDefaultLicenseOption.productLicenseOptionId,
        this.state.newDefaultLicenseOption
      )
      .then((response) => {
        this.props.handleProductLicenseOptionUpdate();
        this.props.getProduct();
        this.setState((prev) => {
          prev.newProduceLicenseOption = {
            licenseSection: "",
            licenseSectionItem: "",
            description: "",
            valueName: "",
            defaultValue: "",
            availableValues: "",
          };

          prev.newDefaultLicenseOption = {
            item: "",
            defaultValue: "",
            productId: "",
            productLicenseOptionId: "",
          };
          prev.formError = "";
          return prev;
        });
      });
  }

  async validateForm() {
    if (this.state.newProduceLicenseOption.availableValues.startsWith("^")) {
      const regex = new RegExp(
        this.state.newProduceLicenseOption.availableValues
      );
      if (!regex.test(this.state.formControlValue)) {
        this.setState({ formError: "invalid value" });
        alert("Default value is invalid");
      } else {
        this.setState({ formError: "" });
      }
    }
  }

  getValueSection() {
    const availableValues = this.state.newProduceLicenseOption.availableValues;
    if (availableValues.startsWith("^")) {
      return (
        <FormControl
          placeholder="Enter a value"
          defaultValue={this.state.newDefaultLicenseOption?.defaultValue}
          onChange={(e) => {
            var text = e.target.value.trim();
            this.setState((prev) => {
              //prev.newProduceLicenseOption.defaultValue = text;
              prev.newDefaultLicenseOption.defaultValue = text;
              return prev;
            });
          }}
          invalid={!!this.state.formError}
        ></FormControl>
      );
    } else {
      return (
        <FormControl
          placeholder="Select a value"
          as="select"
          defaultValue={this.state.newDefaultLicenseOption?.defaultValue}
          onChange={(e) => {
            var text = e.target.value;
            this.setState((prev) => {
              prev.newDefaultLicenseOption.defaultValue = text;
              return prev;
            });
          }}
        >
          {availableValues &&
            availableValues.split(";").map((o) => {
              var value = o.split("!")[0];
              return <SelectOption key={value} value={value}>{value}</SelectOption>;
            })}
        </FormControl>
      );
    }
  }

  render() {
    return (
      <>
        {this.state.newProduceLicenseOption && (
          <Modal show={this.state.showProductLicenseOptionUpdate}>
            <ModalHeader>
              <ModalTitle>Update Default Licnese Option</ModalTitle>
            </ModalHeader>
            <ModalBody>
              <FormGroup>
                <FormLabel>License Section</FormLabel>
                <FormControl
                  defaultValue={
                    this.state.newProduceLicenseOption.licenseSection
                  }
                  disabled={true}
                />
                <FormLabel>License Section Item</FormLabel>
                <FormControl
                  defaultValue={
                    this.state.newProduceLicenseOption.licenseSectionItem
                  }
                  disabled={true}
                />
                <FormLabel>Description</FormLabel>
                <FormControl
                  defaultValue={this.state.newProduceLicenseOption.description}
                  disabled={true}
                />
                <FormLabel>Display Name</FormLabel>
                <FormControl
                  defaultValue={this.state.newProduceLicenseOption.valueName}
                  disabled={true}
                />
                <FormLabel>Available Values</FormLabel>
                <FormControl
                  defaultValue={
                    this.state.newProduceLicenseOption.availableValues
                  }
                  disabled={true}
                />
                <FormLabel>Default Value</FormLabel>
                {this.getValueSection()}
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button
                variant="primary"
                onClick={() => this.submitProductLicenseOptionUpdate()}
              >
                Submit
              </Button>
              <Button
                onClick={() => this.props.handleProductLicenseOptionUpdate()}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </>
    );
  }
}

export default MakitoDefaultProductLicenseOptionUpdate;
