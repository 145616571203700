import { NotFound , ProgressBar } from "@hai/ui-react";
import React, { Component } from "react";
import SharkNotFound from "../../../static/404_shark.png";

class NotAuthorized_Shark extends Component {
  render() {
    return (
      <NotFound
        backgroundImage={SharkNotFound}
        pageTitle="403 Not Authorized"
        subTitle="HTTP 403 - Not Authorized"
        notFoundMessage="Sorry, you are not authrorized to see this."
      />
    );
  }
}

export default NotAuthorized_Shark;
