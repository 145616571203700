import React from 'react';
import {Modal, ModalTitle, ModalHeader, ModalBody, ModalFooter,
    List, ListHeader, TableRow, Button } from '@hai/ui-react';
import {Row, Col} from 'reactstrap'
import  './RatesModal.scss';

class RatesModal extends React.Component {
    
    
    render = () => { 
        return (
        <Modal size="lg" show={this.props.show} onHide={this.props.hide} >
        <ModalHeader>
            <ModalTitle>Rates</ModalTitle>
        </ModalHeader>
        <ModalBody >
            <List style={{height: '450px', 'overflow-y': 'scroll'}}>
                    <ListHeader
checkbox={false} 
                                style={{gridTemplateColumns: '1fr 2fr 2fr 2fr 2fr 2fr 2fr'}}
                                alternateRowColors='true'>
                    <div>From</div>
                    <div>To</div>
                    <div>Quality</div>
                    <div>Price</div>
                    <div>Unit</div>
                    <div>Threshold</div>
                </ListHeader>

            {this.props.rates?.map((rate, index) => (
                <TableRow
checkbox={false} 
                        key= {index}
                        className="table-item"
                        alternateRowColors = {index % 2 == 0}
                        style={{gridTemplateColumns: '1fr 2fr 2fr 2fr 2fr 2fr 2fr', color:'white'}}
                        >
                                <div>  </div>
                                <div> {rate.from} </div>
                                <div> {rate.to} </div>
                                <div> {rate.quality} </div>
                                <div> {rate.price} </div>          
                                <div> {rate.unitOfMeasure} </div>         
                                <div> {rate.threshold} </div>         
                </TableRow>
            ))}
            </List>
        </ModalBody>
        <ModalFooter>
            <Row>
            <Col>
            <Button variant="secondary" onClick={this.props.hide}>
              Close
            </Button>
            </Col>
            </Row>
            
          </ModalFooter>
        </Modal>
    )
}
}

export default RatesModal;