import React, { useEffect, useState } from "react";
import { Button, LabelValue, FormLabel, Animation , ProgressBar } from "@hai/ui-react";
import { Row, Col, Collapse } from "reactstrap";
import "./TopologyDetails.scss";
import TopologyCard from "./TopologyCard";
import {
  faAngleDown,
  faAngleUp,
  faCloud,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "../../../infra/axios";
import HubletsView from "./HubletsView";
import HubRouteDiagram from "../route/routeDiagram";

const TopologyDetails = (props) => {
  const [subscription, setSubscription] = useState(null);
  const [users, setUsers] = useState(null);
  const [collapseHublet, setCollapseHublet] = useState(false);
  const [orchestratorVersion, setOrchestratorVersion] = useState(null);
  
  const querySubData = async () => {
    let orgId = props.route.organizationId;
    let subId = props.route.subscriptionId;
    await axios
      .get(
        process.env.REACT_APP_HUB_API_BASE_URL +
          `/SrtHub/organizations/${orgId}/subscriptions/${subId}`
      )
      .then((response) => setSubscription(response.data));
  };
  const toggleCollapseHublet = () => setCollapseHublet(!collapseHublet);

  const getUserNames = async () => {
    await axios
      .get(
        process.env.REACT_APP_HUB_API_BASE_URL +
          `/SrtHub/organizations/${props.route.organizationId}/subscriptions/${props.route.subscriptionId}/users`
      )
      .then((response) => {
        setUsers(response.data);
      });
  };

  const getOrchestratorVersion = async () => {
    await axios
      .get(
        process.env.REACT_APP_HUB_API_BASE_URL +
          `/Support/features/orchestrator-v2/subscriptions/${props.route.subscriptionId}`
      )
      .then((response) =>
        setOrchestratorVersion(response.data.orchestratorVersion)
      )
      .catch((err) => setOrchestratorVersion("Not found"));
  };

  useEffect(() => {
    (async function () {
      await querySubData();
      await getUserNames();
      await getOrchestratorVersion();
    })();
  }, []);

  return (
    <div>
      <Row>
        <Col style={{ display: "flex", alignItems: "center" }}>
          <h5 style={{ color: "#007bff" }}>Org Name:&nbsp;</h5>{" "}
          <h5>
            {subscription ? subscription.organizationName : "Loading..."} |{" "}
          </h5>
          <h5 style={{ color: "#007bff" }}>&nbsp;Sub name:&nbsp;</h5>{" "}
          <h5>
            {subscription ? subscription.subscriptionName : "Loading..."} |{" "}
          </h5>
          <h5 style={{ color: "#007bff" }}>&nbsp;Orchestrator:&nbsp;</h5>{" "}
          <h5>{orchestratorVersion ? orchestratorVersion : "Loading..."}</h5>
        </Col>
      </Row>
      <div>
        <hr className="hr-divider" />
        <Row>
          {/* Route Id */}
          <Col sm="2">
            <FormLabel className={"topology-label-data"}>Route Id</FormLabel>
            <LabelValue className={"topology-label-data"}>
              {props.route.id}
            </LabelValue>
          </Col>
          {/* Route Name */}
          <Col sm="2">
            <FormLabel className={"topology-label-data"}>Route Name</FormLabel>
            <LabelValue className={"topology-label-data"}>
              {props.route.name}
            </LabelValue>
          </Col>
          {/* Subscription Id */}
          <Col sm="2">
            <FormLabel className={"topology-label-data"}>
              Subscription Id
            </FormLabel>
            <LabelValue className={"topology-label-data"}>
              {props.route.subscriptionId}
            </LabelValue>
          </Col>
          {/* Organization Id */}
          <Col sm="2">
            <FormLabel className={"topology-label-data"}>
              Organization Id
            </FormLabel>
            <LabelValue className={"topology-label-data"}>
              {props.route.organizationId}
            </LabelValue>
          </Col>

          <Col sm="2">
            <FormLabel className={"topology-label-data"}>Status</FormLabel>
            <LabelValue className={"topology-label-data"}>
              {props.route.status}
            </LabelValue>
          </Col>
          {props.azure && (
            <Col sm="2">
              <div className={"float-right"}>
                <Button>
                  <a
                    href={props.azure.linkToResourceGroup}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link-to-resource-primary"
                  >
                    Resource Group{" "}
                    <FontAwesomeIcon icon={faCloud}></FontAwesomeIcon>
                  </a>
                </Button>
              </div>
            </Col>
          )}
        </Row>

        <hr className="hr-divider" />
        <Col>
          <h4
            style={{ color: "#007bff", cursor: "pointer" }}
            onClick={toggleCollapseHublet}
          >
            Hublets scheme&nbsp;
            <FontAwesomeIcon
              icon={collapseHublet ? faAngleUp : faAngleDown}
            ></FontAwesomeIcon>
          </h4>
        </Col>
        <Collapse isOpen={collapseHublet}>
          {props?.route?.hublets && collapseHublet && (
            // <HubletsView hublets={props.route.hublets}></HubletsView>
            <HubRouteDiagram route={props.route}></HubRouteDiagram>
          )}
        </Collapse>
      </div>
      {!users && <ProgressBar
  leftLabel="Loading: "
  barColors={{
    background: '#599bff',
    right: '#599bff',
    left: '#2fccd3',
  }}
  hideRight={true}
  animate={1}
/>}
      {users && props.topologies && (
        <div
          style={{
            marginTop: "2%",
            overflowY: "auto",
            display: "flex",
            flexFlow: "column",
          }}
        >
          {props.topologies
            .sort((t1, t2) => {
              if (t1.provisionStartTime > t2.provisionStartTime) return -1;
              if (t1.provisionStartTime < t2.provisionStartTime) return 1;
              return 0;
            })
            .map((t, index) => (
              <TopologyCard
                key={index}
                topology={t}
                route={props.route}
                users={users}
              ></TopologyCard>
            ))}
        </div>
      )}
    </div>
  );
};

export default TopologyDetails;
