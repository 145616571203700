import React from "react";
import { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import axios from '../../../infra/axios';
import Loading from "../../common/Loading";
import propTypes from 'prop-types';
import { FormLabel, LabelValue , ProgressBar } from "@hai/ui-react";

const HubDevicePairingCode = (props) => {
    const [tenants, setTenants] = useState(null);
    const [loading, setLoading] = useState(false);

    const getDevicePairingCode = () => {
        setLoading(true);
        axios.get(process.env.REACT_APP_HUB_API_BASE_URL + `/Ems/accounts/hub-subscriptions/${props.hubSubId}/device-pair-code`)
        .then(res => {
            setTenants(res.data);
            setLoading(false);
        })
        .catch(e => setLoading(false));
    }

    useEffect(() => {
        getDevicePairingCode();
    }, [])

    return (
    <Row>
        <Loading show={loading}></Loading>
        {tenants && tenants.map(t => {
           return (<Col>
           <FormLabel>{t.tenantName} ({t.tenantId})</FormLabel>
           <LabelValue>{t.deviceCode}</LabelValue>
           </Col>)
        }
        )}

    </Row>);
}
HubDevicePairingCode.propTypes = {
    hubSubId: propTypes.string.isRequired
}

export default HubDevicePairingCode;