import React from "react";
import { Col, Row, Spinner } from "reactstrap";
import { FormControl, List, ListHeader, Animation , ProgressBar } from "@hai/ui-react";
import axios from "axios";
import OrganizationLine from "./OrganizationLine";

class OrganizationTable extends React.Component {
  state = {
    allOrganizations: [],
    organizations: [],
    subscriptions: [],
    loading: true,
    sortState: null,
  };

  async getOrganizations() {
    await axios
      .get(process.env.REACT_APP_HUB_API_BASE_URL + "/SrtHub/organizations")
      .then((response) => {
        this.setState({
          organizations: response.data,
          allOrganizations: response.data,
          loading: false,
        });
      })
      .catch((error) => this.setState({ loading: false }));
  }

  async getSubscriptions(orgId) {
    await axios
      .get(
        process.env.REACT_APP_HUB_API_BASE_URL +
          "/SrtHub/organizations/" +
          orgId +
          "/subscriptions"
      )
      .then((response) => {
        this.setState({ subscriptions: response.data, loading: false });
      })
      .catch((error) => this.setState({ loading: false }));
  }

  componentDidMount() {
    this.getOrganizations();
  }

  filterOrgs(value) {
    if (value == null || value === "") {
      this.setState({ organizations: this.state.allOrganizations });
    } else {
      let filtered = this.state.organizations.filter((c) => {
        let valueUp = value.toUpperCase();
        if (c.domain?.toUpperCase().includes(valueUp)) return true;
        if (c.name?.toUpperCase().includes(valueUp)) return true;
        if (c.organizationId?.toUpperCase().includes(valueUp)) return true;
      });
      this.setState({ organizations: filtered });
    }
  }

  render() {
    if (this.state.loading) {
      return (
        <Row>
          <Col sm="12" md="12">
            {" "}
            {this.state.loading && (
              <ProgressBar
  leftLabel="Loading: "
  barColors={{
    background: '#599bff',
    right: '#599bff',
    left: '#2fccd3',
  }}
  hideRight={true}
  animate={1}
/>
            )}
          </Col>
        </Row>
      );
    } else {
      return (
        <div>
          <FormControl
            name="filter"
            placeholder="Find by domain, name or orgId"
            onChange={(e) => this.filterOrgs(e.target.value)}
          ></FormControl>
          <hr />
          <List>
            <ListHeader
checkbox={false}
              style={{ gridTemplateColumns: "0.3fr 2fr 2fr 2fr 0.2fr 0.2fr" }}
              alternateRowColors="true"
            >
              <div></div>
              <div>Org Name</div>
              <div>Org Domain</div>
              <div>Org Id</div>
              <div></div>
            </ListHeader>

            {this.state.organizations
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((org, index) => (
                <div key={org.organizationId}>
                  <OrganizationLine
                    org={org}
                    index={index}
                    subsViewGrid={this.props.subsViewGrid}
                  ></OrganizationLine>
                </div>
              ))}
          </List>
        </div>
      );
    }
  }
}

export default OrganizationTable;
