import React from "react";
import { Col, Row, Spinner } from "reactstrap";
import { FormControl, List, ListHeader, Animation , ProgressBar } from "@hai/ui-react";
import FeatureFlagTableRow from "./FeatureFlagTableRow";
import axios from "../../../infra/axios";

class FeatureFlagTable extends React.Component {
  state = {
    allFeaturesFlags: [],
    featureflags: [],
    loading: true,
    subNames: new Map(),
  };

  async getFeatureFlags() {
    await axios
      .get(process.env.REACT_APP_HUB_API_BASE_URL + "/Support/features")
      .then((response) => {
        var ff = response.data
          .sort((a, b) => a.id.localeCompare(b.id))
          .filter((a) => a.id.length < 36);
        var hublet = response.data
          .sort((a, b) => a.id.localeCompare(b.id))
          .filter((a) => a.id.length >= 36);
        this.setState({
          allFeaturesFlags: ff.concat(hublet),
          featureflags: ff.concat(hublet),
          loading: false,
        });
      });
  }

  filterFF(value) {
    if (value == null || value === "") {
      this.setState({ featureflags: this.state.allFeaturesFlags });
    } else {
      if (value.length >= 3) {
        let filtered = this.state.featureflags.filter((c) => {
          let valueUp = value.toUpperCase();
          if (c.id?.toUpperCase().includes(valueUp)) return true;
          if (c.description?.toUpperCase().includes(valueUp)) return true;
        });
        this.setState({ featureflags: filtered });
      }
    }
  }

  componentDidMount() {
    this.getFeatureFlags();
  }

  render() {
    if (this.state.loading) {
      return (
        <Row>
          <Col sm="12" md="12">
            {" "}
            {this.state.loading && (
              <ProgressBar
  leftLabel="Loading: "
  barColors={{
    background: '#599bff',
    right: '#599bff',
    left: '#2fccd3',
  }}
  hideRight={true}
  animate={1}
/>
            )}
          </Col>
        </Row>
      );
    } else {
      return (
        <div>
          <FormControl
            name="filter"
            placeholder="Type to filter"
            onChange={(e) => this.filterFF(e.target.value)}
          ></FormControl>
          <hr />
          <List>
            <ListHeader
checkbox={false}
              style={{
                gridTemplateColumns:
                  "0.3fr 2fr 2fr 0.5fr 0.5fr 0.5fr 0.2fr 0.2fr",
              }}
              alternateRowColors="true"
            >
              <div></div>
<div>Feature Flag</div>
<div>Description</div>
<div>Global Flag</div>
<div>Subs</div>
<div>Orgs</div>
<div></div>
            </ListHeader>
            <div>
              {this.state.featureflags.map((ff, index) => {
                return (
                  <FeatureFlagTableRow
                    featureflag={ff}
                    index={index}
                    key={ff.id}
                    getFeatureFlags={this.getFeatureFlags.bind(this)}
                  ></FeatureFlagTableRow>
                );
              })}
            </div>
          </List>
        </div>
      );
    }
  }
}

export default FeatureFlagTable;
