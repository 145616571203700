import React from "react";
import PropTypes from "prop-types";
import { useState } from "react";
import { useEffect } from "react";
import Select from "react-select";
import axios from "../../../infra/axios";
import { handleError } from "../../common/ErrorHandler";
import { Animation , ProgressBar } from "@hai/ui-react";

const SubscriptionSelect = (props) => {
  const [options, setOptions] = useState(null);
  const [loading, setLoading] = useState(null);

  useEffect(() => {
    setLoading(true);
    axios
      .get(process.env.REACT_APP_HUB_API_BASE_URL + `/SrtHub/subscriptions`)
      .then((response) => {
        let subs = response.data.map((s) => {
          return {
            label: `${s.subscriptionName} (${s.subscriptionId})`,
            value: s.subscriptionId,
          };
        });
        subs.unshift({ label: "", value: null });
        setOptions(subs);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted red",
      color: "red",
      background: "white",
    }),
  };

  return (
    <>
      {loading && <ProgressBar
  leftLabel="Loading: "
  barColors={{
    background: '#599bff',
    right: '#599bff',
    left: '#2fccd3',
  }}
  hideRight={true}
  animate={1}
/>}
      {!loading && (
        <Select
          options={options}
          isSearchable
          styles={customStyles}
          onChange={(sub) => props.onChange(sub)}
        ></Select>
      )}
    </>
  );
};

SubscriptionSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default SubscriptionSelect;
