import React, { Component } from 'react';
import Content from './Content/Content';
import HaiSideBar from './SideBar/HaiSideBar';

class Layout extends Component {

  constructor(props) {
    super(props);
    this.state ={
      sideBarOpen : true
    };
  }
  static displayName = Layout.name;
  
  toggleSideBar = () => {
    this.setState({sideBarOpen : !this.state.sideBarOpen })
  }

  render () {
    return (
      <div className="App">
        <HaiSideBar></HaiSideBar>
        <Content>
        </Content>
      </div>
    );
  }
}


export default Layout;