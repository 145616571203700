import {
  Button,
  FormControl,
  FormGroup,
  FormLabel,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Spinner,
 ProgressBar } from "@hai/ui-react";
import cogoToast from "cogo-toast";
import React from "react";
import { useEffect, useState } from "react";
import Select from "react-select";
import { Col } from "reactstrap";
import axios from "../../../infra/axios";

/**
 *
 * @param {*} show boolean to show modal
 * @param {*} hide function to execute onHide
 */
const RoleModal = (props) => {
  const [roles, setRoles] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [userRoleId, setUserRole] = useState(null);
  const [sending, setSending] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    queryRoles();
  }, []);

  const queryRoles = () => {
    setLoading(true);
    axios
      .get(process.env.REACT_APP_HUB_API_BASE_URL + `/srthub/roles`)
      .then((response) => {
        let temp = response.data.map((r) => {
          return { label: r.description, value: r.id };
        });
        setRoles(temp);
        setLoading(false);
      })
      .catch((err) => setLoading(false));
  };

  const sendInvite = () => {
    if (!userEmail) {
      cogoToast.warn("Email is required", { position: "top-right" });
      return;
    }
    if (!userRoleId) {
      cogoToast.warn("Role is required", { position: "top-right" });
      return;
    }
    let body = {
      invitations: [{ emailAddress: userEmail, roleId: userRoleId }],
      message:
        "You were invited from Jellyfish to Subscription: " +
        props.subscriptionId,
    };
    axios
      .put(
        process.env.REACT_APP_HUB_API_BASE_URL +
          `/SrtHub/organizations/${props.organizationId}/subscriptions/${props.subscriptionId}/users/roles`,
        body
      )
      .then((response) => {
        cogoToast.success("User invited", { position: "top-right" });
        setSending(false);
      })
      .catch((error) => {
        setSending(false);
      });
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted red",
      color: "red",
      background: "white",
    }),
  };

  return (
    <Modal size="md" show={props.show} onHide={props.onHide}>
      <ModalHeader>
        <ModalTitle>Invite user</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <Col>
          <FormGroup>
            <FormLabel required style={{ color: "#007bff" }}>
              User e-mail
            </FormLabel>
            <FormControl
              name="useremail"
              defaultValue={userEmail}
              onChange={({ target: { value } }) => setUserEmail(value)}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <FormLabel required style={{ color: "#007bff" }}>
              Role
            </FormLabel>
            {loading && <Spinner spinnerColor="red"></Spinner>}
            {!loading && (
              <Select
                isSearchable
                options={roles}
                isLoading={loading}
                styles={customStyles}
                label="Role"
                onChange={(role) => setUserRole(role.value)}
              />
            )}
          </FormGroup>
        </Col>
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => props.onHide()}>Cancel</Button>
        <Button variant="primary" onClick={sendInvite} disabled={sending}>
          Send
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default RoleModal;
