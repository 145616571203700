import { DataTable, FormLabel } from "@hai/ui-react";
import React from "react";
import { Col, Row } from "reactstrap";

const AwPro4SeriesDataTable = props => {

    return (
        <>
            <Row>
                <p style={{ fontSize: '1.4rem', fontStyle: 'italic' }}>Pro 4 Series</p>
            </Row>
            <Row>
                <Col><FormLabel> <span style={{ fontStyle: 'italic' }}>UUID:</span> <p>{props.pro4?.uuid}</p></FormLabel> </Col>
                <Col><FormLabel> <span style={{ fontStyle: 'italic' }}>Mac Address:</span> <p>{props.pro4?.macAddress1}</p></FormLabel> </Col>
                <Col><FormLabel> <span style={{ fontStyle: 'italic' }}>Mac Address 2:</span> <p>{props.pro4?.macAddress2}</p></FormLabel> </Col>
                <Col><FormLabel> <span style={{ fontStyle: 'italic' }}>Name:</span> <p>{props.pro4?.productName}</p></FormLabel> </Col>
                <Col><FormLabel> <span style={{ fontStyle: 'italic' }}>Kereon version:</span> <p>{props.pro4?.kereonVersion}</p></FormLabel> </Col>
            </Row>
            <Row>
                <Col><FormLabel> <span style={{ fontStyle: 'italic' }}>Version:</span> <p>{props.pro4?.version}</p></FormLabel> </Col>
                <Col><FormLabel> <span style={{ fontStyle: 'italic' }}>Sirocco Id:</span> <p>{props.pro4?.idSirocco}</p></FormLabel> </Col>
                <Col><FormLabel> <span style={{ fontStyle: 'italic' }}>Tresco Id:</span> <p>{props.pro4?.idTresco}</p></FormLabel> </Col>
                <Col><FormLabel> <span style={{ fontStyle: 'italic' }}>Mercury Id:</span> <p>{props.pro4?.idMercury}</p></FormLabel> </Col>
                <Col><FormLabel> <span style={{ fontStyle: 'italic' }}>Chergui Id:</span> <p>{props.pro4?.idChergui}</p></FormLabel> </Col>
            </Row>

            <Row>
                <Col><FormLabel> <span style={{ fontStyle: 'italic' }}>Lavezzi Id:</span> <p>{props.pro4?.idLavezzi}</p></FormLabel> </Col>
            </Row>
        </>
    )
}

export default AwPro4SeriesDataTable;