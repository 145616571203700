import {
  faChevronCircleDown,
  faChevronCircleRight,
  faCog,
  faExternalLinkAlt,
  faSync,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import moment from "moment";
import { Animation, Spinner, TableRow , ProgressBar } from "@hai/ui-react";
import { Collapse } from "reactstrap";
import { useState } from "react";
import AssetsTable from "./AssetsTable";
import ContractItemsTable from "./ContractItemsTable";
import axios from "../../../infra/axios";
import ProvisionsTable from "../provisions/ProvisionsTable";
import DeviceByAccountTable from "../ems/DeviceByAccountTable";

const ContractRow = (props) => {
  useEffect(() => {
    if (props.contract) {
      setContract(props.contract);
    }
  }, [props.contract]);

  const [contract, setContract] = useState(null);
  const [emsAccount, setEmsAccount] = useState(null);
  const [loadingAssets, setLoadingAssets] = useState(false);
  const [collapseSubs, setCollapseSubs] = useState(false);

  const toggleCollapseSubs = async () => {
    if (!collapseSubs) {
      setLoadingAssets(true);
      await axios
        .get(
          process.env.REACT_APP_HUB_API_BASE_URL +
            `/HubServiceContract/${contract.id}/HubAssets`
        )
        .then((resposne_sf) => {
          contract.assets = resposne_sf.data;
          setLoadingAssets(false);
        });
    } else {
      contract.assets = null;
    }
    setCollapseSubs(!collapseSubs);
  };

  const [loadingContractLineItems, setLoadingContractLineItems] = useState(false);
  const [collapseItems, setCollapseItems] = useState(false);

  const toggleCollapseItems = async () => {
    if (!collapseItems) {
      setLoadingContractLineItems(true);
      axios
        .get(
          process.env.REACT_APP_HUB_API_BASE_URL +
            `/HubServiceContract/${contract.id}/HubContractLineItems`
        )
        .then((resposne_sf) => {
          contract.contractLineItems = resposne_sf.data;
          setLoadingContractLineItems(false);
        });

        if (contract.hubOrgId) {
          axios.get(process.env.REACT_APP_HUB_API_BASE_URL + `/EmsApi/accounts/hub/${contract.hubOrgId}`).then(res => {
            setEmsAccount(res.data);
          });
        }

    } else {
      contract.contractLineItems = null;
    }

    setCollapseItems(!collapseItems);
  };

  const [loadingProvisions, setLoadingProvisions] = useState(false);
  const [collapseProvisions, setCollapseProvisions] = useState(false);
  
  const toggleCollapseProvisions = async () => {
    if (!collapseProvisions) {
      setLoadingProvisions(true);
      await axios
        .get(
          process.env.REACT_APP_HUB_API_BASE_URL +
            `/Provisions/by-contracts/${contract.id}`
        )
        .then((provisionResponse) => {
          contract.provisions = provisionResponse.data;
          setLoadingProvisions(false);
        });
    } else {
      contract.provisions = null;
    }
    setCollapseProvisions(!collapseProvisions);
  };

  const navigateToContractDetails = () => {
    window.open("/hub/contracts/" + props.contract.id, "_blank");
  };

  const [refreshingContract, setRefreshingContract] = useState(false);

  const refreshSF = async (id) => {
    if (!refreshingContract) {
      setRefreshingContract(true);

      await axios
        .get(
          process.env.REACT_APP_HUB_API_BASE_URL + `/HubServiceContract/${id}`
        )
        .then(async (contractResponse) => {
          setContract(contractResponse.data);
        });
      setRefreshingContract(false);
    }
  };

  if (contract) {
    if (contract?.id !== props?.contract?.id) setContract(props.contract);
    return (
      <>
        <TableRow
          checkbox={false}
          className="table-item"
          alternateRowColors={props.index % 2 === 0}
          style={{
            gridTemplateColumns:
              "0.1fr 1.5fr 3fr 3fr 1.4fr 2fr 1fr 0.5fr 0.5fr 0.5fr 0.5fr",
            color: "white",
            fontSize: "0.8rem",
          }}
        >
          <div> </div>
          <div style={{ display: "flex", alignItems:"center" }}>
            <a
              style={{ color: "#007bff" }}
              href={`https://haivision.lightning.force.com/${contract.id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {contract.name}
            </a>
          </div>
          <div style={{ display: "flex", alignItems:"center" }}>{contract.accountName} </div>
          <div>
            {contract.orgDomain}
            <br />
            {contract.hubOrgId}
          </div>
          <div>
            {moment(contract.startDate).format("MM/DD/YYYY")}
            <br />
            {moment(contract.endDate).format("MM/DD/YYYY")}
          </div>
          <div style={{overflowWrap: 'break-word'}}>
            {contract.claimCode}
            <br />
            {contract.activationDate
              ? moment(contract.activationDate).format("MM/DD/YYYY")
              : ""}
          </div>
          <div style={{ display: "flex", alignItems:"center" }}>{contract.status} </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              color: "#007bff",
            }}
            onClick={() => toggleCollapseSubs()}
          >
            {loadingAssets && <Spinner/>}
            {!loadingAssets && (
              <FontAwesomeIcon
                icon={collapseSubs ? faChevronCircleDown : faChevronCircleRight}
              />
            )}
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              color: "#ff99ff",
            }}
            onClick={() => toggleCollapseItems()}
          >
            {loadingContractLineItems && <Spinner/>}
            {!loadingContractLineItems && (
              <FontAwesomeIcon
                icon={
                  collapseItems ? faChevronCircleDown : faChevronCircleRight
                }
              />
            )}
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              color: "#F6FF33",
            }}
            onClick={() => toggleCollapseProvisions()}
          >
            {loadingProvisions && <Spinner/>}
            {!loadingProvisions && (
              <FontAwesomeIcon
                icon={
                  collapseProvisions
                    ? faChevronCircleDown
                    : faChevronCircleRight
                }
              />
            )}
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              color: "#007bff",
            }}
          >
            <FontAwesomeIcon
              color={refreshingContract ? "grey" : "#007bff"}
              icon={faSync}
              spin={refreshingContract}
              onClick={() => refreshSF(contract.id)}
            ></FontAwesomeIcon>
            &nbsp;
            <FontAwesomeIcon
              icon={faCog}
              onClick={() => navigateToContractDetails()}
            ></FontAwesomeIcon>
          </div>
        </TableRow>

        <Collapse isOpen={collapseSubs}>
          {collapseSubs && (
            <AssetsTable
              contract={contract}
              history={props.history}
            ></AssetsTable>
          )}
        </Collapse>

        <Collapse isOpen={collapseItems}>
          {collapseItems &&
          <>
            <ContractItemsTable
              organizationId={contract.hubOrgId}
              contractItems={contract.contractLineItems}
              isDetails={false}
            ></ContractItemsTable>

            {/* SHOW EMS ACCOUNT/TENANT */}
            {emsAccount && <div
            style={{
              padding: "1%",
              border: "1px #ff99ff groove",
              borderRadius: "5px",
              marginTop: "5px",
              marginBottom: "3px"
            }}>
              <p onClick={() => window.open("/ems/accounts/" + emsAccount.id, "_blank")} style={{cursor: 'pointer', color: '#ff99ff'}}>
                Ems Account: {emsAccount.name} - {emsAccount.id} {" "}
                <FontAwesomeIcon icon={faExternalLinkAlt} color="#ff99ff"/>
                </p>
          <DeviceByAccountTable account={emsAccount} selectedAccount={emsAccount.id}/>
          </div>
            }
          </>
          }
        </Collapse>

        <Collapse isOpen={collapseProvisions}>
          {collapseProvisions && (
            <ProvisionsTable provisions={contract.provisions} />
          )}
        </Collapse>
      </>
    );
  } else {
    return <ProgressBar
  leftLabel="Loading: "
  barColors={{
    background: '#599bff',
    right: '#599bff',
    left: '#2fccd3',
  }}
  hideRight={true}
  animate={1}
/>;
  }
};

export default ContractRow;
