import { Animation, Button, FormLabel, LabelValue, Modal, ModalBody, Notification, Spinner , ProgressBar } from "@hai/ui-react";
import React, { Component, useEffect, useState } from "react";
import { ModalHeader } from "reactstrap";
import axios from "../../../infra/axios";
import ApiCredentialTable from "./ApiCredentialTable";

const ApiCredential = (props) => {
  const [loading, setLoading] = useState(true);
  const [subscriptionId, setSubscriptionId] = useState(props.subscriptionId);
  const [apiCredentials, setApiCredentials] = useState(null);
  const [creatingCredential, setCreatingCredential] = useState(false);
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    loadApiCredentials();
  }, []);

  const loadApiCredentials = async () => {
    setLoading(true);
    await axios
      .get(
        process.env.REACT_APP_HUB_API_BASE_URL +
          "/HubApiCredentials/subscriptions/" +
          subscriptionId
      )
      .then((response) => {
        setApiCredentials(response.data);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  };

  const createApiCredentials = async () => {
    setCreatingCredential(true);
    axios
      .post(
        process.env.REACT_APP_HUB_API_BASE_URL + "/HubApiCredentials/create",
        {
          customerName: props.customerName,
          hubOrganization: props.organizationId,
          hubSubscription: props.subscriptionId,
          hubSubscriptionRole: "operator",
        }
      )
      .then((response) => {
        setLoading(false);
        setCreatingCredential(false);
        setShowNotification(true);
        loadApiCredentials();
      })
      .catch((e) => {
        setLoading(false);
        setCreatingCredential(false);
      });
  };

  const renewApiCredentials = async (hubApiCredentialId) => {
    await axios
      .put(
        process.env.REACT_APP_HUB_API_BASE_URL +
          "/HubApiCredentials/" +
          hubApiCredentialId +
          "/renew-key"
      )
      .then((response) => loadApiCredentials());
  };

  const rotateApiCredentials = async (hubApiCredentialId) => {
    await axios
      .put(
        process.env.REACT_APP_HUB_API_BASE_URL +
          "/HubApiCredentials/" +
          hubApiCredentialId +
          "/rotate-key"
      )
      .then((response) => loadApiCredentials());
  };

  const removeApiCredentials = async (hubApiCredentialId) => {
    await axios
      .delete(
        process.env.REACT_APP_HUB_API_BASE_URL +
          "/HubApiCredentials/" +
          hubApiCredentialId +
          "/remove-key"
      )
      .then((response) => loadApiCredentials());
  };

  return (
    <>
      {loading && <ProgressBar
  leftLabel="Loading: "
  barColors={{
    background: '#599bff',
    right: '#599bff',
    left: '#2fccd3',
  }}
  hideRight={true}
  animate={1}
/>}
      {!loading && (
        <div>
          <Button
            variant="primary"
            onClick={() => createApiCredentials()}
            disabled={creatingCredential}
          >
            Create Api Credentials
          </Button> 
          {
            creatingCredential && <ProgressBar
  leftLabel="Loading: "
  barColors={{
    background: '#599bff',
    right: '#599bff',
    left: '#2fccd3',
  }}
  hideRight={true}
  animate={1}
/>
          }
          
          <ApiCredentialTable
            apiCredentials={apiCredentials}
            renewAction={renewApiCredentials}
            rotateAction={rotateApiCredentials}
            removeAction={removeApiCredentials}
          />
        </div>
      )}
          <Notification
        style={{ top: "1rem", right: "1rem" }}
        show={showNotification}
        description="Success."
        message="Credentials created."
        autohide={true}
        variant="Success"
        onDismiss={() => setShowNotification(false)}
      />

     
    </>
  );
};

export default ApiCredential;
