import React, {useState, useEffect} from 'react'
import { Header, 
   Button,
  Icon,
  List,
  ListHeader,
  TableRow,
  OverlayTrigger,
  FormLabel,
  FormControl, 
  ProgressBar,
  Pagination,
  Spinner} from "@hai/ui-react";
  import { Col, Row } from "reactstrap";
  import axios from "../../../infra/axios";
  import UUTSAdd from "./haivision-uuts-add";

const HaivisionUUT = () =>{

  const [uuts, SetUUTs] = useState(null);
  const [showCreateUUTModal, setShowCreateUUTModal] = useState(false);
  const [filteredUUTs, SetfilteredUUTs] = useState(null);
  const [loading, setLoading] = useState(false);
  const [uutFilter, setUUTFilter] = useState('');
  const [searchParamDto, setSearchParamDto] = useState({
    PageNumber: 1,
    PageSize: 10,
    ColumnFilters: '',//json string,
    OrderBy:JSON.stringify([{'Id':'DateCreated', 'Desc':true}])
  });

  useEffect(() => {
    getUUTs(1);

  }, []);

  const getUUTs = (currentPage) => {

    if(currentPage == undefined || currentPage == null){
      currentPage = 1;
    }
    setLoading(true);
    var postDto = searchParamDto;
    postDto.PageNumber = currentPage;
    axios
    .get(
    process.env.REACT_APP_PRODUCTION_API_BASE_URL +
        `/Legacy/Units/filteredUnits?PageNumber=${currentPage}&PageSize=${searchParamDto.PageSize}&ColumnFilters=${searchParamDto.ColumnFilters}&orderBy=${searchParamDto.OrderBy}`
    )
    .then((response) => {
        if(response.data){
            const uUTsAll = response.data;
            SetUUTs(uUTsAll);
            SetfilteredUUTs(uUTsAll.data);
        }
        setLoading(false);
    });    
};

  const OnHideCreateModel = () =>{
    setShowCreateUUTModal(false);
  }

  const handleDetails = (id, sn) => {
    window.location = window.location + "/" + id+ "/" + sn;
  }

  return (
    <div>
    { loading && <ProgressBar
        leftLabel="Loading: "
        barColors={{
            background: '#599bff',
            right: '#599bff',
            left: '#2fccd3',
        }}
        hideRight={true}
        animate={1}
    />}
    {<div style={{marginTop: "1%" }}>
        <Header title="Units" />
        <br/>
        <Row style={{ alignItems: "flex-end" }}>
            <Col>
              <FormLabel>Filter by Serial Number</FormLabel>
              <FormControl
                placeholder="Search by Serial Number"
                value = {uutFilter}
                onChange={(e) => {
                  setUUTFilter(e.target.value);

                  var ColumnFilters =  [{
                    id:'SerialNumber',
                    Value:e.target.value,
                    Comparator:'Contains'
                  }];
              
                  setSearchParamDto({...searchParamDto,
                    ColumnFilters : JSON.stringify(ColumnFilters)
                  });
                }}
              />
            </Col>
            <Col>
            <Button
              variant="primary"
              onClick={() => {getUUTs()}}>
              Search
            </Button>
            </Col>
        </Row>
        <br/>
        <Row>
        <Col>
          <Button variant="primary"  onClick={() => setShowCreateUUTModal(true)}>
            Create Unit
          </Button>
          </Col>
        </Row>
          <br/>
        <List>
          <ListHeader
            checkbox={false}
            style={{
              gridTemplateColumns:
                "1fr 2fr 3fr 2fr 1fr",
            }}
          >
            <div>Model</div>
            <div>Serial Number</div>
            <div>Mac Address</div>
            <div>Date Created</div>
            <div>Action</div>
          </ListHeader>
          {filteredUUTs &&
            filteredUUTs.map((uut) => {
              return (
                <div key={uut.id}>
                  <TableRow
                  checkbox={false}
                    style={{
                      gridTemplateColumns:
                        "1fr 2fr 3fr 2fr 1fr",
                    }}
                  >
                  <div>{uut.model.description}</div>
                  <div>{uut.serialNumber}</div>
                  <div>{uut.macAddress}</div>
                  <div>{new Date(uut.dateCreated).toUTCString()}</div>
                  <div style={{ display: "inline-flex" }}>
                        <OverlayTrigger
                          popoverContent={"View the test result of unit"}
                          style={{ cursor: "pointer", paddingRight: "10px" }}
                          onClick={() => handleDetails(uut.id, uut.serialNumber)}
                        >
                          <Icon
                            
                            iconname="View"
                            style={{ fill: "#007bff" }}

                          />
                        </OverlayTrigger>
                      </div>
                  </TableRow>
                </div>
              );
            })}
        </List>
        <div>
        <br/>
        <Pagination
        pageCount={uuts?.totalPages}
        currentPage={searchParamDto?.PageNumber}
        onPageChange={(newPage, previousPage) => {
          if (uuts.hasNextPage || newPage <= uuts?.totalPages) {
            setSearchParamDto({ ...searchParamDto,PageNumber: newPage });
            getUUTs(newPage);
          } else {
            alert('No next page');
            return;
          }

        }}
      />
        </div>
        <UUTSAdd
          show={showCreateUUTModal}
          onHide={()=> OnHideCreateModel()}
          onLoadUUTs={()=> getUUTs()}
        />
      </div>}
      </div>
      
    );

}

export default HaivisionUUT;