import {
  faCheckCircle,
  faCog,
  faMinusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Card, CardBody, CardFooter } from "reactstrap";
import "./SubscriptionCardItem.scss";

const SubscriptionCardItem = (props) => {
  return (
    <div style={{ margin: "1%" }}>
      <Card body inverse className="sub-card">
        <CardBody>
          <p>
            {props.sub.subscriptionName}{" "}
            {props.sub.isDisabled ? (
              <FontAwesomeIcon icon={faMinusCircle} color="red" />
            ) : (
              <FontAwesomeIcon icon={faCheckCircle} color="green" />
            )}
          </p>
          <p>{props.sub.subscriptionId}</p>
          <p></p>
        </CardBody>
        <CardFooter>
          <div className="float-right">
            <FontAwesomeIcon icon={faCog} color="#007bff" />
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};

export default SubscriptionCardItem;
