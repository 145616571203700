import React from "react";
import {
  Modal,
  ModalTitle,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  FormLabel,
  FormControl,
 ProgressBar } from "@hai/ui-react";
import { Row, Col } from "reactstrap";
import axios from "axios";

class ProvisoinDeleteModal extends React.Component {
  deleteProvision(id) {
    axios
      .delete(
        process.env.REACT_APP_HUB_API_BASE_URL + "/Provisions/delete/" + id
      )
      .then((response) => {
        if (response.status == 200) {
          this.props.successNotification();
          this.props.hide(id);
        } else {
          alert("delete failed");
        }
      });
  }

  render = () => {
    return (
      <Modal show={this.props.show} size="lg" onHide={this.props.hide}>
        <ModalHeader>
          <ModalTitle>Delete</ModalTitle>
        </ModalHeader>
        <ModalBody>
          Are you sure deleting the following claim code?
          <br />
          <br />
          <ul>
            <li>"{this.props.provision.claimCode}"</li>
          </ul>
        </ModalBody>
        <ModalFooter>
          <Row>
            <Col>
              <Button
                variant="primary"
                onClick={() => this.deleteProvision(this.props.provision.id)}
              >
                Confirm
              </Button>
            </Col>
            <Col>
              <Button variant="secondary" onClick={() => this.props.hide()}>
                Cancel
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    );
  };
}

export default ProvisoinDeleteModal;
