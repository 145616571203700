import React, { useEffect, useState } from "react"
import axios from "../../infra/axios";
import { DataTable, ProgressBar } from "@hai/ui-react";
import { Col, Row } from "reactstrap";

const ToriiSalesforceAssets = props => {
    const [assets, setAssets] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (props?.contractId) {
            axios.get(`${process.env.REACT_APP_TORII_API_BASE_URL}/service-contracts/${props?.contractId}/assets`)
            .then(res => {
                setAssets(res.data);
                setLoading(false);
            }).catch(e => setLoading(false))
        } else {
            setLoading(false);
        }
    },[]);

    if (loading) {
        return (<ProgressBar leftLabel="Loading: "
        barColors={{
            background: '#599bff',
            right: '#599bff',
            left: '#2fccd3',
        }}
        hideRight={true}
        animate={1} />)
    }

    return (<>
    {!props?.contractId && <p>No contract set to find assets.</p>}
    <div style={{ marginTop: '2%' }}>
        <DataTable
          selectable={false}
          sortable={false}
          columnStructure={[
            { fieldKey: 'name', title: 'Name' },
          { fieldKey: 'serialNumber', title: 'Serial #' },
          { fieldKey: 'macAddresses', title: 'MAC Address'},
          { fieldKey: 'status', title: 'Status' },
          { fieldKey: 'productName', title: 'Product' },
          { fieldKey: 'productUse', title: 'Product Use' }]}
          compact={true}
          emptyStateText="No asset!"
        >
          <DataTable.Header />
          <DataTable.TitleBar
                    title={"Assets"}
                />
                {assets && assets.map((data, index) => {
                    return <DataTable.Row key={data.id} rowData={data} />;
                })}
        </DataTable>
    </div>
</>)
}

export default ToriiSalesforceAssets;