import React, { Component, useEffect, useState } from "react";
import axios from "../../../infra/axios";
import {
  Header,
  Icon,
  List,
  ListHeader,
  TableRow,
  Button,
  FormGroup,
  SelectOption,
  FormControl,
  FormLabel,
  OverlayTrigger,
  Modal,
  ModalHeader,
  ModalTitle,
  ModalBody,
  ModalFooter,
  ProgressBar,
  Link,
  Dialog,
  Notification
} from "@hai/ui-react";
import { Fragment } from "react";
import { Col, Row } from "reactstrap";
import HaivisionProductTypeVersionAdd from "./haivision-producttype-version-add";
import HaivisionProductLicenseOptionAdd from "./haivision-product-license-option-add";
import HaivisionProductLicenseOptionUpdate from "./haivision-product-license-option-update";
import ProductVersionMetadata from "./haivision-product-version-metadata-add-update";
import { useParams } from "react-router-dom";
import moment from "moment";
import ProductVersionImageFileUpload from "./haivision-product-version-image-upload";
import HaivisionProductTypeAdd from "./haivision-producttype-add";

const HaivisionProductTypeDetail = (props) => {
  let { productTypeIdParam } = useParams();
  const [productTypeId, setProductTypeId] = useState(
    props.productTypeId == null ? productTypeIdParam : props.productTypeId
  );
  const [productType, setProductType] = useState("");
  const [versions, setVersions] = useState(null);
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showNoVersionWarnning, setShowNoVersionWarnning] = useState(false);
  const [showProductVersionAdd, setShowProductVersionAdd] = useState(false);
  const [showProductLicenseOptionAdd, setShowProductLicenseOptionAdd] =
    useState(false);
  const [showProductLicenseOptionUpdate, setShowProductLicenseOptionUpdate] =
    useState(false);
  const [updateProductLicenseOption, setUpdateProductLicenseOption] =
    useState(null);
  const [isUpdateVersion, setIsUpdateVersion] = useState(false);
  const [
    showProductVersionImageAddUpdate,
    SetShowProductVersionImageAddUpdate,
  ] = useState(false);
  const [showProductVersionImageUpload, SetShowProductVersionImageUpload] =
    useState(null);
  const [metadataDto, SetMetadataDto] = useState(null);
  const [showProductTypeAdd,SetShowProductTypeAdd] = useState(false);

  const [showWarningdlg,SetShowWarningdlg] = useState(false);
  const [warningTitle, SetWarningTitle] = useState('Warning');
  const [warningContent, SetWarningContent] = useState([]);

  const [showNotification, setShowNotification] = useState(false)
  const handleCloseNotification = () => setShowNotification(false)
  const [resultMessage, SetResultMessage] = useState('')

  const [showInputFileNameModal, SetShowInputFileNameModal] = useState(false);
  const [fileName, SetFileName] = useState('');
  const [selectedImage, SetSelectedImage] = useState(null);


  useEffect(() => {
    getVersion();
  }, []);

  const getVersion = () => {
    setLoading(true);
    const requestVersion = axios.get(
      process.env.REACT_APP_LIC_API_BASE_URL +
        "/Products/" +
        productTypeId +
        "/ProductVersions"
    );
    const requestProductType = axios.get(
      process.env.REACT_APP_LIC_API_BASE_URL +
        "/Products/ProductTypes/" +
        productTypeId
    );
    axios.all([requestVersion, requestProductType]).then(
      axios.spread((...responses) => {
        let tempVersions = sortByVersionDesc(responses[0].data).filter(
          (x) => x.active
        );
        setVersions(tempVersions);
        setSelectedVersion(tempVersions?.[0]);
        setProductType(responses[1].data);
        setShowNoVersionWarnning(tempVersions.length === 0);
        setLoading(false);
      })
    );
  };

  const getUpdatedVersionById = () => {
    setLoading(true);
    const requestVersion = axios.get(
      process.env.REACT_APP_LIC_API_BASE_URL +
        "/Products/ProductTypes/ProductVersions/" +
        selectedVersion.id
    ).then(response=>{
      setSelectedVersion(response.data);
      setVersions(values => values.map((item) => item.id === selectedVersion.id ? response.data: item));
      setLoading(false);
    })
  };

  const getProductTypeById = () => {
    setLoading(true);
    const requestVersion = axios.get(
      process.env.REACT_APP_LIC_API_BASE_URL +
        "/Products/ProductTypes/" +
        productType.id
    ).then(response=>{
      setProductType(response.data); 
      setLoading(false);
    })
  };

  const sortByVersionDesc = (productVersions) => {
    if (productVersions?.length >= 1) {
      productVersions.sort((version1, version2) => {
        if (version1.majorVersion === version2.majorVersion) {
          if (version1.minorVersion === version2.minorVersion) {
            if (version1.patchVersion === version2.patchVersion) {
              // two same version, impossible
            } else if (version1.patchVersion > version2.patchVersion) {
              return -1;
            } else {
              return 1;
            }
          } else if (version1.minorVersion > version2.minorVersion) {
            return -1;
          } else {
            return 1;
          }
        } else if (version1.majorVersion > version2.majorVersion) {
          return -1;
        } else {
          return 1;
        }
      });
    }
    return productVersions;
  };


  const handleProductVersionAdd = () => {
    setShowProductVersionAdd(!showProductVersionAdd);
    setIsUpdateVersion(false);  
  };

  const handleProductVersionUpdate = () => {
    setShowProductVersionAdd(!showProductVersionAdd);
    setIsUpdateVersion(true);
  };

  const handleProductLicenseOptionAdd = () => {
    setShowProductLicenseOptionAdd(!showProductLicenseOptionAdd);
  };

  const handleProductLicenseOptionUpdate = (opt) => {
    setShowProductLicenseOptionUpdate(!showProductLicenseOptionUpdate);
    setUpdateProductLicenseOption(
      updateProductLicenseOption == null ? opt : null
    );
  };

  const handleDeleteProductOptionData = (id) => {
    axios
      .delete(
        process.env.REACT_APP_LIC_API_BASE_URL +
          "/Products/ProductVersions/ProductLicenseOptions/" +
          id
      )
      .then((response) => {
        getUpdatedVersionById();
      });
  };

  const onHideProductVersionImageUpdate = () => {
    SetShowProductVersionImageAddUpdate(false);
  };

  const handleProductVersionImageAdd = () => {
    SetShowProductVersionImageAddUpdate(true);
    SetMetadataDto(null);
  };

  const handleProductVersionImageUpdate = (image) => {
    SetShowProductVersionImageAddUpdate(true);
    SetMetadataDto(image);
  };

  const handleSubmitCreateSFDownloadLink = (image, fileName) =>{
    if(image.blobUri === '' || image.blobUri === null){
      alert('Blob Uri should not be empty!');
      return;   
    }

    if(fileName === '' || fileName === null){
      alert('Salesforce File Name should not be empty!');
      return;   
    }

    setLoading(true);

    axios
    .post(
      process.env.REACT_APP_METADATA_API_BASE_URL + "/ReleaseMetadata/CreateDownloadCenterFileLink?blobUri="+image.blobUri+"&title="+fileName
    )
    .then((reponse) => {
      setLoading(false);
      SetResultMessage('Create Salesforce download link successful!');
      setShowNotification(true);
      getUpdatedVersionById();
    });
  }

  const handleCreateSFDownloadLink= (image) => {

    SetSelectedImage(image);
    SetShowInputFileNameModal(true);
  }; 

  const handleUpdateProductType = () =>{
    SetShowProductTypeAdd(true);
  }

  const handleHideProductType = () => {
    SetShowProductTypeAdd(false);
  }

  const handleProductVersionImageUpload = (image) => {
    SetShowProductVersionImageUpload(true);
    SetMetadataDto(image);
  };
  const onHideProductVersionImageUpload = () => {
    SetShowProductVersionImageUpload(false);
  };

  const handleProductVersionImageDownload = (image) => {
    axios
      .get(
        process.env.REACT_APP_METADATA_API_BASE_URL +
          `/ReleaseMetadata/GenerateDownloadLink?blobUri=${image.blobUri}&withRedirect=false`
      )
      .then((response) => {
        let url = response.data;
        window.open(url, "").focus();
      });
  };

  const handleProductVersionImageDelete = (id) => {
    axios
      .delete(
        process.env.REACT_APP_LIC_API_BASE_URL +
          "/Products/ProductVersions/metadata/" +
          id
      )
      .then((response) => {
        getUpdatedVersionById();
      });
  };

  const getSfLinkContent = (salesforceFileName, salesforceDownloadUrl) =>{
    if((salesforceFileName === null || salesforceFileName === '') && salesforceDownloadUrl != null && salesforceDownloadUrl !== ''){
        return 'Salesforce Download Link'
    }
    else if(salesforceFileName !== null && salesforceFileName !== '' && salesforceDownloadUrl !== null && salesforceDownloadUrl !== ''){
      return 'Salesforce Download ' + salesforceFileName;
    } 
    else if((salesforceFileName === null || salesforceFileName === '') && (salesforceDownloadUrl === null || salesforceDownloadUrl === '')){
      return '';
    } 
    else if((salesforceFileName !== null && salesforceFileName !== '') && (salesforceDownloadUrl === null || salesforceDownloadUrl === '')){
      return '';
    } 
  }

  const getImageLinkContent = (salesforceFileName, imageLink) =>{
    if((salesforceFileName === null || salesforceFileName === '') && imageLink != null && imageLink !== ''){
      return 'Image Link';
    }
    else if(salesforceFileName !== null && salesforceFileName !== '' && imageLink !== null && imageLink !== ''){
      return 'Image Link ' + salesforceFileName;
    } 
    else if((salesforceFileName === null || salesforceFileName === '') && (imageLink === null || imageLink === '')){
      return '';
    } 
    else if((salesforceFileName !== null && salesforceFileName !== '') && (imageLink === null || imageLink === '')){
      return '';
    }
  }

  const handleWarningDlgOk = () =>{
    //alert('new name' + fileName + ', old name : ' + selectedImage.salesforceFileName);
    SetWarningContent(['This operation will generate a new file in salesforce download center,',
                      'but it will not remove the file with old salesforce file name.',
                      'Are you sure you want to create it?']);
    SetShowInputFileNameModal(false);
    SetShowWarningdlg(true);
  }

  const handleInputFileNameClose = () =>{
    SetShowInputFileNameModal(false);
    SetFileName('');
  }

  function handleChange(event) {
    SetFileName(event.target.value);
  }

  const handleWarningDlgCancel = () =>{
    SetFileName('');
    SetShowWarningdlg(false);
  }

  const handleInputFileNameConfirm = () =>{
    handleSubmitCreateSFDownloadLink(selectedImage, fileName);
    SetShowWarningdlg(false);
    SetFileName('');  
  }
 
  const showAction = (opt) => {
    if (opt.active !== true) {
      return <div />;
    }

    return (
      <div>
        <OverlayTrigger
          popoverContent={"Update License Option"}
          style={{ cursor: "pointer", paddingRight: "10px" }}
          onClick={() => handleProductLicenseOptionUpdate(opt)}
        >
          <Icon iconname="Edit" style={{ fill: "#007bff" }} />
        </OverlayTrigger>
        <OverlayTrigger
          popoverContent={"Disable"}
          onClick={() => handleDeleteProductOptionData(opt.id)}
          style={{ cursor: "pointer", paddingRight: "10px" }}
        >
          <Icon iconname="TrashCan" style={{ fill: "#007bff" }} />
        </OverlayTrigger>
      </div>
    );
  };

  if (loading) {
    return (
      <ProgressBar
        leftLabel="Loading: "
        barColors={{
          background: "#599bff",
          right: "#599bff",
          left: "#2fccd3",
        }}
        hideRight={true}
        animate={1}
      />
    );
  } else {
    return (
      <div>
        <div>
          <Header title={productType.name} />
          <h4>Available License Options</h4>{" "}
          <Button
            style={{ display: "inline" }}
            onClick={() => (window.location = "license/producttypes")}
          >
            Back
          </Button>
        </div>
        <hr />
        <div style={{ display: "inline-flex" }}>
          <FormGroup>
            <FormLabel>Current Version</FormLabel>
            <FormControl
              as="select"
              style={{ width: "380px" }}
              defaultValue={
                selectedVersion?.majorVersion +
                "." +
                selectedVersion?.minorVersion +
                "." +
                selectedVersion?.patchVersion
              }
              onChange={(e) => {
                var text = e.target.value;
                setSelectedVersion(
                  versions.filter(
                    (x) =>
                      x.majorVersion +
                        "." +
                        x.minorVersion +
                        "." +
                        x.patchVersion ===
                      text
                  )[0]
                );
              }}
            >
              {versions &&
                versions.map((ver) => {
                  return (
                    <SelectOption
                      key={ver.id}
                      value={`${ver.majorVersion}.${ver.minorVersion}.${ver.patchVersion}`}
                    >
                      {" "}
                      {ver.majorVersion}.{ver.minorVersion}.{ver.patchVersion}
                    </SelectOption>
                  );
                })}
            </FormControl>
          </FormGroup>
        </div>
        <hr />
        <div>
          {" "}
          <Button variant="primary" onClick={() => handleUpdateProductType()}>
            Update Product Type
          </Button>
          <Button variant="primary" onClick={() => handleProductVersionAdd()}>
            Add Version
          </Button>
          <Button
            variant="primary"
            onClick={() => handleProductVersionUpdate()}
          >
            Update Version
          </Button>
          <Button
            variant="primary"
            onClick={() => handleProductLicenseOptionAdd()}
          >
            Add License Option
          </Button>
          <Button
            variant="primary"
            onClick={() => handleProductVersionImageAdd()}
          >
            Add Version Image
          </Button>
        </div>
        <Row>
        <Col>
            <FormGroup>
              <FormLabel>Salesforce Library Name</FormLabel>
              <FormControl
                disabled={true}
                value={
                  productType?.salesforceLibraryName == null
                    ? ""
                    : productType?.salesforceLibraryName
                }
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <FormLabel>Salesforce Library Subfolder</FormLabel>
              <FormControl
                disabled={true}
                value={
                  productType?.salesforceLibrarySubfolder == null
                    ? ""
                    : productType?.salesforceLibrarySubfolder
                }
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <FormLabel>Published Date (Format : yyyy-mm-dd)</FormLabel>
              <FormControl
                disabled={true}
                value={
                  selectedVersion?.publishedDate == null
                    ? ""
                    : moment(selectedVersion?.publishedDate)
                        .utc()
                        .format("YYYY-MM-DD")
                }
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <FormLabel>Release Type</FormLabel>
              <FormControl
                disabled={true}
                value={
                  selectedVersion?.releaseType == null
                    ? ""
                    : selectedVersion?.releaseType
                }
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <FormLabel>Description</FormLabel>
              <FormControl
                disabled={true}
                value={
                  selectedVersion?.versionDescription == null
                    ? ""
                    : selectedVersion?.versionDescription
                }
              />
            </FormGroup>
          </Col>
        </Row>
        <Header type="sub-section" title="Image list" />
        <List>
          <ListHeader
            checkbox={false}
            style={{
              gridTemplateColumns: "0.5fr 2fr 0.5fr 0.5fr 0.5fr 1.5fr 2fr 1fr 1.5fr",
            }}
          >
            <div>Image Type</div>
            <div>Image Link</div>
            <div>Md5</div>
            <div>Sha1</div>
            <div>Sha2</div>
            <div>Blob URI</div>
            <div>SF Download URL</div>
            <div>SF File Name</div>
            <div>Actions</div>
          </ListHeader>
          {selectedVersion &&
            selectedVersion?.productVersionImages?.map((img) => {
              return (
                <div key={img.id}>
                  <TableRow
                    checkbox={false}
                    style={{
                      gridTemplateColumns: "0.5fr 2fr 0.5fr 0.5fr 0.5fr 1.5fr 2fr 1fr 1.5fr",
                      fontSize: "0.75rem"
                    } }
                  >
                    <div>{img.imageType}</div>
                    <div>
                      <OverlayTrigger
                          showOnTooltipHover
                          popoverContent={img.imageLink}
                          style={{ cursor: "pointer", paddingRight: "10px" }}
                        >
                        <Link fontSize="0.75rem" href={img.imageLink}>{getImageLinkContent(img.salesforceFileName,img.imageLink)}</Link>
                      </OverlayTrigger>
                    </div>
                    <div>{img.md5}</div>
                    <div>{img.sha1}</div>
                    <div>{img.sha2}</div>
                    <div>{img.blobUri}</div>
                    <div>
                    <OverlayTrigger
                          showOnTooltipHover
                          popoverContent={img.salesforceDownloadUrl}
                          style={{ cursor: "pointer", paddingRight: "10px" }}
                        >
                        <Link fontSize="0.75rem"  target="_blank" showGoTo={(getSfLinkContent(img.salesforceFileName, img.salesforceDownloadUrl)==='')?false:true} href={img.salesforceDownloadUrl} >
                          {getSfLinkContent(img.salesforceFileName, img.salesforceDownloadUrl)}
                        </Link>
                    </OverlayTrigger>
                    </div>
                    <div>{img.salesforceFileName}</div>
                    <div style={{ display: "inline-flex" }}>
                      <div>
                        <OverlayTrigger
                          popoverContent={"Upload version Image File"}
                          style={{ cursor: "pointer", paddingRight: "10px" }}
                        >
                          <Icon
                            iconname="Upload"
                            style={{ fill: "#007bff" }}
                            onClick={() => handleProductVersionImageUpload(img)}
                          />
                        </OverlayTrigger>
                        <OverlayTrigger
                          popoverContent={"Download this file"}
                          style={{ cursor: "pointer", paddingRight: "10px" }}
                        >
                          <Icon
                            iconname="Download"
                            style={{ fill: "#007bff" }}
                            onClick={() =>
                              handleProductVersionImageDownload(img)
                            }
                          />
                        </OverlayTrigger>
                        {/*<OverlayTrigger
                          popoverContent={"Update version Image"}
                          style={{ cursor: "pointer", paddingRight: "10px" }}
                        >
                          <Icon
                            iconname="Edit"
                            style={{ fill: "#007bff" }}
                            onClick={() => handleProductVersionImageUpdate(img)}
                          />
                        </OverlayTrigger>*/}
                        <OverlayTrigger
                          popoverContent={"Create Salesforce Download link"}
                          style={{ cursor: "pointer", paddingRight: "10px" }}
                        >
                          <Icon
                            iconname="StatusStreaming"
                            style={{ fill: "#007bff" }}
                            onClick={() => handleCreateSFDownloadLink(img)}
                          />
                        </OverlayTrigger>
                        <OverlayTrigger
                          popoverContent={"Delete"}
                          style={{ cursor: "pointer", paddingRight: "10px" }}
                        >
                          <Icon
                            iconname="TrashCan"
                            style={{ fill: "#007bff" }}
                            onClick={() =>
                              handleProductVersionImageDelete(img.id)
                            }
                          />
                        </OverlayTrigger>
                      </div>
                    </div>
                  </TableRow>
                </div>
              );
            })}
        </List>
        <hr />
        <Header type="sub-section" title="Product Option Data" />
        <List>
          <ListHeader
            checkbox={false}
            alternateRowColors
            style={{
              gridTemplateColumns: "1fr 1fr 2fr 2fr 2fr 1fr 1fr",
            }}
          >
            <div>Section</div>
            <div>Item</div>
            <div>Value Name</div>
            <div>Available Values</div>
            <div>Description</div>
            <div>Status</div>
            <div>Actions</div>
          </ListHeader>
          {selectedVersion &&
            selectedVersion?.productLicenseOptions?.map((opt) => {
              return (
                <Fragment key={opt.id}>
                  <TableRow
                    checkbox={false}
                    style={{
                      gridTemplateColumns: "1fr 1fr 2fr 2fr 2fr 1fr 1fr",
                    }}
                  >
                    <div>{opt.licenseSection}</div>
                    <div>{opt.licenseSectionItem}</div>
                    <div>{opt.valueName}</div>
                    <div>{opt.availableValues}</div>
                    <div>{opt.description}</div>
                    <div>{opt.active ? "Active" : "Inactive"}</div>
                    <div style={{ display: "inline-flex" }}>
                      {
                        /* <OverlayTrigger
                          
                          popoverContent={"Update License Option"}
                          style={{ cursor: "pointer", paddingRight: "10px" }}
                          onClick={() =>
                            this.handleProductLicenseOptionUpdate(opt)
                          }
                        >
                          <Icon
                            
                            iconname="EditRnd"
                            style={{ fill: "#007bff" }}
                          />
                        </OverlayTrigger>
                        <OverlayTrigger
                          
                          popoverContent={"Disable"}
                          onClick={() => this.handleDeleteProductOptionData(opt.id)}
                          style={{ cursor: "pointer", paddingRight: "10px" }}
                        >
                          <Icon
                            
                            iconname="Delete"
                            style={{ fill: "#007bff" }}
                          />
                        </OverlayTrigger> */ showAction(opt)
                      }
                    </div>
                  </TableRow>
                </Fragment>
              );
            })}
        </List>

        <Modal show={showNoVersionWarnning} size="md" centered>
          <ModalHeader>
            <ModalTitle></ModalTitle>
          </ModalHeader>
          <ModalBody>No Product Version Found</ModalBody>
          <ModalFooter>
            <Button
              onClick={() => {
                setShowNoVersionWarnning(false);
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
        <HaivisionProductTypeVersionAdd
          show={showProductVersionAdd}
          handleProductVersionAdd={handleProductVersionAdd}
          getVersion={getVersion}
          getUpdatedVersion ={getUpdatedVersionById}
          productTypeId={productTypeId}
          selectedVersion={selectedVersion}
          isUpdateVersion={isUpdateVersion}
        />
        <HaivisionProductLicenseOptionAdd
          show={showProductLicenseOptionAdd}
          handleProductLicenseOptionAdd={handleProductLicenseOptionAdd}
          getVersion={getUpdatedVersionById}
          selectedVersionId={selectedVersion?.id}
        />
        <HaivisionProductLicenseOptionUpdate
          show={showProductLicenseOptionUpdate}
          handleProductLicenseOptionUpdate={handleProductLicenseOptionUpdate}
          getVersion={getUpdatedVersionById}
          productLicenseOption={updateProductLicenseOption}
        />
        <ProductVersionMetadata
          show={showProductVersionImageAddUpdate}
          metaData={metadataDto}
          getVersion={getUpdatedVersionById}
          onHide={onHideProductVersionImageUpdate}
          productVersionId={selectedVersion?.id}
        />
        <ProductVersionImageFileUpload
          show={showProductVersionImageUpload}
          onHide={onHideProductVersionImageUpload}
          productVersionImageId={metadataDto?.id}
          productTypeName={productType.name}
          getVersion={getUpdatedVersionById}
          productVersion={
            selectedVersion?.majorVersion +
            "." +
            selectedVersion?.minorVersion +
            "." +
            selectedVersion?.patchVersion
          }
        />
         <HaivisionProductTypeAdd
            selectedProductType={productType}
            show={showProductTypeAdd}
            handleAddProductType={handleHideProductType}
            getProductTypes={getProductTypeById}
          />

          <Dialog
            headline={warningTitle}
            bodyText={warningContent}
            onClose={()=>SetShowWarningdlg(false)}
            show={showWarningdlg}
            dynamicResizing={{ minWidth: 100, maxWidth: 500, margin: 100 }}
            buttons={[
              { label: 'CANCEL',onClick: () => handleWarningDlgCancel() },
              { label: 'YES', variant: 'danger' ,onClick: () => handleInputFileNameConfirm()},
            ]}
            />
           <Notification
            delay={3000}
            position="upper-right"
            show={showNotification}
            description="Success"
            onDismiss={handleCloseNotification}
            message={resultMessage}
            variant="Success"
          />

          <InputFileNameModal 
          handleOk = {handleWarningDlgOk} 
          handleClose={handleInputFileNameClose} 
          fileName = {fileName}
          onChange = {handleChange}
          show= {showInputFileNameModal} 
        />

      </div>
    );
  }
};

function InputFileNameModal (props){
  const {handleOk, handleClose, fileName, onChange,show} = props;
  
    return (
      <Modal
        size={{ default: 'md', mobileMode: 'fs' }}
        show={show}
        onHide={handleClose}
      >
        <ModalHeader>
          <ModalTitle>Please Input the salesforce file name</ModalTitle>
        </ModalHeader>
        <ModalBody>
        <FormGroup>
        <FormLabel>Salesforce File Name</FormLabel>
        <FormControl
          value={fileName}
          onChange={onChange}
        />
        </FormGroup>
        </ModalBody>
        <ModalFooter>
        <Button variant="primary" onClick={handleOk}>
            Ok
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    ); 
}

export default HaivisionProductTypeDetail;
