import {
  Button,
  FormControl,
  List,
  ListHeader,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Spinner,
  Animation,
  TableRow,
  Notification,
  Dialog,
  OverlayTrigger,
  SelectOption,
  FormLabel,
 ProgressBar, 
 Header} from "@hai/ui-react";
import cogoToast from "cogo-toast";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../../../infra/axios";
import JellyHeader from "../../common/Utils/JellyHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faEdit,
  faMinusCircle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { UserContext } from "../../common/userContext";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";

const Users = (props) => {
  const [allUsers, setAllUsers] = useState(null);
  const [filteredUsers, setfilteredUsers] = useState(null);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [clickedUser, setClickedUser] = useState(null);
  const [showDeleteUser, setshowDeleteUser] = useState(false);
  const [showUserStatusChangeConfirm, setShowUserStatusChangeConfirm] =
    useState(false);
  const [
    showUserStatusChangeNotification,
    setShowUserStatusChangeNotification,
  ] = useState(false);
  const [userTypeFilter, setUserTypeFilter] = useState("all");
  const [userFilter, setUserFilter] = useState("");
  const [userRoleFilter, setUserRoleFilter] = useState("");

  const firstTimeRender = useRef(true);

  const [roles] = useState([
    "SYS_ADMIN",
    "HUB_ADMIN",
    "HUB_OPERATOR",
    "HUB_READONLY",
    "CONNECT_ADMIN",
    "CONNECT_OPERATOR",
    "CONNECT_READONLY",
  ]);

  const navigate = useNavigate();

  useEffect(() => {
    (async function () {
      await getAllUsers();
    })();
  }, []);

  useEffect(() => {
    firstTimeRender.current = false;
  }, []);

  useEffect(() => {
    if (!firstTimeRender.current) {
      filterUsers();
    }
  }, [userTypeFilter, userFilter, userRoleFilter]);

  const goToEditPage = (id) => {
    navigate(`/system/user/edit/${id}`);
  };

  const openDeleteDialog = (user) => {
    setClickedUser(user);
    setshowDeleteUser(true);
  };

  const deleteUser = () => {
    axios
      .delete(
        process.env.REACT_APP_JELLYFISH_API_BASE_URL + `/User/${clickedUser.id}`
      )
      .then((response) => {
        cogoToast.success("User deleted", { position: "top-right" });
        setshowDeleteUser(false);
      });
  };

  const getAllUsers = async () => {
    setLoadingUsers(true);
    await axios
      .get(process.env.REACT_APP_JELLYFISH_API_BASE_URL + "/User", {
        headers: {
          "Cache-Control": "no-cache",
        },
      })
      .then((response) => {
        setAllUsers(response.data);
        setfilteredUsers(response.data);
        setLoadingUsers(false);
      })
      .catch((err) => setLoadingUsers(false));
  };

  const filterUsers = async () => {
    let filteredData = allUsers;
    if (userFilter !== "") {
      filteredData = allUsers?.filter(filterByName);
    }
    if (userTypeFilter !== "all") {
      filteredData = filteredData?.filter(
        (user) => user.type === userTypeFilter
      );
    }
    if (userRoleFilter !== null && userRoleFilter?.length > 0) {
      filteredData = filteredData?.filter((user) =>
        user.roles.map((r) => r.role).includes(userRoleFilter)
      );
    }
    setfilteredUsers(filteredData);
  };

  const filterByName = (user) => {
    let valueUp = userFilter.toUpperCase();
    if (user.displayName?.toUpperCase().includes(valueUp)) return true;
    if (user.email?.toUpperCase().includes(valueUp)) return true;
    if (user.appId?.toUpperCase().includes(valueUp)) return true;
    //return false;
  };

  const updateUserStatus = async () => {
    await axios
      .patch(
        process.env.REACT_APP_JELLYFISH_API_BASE_URL +
          "/User/" +
          clickedUser?.id +
          "/" +
          (clickedUser?.status == "Enabled" ? "disable" : "enable")
      )
      .then((response) => {
        getAllUsers();
        setShowUserStatusChangeNotification(true);
        setShowUserStatusChangeConfirm(false);
        setClickedUser(null);
      });
  };

  return (
    <div>
      <Header title="User Management"></Header>

      <UserContext.Consumer>
        {(user) => {
          if (user.roles?.map((r) => r.role).some((r) => r === "SYS_ADMIN")) {
            return (
              <>
                <Row>
                  <Col>
                    <FormLabel>Filter by name</FormLabel>
                    <FormControl
                      defaultValue={userFilter}
                      name="filter"
                      placeholder="Search by Name"
                      onChange={(e) => {
                        setUserFilter(e.target.value);
                        filterUsers();
                      }}
                    />
                  </Col>

                  <Col>
                    <FormLabel>Filter by type</FormLabel>
                    <FormControl
                      name="typeFilter"
                      defaultValue={userTypeFilter}
                      as="select"
                      onChange={async (e) => {
                        setUserTypeFilter(e.target.value);
                        await filterUsers();
                      }}
                    >
                      <SelectOption value="all">All</SelectOption>
                      <SelectOption value="regular_user">User</SelectOption>
                      <SelectOption value="service_principal">App</SelectOption>
                    </FormControl>
                  </Col>

                  <Col>
                    <FormLabel>Filter by role</FormLabel>
                    <FormControl
                      name="roleFilter"
                      defaultValue={userRoleFilter}
                      as="select"
                      onChange={(e) => {
                        setUserRoleFilter(e.target.value);
                        filterUsers();
                      }}
                    >
                      <SelectOption value="">All</SelectOption>
                      {roles.map((r) => (
                        <SelectOption value={r} key={r}>{r}</SelectOption>
                      ))}
                    </FormControl>
                  </Col>
                </Row>

                <hr />
                <List>
                  <ListHeader
checkbox={false}  checkbox={false} alternateRowColors={false}
                    style={{
                      gridTemplateColumns:
                        "0.2fr 3fr 2fr 2fr 2fr 1fr 0.3fr 0.3fr 0.2fr",
                    }}
                  >
                    <div></div>
                    <div>Display name</div>
                    <div>Email</div>
                    <div>Type</div>
                    <div>Roles</div>
                    <div>Status</div>
                    <div>Actions</div>
                  </ListHeader>
                  {loadingUsers && (
                    <ProgressBar
  leftLabel="Loading: "
  barColors={{
    background: '#599bff',
    right: '#599bff',
    left: '#2fccd3',
  }}
  hideRight={true}
  animate={1}
/>
                  )}
                  {filteredUsers &&
                    filteredUsers
                      .sort((a, b) => (a.displayName > b.displayName ? 1 : -1))
                      .map((u, index) => {
                        return (
                          <TableRow
checkbox={false}
                            key={u.id}
                            style={{
                              gridTemplateColumns:
                                "0.2fr 3fr 2fr 2fr 2fr 1fr 0.3fr 0.3fr 0.2fr",
                              color: "white",
                            }}
                          >
                            <div></div>
                            <div>{u.displayName}
                            {u.appId && <small>({u.appId})</small>}
                            </div>
                            <div>{u.email}</div>
                            <div>
                              {u.type === "service_principal"
                                ? "Service Principal"
                                : "User"}
                            </div>
                            <div>
                              <OverlayTrigger
                                placement="right"
                                popoverContent={
                                  <ul>
                                    {u.roles?.map((r) => (
                                      <li key={r.role}>{r.role}</li>
                                    ))}
                                  </ul>
                                }
                              >
                                <Button>Preview Roles</Button>
                              </OverlayTrigger>
                            </div>
                            <div>
                              <FontAwesomeIcon
                                size="lg"
                                style={{ cursor: "pointer" }}
                                icon={
                                  u.status == "Enabled"
                                    ? faCheckCircle
                                    : faMinusCircle
                                }
                                color={u.status == "Enabled" ? "green" : "red"}
                                onClick={() => {
                                  setClickedUser(u);
                                  setShowUserStatusChangeConfirm(true);
                                }}
                              />
                            </div>
                            <div>
                              <FontAwesomeIcon
                                icon={faEdit}
                                size="lg"
                                style={{ cursor: "pointer" }}
                                onClick={() => goToEditPage(u.id)}
                                title="Edit"
                              />
                            </div>
                            <div>
                              <FontAwesomeIcon
                                icon={faTrash}
                                size="lg"
                                style={{ cursor: "pointer" }}
                                onClick={() => openDeleteDialog(u)}
                                title="Delete"
                              />
                            </div>
                          </TableRow>
                        );
                      })}
                </List>

                <Notification
                  style={{ top: "1rem", right: "1rem" }}
                  show={showUserStatusChangeNotification}
                  description="Success"
                  message="User Status update successed"
                  autohide={true}
                  variant="Success"
                />
                <Modal show={showUserStatusChangeConfirm} size="lg" centered>
                  <ModalHeader>
                    <ModalTitle>Confirm</ModalTitle>
                  </ModalHeader>
                  <ModalBody>
                    Please confirm the following action:
                    <br />
                    <ul>
                      <li>
                        {clickedUser?.status == "Enabled"
                          ? "Disable"
                          : "Enable"}{" "}
                        - {clickedUser?.displayName}
                      </li>
                    </ul>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      variant="primary"
                      onClick={() => updateUserStatus()}
                    >
                      Confirm
                    </Button>
                    <Button
                      onClick={() => {
                        setClickedUser(null);
                        setShowUserStatusChangeConfirm(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </ModalFooter>
                </Modal>

                <Modal
                  show={showDeleteUser}
                  size="lg"
                  centered
                  onHide={() => {
                    setClickedUser(null);
                    setshowDeleteUser(false);
                  }}
                >
                  <ModalHeader>
                    <ModalTitle>Confirmation</ModalTitle>
                  </ModalHeader>
                  <ModalBody>
                    <p>The following user(s) will be removed:</p>
                    <ul>
                      <li>
                        {clickedUser?.displayName} {clickedUser?.appId} (
                        {clickedUser?.email})
                      </li>
                    </ul>
                  </ModalBody>
                  <ModalFooter>
                    <Button variant="primary" onClick={() => deleteUser()}>
                      Confirm
                    </Button>
                    <Button
                      onClick={() => {
                        setClickedUser(null);
                        setshowDeleteUser(false);
                      }}
                    >
                      Cancel
                    </Button>
                  </ModalFooter>
                </Modal>
              </>
            );
          } else {
            return <p>You don't have permission on this page</p>;
          }
        }}
      </UserContext.Consumer>
    </div>
  );
};

export default Users;
