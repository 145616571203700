import React from "react";
import propTypes from 'prop-types';
import { Button } from "@hai/ui-react";

const NavigationButtons = props => {

    const handleNextPage = () => {
        let nextPage =  props.pageData.currentPage+1;
        props.setPageData({...props.pageData, currentPage: nextPage});
        let start = ((nextPage - 1) * props.pageData.pageSize);
        let end = nextPage * props.pageData.pageSize;
        props.setVisibleData(props.data.slice(start,end))
        window.history.replaceState('','', props.baseUrl + '/page/'+ nextPage)
    }

    const handlePreviousPage = () => {
        let previousPage =  props.pageData.currentPage-1;
        props.setPageData({...props.pageData, currentPage: previousPage});
        let start = ((previousPage - 1) * props.pageData.pageSize);
        let end = previousPage * props.pageData.pageSize;
        props.setVisibleData(props.data.slice(start, end))
        window.history.replaceState('','', props.baseUrl + '/page/'+ previousPage)
    }

    return (<div style={{ display: 'flex', alignItems: 'baseline' }}>
    <Button disabled={props.pageData.currentPage === 1} onClick={handlePreviousPage}>Previous page</Button>
    <p style={{ borderBlock: `1px black`, padding: '1%' }}>{props.pageData.currentPage}</p>
    <Button disabled={props.pageData.currentPage * props.pageData.pageSize > props.data?.length - 1} onClick={handleNextPage}>Next page</Button>
</div>);
}


NavigationButtons.propTypes = {
    baseUrl: propTypes.string.isRequired,
    pageData: propTypes.any.isRequired,
    setPageData: propTypes.func.isRequired,
    visibleData: propTypes.any.isRequired,
    setVisibleData: propTypes.func.isRequired,
    data: propTypes.array.isRequired,
}

export default NavigationButtons;