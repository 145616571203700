import React, { useState, useEffect } from 'react'
import {
  Header,
  Button,
  Icon,
  List,
  ListHeader,
  TableRow,
  Pagination,
  FormLabel,
  FormControl,
  ProgressBar,
  OverlayTrigger,
  Spinner
} from "@hai/ui-react";
import { Col, Row } from "reactstrap";
import axios from "../../../infra/axios";
import WorkOrderAdd from "./haivision-work-order-add";
import { useParams } from "react-router-dom";
import cogoToast from "cogo-toast";

const HaivisionWorkOrder = () => {
  var params = useParams();
  const [workOrders, SetWorkOrders] = useState(null);
  const [showCreateWOModal, setShowCreateWOModal] = useState(false);
  const [filteredWorkOrders, SetfilteredWorkOrders] = useState(null);
  const [loading, setLoading] = useState(false);
  const [woFilter, setWoFilter] = useState('');
  const [filterParams, setFilterParams] = useState([]);
  const [pageData, setPageData] = useState({
    currentPage: params?.pageNum ? parseInt(params?.pageNum) : 1,
    pageSize: 10
  });
  const OrderBy = JSON.stringify([{'Id':'DateCreated', 'Desc':true}]);

  useEffect(() => {
    getWorkOrders(1, 12);

  }, []);

  const filterByWorkOrderNumber = (filterstr, workOrders) => {
    if (filterstr === '' || filterstr === undefined || filterstr.trim() === '') {
      SetfilteredWorkOrders(workOrders);
    }
    else {
      var filteredWos = workOrders.filter(x => x.workOrderNumber.toLowerCase().includes(filterstr.toLowerCase()));
      SetfilteredWorkOrders(filteredWos);
    }
  }

  const getWorkOrders = (page, pageSize) => {

    setLoading(true);
    axios
      .get(
        process.env.REACT_APP_PRODUCTION_API_BASE_URL +
        `/Legacy/WorkOrders/all?PageNumber=${page}&PageSize=${pageSize}&ColumnFilters=${JSON.stringify(filterParams)}&OrderBy=${OrderBy}`
      )
      .then((response) => {
        if (response.data) {
          const workOrdersAll = response.data;
          workOrdersAll.data.sort(function (a, b) {
            return new Date(b.dateCreated) - new Date(a.dateCreated);
          });
          SetWorkOrders(workOrdersAll);
          setPageData({ currentPage: response.data.pageIndex, pageSize: response.data.pageSize })
          SetfilteredWorkOrders(workOrdersAll);
          // filterByWorkOrderNumber(woFilter, workOrdersAll);
        }
        setLoading(false);
      });
  };

  const OnHideCreateModel = () => {
    setShowCreateWOModal(false);
  }

  const handleDetails = (id) => {
    window.location = window.location + "/" + id;
  }

  return (
    <div>
      {loading && <ProgressBar
        leftLabel="Loading: "
        barColors={{
          background: '#599bff',
          right: '#599bff',
          left: '#2fccd3',
        }}
        hideRight={true}
        animate={1}
      />}
      {!loading && <div style={{ marginTop: "1%" }}>
        <Header title="Work Orders" />
        <Row style={{ alignItems: "flex-end" }}>
          <Col>
            <FormLabel>Filter by Work Order Number</FormLabel>
            <FormControl
              placeholder="Filter by Work Order Number"
              defaultValue={ woFilter}
              onChange={(e) => {
                let tempSet = filterParams.filter(f => f.id !== "workOrderNumber");
                if (e.target.value !== "") {
                  tempSet.push({
                    id: 'workOrderNumber',
                    Value: e.target.value,
                    Comparator: 'Contains'
                  });
                  setFilterParams(tempSet);
                } else {
                  setFilterParams(tempSet);
                }
                setWoFilter(e.target.value);
              }
              }
            />
          </Col>
          <Col>
            <Button onClick={() => getWorkOrders(1, 12)}>Search</Button>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <Button variant="primary" onClick={() => setShowCreateWOModal(true)}>
              Create Work Order
            </Button>
          </Col>
        </Row>
        <br />
          <List>
            <ListHeader
              checkbox={false}
              style={{
                gridTemplateColumns:
                  "1fr 1fr 2fr 2fr 1fr 2fr 1fr",
              }}
            >
              <div>Factory Name</div>
              <div>Work Number</div>             
              <div>Serial Number From</div>
              <div>Serial Number To</div>
              <div>Date Created</div>
              <div>Comments</div>
              <div>Action</div>
            </ListHeader>
            {filteredWorkOrders &&
              filteredWorkOrders.data.map((wo) => {
                return (
                  <div key={wo.id}>
                    <TableRow
                      checkbox={false}
                      style={{
                        gridTemplateColumns:
                          "1fr 1fr 2fr 2fr 1fr 2fr 1fr",
                      }}
                    >
                      <div>{wo.workOrderFactoryName}</div>
                      <div>{wo.workOrderNumber}</div>                     
                      <div>{wo.workOrderFirstSerialNumber}</div>
                      <div>{wo.workOrderLastSerialNumber}</div>
                      <div>{new Date(wo.dateCreated).toUTCString()}</div>
                      <div>{wo.comments}</div>
                      <div style={{ display: "inline-flex" }}>
                        <OverlayTrigger
                          popoverContent={"View the units of work order"}
                          style={{ cursor: "pointer", paddingRight: "10px" }}
                          onClick={() => handleDetails(wo.workOrderNumber)}
                        >
                          <Icon

                            iconname="View"
                            style={{ fill: "#007bff" }}

                          />
                        </OverlayTrigger>
                      </div>
                    </TableRow>
                  </div>
                );
              })}
          </List>
          <Pagination
            pageCount={workOrders?.totalPages}
            currentPage={pageData.currentPage}
            onPageChange={(newPage, previousPage) => {
              if (workOrders.hasNextPage || newPage <= workOrders?.totalPages) {
                setPageData({ currentPage: newPage, pageSize: pageData.pageSize });
                getWorkOrders(newPage, pageData.pageSize);
              } else {
                cogoToast.warn("No next page", { position: 'top-right' });
              }

            }}
          />
        <WorkOrderAdd
          show={showCreateWOModal}
          onHide={() => OnHideCreateModel()}
          onLoadWorkOrders={() => getWorkOrders(1, 12)}
        />
      </div>}
    </div>

  );

}

export default HaivisionWorkOrder;