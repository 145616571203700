import {
  Header,
  FormGroup,
  FormControl,
  FormLabel,
  Button,
  SelectOption,
  ProgressBar } from "@hai/ui-react";
import {Col, Row} from 'reactstrap';
import React, { Component } from "react";
import AuditLogTable from "./AuditLogTable";
import { Fragment } from "react";
import axios from "../../../infra/axios";
import moment from "moment";

class AuditLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      logs: null,
      logFilter: () => true,
      app: "All",
      from: moment().add(-1, "hour"),
      to: moment(),
      logType: "all",
    };
  }

  setLogFilters() {
    this.loadAuditLog();
    this.setState((prev) => {
      prev.logFilter = (log) =>
        (prev.logType == "user"
          ? log.action.UserName != null
          : prev.logType == "app"
          ? log.action.UserName == null
          : true) &&
        (prev.app == "All" ? true : log.action.appModule == prev.app);
      return prev;
    });
  }

  clearLogFilters() {
    this.setState((prev) => {
      prev.logFilter = () => true;
      prev.app = "All";
      prev.from = moment().add(-1, "hour");
      prev.to = moment();
      prev.logType = "all";
      return prev;
    });
  }

  setLogType(type) {
    this.setState((prev) => {
      prev.logType = type;
      return prev;
    });
  }

  setApp(app) {
    this.setState((prev) => {
      prev.app = app;
      return prev;
    });
  }

  setFrom(date) {
    this.setState((prev) => {
      prev.from = moment(date);
      return prev;
    });
  }

  setTo(date) {
    this.setState((prev) => {
      prev.to = moment(date);
      return prev;
    });
  }

  componentDidMount() {
    this.loadAuditLog();
  }

  async loadAuditLog() {
    await axios
      .get(
        process.env.REACT_APP_JELLYFISH_API_BASE_URL +
          "/AuditLog/events?from=" +
          this.state.from.format("YYYY-MM-DD" + "T" + "HH:mm:ssZ") +
          "&to=" +
          this.state.to.format("YYYY-MM-DD" + "T" + "HH:mm:ssZ")
      )
      .then((response) => {
        this.setState((prev) => {
          prev.logs = response.data;
          prev.loading = false;
          return prev;
        });
      });
  }

  render() {
    if (this.state.loading) {
      return (
      <Fragment>
        <ProgressBar
  leftLabel="Loading: "
  barColors={{
    background: '#599bff',
    right: '#599bff',
    left: '#2fccd3',
  }}
  hideRight={true}
  animate={1}
/>
      </Fragment>);
    } else {
      return (
        <Fragment>
          <Header
            title="Jellyfish Audit Log"
            style={{ marginBottom: "1%", color: "#007bff" }}
          />
          <div>
            <Row>
              <Col>
                <FormLabel>Log Type</FormLabel>
                <FormControl
                  style={{ width: "200px" }}
                  as="select"
                  onChange={(e) => {
                    console.log(e.target.value);
                    this.setLogType(e.target.value);
                  }}
                >
                  <SelectOption value="all">All</SelectOption>
                  <SelectOption value="user">User</SelectOption>
                  <SelectOption value="app">App</SelectOption>
                </FormControl>
              </Col>
              <Col>
                <FormLabel>App</FormLabel>
                <FormGroup style={{ display: "inline" }}>
                  <FormControl
                    style={{ width: "200px" }}
                    as="select"
                    onChange={(e) => {
                      this.setApp(e.target.value);
                    }}
                    defaultValue={this.state.app}
                  >
                    <SelectOption>All</SelectOption>
                    <SelectOption>Connect</SelectOption>
                    <SelectOption>Hub</SelectOption>
                    <SelectOption>Jellyfish</SelectOption>
                  </FormControl>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <FormLabel>From</FormLabel>
                  <FormControl
                    style={{ width: "200px", display: "inline" }}
                    defaultValue={moment(this.state.from).format(
                      "YYYY-MM-DD HH:mm:ss"
                    )}
                    onChange={(e) => this.setFrom(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <FormLabel>To</FormLabel>
                  <FormControl
                    style={{ width: "200px", display: "inline" }}
                    defaultValue={moment(this.state.to).format(
                      "YYYY-MM-DD HH:mm:ss"
                    )}
                    onChange={(e) => this.setTo(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <FormLabel></FormLabel>
                  <Button
                    style={{ display: "inline" }}
                    onClick={() => this.setLogFilters()}
                    variant="primary"
                  >
                    Apply
                  </Button>
                  <Button
                    onClick={() => this.clearLogFilters()}
                    style={{ display: "inline" }}
                  >
                    Reset
                  </Button>
                </FormGroup>
              </Col>
            </Row>
            <hr />
          </div>
          <AuditLogTable logs={this.state.logs.filter(this.state.logFilter)} />
        </Fragment>
      );
    }
  }
}

export default AuditLog;
