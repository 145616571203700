import {
  Button,
  FormLabel,
  Header,
  LabelValue,
  List,
  ListHeader,
  Switch,
  TableRow } from "@hai/ui-react";
import cogoToast from "cogo-toast";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Row } from "reactstrap";
import axios from "../../../infra/axios";
import AuditLogTable from "../audit-log/AuditLogTable";
import "./useredit.scss";

const UserEdit = (props) => {
  let { idParam } = useParams();
  const [user, setUser] = useState(null);
  const [allRoles, setAllRoles] = useState(null);
  const [sendingUpdate, setSendingUpdate] = useState(false);
  const [userRoles, setUserRoles] = useState([]);
  const [logs, setLogs] = useState(null);
  var params = useParams();

  useEffect(() => {
    (async function () {
      await getUserById(idParam);
      await getAllRoles();
      //await getUserAuditLogs(userId);
    })();
  }, []);

  const getUserById = async (id) => {
    await axios
      .get(process.env.REACT_APP_JELLYFISH_API_BASE_URL + `/User/${id}`, {
        headers: {
          "Cache-Control": "no-cache",
        },
      })
      .then((response) => {
        setUser(response.data);
        if (response.data.email) {
          getUserAuditLogs(response.data.email);
        } else {
          getAppAuditLogs(response.data.appId);
        }
      });
  };

  const getAllRoles = async () => {
    await axios
      .get(process.env.REACT_APP_JELLYFISH_API_BASE_URL + `/roles`, {
        headers: {
          "Cache-Control": "no-cache",
        },
      })
      .then((response) => setAllRoles(response.data));
  };

  const getUserAuditLogs = async (email) => {
    await axios
      .get(
        process.env.REACT_APP_JELLYFISH_API_BASE_URL +
          `/AuditLog/events/users/` +
          email,
        {
          headers: {
            "Cache-Control": "no-cache",
          },
        }
      )
      .then((response) => setLogs(response.data));
  };

  const getAppAuditLogs = async (id) => {
    await axios
      .get(
        process.env.REACT_APP_JELLYFISH_API_BASE_URL +
          `/AuditLog/events/apps/` +
          id,
        {
          headers: {
            "Cache-Control": "no-cache",
          },
        }
      )
      .then((response) => setLogs(response.data));
  };

  const addOrRemoveRoles = () => {
    let body = {
      rolesToAdd: userRoles.filter((r) => r.status == true).map((r) => r.role),
      rolesToRemove: userRoles
        .filter((r) => r.status == false)
        .map((r) => r.role),
    };
    setSendingUpdate(true);
    axios
      .put(
        process.env.REACT_APP_JELLYFISH_API_BASE_URL +
          `/User/${idParam}/roleAssignment/roles`,
        body
      )
      .then((response) => {
        cogoToast.success("User roles updated", { position: "top-right" });
        setSendingUpdate(false);
      })
      .catch((err) => setSendingUpdate(false));
  };

  const changeUserRole = (role) => {
    let newRoles = userRoles.map((r) => {
      if (r.role === role) {
        return { role: role, status: !r.status };
      } else {
        return r;
      }
    });
    setUserRoles(newRoles);
  };

  const navigate = useNavigate();

  return (
    <>
      <Header title="User Details"></Header>
      {user && (
        <div>
          <Row>
            <Col>
              <FormLabel>Name</FormLabel>
              <LabelValue>
                {user.displayName ? user.displayName : "Service Principal"}
              </LabelValue>
            </Col>

            <Col>
              <FormLabel>Type</FormLabel>
              <LabelValue>{user.type}</LabelValue>
            </Col>

            <Col>
              <FormLabel>Email</FormLabel>
              <LabelValue>{user.email}</LabelValue>
            </Col>

            {user.type === "service_principal" && (
              <Col>
                <FormLabel>App Id</FormLabel>
                <LabelValue>{user.appId}</LabelValue>
              </Col>
            )}
          </Row>
          <hr />
          <Row>
        {userRoles.length > 0 && (
          <Button
            variant="primary"
            disabled={sendingUpdate}
            onClick={() => addOrRemoveRoles()}
          >
            Update
          </Button>
        )}
        <Button onClick={() => navigate("/system/user")}> Back</Button>
      </Row>
      <hr/>
          <FormLabel>Roles</FormLabel>
          <List>
            <ListHeader
checkbox={false}
              style={{
                gridTemplateColumns: "0fr 1fr 1fr",
              }}
            >
              <div>Role</div>
              <div>Status</div>
            </ListHeader>
            {allRoles &&
              user &&
              allRoles.map((role, index) => {
                let userHasRole = user.roles?.map((r) => r.role).includes(role);
                if (userRoles.length < allRoles.length) {
                  userRoles.push({ role: role, status: userHasRole });
                }
                return (
                  <TableRow
checkbox={false}
                    key={index}
                    style={{
                      gridTemplateColumns: " 1fr 1fr",
                    }}
                  >
                    <div>{role}</div>
                    <div>
                      <Switch
                        key={role}
                        checked={userHasRole}
                        onChange={() => changeUserRole(role)}
                      />
                    </div>
                  </TableRow>
                );
              })}
          </List>
        </div>
      )}
     
      <hr />
      <FormLabel>Audit Logs</FormLabel>
      <br />
      <Row>{logs && <AuditLogTable logs={logs} />}</Row>
    </>
  );
};
export default UserEdit;
