import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import { CardContainer, Spinner , ProgressBar } from "@hai/ui-react";
import HubRouteHublet from "./routeHublet";
import LineTo from "react-lineto";
import PropTypes from 'prop-types';

class HubRouteDiagram extends Component {
  constructor(props) {
    super(props);
    this.state = { route: this.props.route };
  }
  render() {
    return (
      <div>
        <Row
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignContent: "center",
          }}
        >
          <Col>
            <CardContainer
              label="INPUT"
              variant="large"
              card={this.state.route.hublets
                .filter((h) => h.type === "input")
                .map((h, index) => {
                  return (
                    <HubRouteHublet
                      hublet={h}
                      key={index}
                      hubletResource={this.state.route.hubletResources?.filter(
                        (x) => x.hubletId === h.id
                      )}
                    />
                  );
                })}
            />
          </Col>

          <Col>
            <CardContainer
              label="PROCESSING"
              variant="large"
              card={this.state.route.hublets
                .filter((h) => h.type === "processing")
                .map((h, index) => {
                  return (
                    <HubRouteHublet
                      hublet={h}
                      key={index}
                      hubletResource={this.state.route.hubletResources?.filter(
                        (x) => x.hubletId === h.id
                      )}
                    />
                  );
                })}
            />
          </Col>

          <Col>
            <CardContainer
              label="OUTPUT"
              variant="large"
              card={this.state.route.hublets
                .filter((h) => h.type === "output")
                .map((h, index) => {
                  return (
                    <HubRouteHublet
                      hublet={h}
                      key={index}
                      hubletResource={this.state.route.hubletResources?.filter(
                        (x) => x.hubletId === h.id
                      )}
                    />
                  );
                })}
            />
          </Col>
        </Row>

        {this.state.route.hublets
          .filter((h) => h.outputs !== null && h.outputs.length > 0)
          .map((hublet) => {
            return hublet.outputs.map((out, index) => {
              return (
                <LineTo
                  key={index}
                  delay={500}
                  fromAnchor="right"
                  from={hublet.id}
                  to={out}
                  toAnchor="left"
                />
              );
            });
          })}
      </div>
    );
  }
}

HubRouteDiagram.propTypes = {
  route: PropTypes.object.isRequired
}

export default HubRouteDiagram;
