import {
  Animation,
  FormControl,
  FormGroup,
  FormLabel,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  SelectOption,
  Spinner,
  ModalTitle,
 ProgressBar } from "@hai/ui-react";
import axios from "../../../infra/axios";
import React, { Component, Fragment } from "react";

class HaivisionLicenseAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      products: null,
      show: this.props.show,
      productTypeList: null,
      productList: null,
      versionList: null,
      licenseOptions: [],
      isMx: true,
      isVm: false,
      isAzure: false,
      isCommand360:false,
      newLicense: {
        sku: "",
        serialNumber: "",
        productVersionNumber: "",
        macAddresses: [
          {
            value: "",
          },
        ],
        licenseOptionDatas: [],
      },
      existLicense:null,
      showCommand360ConfirmModel:false,
    };
  }
  componentDidMount() {
    this.getProducts();
  }

  componentWillReceiveProps(nextProps) {
    this.setState((prev) => {
      prev.show = nextProps.show;
      return prev;
    });
  }

  getProducts = () => {
    const requestProducts = axios.get(
      process.env.REACT_APP_LIC_API_BASE_URL + "/Products"
    );
    const requestProductTypes = axios.get(
      process.env.REACT_APP_LIC_API_BASE_URL + "/Products/ProductTypes"
    );
    axios.all([requestProducts, requestProductTypes]).then(
      axios.spread((...responses) => {
        this.setState((prev) => {
          responses[0].data.unshift({name: '--Select--', value:'--Select--', id: '--Select--', productType:{ name: undefined}});
          prev.products = responses[0].data;
          responses[1].data.unshift({name: '--Select--', value:'--Select--', id: '--Select--'});
          prev.productTypeList = responses[1].data;
          if (prev.productTypeList.length > 0) {
            prev.productList = this.setProductList(
              prev.productTypeList[0].name
            );
          }
          if (prev.productList?.length > 0) {
            prev.selectedSku = prev.productList[0];
          }
          if (prev.versionList?.length > 0) {
            prev.selectedVersion = prev.versionList[0];
          }

          prev.loading = false;
          return prev;
        });
      })
    );
  };

  setProductTypeList() {
    this.setState((prev) => {
      prev.productTypeList = Array.from(
        new Set(
          prev.products.map((p) => {
            return p.productType.name;
          })
        )
      );
      
      // if (prev.productTypeList) {
      //   prev.productTypeList.unshift("--Select--");
      // }

      if (prev.productTypeList?.length > 0) {
        prev.selectedProductType = prev.productTypeList[0].name;
      }
      if (prev.productList?.length > 0) {
        prev.selectedSku = prev.productList[0];
      }
      if (prev.versionList?.length > 0) {
        prev.selectedVersion = prev.versionList[0];
      }

      return prev;
    });
  }

  setProductList = (productType) => {
    this.setState((prev) => {
       prev.productList = Array.from(
        new Set(
          prev.products
            .filter((x) => x.productType.name === productType)
            .map((p) => {
              return p.sku;
            })
        )
      );
      
      
     prev.productList.unshift("--Select--");

      prev.selectedProductType = productType;
      prev.isMx = (productType.includes("MX") || productType.includes("MFXE")) ? true : false;
      prev.isCommand360 = (productType.includes("Command360")) ? true : false;
      if (prev.productList?.length > 0) {
        prev.selectedSku = prev.productList[0];
      }
      if (prev.versionList?.length > 0) {
        prev.selectedVersion = prev.versionList[0];
      }
      return prev;
    });
  }

  setProductVersionList(productSku) {
    this.setState((prev) => {
      prev.versionList = Array.from(
        new Set(
          prev.products
            .filter((x) => x.sku === productSku)
            .map((p) => {
              return p.productType.productVersions
                .sort((version1, version2) => {
                  if (version1.majorVersion === version2.majorVersion) {
                    if (version1.minorVersion === version2.minorVersion) {
                      if (version1.patchVersion === version2.patchVersion) {
                        // two same version, impossible
                      } else if (
                        version1.patchVersion > version2.patchVersion
                      ) {
                        return -1;
                      } else {
                        return 1;
                      }
                    } else if (version1.minorVersion > version2.minorVersion) {
                      return -1;
                    } else {
                      return 1;
                    }
                  } else if (version1.majorVersion > version2.majorVersion) {
                    return -1;
                  } else {
                    return 1;
                  }
                })
                .filter((x)=> x.active === true)
                .map((v) => {
                  return (
                    v.majorVersion + "." + v.minorVersion + "." + v.patchVersion
                  );
                })
            })
        )
      )[0];

      if (prev.versionList) {
        prev.versionList.unshift("--Select--");
      }

      prev.selectedSku = productSku;
      if (prev.versionList?.length > 0) {
        prev.selectedVersion = prev.versionList[0];
      }

      return prev;
    });
  }

  setSelectToFirst() {
    if (this.state.productTypeList.length > 0) {
      this.setState((prev) => {
        prev.selectedProductType = prev.productTypeList[0].name;
      });
    }

    if (this.state.versionList.length > 0) {
      this.setState((prev) => {
        prev.selectedSku = prev.productList[0];
      });
    }

    if (this.state.productTypeList.length > 0) {
      this.setState((prev) => {
        prev.selectedVersion = prev.versionList[0];
      });
    }
    /*this.setState((prev) => {
      if (prev.productTypeList.length > 0) {
        prev.selectedProductType = prev.productTypeList[0].name;
      }
      if (prev.versionList.length > 0) {
        prev.selectedSku = prev.productList[0];
      }
      if (prev.productTypeList.length > 0) {
        prev.selectedVersion = prev.versionList[0];
      }
    });*/
  }

  setLicenseOptions(productSku, versionNumber) {
    this.setState((prev) => {
      var product = prev.products.filter((x) => x.sku === productSku)[0];
      var version = product.productType.productVersions.filter(
        (x) =>
          x.majorVersion + "." + x.minorVersion + "." + x.patchVersion ===
          versionNumber
      )[0];
      prev.licenseOptions = [];

      product.productDefaultOptions.forEach((opdef) => {
        var option = version?.productLicenseOptions?.find(
          (op) => op.id === opdef.productLicenseOptionId
        );
        if (option !== null && option !== undefined) {
          prev.licenseOptions.push({
            productLicenseOption: option,
            selectedValue: opdef.defaultValue
          });
          this.setVmAndAzure(option.licenseSectionItem, opdef.defaultValue);
        }
      });
      prev.selectedVersion = versionNumber;
      return prev;
    });
  }

  getDefaultValueFromAvailables(availableValues) {
    var defaultValue = "";
    if (availableValues && !availableValues.startsWith("^")) {
      var availables = availableValues.split(";")[0];
      defaultValue = availables.length > 0 ? availables.split("!")[0] : "";
    }

    return defaultValue;
  }

  setLicenseOptionDatas = (request) => {
    if (
      this.state.licenseOptions == null ||
      this.state.licenseOptions === undefined
    ) {
      return;
    }

    var licensesOptionDatatmp = [];

    this.state.licenseOptions.forEach((opt) => {
      if (
        !licensesOptionDatatmp.includes(
          opt.productLicenseOption.licenseSectionItem
        )
      ) {
        licensesOptionDatatmp.push({
          Item: opt.productLicenseOption.licenseSectionItem,
          Value: opt.selectedValue,
        });
      }
    });

    this.setState((prev) => {
      prev.newLicense.licenseOptionDatas = licensesOptionDatatmp;
    }, request);
  };

  handleSubmitModal() {
    var bMissingExpdate = false;
    var options = this.state.licenseOptions;
    for (var opt of options) {
      if (
        opt.productLicenseOption.licenseSectionItem === "expdate" &&
        (opt.selectedValue.trim() === "" ||
          opt.selectedValue.trim() === "0000-00-00")
      ) {
        bMissingExpdate = true;
      }
    }
    if (bMissingExpdate) {
      alert("Please enter expdate");
      return;
    }

    if(this.state.isCommand360) {
      //check sn is exist or not
      this.getCommand360License();
    }
    else{   
      this.setLicenseOptionDatas(this.handleNewLicense);      
    }
    
  }


  getCommand360License(){


    axios
      .get(
        process.env.REACT_APP_LIC_API_BASE_URL +
          "/Licenses/serialNumber/" +
          this.state.newLicense.serialNumber
      )
      .then((response) => {
        var license = null;
        if(response.data.length <= 0)
        {
          license = null;
          this.setState((prev) => {
            prev.existLicense = null;
            return prev;
          }, ()=> {this.setLicenseOptionDatas(this.handleNewLicense)});
        }
        else{
          license = response.data[0];
          this.setState((prev) => {
            prev.existLicense = license;
            return prev;
          }, () => {this.handleShowsCommand360ConfirmModel()});
        }
        
      });
  }

  handleConfirmDelete() {
    axios
      .delete(
        process.env.REACT_APP_LIC_API_BASE_URL +
          "/Licenses/" +
          this.state.existLicense.id
      )
      .then((reponse) => {
        this.setState((prev) => {
          prev.existLicense = null;
          return prev;
        }, () => {this.setLicenseOptionDatas(this.handleNewLicense)});
      });
  }

  getExistLicenseInfo(){
    if(this.state.existLicense === null || this.state.existLicense === undefined){
      return '';
    }

    var message = `License with serial number ${this.state.newLicense.serialNumber} is existing,
    ${this.state.existLicense.licenseOptionDatas.map((option) => {return `${option.item}:[${option.value}]`}).join(' ')}
    Do you want to replace it?`

    return message;
  }

  handleConfirmUpdate(){
    this.handleConfirmDelete();
  }

  handleShowsCommand360ConfirmModel(){
    this.setState((prev) => {
      prev.showCommand360ConfirmModel = !prev.showCommand360ConfirmModel;
      return prev;
    });
  }

  showUpdateCommand360LicenseModel(){
    return (
      <div>
        <Modal
          show={this.state.show}
          onHide={() => this.handleShowsCommand360ConfirmModel()}
          size="lg"
        >
          <ModalHeader>
            <ModalTitle>Warning</ModalTitle>
          </ModalHeader>
          <ModalBody style={{ textAlign: "center" }}>
            {this.getExistLicenseInfo()}
          </ModalBody>
          <ModalFooter>
            <Button
              variant="primary"
              onClick={() => this.handleConfirmUpdate()}
            >
              Confirm
            </Button>
            <Button variant="secondary" onClick={() => this.handleShowsCommand360ConfirmModel()}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );   
  }

  handleNewLicense() {
    axios
      .post(
        process.env.REACT_APP_LIC_API_BASE_URL + "/Licenses",
        this.state.newLicense
      )
      .then((reponse) => {
        this.handleHideModal();
        this.props.refresh();
      });
  }

  setVmAndAzure(item, value) {
    if (
      item === null ||
      item === undefined ||
      value === null ||
      value === undefined
    ) {
      return;
    }
    if (item.toLowerCase() === "vmware") {
      this.setState((prev) => {
        prev.isVm = value.toLowerCase() === "on" ? true : false;
        return prev;
      });
    }
    if (item.toLowerCase() === "licensetype") {
      this.setState((prev) => {
        prev.isAzure = value.toLowerCase() === "azure byol" ? true : false;
        return prev;
      });
    }
  }

  handleLicenseOptionValue(id, e) {
    var tmp = this.state.licenseOptions;
    var loIndex = tmp.findIndex((x) => x.productLicenseOption.id === id);
    if (loIndex == null || loIndex == -1 || e.target == null) {
      return;
    }
    tmp[loIndex].selectedValue = e.target.value.trim();
    this.setState((pre) => {
      pre.licenseOptions.productLicenseOption = tmp;
      return pre;
    });

    this.setVmAndAzure(tmp.licenseSectionItem, e.target.value.trim());
  }


  handleHideModal() {
    this.setState(
      {
        loading: false,
        products: null,
        show: false,
        productTypeList: null,
        productList: null,
        versionList: null,
        licenseOptions: [],
        selectedProductType: "",
        selectedSku: "",
        selectedVersion: "",
        isMx: true,
        isVm: false,
        isAzure: false,
        isCommand360:false,
        newLicense: {
          sku: "",
          serialNumber: "",
          productVersionNumber: "",
          resourceId: "",
          instanceUuid: "",
          cpuId: "",
          licenseRequestNumber: "",
          macAddresses: [
            {
              value: "",
            },
          ],
          productType: "",
          licenseOptionDatas: [],
          existLicense:null,
          showCommand360ConfirmModel:false,
        },
      },
      () => {
        this.props.onHide();
      }
    );
  }

  showCommand360LicenseItem = () => {
    return (
      <div>
          <FormGroup>
            <FormLabel>Serial Number</FormLabel>
            <FormControl
              defaultValue=""
              onChange={(e) => {
                var text = e.target.value.trim();
                this.setState((prev) => {
                  prev.newLicense.serialNumber = text;
                  return prev;
                });
              }}
            ></FormControl>
        </FormGroup>
        <FormGroup>
          <FormLabel>Mac Address</FormLabel>
          <FormControl
            as="textarea"
            onChange={(e) => {
              var text = e.target.value.trim();

              this.setState((prev) => {
                prev.newLicense.macAddresses[0].value = text;
                return prev;
              });
            }}
          ></FormControl>
        </FormGroup>
      </div>
    );
  }

  showServerLicenseItem() {
    return (
      <div>
        {!this.state.isVm && (
          <FormGroup>
            <FormLabel>Serial Number</FormLabel>
            <FormControl
              defaultValue=""
              onChange={(e) => {
                var text = e.target.value.trim();
                this.setState((prev) => {
                  prev.newLicense.serialNumber = text;
                  return prev;
                });
              }}
            ></FormControl>
          </FormGroup>
        )}
        {this.state.isAzure && (
          <div>
            <FormGroup>
              <FormLabel>License Request Number</FormLabel>
              <FormControl
                as="textarea"
                defaultValue=""

                onChange={(e) => {
                  var text = e.target.value.trim();
                  this.setState((prev) => {
                    prev.newLicense.licenseRequestNumber = text;
                    return prev;
                  });
                }}
              ></FormControl>
            </FormGroup>

            <FormGroup>
              <FormLabel>Resource ID</FormLabel>
              <FormControl
                as="textarea"
                defaultValue=""
                onChange={(e) => {
                  var text = e.target.value.trim();
                  this.setState((prev) => {
                    prev.newLicense.resourceId = text;
                    return prev;
                  });
                }}
              ></FormControl>
            </FormGroup>
          </div>
        )}
        {this.state.isVm && (
          <div>
            <FormGroup>
              <FormLabel>Instance UUID</FormLabel>
              <FormControl
                as="textarea"
                defaultValue=""
                onChange={(e) => {
                  var text = e.target.value.trim();
                  this.setState((prev) => {
                    prev.newLicense.instanceUuid = text;
                    return prev;
                  });
                }}
              ></FormControl>
            </FormGroup>
            <FormGroup>
              <FormLabel>CPU ID</FormLabel>
              <FormControl
                as="textarea"
                defaultValue=""
                onChange={(e) => {
                  var text = e.target.value.trim();
                  this.setState((prev) => {
                    prev.newLicense.cpuId = text;
                    return prev;
                  });
                }}
              ></FormControl>
            </FormGroup>
          </div>
        )}
        <FormGroup>
          <FormLabel>Mac Address</FormLabel>
          <FormControl
            as="textarea"
            onChange={(e) => {
              var text = e.target.value.trim();

              this.setState((prev) => {
                prev.newLicense.macAddresses[0].value = text;
                return prev;
              });
            }}
          ></FormControl>
        </FormGroup>
      </div>
    );
  }

  render() {
    
      return (
        <div>
          <Modal
            show={this.state.show}
            onHide={false}
            size="lg"
            type="wide-padding"
          >
            <ModalHeader>
              <ModalTitle>New Haivision License</ModalTitle>
            </ModalHeader>
            <ModalBody>
              {this.state.show && this.state.loading &&
                  <ProgressBar
                    leftLabel="Loading: "
                    barColors={{
                      background: '#599bff',
                      right: '#599bff',
                      left: '#2fccd3',
                    }}
                    hideRight={true}
                    animate={1}/>
              } 
              {this.state.show && !this.state.loading && <Fragment>
              <FormGroup>
                <FormLabel>Product Type</FormLabel>
                <FormControl
                  as="select"
                  defaultValue={this.state.selectedProductType}
                  onChange={(e) => { 
                    var text = e.target.value;                 
                    this.setProductList(text);
                  }}
                >
                  {this.state.productTypeList &&
                    this.state.productTypeList.map((x) => {
                      return <SelectOption value={x.name} key={x.id} >{x.name}</SelectOption>;
                    })}
                </FormControl>
              </FormGroup>

              <FormGroup>
                <FormLabel>SKU</FormLabel>
                <FormControl
                  as="select"
                  defaultValue={this.state.selectedSku}
                  onChange={(e) => {
                    var text = e.target.value;
                    this.setState((prev) => {
                      prev.newLicense.sku = text.trim();
                      return prev;
                    });
                    this.setProductVersionList(text);
                  }}
                >
                  {this.state.productList &&
                    this.state.productList.map((p) => {
                      return <SelectOption value={p} key={p}>{p}</SelectOption>;
                    })}
                </FormControl>
              </FormGroup>

              <FormGroup>
                <FormLabel>Version</FormLabel>
                <FormControl
                  as="select"
                  defaultValue={this.state.selectedVersion}
                  onChange={(e) => {
                    var text = e.target.value;
                    this.setState((prev) => {
                      prev.newLicense.productVersionNumber = text;
                      return prev;
                    });
                    this.setLicenseOptions(this.state.newLicense.sku, text);
                  }}
                >
                  {this.state.versionList &&
                    this.state.versionList.map((p) => {
                      return <SelectOption value={p} key={p}>{p}</SelectOption>;
                    })}
                </FormControl>
              </FormGroup>
              {!this.state.isMx && !this.state.isCommand360 && this.showServerLicenseItem()}
              {this.state.isCommand360 && this.showCommand360LicenseItem()}
              {this.state.isMx && (
                <div>
                  <FormGroup>
                    <FormLabel>Serial Number (12 digit)</FormLabel>
                    <FormControl
                      defaultValue="HAI-"
                      onChange={(e) => {
                        var text = e.target.value.trim();
                        if (!text.startsWith("HAI-")) {
                          text = "HAI-" + text;
                        }
                        this.setState((prev) => {
                          prev.newLicense.serialNumber = text;
                          return prev;
                        });
                      }}
                    ></FormControl>
                  </FormGroup>
                  <FormGroup>
                    <FormLabel>
                      Mac Address (Seperate by comma for multiple Mac)
                    </FormLabel>
                    <FormControl
                      as="textarea"
                      onChange={(e) => {
                        var text = e.target.value.trim();
                        var macs = [
                          new Set(
                            text.split(",").map((m) => {
                              return m;
                            })
                          ),
                        ];

                        this.setState((prev) => {
                          prev.newLicense.macAddresses = [...macs[0]].map(
                            (m) => {
                              return { value: m };
                            }
                          );
                          return prev;
                        });
                      }}
                    ></FormControl>
                  </FormGroup>
                </div>
              )}

              <hr color="#9595a1" />
              {
                this.state.isCommand360 && 
                this.state.licenseOptions &&
                this.state.licenseOptions.map((opt) => {
                  return (
                    <div key={opt.productLicenseOption.id}>
                      <FormGroup>
                        <FormLabel>
                          {opt.productLicenseOption.description}
                        </FormLabel>
                        {opt.productLicenseOption.licenseSectionItem ===
                          "accesskey" && (
                          <div>
                            <FormControl
                              as="textarea"
                              value={opt.selectedValue}
                              onChange={(e) => {
                                this.handleLicenseOptionValue(
                                  opt.productLicenseOption.id,
                                  e
                                );
                              }}
                            ></FormControl>
                          </div>
                        )}
                        {opt.productLicenseOption.licenseSectionItem !==
                          "accesskey" && 
                              <div>
                                <FormControl
                                  as="select"
                                  defaultValue={opt.selectedValue}
                                  onChange={(e) => {
                                    this.handleLicenseOptionValue(
                                      opt.productLicenseOption.id,
                                      e
                                    );
                                  }}
                                >
                                {opt.productLicenseOption.availableValues
                                    .split(";")
                                    .map((o) => {
                                      var itemValue = o.split("!")[0];
                                      return <SelectOption key={itemValue} value={itemValue}>{itemValue}</SelectOption>;
                                    })
                                }
                              </FormControl>
                            </div>
                          }
                      </FormGroup>
                    </div>
                  );
                })}
              {
                !this.state.isCommand360 && 
                this.state.licenseOptions &&
                this.state.licenseOptions.map((opt) => {
                  return (
                    <div key={opt.productLicenseOption.id}>
                      <FormGroup>
                        <FormLabel>
                          {opt.productLicenseOption.description}
                        </FormLabel>
                        {(opt.productLicenseOption.licenseSectionItem ===
                          "expdate" ||
                          opt.productLicenseOption.licenseSectionItem ===
                            "expiration_date") && (
                          <div>
                            {opt.productLicenseOption.licenseSectionItem ===
                              "expdate" && (
                              <FormLabel>
                                {
                                  "Note: Trial permission can only request hardware licenses with expire date of 30 days"
                                }
                              </FormLabel>
                            )}
                            <FormControl
                              as="textarea"
                              value={opt.selectedValue}
                              onChange={(e) => {
                                this.handleLicenseOptionValue(
                                  opt.productLicenseOption.id,
                                  e
                                );
                              }}
                            ></FormControl>
                          </div>
                        )}
                        {opt.productLicenseOption.licenseSectionItem !==
                          "expdate" &&
                          opt.productLicenseOption.licenseSectionItem !==
                            "expiration_date" && (
                            <FormControl as="textarea" disabled={true} defaultValue={opt.selectedValue}>
                              
                            </FormControl>
                          )}
                      </FormGroup>
                    </div>
                  );
                })}
                </Fragment>}
            </ModalBody>
            <ModalFooter>
              <Button
                variant="primary"
                onClick={() => this.handleSubmitModal()}
                disabled={this.state.loading}
              >
                Create
              </Button>
              <Button
                variant="secondary"
                onClick={() => this.handleHideModal()}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
          {this.state.showCommand360ConfirmModel && this.showUpdateCommand360LicenseModel()}
        </div>
      );
    }
  }


export default HaivisionLicenseAdd;
