import React, { useEffect, useState } from "react"
import axios from "../../infra/axios";
import { Header, NotFound, ProgressBar, FormControl, List, FormLabel, Divider, Button } from "@hai/ui-react";
import { useParams } from "react-router-dom";
import { Row, Col } from "reactstrap";
import NavigationButtons from "../common/Utils/NavigationButtons";
import ToriiSalesforceItems from "./ToriiSalesforceItems";
import ToriiSalesforceAssets from "./ToriiSalesforceAssets";
import ToriiUsageBySubscription from "./ToriiUsageBySubscription";

const ToriiSubscriptions = (props) => {

    const params  = useParams();
    const [subscriptions, setSubscriptions] = useState(null);
    const [loading, setLoading] = useState(true);
    const [notFound, setNotFound] = useState(false);
    const [expandContent, setExpandContent] = useState(null);
    const [isAllSubscriptions, setIsAllSubscriptions] = useState(true)

    const [pageData, setPageData] = useState({
        currentPage: params?.pageNum ? parseInt(params?.pageNum) : 1,
        pageSize: 10
    });
    const [visibleData, setVisibleData] = useState(null);
    const [userFilter, setUserFilter] = useState(null);


    useEffect(() => {
        if (params.orgId) {
            setIsAllSubscriptions(false);
            getSubByOrgId(params.orgId);
        } else {
            getAllSubscriptions();
        }
    }, [])

    useEffect(() => {
        search();
    }, [subscriptions])

    const search = () => {
        if (!params.orgId) { 
            if (userFilter === '*') {
                setVisibleData(subscriptions);
                setIsAllSubscriptions(true);
            } else if (userFilter) {
                var data = subscriptions?.filter(a => a.id === userFilter || a.name.includes(userFilter));
                setVisibleData(data);
            } else if (!userFilter && !params.pageNum) {
                setVisibleData(subscriptions?.slice(0, 10));
                setPageData({...pageData, currentPage: 1});
                window.history.replaceState('','', '/torii/subscriptions/page/1')
            }  else if (params?.pageNum) {
                setVisibleDataFromPageNum(subscriptions);
            }
        }
    }

    const getSubByOrgId = (org) => {
        axios.get(`${process.env.REACT_APP_TORII_API_BASE_URL}/organizations/${org}/subscriptions`)
        .then(res => {
            setSubscriptions(res.data);
            setVisibleData(res.data);
            setLoading(false);
        })
        .catch(e => setLoading(false));
    } 

    const getAllSubscriptions = () => {
        axios.get(`${process.env.REACT_APP_TORII_API_BASE_URL}/subscriptions`)
        .then(res => {
            setSubscriptions(res.data);
            setVisibleDataFromPageNum(res.data);
            setLoading(false);
        })
        .catch(e => setLoading(false));
    }

    const setVisibleDataFromPageNum = (data) => {
        if (data) {
            let start = params?.pageNum != null ? ((params?.pageNum - 1) * pageData.pageSize) : 0;
            setVisibleData(data.slice(start, pageData.currentPage * pageData.pageSize));
            if (data.length < pageData.currentPage * pageData.pageSize) {
                console.log('invalid page');
                setVisibleData(data.slice(0, 1 * pageData.pageSize));
            }
        }
    }

    return (<div key='toriiSubscriptions'>
        <Header title="Torii Subscriptions" key="toriiSubHeader"></Header>
            <br></br>
            {loading && <ProgressBar leftLabel="Loading: "
                barColors={{
                    background: '#599bff',
                    right: '#599bff',
                    left: '#2fccd3',
                }}
                hideRight={true}
                animate={1} />
            }
        {!loading && subscriptions?.length === 0 &&
            <p>No subscription found for this organization.</p>}
        {!loading && isAllSubscriptions && 
            <div style={{display: 'flex', alignItems: 'baseline'}}>
                <FormLabel style={{marginRight: '1%'}}>Subscription Id/Name</FormLabel>
                <FormControl onChange={(e) => setUserFilter(e.target.value)} defaultValue={params?.id}></FormControl>
                <Button onClick={search}>Search</Button>
            </div> }

            {notFound && <NotFound></NotFound>}
            {!notFound && visibleData && visibleData.map(a => {
                return (
                <>
                    <List key={a.id}>
                        <List.Panel panelColor={a.enabled ? 'haiui-green-01' : 'haiui-red-01'} expandedState={{expanded: (subscriptions?.length === 1 || expandContent === "showDetails"+a.id), eventKey: 'showDetails'}}>
                            <List.PanelTitle title={a.name} />
                            <List.PanelDetail>
                                <List.PanelDetailItem icon="Information" text={a.id} divider="vertical"></List.PanelDetailItem>
                            </List.PanelDetail>
                            <List.Actions>
                                <List.ActionItem expandButton eventKey={"showDetails"+a.id} icon='View' title="Details" showActive
                                    onSelect={(eventKey, expanded) => {
                                        setExpandContent(expanded ? eventKey : '')
                                    }} />
                            </List.Actions>
                            <List.ExpandedPanel>
                                <div>
                                    {(expandContent === "showDetails"+a.id || subscriptions?.length === 1) &&
                                       <div>
                                            <Row>
                                                    <Col>
                                                        <FormLabel>Description: <p style={{color: 'white'}}>{a.description}</p></FormLabel>
                                                    </Col>
                                                    <Col>
                                                        <FormLabel>Tier: <p style={{color: 'white'}}>{a.tier}</p></FormLabel>
                                                    </Col>
                                                    <Col>
                                                        <FormLabel>Created: <p style={{color: 'white'}}>{a.CreatedOn}</p></FormLabel>
                                                    </Col>
                                                    <Col>
                                                        <FormLabel>Support Status: <p style={{color: 'white'}}>{a.supportStatus}</p></FormLabel>
                                                    </Col>
                                                    <Col>
                                                        <FormLabel>Salesforce Contract Id: <p style={{color: 'white'}}>{a.salesforceServiceContractId}</p></FormLabel>
                                                    </Col>
                                                    <Col>
                                                        <FormLabel>Mcr Account Id: <p style={{color: 'white'}}>{a.mcrAccountId}</p></FormLabel>
                                                    </Col>
                                                    <Col>
                                                        <FormLabel>Monthly Cost: <p style={{color: 'white'}}>{a.monthlyCost}</p></FormLabel>
                                                    </Col>
                                                </Row>
                                                
                                                <div style={{ marginTop: '2%' }}>
                                                    <Row><b>SKUs</b></Row>
                                                    <br/>
                                                    <Row>
                                                        {a.skus?.map(sku => {
                                                            return (
                                                                <Col key={sku.name}>
                                                                <div style={{alignItems: 'center', display: 'flex'}}>
                                                                {sku.name}
                                                                     <Divider type="vertical"
                                                                    color={sku.status === 'active' ? "haiui-green-01" : "haiui-red-01"}
                                                                    style={{ height: 30 }} />
                                                                   {sku.status}
                                                                </div>
                                                                </Col>                                                            
                                                            )
                                                        })}
                                                    </Row>
                                                </div>
                                                <hr></hr>
                                        </div>
                                    }
                                </div>
                            </List.ExpandedPanel>
                        </List.Panel>
                    </List>
                    {console.log(subscriptions?.length)}

                    {(subscriptions?.length === 1 || expandContent === "showDetails"+a.id) &&
                    <>
                    <ToriiSalesforceItems contractId={a.salesforceServiceContractId} key="toriiItems"/>
                    <ToriiSalesforceAssets contractId={a.salesforceServiceContractId} key="toriiAssets"/>
                    <ToriiUsageBySubscription subscriptionId={a.id} key="toriiUsage"/>
                    <hr/>
                    </>
                    }
                   
               </> )
            })}

        {isAllSubscriptions && subscriptions &&
             <NavigationButtons
             baseUrl="/torii/subscriptions"
             setPageData={setPageData}
             pageData={pageData}
             setVisibleData={setVisibleData}
             visibleData={visibleData}
             data={subscriptions}
         ></NavigationButtons>
        }

    </div>)
}

export default ToriiSubscriptions;