import {
  faCheckCircle,
  faMinusCircle,
  faCog,
  faKey,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Dialog,
  OverlayTrigger,
  TableRow,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  ModalTitle } from "@hai/ui-react";
import cogoToast from "cogo-toast";
import React, { useState, useEffect } from "react";
import axios from "../../../infra/axios";
import ApiCredential from "../api-credentials/ApiCredential";

const AssetsRow = (props) => {
  const [asset, setAsset] = useState(props.asset);
  const [processingAsset, setProcessingAsset] = useState(false);

  //disable
  const [showDisableDialog, setShowDisableDialog] = useState(false);
  // enable
  const [showEnableDialog, setShowEnableDialog] = useState(false);

  // apiCredDialogShow
  const [showApiCredDialog, setShowApiCredDialog] = useState(false);
  const [apiCredSubId, setApiCredSubId] = useState(null);

  useEffect(() => {}, []);

  const goToSubscription = (subId) => {
    if (!props.orgId) {
      cogoToast.error("Could not find organization", { position: "top-right" });
      return;
    }
    window.open(
      `/hub/organizations/${props.orgId}/subscriptions/${subId}`,
      "_blank"
    );
  };

  const disableOrEnableSub = (isDisable) => {
    setProcessingAsset(true);
    axios
      .put(
        process.env.REACT_APP_HUB_API_BASE_URL +
          `/Operations/client-subscription/organizations/${
            props.orgId
          }/subscriptions/${asset.subscriptionId}/${
            isDisable ? "disable" : "enable"
          }`
      )
      .then((response) => {
        setProcessingAsset(false);
        setShowDisableDialog(false);
        setShowEnableDialog(false);
        updateAssetStatus();
        cogoToast.success(
          `Subscription ${isDisable ? "disabled" : "enabled"}`,
          { position: "top-right" }
        );
      })
      .catch((err) => setProcessingAsset(false));
  };

  const updateAssetStatus = () => {
    let newAsset = { ...asset, isDisabled: !asset.isDisabled };
    setAsset(newAsset);
  };

  return (
    <>
      <TableRow
checkbox={false}
        
        alternateRowColors={props.index % 2 == 0}
        style={{
          gridTemplateColumns: "0.3fr 2.5fr 2.5fr 1.5fr 0.3fr 0.3fr",
          color: "white",
          height: "auto !important",
          fontSize: "0.8rem",
        }}
      >
        <div></div>
        <div style={{flexDirection: 'column'}}>
          <a
            style={{ color: "#007bff" }}
            href={`https://haivision.lightning.force.com/${asset.id}`}
            target="_blank"
          >
            {asset.subscriptionName}
          </a>
          <p>{asset.subscriptionId}</p>
        </div>
        <div>
          {asset.azureSubscriptionName
            ? asset.azureSubscriptionName
            : asset.name}
          <br />
          {asset.azureSubscriptionId}
        </div>
        <div>
          {" "}
          {asset.isDisabled != null ? (
            <FontAwesomeIcon
              style={{ cursor: "pointer" }}
              icon={asset.isDisabled ? faMinusCircle : faCheckCircle}
              color={asset.isDisabled ? "red" : "green"}
              onClick={() => {
                if (!asset.isDisabled) {
                  setShowDisableDialog(true);
                } else {
                  setShowEnableDialog(true);
                }
              }}
            />
          ) : (
            "Not found"
          )}
        </div>
        {/* ApiCredentials Dialog */}
        <div
          onClick={() => {
            setApiCredSubId(asset.subscriptionId);
            setShowApiCredDialog(true);
          }}
        >
          <FontAwesomeIcon
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              color: "white",
            }}
            icon={faKey}
          />
        </div>

        {/* Details ICON */}
        <div
          onClick={() => {
            if (asset.isDisabled) {
              cogoToast.info("This subscription is disabled", {
                position: "top-right",
              });
            } else {
              goToSubscription(asset.subscriptionId);
            }
          }}
        >
          <OverlayTrigger placement={"bottom"} popoverContent="Details">
            <FontAwesomeIcon
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                color: "white",
              }}
              icon={faCog}
            ></FontAwesomeIcon>
          </OverlayTrigger>
        </div>
      </TableRow>

      <Modal
        show={showApiCredDialog}
        onHide={() => {
          setShowApiCredDialog(false);
          setApiCredSubId(null);
        }}
        size="xl"
      >
        <ModalHeader style={{ color: "white" }}>Api Credentials</ModalHeader>
        <ModalBody>
          <ModalTitle>{"Subscription Id: " + apiCredSubId}</ModalTitle>
          <hr />
          <ApiCredential
            subscriptionId={apiCredSubId}
            organizationId={props.orgId}
            customerName={props.customerName}
          />
          <hr />
        </ModalBody>
        <ModalFooter>
          <Button
            variant="primary"
            onClick={() => {
              setShowApiCredDialog(false);
              setApiCredSubId(null);
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>

      <Dialog
        show={showDisableDialog}
        onClose={() => {
          setShowDisableDialog(false);
        }}
        headline={`Disable subscription`}
        content={
          <>
            <p>The following subscription will be disabled: </p>
            <br></br>
            {asset?.azureSubscriptionName} - {asset?.subscriptionId}
            <br></br>
            <br></br>
            <p>
              Note: ClaimCode will also be disabled as well. Please confirm this
              action.
            </p>
          </>
        }
        buttons={[
          {
            label: "Yes",
            disabled: processingAsset,
            onClick: () => disableOrEnableSub(true),
          },
          {
            label: "No",
            primary: true,
            onClick: () => {
              setShowDisableDialog(false);
            },
          },
        ]}
      ></Dialog>

      {/* ENABLE SUB DIALOG */}
      <Dialog
        show={showEnableDialog}
        onClose={() => {
          setShowEnableDialog(false);
        }}
        headline={`Enable subscription`}
        content={
          <>
            <p>The following subscription will be enabled: </p>
            <br></br>
            {asset?.azureSubscriptionName} - {asset?.subscriptionId}
            <br></br>
            <br></br>
            <p>
              Note: ClaimCode will also be enabled as well. Please confirm this
              action.
            </p>
          </>
        }
        buttons={[
          {
            label: "Yes",
            disabled: processingAsset,
            onClick: () => disableOrEnableSub(false),
          },
          {
            label: "No",
            primary: true,
            onClick: () => {
              setShowEnableDialog(false);
            },
          },
        ]}
      ></Dialog>
    </>
  );
};

export default AssetsRow;
