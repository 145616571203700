import {Modal, ModalTitle, ModalHeader, ModalBody, ModalFooter, LabelValue } from '@hai/ui-react';
import React, { Component }  from 'react';
import { Button, FormGroup, FormLabel } from '@hai/ui-react';
import {Row, Col} from 'reactstrap';

class ContractProvisionSuccessModal extends Component {
    constructor(props) {
        super(props);      
    }

    render() { 

        return (
          
        <Modal size="lg"  show={this.props.show} onHide={this.props.hide}>

                <ModalHeader>
                    <ModalTitle>Provisioned successfully!</ModalTitle>
                </ModalHeader>
               <ModalBody>
              

              <Row>
                <Col>
                  <FormGroup>
                    <FormLabel style={{color: '#007bff'}}>Contract Name</FormLabel>
                    <LabelValue>{this.props.contract.contractName}</LabelValue>
                  </FormGroup>
                </Col>

                <Col>
                  <FormGroup>
                    <FormLabel  style={{color: '#007bff'}}>Company Name</FormLabel>
                    <LabelValue>{this.props.contract.accountName}</LabelValue>
                  </FormGroup>
                </Col>

              </Row>
              <Row>
                <Col>
                <FormGroup>
                  <FormLabel  style={{color: '#007bff'}}>Domain</FormLabel>
                  <LabelValue>{this.props.provision.domain}</LabelValue>
                </FormGroup>
                </Col>

                <Col>
                  <FormGroup>
                    <FormLabel   style={{color: '#007bff'}}>Claim code</FormLabel>
                    <LabelValue>{this.props.provision.claimCode}</LabelValue>
                  </FormGroup>
                </Col>
                </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <FormLabel  style={{color: '#007bff'}}>Subscription Id</FormLabel>
                    <LabelValue>{this.props.provision.subscriptionId}</LabelValue>
                  </FormGroup>
                </Col>

                <Col>
                <FormGroup>
                  <FormLabel  style={{color: '#007bff'}}>Subscription Name</FormLabel>
                  <LabelValue>{this.props.provision.subscriptionName}</LabelValue>
                </FormGroup>
                </Col>
              </Row>
        
        </ModalBody>
        <ModalFooter>
            <Row>
            <Col>
            <Button variant="secondary" onClick={this.props.hide}>
              Close
            </Button>
            </Col>
            </Row>
            
          </ModalFooter>
        </Modal>
        )        
  }
}


export default ContractProvisionSuccessModal;
