import { DataTable } from "@hai/ui-react";
import React from "react";

const AwModemsDataTable = props => {

    return (
        <DataTable
            columnStructure={[{ fieldKey: 'uuid', title: 'UUID' },
            { fieldKey: 'macAddress', title: 'MAC' },
            { fieldKey: 'model', title: 'Model' },
            { fieldKey: 'serial', title: 'Serial' },
            { fieldKey: 'type', title: 'Type' },
            { fieldKey: 'firmwareFull', title: 'Firmware' },
            { fieldKey: 'revision', title: 'Revision' }
            ]}
            compact={true}
            emptyStateText="No modem!"
            selectable={false}
        >
            <DataTable.TitleBar
                title={"Modems"}
                subtitle={'Product ' + props?.uuid}
                withSearch={false}
            />
            <DataTable.Header />

            {props?.modems && props?.modems.map((data, index) => {
                return <DataTable.Row key={data.id} rowData={data} />;
            })}
        </DataTable>
    )
}

export default AwModemsDataTable;