import {
  faExclamationTriangle,
  faRedo,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  TableRow,
  Notification,
  Dialog } from "@hai/ui-react";
import axios from "axios";
import moment from "moment";
import React, { useState } from "react";

const SecretsRow = (props) => {
  const [secret, setSecret] = useState(props.secret);
  const [showRenewDialog, setShowRenewDialog] = useState(false);
  const [showDeleteSecret, setShowDeleteSecret] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState("");
  const [showSecretModal, setShowSecretModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const deleteSecret = async () => {
    setLoading(true);
    await axios
      .delete(
        `${process.env.REACT_APP_JELLYFISH_API_BASE_URL}/azure-resource/service-principals/${props.spId}/secrets/${secret.secretId}`
      )
      .then((res) => {
        setNotificationMsg("Secret deleted.");
        setShowDeleteSecret(false);
        setShowNotification(true);
        setLoading(false);
        props.getSecrets();
      })
      .catch((e) => setLoading(false));
  };

  const renewSecret = async () => {
    setLoading(true);
    await axios
      .put(
        `${process.env.REACT_APP_JELLYFISH_API_BASE_URL}/azure-resource/service-principals/${props.spId}/secrets/${secret.secretId}/renew`
      )
      .then((res) => {
        setNotificationMsg("Secret renewed.");
        setShowNotification(true);
        setSecret(res.data);
        setLoading(false);
        setShowRenewDialog(false);
        // props.getSecrets();
      })
      .catch((e) => setLoading(false));
    };

    const url = `https://portal.azure.com/#@haivision.com/asset/Microsoft_Azure_KeyVault/Secret/https://${secret.keyVaultId.substring(secret.keyVaultId?.lastIndexOf("/") + 1, secret.keyVaultId?.length)}.vault.azure.net/secrets/${secret.keyVaultSecretName}`;
    const encodedUrl = escape(encodeURIComponent(url));
    console.log({url: url, encoded: encodedUrl});

  return (
    <>
      <TableRow
checkbox={false}
        alternateRowColors={props.index % 2 === 0}
        style={{
          gridTemplateColumns: "0.2fr 2fr 1fr 1fr 1fr 1fr 1fr 0.2fr 0.2fr 1fr",
          color: "white",
        }}
      >
        <div></div>
        <div>{secret.secretId}</div>
        <div>{moment(secret.startDate).format("MM/DD/YYYY")}</div>
        <div>
          {moment(secret.expireDate).format("MM/DD/YYYY")}{" "}
          {"(in " +
            moment
              .duration(moment(secret.expireDate).diff(moment.now()))
              .asDays()
              .toFixed() +
            " days)"}
        </div>
        <div>
          {secret.renewDate
            ? moment(secret.renewDate).format("MM/DD/YYYY")
            : ""}
        </div>
        <div>{secret.secretValue}</div>
        <div>
          {secret.secretType === "SP" ? "Service Principal" : "Application"}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            color: "#5a98ef",
          }}
        >
          {moment
            .duration(moment(secret.expireDate).diff(moment.now()))
            .asDays() < 14 && (
            <>
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                color="yellow"
                title="Close to expire"
              ></FontAwesomeIcon>
              &nbsp;
            </>
          )}
          <FontAwesomeIcon
            icon={faRedo}
            size="sm"
            style={{ cursor: "pointer" }}
            onClick={() => setShowRenewDialog(true)}
            title="Renew"
          />
        </div>
        <div>
          <FontAwesomeIcon
            icon={faTrash}
            size="sm"
            style={{ cursor: "pointer" }}
            onClick={() => setShowDeleteSecret(true)}
            title="Delete"
          />
        </div>
      <div>{
          <Button>
            <a
              href={url}
              target="_blank"
              rel="noopener noreferrer"
            >
              Go to KV
            </a>
          </Button>
        }
        </div>
      </TableRow>

      <Dialog
        show={showRenewDialog}
        headline="Renew secret"
        content="Are you sure you want to renew this secret?"
        onHide={() => setShowRenewDialog(false)}
        buttons={[
          {
            disabled: loading,
            label: "Yes",
            primary: true,
            onClick: () => renewSecret(),
          },
          { label: "No", onClick: () => setShowRenewDialog(false) },
        ]}
      ></Dialog>
      <Modal
        show={showDeleteSecret}
        size="sm"
        centered
        onHide={() => {
          setShowDeleteSecret(false);
        }}
      >
        <ModalHeader>
          <ModalTitle>Confirmation</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <p>The following Secret will be removed:</p>
          <ul>
            <li>{secret.secretId}</li>
          </ul>
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={loading}
            variant="primary"
            onClick={() => deleteSecret()}
          >
            Confirm
          </Button>
          <Button
            onClick={() => {
              setShowDeleteSecret(false);
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Notification
        style={{ top: "1rem", right: "1rem" }}
        show={showNotification}
        description="Success"
        message={notificationMsg}
        autohide={true}
        variant="Success"
      />
    </>
  );
};

export default SecretsRow;
