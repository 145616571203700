import { Button, List, ListHeader, TableRow , ProgressBar } from "@hai/ui-react";
import React, { Component } from "react";
import RevealKeyvaultSecrets from "./RevealKeyvaultSecrets";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarPlus,
  faSyncAlt,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import SecretRevealButton from "../../common/secretRevealButton";

class ApiCredentialTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apiCredentials: this.props.apiCredentials,
      renewAction: this.props.renewAction,
      rotateAction: this.props.rotateAction,
      removeAction: this.props.removeAction,
    };
  }

  componentDidMount() {}

  render() {
    return (
      <List style={{ fontSize: "0.75rem" }}>
        <ListHeader
checkbox={false}
          style={{
            gridTemplateColumns: "0.3fr 2fr 2fr 1fr 0.8fr 0.4fr 0.4fr 0.4fr",
          }}
          alternateRowColors="true"
        >
          <div></div>
          <div>Client Id</div>
          <div>Client Secret</div>
          <div>Expire Date</div>
          <div>Role</div>
          <div>Renew</div>
          <div>Rekey</div>  
          <div>Remove</div>
        </ListHeader>
        {this.state.apiCredentials.map((cred, index) => {
          return (
            <TableRow
checkbox={false}
              key={cred.clientId}
              style={{
                gridTemplateColumns:
                  "0.3fr 2fr 2fr 1fr 0.8fr 0.4fr 0.4fr 0.4fr ",
                color: "white",
                fontSize: "0.75rem",
              }}
            >
              <div></div>
              <div> {cred.clientId} </div>
              <div>
                {" "}{cred.deletedDate != null ? "" : <SecretRevealButton jellyfishDbId={cred.jellyServicePrincipalId} />}{" "}
              </div>
              <div>{cred.deletedDate != null ? "Deleted" : moment(cred.validTo).format("yyyy-MM-DD")} </div>
              <div>{cred.hubSubscriptionRole}</div>
              <div>
                <FontAwesomeIcon
                  icon={faCalendarPlus}
                  style={{ cursor: "pointer" }}
                  onClick={() => this.state.renewAction(cred.id)}
                />
              </div>
              <div>
                <FontAwesomeIcon
                  icon={faSyncAlt}
                  style={{ cursor: "pointer" }}
                  onClick={() => this.state.rotateAction(cred.id)}
                />
              </div>
              <div>
                <FontAwesomeIcon
                  icon={faTrash}
                  style={{ cursor: "pointer" }}
                  onClick={() => this.state.removeAction(cred.id)}
                />
              </div>
            </TableRow>
          );
        })}
      </List>
    );
  }
}

export default ApiCredentialTable;
