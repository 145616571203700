import { authProvider } from "./authProvider";
import { AuthenticationState } from "react-aad-msal";

export const storeToken = async () => {
  var acc = authProvider.getAccount();
  sessionStorage.setItem("user.authState", AuthenticationState.Authenticated);
  sessionStorage.setItem("user.name", acc.name);
  sessionStorage.setItem("user.username", acc.userName);
  sessionStorage.setItem(
    "user.accessToken",
    (await authProvider.getAccessToken()).accessToken
  );
};
export const removeToken = () => {
  console.log("removing token");
  sessionStorage.removeItem("user.authState");
  sessionStorage.removeItem("user.name");
  sessionStorage.removeItem("user.username");
  sessionStorage.removeItem("user.accessToken");
};
export const getToken = () => {
  return sessionStorage.getItem("user.accessToken");
};

export const setJWT = () => {
  var acc = authProvider.getAccount();
  if (acc) {
    storeToken();
  } else {
    console.log("user not found");
    removeToken();
  }
};
