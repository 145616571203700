import axios from "../../infra/axios";

const BASE_URL = process.env.REACT_APP_WEBBING_API_BASE_URL;

export const getAccounts = (queryParams) => {
  return axios.get(`${BASE_URL}/Accounts${queryParams ?? ''}`);
};

export const getDevices = () => {
  return axios.get(`${BASE_URL}/Devices/ServicesDevices`);
};

export const getDevicesByAccountId = (props) => {
  return axios.get(
    `${BASE_URL}/Devices/ServicesDevices/AccountID/${props.accountID}`
  );
};

export const getDevicesByBranchId = (props) => {
  return axios.get(
    `${BASE_URL}/Devices/ServicesDevices/BranchID/${props.branchID}`
  );
};

export const getDevicesByIccid = (props) => {
  return axios.get(`${BASE_URL}/Devices/ServicesDevices/ICCID/${props.iccid}`);
};

export const getBranches = (queryParams) => {
  return axios.get(`${BASE_URL}/Accounts/Branches${queryParams ?? ''}`);
};

export const getProducts = () => {
  return axios.get(`${BASE_URL}/Services/Products`);
};

export const setServiceDeviceBranch = (props) => {
  return axios.patch(
    `${BASE_URL}/Devices/ServicesDevices/${props.iccid}/Branches/${props.branchID}`
  );
};

export const setServiceDeviceProduct = (props) => {
  return axios.patch(
    `${BASE_URL}/Devices/ServicesDevices/${props.iccid}/Products/${props.productID}`
  );
};

export const activateServicesDevice = (props) => {
  return axios.patch(
    `${BASE_URL}/Devices/ServicesDevices/${props.iccid}/Activate`
  );
};

export const suspendServicesDevice = (props) => {
  return axios.patch(
    `${BASE_URL}/Devices/ServicesDevices/${props.iccid}/Suspend`
  );
};

export const createBranch = (props) => {
  return axios.post(`${BASE_URL}/Accounts/Branches?name=${props.name}`);
};

export const createAccount = (form) => {
  axios.post(`${BASE_URL}/Accounts`, form)
}

export const createServiceDeviceAssignmentAccount = (props) => {
    return axios.post(
        `${BASE_URL}/Devices/ServicesDevices/${props.iccid}/AccountID/${props.accountID}`
    )
}

export const getServiceDeviceAssignment = (props) => {
    return axios.get(
        `${BASE_URL}/Devices/ServicesDevices/assignment/AccountID/${props.accountID}`
    )
}