import React, { Component } from "react";
import {
  Card,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  ModalTitle,
  FormLabel,
  FormControl,
  Form,
  Header,
  LabelValue,
 ProgressBar } from "@hai/ui-react";
import { Col, Row } from "reactstrap";
import FormGroup from "reactstrap/lib/FormGroup";
import SecretRevealButton from "../../common/secretRevealButton";
import PropTypes from 'prop-types';

class HubRouteHublet extends Component {
  constructor(props) {
    console.log('aaaaa', props);
    super(props);
    this.state = {
      hublet: this.props.hublet,
      modalShow: false,
      hubletResource: this.props.hubletResource,
      showSPSecret: false,
    };
    this.handleModalShow = this.handleModalShow.bind(this);
  }

  handleModalShow() {
    this.setState((prev) => {
      prev.modalShow = !prev.modalShow;
      return prev;
    });
  }

  render() {
    return (
      <div
        className={this.state.hublet.id}
        style={{ marginBottom: "1%", marginRight: "10%" }}
      >
        <Card
          name={"Hublet Name: " + this.state.hublet.name}
          title={this.state.hublet.definitionId}
          info={
            <Col>
              <Row>
                <small>Region: {this.state.hublet.region?.toUpperCase()}</small>
              </Row>
              <Row>
                <small>Status: {this.state.hublet.status}</small>
              </Row>
              <Row>
                <small>Version: {this.state.hublet.version}</small>
              </Row>
              <Row>
                <Button onClick={() => this.handleModalShow()}>
                  More details
                </Button>
              </Row>
            </Col>
          }
        />
        <Modal
          size="xl"
          show={this.state.modalShow}
          onHide={this.handleModalShow}
        >
          <ModalHeader>
            <ModalTitle>Hublet: {this.state.hublet.name}</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Header>Input</Header>
              {this.state.hubletResource?.map((p, index) => {
                return (
                  <Row key={index}>
                    <FormLabel>FQDN</FormLabel>
                    <FormControl
                      defaultValue={p.data?.inputConnectivityData?.[0].fqdn}
                    ></FormControl>
                    <FormLabel>IP Address</FormLabel>
                    <FormControl
                      defaultValue={p.data?.inputConnectivityData?.[0].ipAddress}
                    ></FormControl>
                    <FormLabel>Port</FormLabel>
                    <FormControl
                      defaultValue={p.data?.inputConnectivityData?.[0].port}
                    ></FormControl>
                    <FormLabel>URL</FormLabel>
                    <FormControl
                      defaultValue={p.data?.inputConnectivityData?.[0].url}
                    ></FormControl>
                    <FormLabel>SRT Latency</FormLabel>
                    <FormControl
                      defaultValue={
                        p.data?.inputConnectivityData?.[0].srtConfig?.srtLatency
                      }
                    />
                    <FormLabel>SRT Mode</FormLabel>
                    <FormControl
                      defaultValue={
                        p.data?.inputConnectivityData?.[0].srtConfig?.srtMode
                      }
                    />
                    <FormLabel>SRT Passphrase</FormLabel>
                    <LabelValue>
                      <SecretRevealButton
                        showSecret={this.state.showSPSecret}
                        secretValue={
                          p.data?.inputConnectivityData?.[0].srtConfig
                            ?.srtPassphrase
                        }
                      />
                    </LabelValue>
                  </Row>
                );
              })}
              <br />
              <Header>Output</Header>
              {this.state.hubletResource?.map((p, index) => {
                return (
                  <Row key={index}>
                    <FormLabel>FQDN</FormLabel>
                    <FormControl
                      defaultValue={p.data?.outputConnectivityData?.[0].fqdn}
                    ></FormControl>
                    <FormLabel>IP Address</FormLabel>
                    <FormControl
                      defaultValue={p.data?.outputConnectivityData?.[0].ipAddress}
                    ></FormControl>
                    <FormLabel>Port</FormLabel>
                    <FormControl
                      defaultValue={p.data?.outputConnectivityData?.[0].port}
                    ></FormControl>
                    <FormLabel>URL</FormLabel>
                    <FormControl
                      defaultValue={p.data?.outputConnectivityData?.[0].url}
                    ></FormControl>
                    <FormLabel>SRT Latency</FormLabel>
                    <FormControl
                      defaultValue={
                        p.data?.outputConnectivityData?.[0].srtConfig
                          ?.srtLatency
                      }
                    />
                    <FormLabel>SRT Mode</FormLabel>
                    <FormControl
                      defaultValue={
                        p.data?.outputConnectivityData?.[0].srtConfig?.srtMode
                      }
                    />
                    <FormLabel>SRT Passphrase</FormLabel>
                    <LabelValue>
                      <SecretRevealButton
                        showSecret={this.state.showSPSecret}
                        secretValue={
                          p.data?.outputConnectivityData?.[0].srtConfig
                            ?.srtPassphrase
                        }
                      />
                    </LabelValue>
                  </Row>
                );
              })}
              <br />
              <Header>Additional Parameters</Header>
              {this.state.hublet.parameters.map((p, index) => {
                if (p.name.toLowerCase().includes("passphrase")) {
                  return (
                    <Row key={index}>
                      <FormLabel>{p.name}</FormLabel>
                      <LabelValue>
                        <SecretRevealButton
                          showSecret={this.state.showSPSecret}
                          secretValue={p.value}
                        />
                      </LabelValue>
                    </Row>
                  );
                } else {
                  return (
                    <Row key={index}>
                      <FormLabel>{p.name}</FormLabel>
                      <FormControl defaultValue={p.value}></FormControl>
                    </Row>
                  );
                }
              })}
            </FormGroup>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

HubRouteHublet.propTypes = {
  hublet: PropTypes.object.isRequired,
  hubletResource: PropTypes.any.isRequired
}

export default HubRouteHublet;
