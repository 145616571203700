import React from 'react';
import PropTypes from 'prop-types';
  import {
    Switch,
    Notification,
    Dialog,
    Animation,
    OverlayTrigger,
    Icon,
   ProgressBar } from "@hai/ui-react";
  import {
    Card,
    CardFooter,
    CardHeader,
    CardBody,
  } from "reactstrap";

const FeatureFlagCard = props => {

    return (
        <Card
        body
        inverse
        style={{
          height: "100%",
          backgroundColor: "#333",
          borderColor: "#333"
        }}
      >
        <CardHeader>
          {props.cardTitle}{" "}
          <OverlayTrigger
            styleVariant="smallarrow"
            placement="right"
            popoverContent={props.changes}
          >
            <Icon
              
              iconname="Help"
              style={{ color: "white" }}
            />
          </OverlayTrigger>
        </CardHeader>
        <CardFooter>
          {props.loadingRequirement && (
            <ProgressBar
  leftLabel="Loading: "
  barColors={{
    background: '#599bff',
    right: '#599bff',
    left: '#2fccd3',
  }}
  hideRight={true}
  animate={1}
/>
          )}
          {!props.loadingRequirement && (
            <Switch
              className={"float-right"}
              onChange={(valueChangedTo) =>
                props.openFFDialog({
                  id: props.featureId,
                  flag: !valueChangedTo,
                })
              }
              checked={props.checked}
              disabled={props.disabled}
              key="rttPresetEnabled"
            />
          )}
        </CardFooter>
      </Card>
    )
}

FeatureFlagCard.propTypes = {
    checked: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    featureId: PropTypes.string.isRequired,
    cardTitle: PropTypes.string.isRequired,
    loadingRequirement: PropTypes.bool.isRequired,
    changes: PropTypes.element,
    openFFDialog: PropTypes.func.isRequired
}

export default FeatureFlagCard;