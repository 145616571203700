import React, { Component, Fragment, useEffect, useState } from "react";
import axios from "../../../infra/axios";

import {

    FormGroup,
    FormControl,
    FormLabel,
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    SelectOption,
    ModalTitle,
   ProgressBar, 
   Header} from "@hai/ui-react";

export default function HaivisionCommand360License () {

    let newlicense = {
        sku: '',
        serialNumber: '',
        productVersionNumber: '',
        macAddresses: [],
        licenseOptionDatas: [],
    };

    const [loading, setLoading] = useState(true);


    const [product, setProduct] = useState(null);
    const [existLicense, setExistLicense] = useState(null);
    const [showCommand360ConfirmModel, setShowCommand360ConfirmModel] = useState(false);
    const [licenseText, setLicenseText] = useState("");
    const [macAddressString, setMacAddressString] = useState("");
    const [serialNumber, SetSerialNumber] = useState('');
    const [macAddresses, SetMacAddresses] = useState([]);
    const [replaceMsg, SetReplaceMsg] = useState('');

    useEffect(() => {
        getCommand360ProductType();

    }, []);


const getCommand360ProductType = () => {

        setLoading(true);
        axios
        .get(
        process.env.REACT_APP_LIC_API_BASE_URL +
            "/Products/ProductType/Command360"
        )
        .then((response) => {
            if(response.data){
                const product = response.data.filter(x=>x.sku === "C360-Standard-NoDevice" || x.sku === "Command360")[0];
                setProduct(product);
                newlicense.sku = product.sku;
                newlicense.productVersionNumber = product.productType.productVersions[0].majorVersion + "." + product.productType.productVersions[0].minorVersion;
            }
            setLoading(false);
        });    
    };

    const setNewLicense = () =>{
        var optionTmp = [];
        product.productType.productVersions[0].productLicenseOptions.forEach((opt) =>{
            optionTmp.push({item : opt.licenseSectionItem, value: opt.selectedValue});
        });
        
        newlicense.sku = product.sku;
        newlicense.licenseOptionDatas = optionTmp;
        newlicense.macAddresses = macAddresses;
        newlicense.serialNumber = serialNumber;
        newlicense.productVersionNumber = product.productType.productVersions[0].majorVersion + "." + product.productType.productVersions[0].minorVersion;
    }

    async function handleSubmitModal(){
        var warningMessage = 'Please input the value of ';
        var warningOption = '';
        if(serialNumber === ''){
            warningOption += 'Serial Number,'
        }
        product.productType.productVersions[0].productLicenseOptions.forEach((opt) => {
            if(opt.selectedValue === undefined || opt.selectedValue === null || opt.selectedValue === ""){
                warningOption += `${opt.description} ,`;
            }
        });

        if(warningOption !== '')
        {
            warningMessage += warningOption.replace(/,+$/, '') + '!';
            alert(warningMessage);
            return;
        }

        setExistLicense(null);

        setNewLicense();

        var result =  await getCommand360License();
        var license = null;
        if(result=== undefined || result === null || result.length <= 0)
        {
          setExistLicense(null);
          handleNewLicense();
        }
        else{
          license = result[0];
          setExistLicense(license);
          SetReplaceMsg('');
          getExistLicenseInfo(license);
          setShowCommand360ConfirmModel(true);
        } 
    }

      const getCommand360License = async() => {
        var response = await axios
          .get(
            process.env.REACT_APP_LIC_API_BASE_URL +
              "/Licenses/serialNumber/" +
              newlicense.serialNumber
          );

          return response.data;
      }

      const handleConfirmDelete = () => {
        setShowCommand360ConfirmModel(false);
        axios
          .delete(
            process.env.REACT_APP_LIC_API_BASE_URL +
              "/Licenses/" +
              existLicense.id
          )
          .then((reponse) => {
            handleNewLicense();
          });
      }

      const getExistLicenseInfo = (license) => {
        if(license === null || license === undefined){
          return '';
        }
    
        var message = `License with serial number ${license.serialNumber} is existing,
        ${license.licenseOptionDatas.map((option) => {return `${option.item}:[${option.value}]`}).join(' ')}
        Do you want to replace it?`
    
        SetReplaceMsg(message);
      }

      const handleNewLicense = () => {
        setLoading(true);
        setLicenseText('');
        setNewLicense();
        axios
          .post(
            process.env.REACT_APP_LIC_API_BASE_URL + "/Licenses",
            newlicense
          )
          .then((reponse) => {
            handleDownloadLicense(reponse.data?.id);
          })
          .catch((ex) => {
            setLoading(false);
          });
      }

      const handleDownloadLicense = (id) => {
        setLoading(true);
        axios
          .get(
            process.env.REACT_APP_LIC_API_BASE_URL +
              "/LicenseText/Licenses/" +
              id
          )
          .then((response) => {
            setLicenseText(response.data);
            copyContent(response.data);
            setLoading(false);
          });
      }

       const copyContent = async(data) => {
        try {
            navigator.permissions.query({ name: "clipboard-write" }).then((result) => {
                if (result.state === "granted" || result.state === "prompt") {
                    console.log("Write access granted!");
                }
              });

          await navigator.clipboard.writeText(data);
        } catch (err) {
          alert('Failed to copy: ', err);
        }
      }

      const showUpdateCommand360LicenseModel = () => {
        return (
          <div>
            <Modal
              show={showCommand360ConfirmModel}
              onHide={() => setShowCommand360ConfirmModel(false)}
              size="lg"
            >
              <ModalHeader>
                <ModalTitle>Warning</ModalTitle>
              </ModalHeader>
              <ModalBody style={{ textAlign: "center" }}>
                {replaceMsg}
              </ModalBody>
              <ModalFooter>
                <Button
                  variant="primary"
                  onClick={() => handleConfirmDelete()}
                >
                  Confirm
                </Button>
                <Button variant="secondary" onClick={() => setShowCommand360ConfirmModel(!showCommand360ConfirmModel)}>
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>
          </div>
        );   
      }


    return (
    <Fragment>
    <br />
    <Header title="Haivision Command360 License Request" />
    <hr />
    { loading && <ProgressBar
        leftLabel="Loading: "
        barColors={{
            background: '#599bff',
            right: '#599bff',
            left: '#2fccd3',
        }}
        hideRight={true}
        animate={1}
    />}
    {!loading && 
        <div style={{  width: '50%', padding: '50px', margin: 'auto'}}>
          <FormGroup>
            <FormLabel>Serial Number</FormLabel>
            <FormControl
              defaultValue={serialNumber}
              onChange={(e) => {
                var text = e.target.value.trim();
                SetSerialNumber(text);
              }}
            ></FormControl>
        </FormGroup>
        <FormGroup>
          <FormLabel>Mac Address</FormLabel>
          <FormControl
            as="textarea"
            defaultValue={macAddressString}
            onChange={(e) => {
              var text = e.target.value.trim();
              var obj = [];
              text.split(',').forEach((item)=>{
                obj.push({value : item});
              });
              //setNewLicense({...newlicense, macAddresses: obj});
              SetMacAddresses(obj);
              setMacAddressString(text);
            }}
          ></FormControl>
        </FormGroup>
        {
            product.productType.productVersions[0].productLicenseOptions &&
            product.productType.productVersions[0].productLicenseOptions.map((opt) => {
            return (
                <div key={opt.id}>
                <FormGroup>
                    <FormLabel>
                    {opt.description}
                    </FormLabel>
                    {opt.licenseSectionItem ===
                    "accesskey" && (
                    <div>
                        <FormControl
                        as="textarea"
                        defaultValue={opt?.selectedValue}
                        onChange={(e) => {
                            var loIndex = product.productType.productVersions[0].productLicenseOptions.findIndex((x) => x.id === opt.id);
                            if (loIndex == null || loIndex === -1 || e.target == null) {
                              return;
                            }
                            product.productType.productVersions[0].productLicenseOptions[loIndex].selectedValue = e.target.value.trim();
                        }}
                        ></FormControl>
                    </div>
                    )}
                    {opt.licenseSectionItem !==
                    "accesskey" && 
                        <div>
                            <FormControl
                            as="select"
                            defaultValue={opt?.selectedValue}
                            onChange={(e) => {
                                var loIndex = product.productType.productVersions[0].productLicenseOptions.findIndex((x) => x.id === opt.id);
                                if (loIndex == null || loIndex === -1 || e.target == null) {
                                  return;
                                }
                                product.productType.productVersions[0].productLicenseOptions[loIndex].selectedValue = e.target.value.trim();
                            }}
                            >
                            {opt.availableValues
                                .split(";")
                                .map((o) => {
                                var itemValue = o.split("!")[0];
                                return <SelectOption key={itemValue} value={itemValue}>{itemValue}</SelectOption>;
                                })
                            }
                        </FormControl>
                        </div>
                    }
                </FormGroup>
        
                </div>
            );
            })
        } 
         <FormGroup>
          <FormLabel>License Text (Copy to clipboard automatically)</FormLabel>
          <FormControl
            as="textarea"
            defaultValue={licenseText}
            disabled = {true}
          ></FormControl>
        </FormGroup>

        <FormGroup>
            <Button
                variant="primary"
                onClick={() => handleSubmitModal()}
                disabled={loading}
              >
                Create
            </Button>
       </FormGroup>           
        </div>
    }
    {showCommand360ConfirmModel && showUpdateCommand360LicenseModel()}
    </Fragment>);
    
}


