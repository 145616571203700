import {
  faCheckCircle,
  faCog,
  faMinusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { List, ListHeader, Spinner, TableRow , ProgressBar } from "@hai/ui-react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const HubUserSubscriptions = (props) => {
  const [user, setUser] = useState(null);
  const [loadingSubs, setLoadingSubs] = useState(false);

  useEffect(() => {
    getUserById();
  }, []);

  const getUserById = async () => {
    setLoadingSubs(true);
    await axios
      .get(
        process.env.REACT_APP_HUB_API_BASE_URL +
          "/Support/hub/users/" +
          props.userId
      )
      .then((response) => {
        setUser(response.data);
        setLoadingSubs(false);
      })
      .catch((error) => setLoadingSubs(false));
  };

  let navigate = useNavigate();
  const goToSubscriptionPage = (orgId, subId) => {
    navigate(`/hub/organizations/${orgId}/subscriptions/${subId}`);
  };

  return (
    <div
      style={{
        padding: "1%",
        border: "1px #007bff groove",
        borderRadius: "5px",
        marginTop: "5px",
      }}
    >
      <p
        style={{
          color: "#007bff",
          marginBottom: "1%",
          fontFamily: "'HCo Gotham SSm Bold', Helvetica, Arial",
          fontSize: "0.9rem",
        }}
      >
        Subscriptions
      </p>
      <List>
        <ListHeader
checkbox={false}
          style={{ gridTemplateColumns: "0.2fr 2fr 2fr 1fr 0.3fr 0.3fr" }}
          alternateRowColors="true"
        >
          <div>SRTHub Sub Id</div>
          <div>Azure Sub Id (Sub Name)</div>
          <div>"Status</div>
          <div></div>
        </ListHeader>
        {loadingSubs && <Spinner backgroundColor="red"></Spinner>}
        {user &&
          user.subscriptions?.map((sub, index) => {
            return (
              <TableRow
checkbox={false}
                
                key={index}
                alternateRowColors={index % 2 == 0}
                style={{
                  gridTemplateColumns: "0.2fr 2fr 2fr 1fr 0.3fr 0.3fr",
                }}
              >
                <div></div>
                <div> {sub.subscriptionId} </div>
                <div> {sub.subscriptionName} </div>
                <div>
                  {sub.isDisabled != null ? (
                    <FontAwesomeIcon
                      icon={sub.isDisabled ? faMinusCircle : faCheckCircle}
                      color={sub.isDisabled ? "red" : "green"}
                    />
                  ) : (
                    "Not found"
                  )}
                </div>
                <div>
                  <FontAwesomeIcon
                    icon={faCog}
                    color={"#007bff"}
                    style={{ cursor: "pointer" }}
                    title="Subscription page"
                    onClick={() =>
                      goToSubscriptionPage(
                        sub.organizationId,
                        sub.subscriptionId
                      )
                    }
                  />
                </div>
              </TableRow>
            );
          })}
      </List>
    </div>
  );
};

export default HubUserSubscriptions;
