import React, { Component } from "react";
import axios from "../../../infra/axios";
import {
  OverlayTrigger,
  List,
  ListHeader,
  TableRow,
  SelectOption,
  Icon,
  FormLabel,
  FormControl,
  Button,
  ProgressBar,
  Header,
  Pagination
} from "@hai/ui-react";
import { Row, Col, ModalBody } from "reactstrap";
import HaivisionProductAdd from "./haivision-product-add";
import HaivisionLicenseDelete from "./Haivision-product-delete";
import HaivisionSetProductDefaultValueByProductType from "./haivision-set-product-default-value-by-productType-and-version";
import { useParams } from "react-router-dom";
import cogoToast from "cogo-toast";

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}


class HaivisionProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      products: null,
      filteredProducts: [],
      productTypes: null,
      showProductAdd: false,
      showDelete: false,
      showSetProductDefaultValyeByProductType: false,
      deleteProduct: null,
      filterParams: [],
      pageData: {
        currentPage: this.params?.pageNum ? parseInt(this.params?.pageNum) : 1,
        pageSize: 12
      }
    };
    this.GetProducts = this.GetProducts.bind(this);
    this.handleAddProduct = this.handleAddProduct.bind(this);
    this.handleDisable = this.handleDisable.bind(this);
    this.handleSetProductDefaultValueByType =
      this.handleSetProductDefaultValueByType.bind(this);
  }

  componentDidMount() {
    this.GetProducts(1, 12);
  }

  GetProducts(page = 1, pageSize = 12) {
    const requestProducts = this.getPaginatedProducts(page, pageSize)
    const requestProductTypes = axios.get(
      process.env.REACT_APP_LIC_API_BASE_URL + "/Products/ProductTypes"
    );
    axios.all([requestProducts, requestProductTypes]).then(
      axios.spread((...responses) => {
        this.setState((prev) => {
          prev.filteredProducts = responses[0].data;
          prev.productTypes = responses[1].data;
          prev.productTypes.unshift({ id: "All", name: "All" });
          prev.loading = false;

          return prev;
        });
      })
    );
  }

  getPaginatedProducts(page, pageSize) {
    this.setState({ loading: true })
    return axios.get(
      process.env.REACT_APP_LIC_API_BASE_URL + `/Products/all?PageNumber=${page}&PageSize=${pageSize}&ColumnFilters=${JSON.stringify(this.state.filterParams)}`
    );
  }

  handleDisable(product) {
    this.setState((prev) => {
      prev.showDelete = !prev.showDelete;
      prev.deleteProduct = prev.deleteProduct == null ? product : null;
      return prev;
    });
  }

  handleDetails(id) {
    window.location = window.location + "/" + id;
  }

  handleAddProduct() {
    this.setState((prev) => {
      prev.showProductAdd = !prev.showProductAdd;
      return prev;
    });
  }

  handleSetProductDefaultValueByType() {
    this.setState((prev) => {
      prev.showSetProductDefaultValyeByProductType =
        !prev.showSetProductDefaultValyeByProductType;
      return prev;
    });
  }

  render() {
    return (
      <div>
        <div>
          <br />
          <Header title="Haivision Products" />
          <hr />
          <Row style={{ alignItems: "flex-end" }}>
            <Col>
              <FormLabel>Filter by Sku</FormLabel>
              <FormControl
                placeholder="Filter by Sku"
                onChange={(e) => {
                  let tempSet = this.state.filterParams.filter(f => f.id !== "sku");
                  if (e.target.value !== "") {
                    tempSet.push({
                      id: 'sku',
                      Value: e.target.value,
                      Comparator: 'Contains'
                    });
                    this.setState({ filterParams: tempSet });
                  } else {
                    this.setState({ filterParams: tempSet });
                  }
                }
                }
              />
            </Col>
            <Col>
              <FormLabel>Filter by Product Type</FormLabel>
              <FormControl
                as="select"
                placeholder="Filter by type"
                onChange={(e) => {
                  let tempSet = this.state.filterParams.filter(f => f.id !== "productType.name");;
                  if (e.target.value !== "All") {
                    tempSet.push({
                      id: 'productType.name',
                      Value: e.target.value,
                      Comparator: 'Equal'
                    });
                    this.setState({ filterParams: tempSet });
                  } else {
                    this.setState({ filterParams: tempSet });
                  }
                }
                }
              >
                {this.state.productTypes &&
                  this.state.productTypes.map((t) => {
                    return <SelectOption key={t.id}>{t.name}</SelectOption>;
                  })}
              </FormControl>
            </Col>

            <Col>
              <Button onClick={() => this.getPaginatedProducts(1, 12).then(a => {
                this.setState({
                  filteredProducts: a.data,
                  loading: false
                })
              })}>Search</Button>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col sm="12" md="2">
              <Button variant="primary" onClick={() => this.handleAddProduct()}
                style={{ width: "100%" }}>
                Add Product
              </Button>
            </Col>
            <Col sm="12" md="2">
              <Button
                variant="primary"
                onClick={() => this.handleSetProductDefaultValueByType()}
                style={{ width: "100%" }}
              >
                Set Product Default Value By Product Type
              </Button>
            </Col>
          </Row>
          <hr />
          <List>
            <ListHeader
              checkbox={false}
              alternateRowColors="true"
              style={{
                gridTemplateColumns: "0.1fr 1fr 1fr 5fr 1fr",
              }}
            >
              <div></div>
              <div>SKU</div>
              <div>Product Type</div>
              <div>Description</div>
              <div>Actions</div>
            </ListHeader>
            {this.state.loading && <div>
              <ProgressBar
                leftLabel="Loading: "
                barColors={{
                  background: '#599bff',
                  right: '#599bff',
                  left: '#2fccd3',
                }}
                hideRight={true}
                animate={1}
              /></div>
            }

            {this.state.filteredProducts.data &&
              this.state.filteredProducts?.data?.map((p) => {
                return (
                  <div key={p.id}>
                    <TableRow
                      checkbox={false}
                      style={{
                        gridTemplateColumns: "0.1fr 1fr 1fr 5fr 1fr",
                      }}
                    >
                      <div></div>
                      <div>{p.sku}</div>
                      <div>{p.productType?.name}</div>
                      {
                        <OverlayTrigger
                          popoverContent={p.description}
                          extendedCallout={true}
                          triggerByHover={true}
                          placement={"top"}
                          arrowdirection={"left"}
                          tooltiptopspacing="1.25rem"
                          tooltipleftspacing=""
                          calloutleftspacing="0rem"
                          style={{ flex: 1 }}
                        >
                          <div>
                            {p.description.length > 200
                              ? p.description.substring(0, 200) + "..."
                              : p.description}
                          </div>
                        </OverlayTrigger>
                      }
                      <div style={{ display: "inline-flex" }}>
                        <OverlayTrigger
                          popoverContent={"View Details"}
                          style={{ cursor: "pointer", paddingRight: "10px" }}
                          onClick={() => this.handleDetails(p.id)}
                        >
                          <Icon

                            iconname="AccessObserver"
                            style={{ fill: "#007bff" }}
                          />
                        </OverlayTrigger>
                        <OverlayTrigger
                          popoverContent={"Disable"}
                          onClick={() => this.handleDisable(p)}
                          style={{ cursor: "pointer", paddingRight: "10px" }}
                        >
                          <Icon

                            iconname="StatusStopped"
                            style={{ fill: "#dc3545" }}
                          />
                        </OverlayTrigger>
                      </div>
                    </TableRow>
                  </div>
                );
              })}
          </List>

          <Pagination
            pageCount={this.state.filteredProducts?.totalPages}
            currentPage={this.state.pageData.currentPage}
            onPageChange={(newPage, previousPage) => {
              if (this.state.filteredProducts.hasNextPage || newPage <= this.state.filteredProducts?.totalPages) {
                this.setState({ ...this.state, pageData: { currentPage: newPage, pageSize: this.state.pageData.pageSize }, loading: true });
                this.getPaginatedProducts(newPage, this.state.pageData.pageSize).then(a => {
                  this.setState({
                    filteredProducts: a.data,
                    loading: false
                  })
                });
              } else {
                cogoToast.warn("No next page", { position: 'top-right' });
              }

            }}
          />

          {this.state.productTypes && (<>
            <HaivisionProductAdd
              show={this.state.showProductAdd}
              productTypes={this.state.productTypes}
              handleAddProduct={this.handleAddProduct}
              getProducts={this.GetProducts}
            />
            <HaivisionLicenseDelete
              show={this.state.showDelete}
              product={this.state.deleteProduct}
              onHide={this.handleDisable}
              refresh={this.GetProducts}
            />

            <HaivisionSetProductDefaultValueByProductType
              show={this.state.showSetProductDefaultValyeByProductType}
              handleAddProduct={this.handleSetProductDefaultValueByType}
              getProducts={this.GetProducts}
              productTypeList={this.state.productTypes.slice(1)}
              productList={this.state.products}
            />
          </>)}
        </div>
      </div>
    )

  }
}
export default withParams(HaivisionProduct);
