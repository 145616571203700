import { Button, Form, FormControl, FormGroup, FormLabel } from "@hai/ui-react";
import React, { useState } from "react";
import axios from "../../../infra/axios";
import SelectUUIDBox from "./SelectUUIDBox";
import cogoToast from "cogo-toast";
import { Col, Row } from "reactstrap";

const CreateAirProduct = props => {

    const [isNewProduct, setIsNewProduct] = useState(true)
    const [form, setForm] = useState({
        serialNumber: null,
        uuid: null,
        macAddress: null,
        productName: null,
        maupitiVersion: null,
        version: null,
        idTahiti: null,
        idCuracao: null,
        idBull: null,
        idPalma: null,
        idOleron: null,
        idMercury: null
    });

    const createAirProduct = () => {
        if (form.uuid && form.serialNumber) {
            axios.post(`${process.env.REACT_APP_PRODUCTION_API_BASE_URL}/Legacy/AwProducts/Uuid/${form.uuid}/AirSeries`, form)
                .then(a => {
                    setForm({
                        serialNumber: null,
                        uuid: null,
                        macAddress: null,
                        productName: null,
                        maupitiVersion: null,
                        version: null,
                        idTahiti: null,
                        idCuracao: null,
                        idBull: null,
                        idPalma: null,
                        idOleron: null,
                        idMercury: null
                    });
                    cogoToast.success('Product created', { position: 'top-right' });
                })
                .catch(e => cogoToast.error("Failed to create Air Product.", { position: 'top-right' }));
        } else {
            cogoToast.error('Select an UUID and Serial Number', { position: 'top-right' });
        }
    }

    const getProductBySerial = () => {
        if (form.serialNumber) {
            axios.get(`${process.env.REACT_APP_PRODUCTION_API_BASE_URL}/Legacy/AwProducts/SerialNumber/${form.serialNumber}`)
                .then(a => setIsNewProduct(false))
                .catch(e => setIsNewProduct(true));
        }
    }

    const onChangeHandler = evt => {
        const { name, value } = evt.target
        setForm({
            ...form,
            [name]: value,
        })
    }

    return (<>
        <Form>
            <Row>
                <Col>
                    <SelectUUIDBox label={'UUID'} onChange={a => {
                        if (a && a[0]) {
                            setForm({ ...form, uuid: a[0].uuid, serialNumber: a[0].serial });
                        }
                    }}
                        onInputChange={i => {
                            setForm({ ...form, uuid: i });
                        }}
                        onBlur={getProductBySerial}
                    ></SelectUUIDBox>
                </Col>
                {isNewProduct && <Col md='3'>
                    <FormGroup>
                        <FormLabel required>Serial Number</FormLabel>
                        <FormControl placeholder="AW..." name="serialNumber" onChange={onChangeHandler} defaultValue={form.serialNumber} />
                    </FormGroup>
                </Col>}
                <Col>
                    <FormGroup>
                        <FormLabel>MAC Address</FormLabel>
                        <FormControl placeholder="AVW..." name="macAddress" onChange={onChangeHandler} defaultValue={form.macAddress} />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <FormLabel>Product name</FormLabel>
                        <FormControl placeholder="Product name" name="productName" onChange={onChangeHandler} defaultValue={form.productName} />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <FormLabel>Maupiti Version</FormLabel>
                        <FormControl placeholder="1.9" name="maupitiVersion" onChange={onChangeHandler} defaultValue={form.maupitiVersion} />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <FormLabel>Version</FormLabel>
                        <FormControl placeholder="1.0.0_BX" name="version" onChange={onChangeHandler} defaultValue={form.version} />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <FormLabel>Tahiti Id</FormLabel>
                        <FormControl placeholder="1" name="idTahiti" onChange={onChangeHandler} defaultValue={form.idTahiti} />
                    </FormGroup>
                </Col>
            </Row>

            <Row>
                <Col>
                    <FormGroup>
                        <FormLabel>Curacao Id</FormLabel>
                        <FormControl placeholder="1" name="idCuracao" onChange={onChangeHandler} defaultValue={form.idCuracao} />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <FormLabel>Bull Id</FormLabel>
                        <FormControl placeholder="1" name="idBull" onChange={onChangeHandler} defaultValue={form.idBull} />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <FormLabel>Palma Id</FormLabel>
                        <FormControl placeholder="1" name="idPalma" onChange={onChangeHandler} defaultValue={form.idPalma} />
                    </FormGroup>
                </Col>
            </Row>


            <Row>
                <Col>
                    <FormGroup>
                        <FormLabel>Oleron Id</FormLabel>
                        <FormControl placeholder="1" name="idOleron" onChange={onChangeHandler} defaultValue={form.idOleron} />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <FormLabel>Mercury Id</FormLabel>
                        <FormControl placeholder="1" name="idMercury" onChange={onChangeHandler} defaultValue={form.idMercury} />
                    </FormGroup>
                </Col>
            </Row>

            <Button onClick={() => createAirProduct()}>Create</Button>

        </Form>
    </>)
}

export default CreateAirProduct;