import React from 'react';
import {Button, Dialog} from '@hai/ui-react';

    const ClearModal = (props) => {
      return (
        <>
          <Dialog
            title={'Do you want to clear the last result?'}
            show={props.show}
            buttons={[{ label: 'Yes', primary: true, onClick: () => props.clearResult() },
                    {label: 'No', onClick:() => props.close()}]}
          />
        </>
      )
    }
export default ClearModal;