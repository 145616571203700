const { default: axios } = require("../../../infra/axios");

const enableBySub = (id, subId, callback) => {
  return axios
    .put(
      process.env.REACT_APP_HUB_API_BASE_URL +
        `/Support/features/${id}/subscriptions/${subId}/activate`
    )
    .then(callback);
};

const disableBySub = (id, subId, callback) => {
  return axios
    .put(
      process.env.REACT_APP_HUB_API_BASE_URL +
        `/Support/features/${id}/subscriptions/${subId}/deactivate`
    )
    .then(callback);
};

const enableGlobally = (id, callback) => {
  axios
    .put(
      process.env.REACT_APP_HUB_API_BASE_URL +
        `/Support/features/${id}/activate`
    )
    .then(callback);
};

const disableGlobally = (id, callback) => {
  axios
    .put(
      process.env.REACT_APP_HUB_API_BASE_URL +
        `/Support/features/${id}/deactivate`
    )
    .then(callback);
};

export const enableBySubHttp = enableBySub,
  disableBySubHttp = disableBySub,
  enableGloballyHttp = enableGlobally,
  disableGloballyHttp = disableGlobally;
