import React, { useEffect, useState } from "react";
import axios from "../../../infra/axios";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalTitle,
  ProgressBar,
} from "@hai/ui-react";
import { ModalFooter } from "react-bootstrap";
import { BlobServiceClient } from "@azure/storage-blob";

const ProductVersionImageFileUpload = (props) => {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [productVersionImageId, SetProductVersionImageId] = useState();

  const [productTypeName, setProductTypeName] = useState();
  const [productVersion, setProductVersion] = useState();

  const [progressPercentage, setProgressPercentage] = useState(0);

  const onFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  useEffect(() => {
    SetProductVersionImageId(props.productVersionImageId);
    setProductTypeName(props.productTypeName);
    setProductVersion(props.productVersion);
  }, [
    props.productVersionImageId,
    props.productTypeName,
    props.productVersion,
  ]);

  const onFileUpload = async () => {
    if (file && file?.name) {
      setUploading(true);
      
      var blobUri = await fileUploadToAzureBlob(file);
      await completeFileUploadInfo(blobUri);
      await handleHideModal();
    }
  };

  const handleHideModal = async () => {
    setFile(null);
    setUploading(false);
    props.getVersion();
    props.onHide();
  };

  const completeFileUploadInfo = async (blobUri) => {
    await axios.post(
      process.env.REACT_APP_METADATA_API_BASE_URL +
        `/ReleaseMetadata/ProductVersionImage/${productVersionImageId}/UploadInfo?blobUri=${blobUri}`
    );
  };

  const fileUploadToAzureBlob = async (file) => {
    var blobUri = productTypeName + "/" + productVersion + "/" + file.name;

    var resp = await axios.get(
      process.env.REACT_APP_METADATA_API_BASE_URL +
        `/ReleaseMetadata/GenerateUploadLink?blobUri=${blobUri}`
    );

    var blobUploadSasUrl = resp.data;

    var blobServiceClient = new BlobServiceClient(blobUploadSasUrl);

    var containerName = "haivision-releases";
    var containerClient = blobServiceClient.getContainerClient(containerName);

    var blobClient = containerClient.getBlockBlobClient(blobUri);

    const ONE_MB = 1_048_576;
    const options = {
      blobHTTPHeaders: {
        blobContentDisposition:
          'attachment; filename="' +
          file.name +
          "\"; filename*=UTF-8''\"" +
          file.name +
          '"',
        blobCacheControl: "max-age=31536000",
      },
      onProgress: (ev) => {
        setProgressPercentage((ev.loadedBytes / file.size) * 100);
      },
      maxSingleShotSize: 4 * ONE_MB,
    };

    // upload file
    await blobClient.uploadData(file, options);
    return blobClient.name;
  };

  return (
    <Modal show={props.show} size="lg">
      <ModalHeader>
        <ModalTitle>Firmware Upload</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <input type="file" onChange={onFileChange} />
        <br />
        <span>Please do not close this window during uploads.</span>
        <hr />
        {uploading && (
          <ProgressBar
            leftLabel="Upload Progress: "
            value={progressPercentage}
            animate={2}
          />
        )}
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => onFileUpload()}>Upload</Button>
        <Button onClick={() => handleHideModal(true)}>Close</Button>
      </ModalFooter>
    </Modal>
  );
};

export default ProductVersionImageFileUpload;
