import { List, ListHeader, TableRow , ProgressBar } from "@hai/ui-react";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBan,
  faCaretSquareRight,
  faCheckCircle,
  faChevronCircleRight,
  faMinusCircle,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";

class HubRouteTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      routes: this.props.routes,
      subscriptionId: this.props.subscriptionId,
    };
  }

  routeDetailPage(subscriptionId, routeId) {
    window.location =
      "/hub/subscriptions/" + subscriptionId + "/routes/" + routeId;
  }

  render() {
    return (
      <div>
        <List>
          <ListHeader
checkbox={false}
            style={{
              gridTemplateColumns: "0.1fr 3fr 2fr 2fr 2fr 0.5fr",
            }}
          >
            <div></div>
            <div>Name</div>
            <div>Status</div>
            <div>Duration</div>
            <div></div>
          </ListHeader>
          {this.state.routes.map((route, index) => {
            return (
              <TableRow
checkbox={false}
                key={index}
                style={{
                  gridTemplateColumns: "0.1fr 3fr 2fr 2fr 2fr 0.5fr",
                  color: "white",
                  borderRadius: 5,
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "#007bff",
                  marginBottom: "0.3%",
                  cursor: "pointer",
                }}
                onClick={() =>
                  this.routeDetailPage(this.state.subscriptionId, route.id)
                }
              >
                <div></div>
                <div>{route.name}</div>
                <div>
                  <div style={{ display: "inline", paddingTop: "1rem" }}>
                    {(route.status == "Ended" || route.status == "Idle") && (
                      <FontAwesomeIcon
                        icon={faMinusCircle}
                        className="spinner"
                        style={{ marginRight: "5px", color: "grey" }}
                      />
                    )}
                    {(route.status == "Stopping" ||
                      route.status == "Starting") && (
                      <FontAwesomeIcon
                        icon={faSpinner}
                        spin={true}
                        style={{ marginRight: "5px", color: "yellow" }}
                      />
                    )}
                    {route.status == "StoppingIncomplete" && (
                      <FontAwesomeIcon
                        icon={faBan}
                        style={{ marginRight: "5px", color: "red" }}
                      />
                    )}
                    {route.status == "Running" && (
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        style={{ marginRight: "5px", color: "green" }}
                      />
                    )}
                    <span>{route.status}</span>
                  </div>
                </div>
                <div>
                  {new Date(route.streamingRunningTime)
                    .toISOString()
                    .substr(11, 8)}
                </div>
                <div></div>
                <div>
                  <FontAwesomeIcon
                    icon={faChevronCircleRight}
                    style={{ color: "#007bff" }}
                  />
                </div>
              </TableRow>
            );
          })}
        </List>
      </div>
    );
  }
}

HubRouteTable.propTypes = {
  routes: PropTypes.array,
  subscriptionId: PropTypes.string,
};

export default HubRouteTable;
