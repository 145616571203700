import {
  faChevronCircleDown,
  faChevronCircleRight,
  faExclamationTriangle,
  faPlusCircle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TableRow, Animation, Dialog , Spinner } from "@hai/ui-react";
import axios from "axios";
import cogoToast from "cogo-toast";
import moment from "moment";
import React, { useState } from "react";
import Collapse from "reactstrap/lib/Collapse";
import SecretModal from "./SecretModal";
import SecretsTable from "./SecretsTable";

const ServicePrincipalRow = (props) => {
  const [showDeleteSP, setShowDeleteSP] = useState(false);
  const [sp, setSp] = useState(props.sp);
  const [collapseSecrets, setCollapseSecrets] = useState(true);
  const [showCreateNewSecret, setShowCreateNewSecret] = useState(false);
  const [createdSecret, setCreatedSecret] = useState(null);
  const [showSecretModal, setShowSecretModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingSecrets, setLoadingSecrets] = useState(false);

  const deleteSP = async () => {
    setLoading(true);
    await axios
      .delete(
        process.env.REACT_APP_JELLYFISH_API_BASE_URL + `/azure-resource/service-principals/${sp.applicationId}`
      )
      .then((response) => {
        cogoToast.success("Service principal deleted", {
          position: "top-right",
        });
        setShowDeleteSP(false);
        setLoading(false);
        props.loadSps();
      })
      .catch((e) => setLoading(false));
  };

  const createNewSecret = async () => {
    setLoading(true);
    await axios
      .post(
        process.env.REACT_APP_JELLYFISH_API_BASE_URL +
          `/azure-resource/service-principals/${sp.applicationId}/secrets`
      )
      .then((response) => {
        cogoToast.success("Secret created", { position: "top-right" });
        setShowCreateNewSecret(false);
        setCreatedSecret(response.data);
        setShowSecretModal(true);
        setLoading(false);
        getSecrets();
      })
      .catch((e) => setLoading(false));
  };

  const getSecrets = async () => {
    setLoadingSecrets(true);
    await axios
      .get(
        process.env.REACT_APP_JELLYFISH_API_BASE_URL +
          `/azure-resource/service-principals/${sp.applicationId}/secrets`
      )
      .then((res) => {
        var tmp = sp;
        tmp.secrets = res.data;
        setSp(tmp);
        setLoadingSecrets(false);
      })
      .catch((e) => setLoadingSecrets(false));
  };

  return (
    <>
      <TableRow
checkbox={false}
        key={sp.applicationId}
        style={{
          gridTemplateColumns: "0.2fr 3fr 3fr 3fr 2fr 1fr 0.2fr 0.2fr",
          color: "white",
        }}
      >
        <div></div>
        <div>{sp.name}</div>
        <div>{sp.applicationId}</div>
        <div>{sp.tenantId}</div>
        <div>{moment(sp.createdDate ?? sp._last_modified_date).format("MM/DD/YYYY HH:mm")}</div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            color: "#5a98ef",
          }}
          onClick={() => setCollapseSecrets(!collapseSecrets)}
        >
          {sp.secrets ? sp.secrets.length : 0}&nbsp;
          <FontAwesomeIcon
            icon={collapseSecrets ? faChevronCircleRight : faChevronCircleDown}
          ></FontAwesomeIcon>
          &nbsp;
          {sp.secrets?.some(
            (s) =>
              moment
                .duration(moment(s.expireDate).diff(moment.now()))
                .asDays() < 14
          ) && (
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              color="yellow"
              title="Close to expire"
            ></FontAwesomeIcon>
          )}
        </div>

        {/* NEW SECRET FOR THIS SP */}
        <div>
          <FontAwesomeIcon
            icon={faPlusCircle}
            style={{ cursor: "pointer", color: "#5a98ef" }}
            onClick={() => setShowCreateNewSecret(true)}
            title="Add secret"
          />
        </div>

        {/* DELETE SP */}
        <div>
          <FontAwesomeIcon
            icon={faTrash}
            style={{ cursor: "pointer" }}
            onClick={() => setShowDeleteSP(true)}
            title="Delete"
          />
        </div>
      </TableRow>

      <Collapse isOpen={!collapseSecrets}>
        {!collapseSecrets && (
          <>
            {loadingSecrets && <Spinner />}
            {!loadingSecrets && (
              <SecretsTable
                spId={sp.applicationId}
                secrets={sp.secrets}
                getSecrets={getSecrets}
              ></SecretsTable>
            )}
          </>
        )}
      </Collapse>

      <Dialog
        headline="Delete secret"
        content={
          <>
            <p>Are you sure you want to delete this Application?</p>
            <p>The following app and service principal will be removed:</p>
            <ul>
              <li>
                {sp.name} ({sp.applicationId})
              </li>
            </ul>
            {loading && <Spinner />}
          </>
        }
        show={showDeleteSP}
        buttons={[
          {
            disabled: loading,
            label: "Yes",
            primary: true,
            onClick: () => deleteSP(),
          },
          { label: "No", onClick: () => setShowDeleteSP(false) },
        ]}
      ></Dialog>

      <Dialog
        headline="New secret"
        content={
          <>
            <p>{`Are you sure you want to create a new secret for ${sp.name}?`}</p>
            {loading && <Spinner />}
          </>
        }
        show={showCreateNewSecret}
        buttons={[
          {
            disabled: loading,
            label: "Yes",
            primary: true,
            onClick: () => createNewSecret(),
          },
          { label: "No", onClick: () => setShowCreateNewSecret(false) },
        ]}
      ></Dialog>

      {showSecretModal && (
        <SecretModal
          sp={sp}
          show={showSecretModal}
          secret={createdSecret}
          onClose={() => {
            setCreatedSecret(null);
            setShowSecretModal(false);
          }}
        ></SecretModal>
      )}
    </>
  );
};

export default ServicePrincipalRow;
