import React, {useState, useEffect} from 'react'
import { Header, 
    FormControl,
    FormGroup,
    FormLabel,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalTitle,
    Button,
    Notification,
    ProgressBar} from "@hai/ui-react";

import { Col, Row } from "reactstrap";

import axios from "../../../infra/axios";
import Select from "react-select";

const HaivisionUUTSAdd = (props) =>{
    const [modelFilter, setModelFilter] = useState([]);
    const [modelFilterStr, setModelFilterStr] = useState('');
    const [models, setModels] = useState([]);
    const [modelOptions, setModelOptions] = useState([]);
    const [selectedModel, SetSelectedModel] = useState('');
    const [loading, setLoading] = useState(false);
    const [serialNumbers, SetSerialNumbers] = useState('');
    const [showNotification, SetShowNotification] = useState(false);

    useEffect(() => {
        GetModels();

    }, []);
  
      const GetModels = () =>{
        setLoading(true);
        axios
        .get(
        process.env.REACT_APP_PRODUCTION_API_BASE_URL +
            "/Legacy/Models?PageNumber=1&PageSize=1000"
        )
        .then((response) => {
            if(response.data){
                const models = response.data;
                setModels(models.data);

                let modelOptions = models.data.map(item => {
                  return { value: item.description, label: item.description };
               });
               setModelOptions(modelOptions);

                //setModelFilter(models);
                //SetSelectedModel(models.data[1].description);
            }
            setLoading(false);
        });      
      }

      const filterModel = (filterStr) =>{
        setModelFilterStr(filterStr);
        var filterdModels = models.filter(x=>x.description?.toLowerCase().includes(filterStr?.toLowerCase()));
        //setModelFilter(filterdModels);
        if(filterdModels.length > 0){
          SetSelectedModel(filterdModels[0].description);
        }
      }

      const handleHideModal = (isCancelled) =>{

        //setModelFilter(models);
        setModelFilterStr('');
        //setModels([]);
        SetSelectedModel('');
        //setLocations([]);
        SetSerialNumbers('');
        setLoading(false);
        props.onHide();
        if(!isCancelled){
          props.onLoadUUTs();
        }
        
      }

      const handleCreate = () =>{
        if(selectedModel === '' || selectedModel === undefined || selectedModel === null){
            alert('Please select a model!');
            return;   
          }
           
          var selectedModelObj = models.filter(x=>x.description === selectedModel);
          var modelId = selectedModelObj[0].id;
          var maxMacAddressCount = selectedModelObj[0].macCount;
          // to do, this part should use user role to control, only admin can create single unit
          if(maxMacAddressCount > 0){
            SetShowNotification(true);
            return;
          }

          var serialNumberDto = serialNumbers.trim(' ').split(/\r?\n/);

          if(serialNumbers === '' || serialNumberDto.length <= 0){
            alert('Please input serial Numbers!');
            return; 
          }

          axios
          .post(
            process.env.REACT_APP_PRODUCTION_API_BASE_URL + "/Legacy/Units/modelId/" + modelId + "/Units",
            serialNumberDto
          )
          .then((reponse) => {
            handleHideModal(false);
          });    
      }

      const customStyles = {
        option: (provided, state) => ({
          ...provided,
          color: "#777782",
          background: "#33333c",
        }),
      };

    return (
        <div>
        { loading && <ProgressBar
            leftLabel="Loading: "
            barColors={{
                background: '#599bff',
                right: '#599bff',
                left: '#2fccd3',
            }}
            hideRight={true}
            animate={1}
        />}
        {!loading && 
          <div>
            <Modal show={props.show} size="lg">
            <ModalHeader>
              <ModalTitle>Create Units</ModalTitle>
            </ModalHeader>
            <ModalBody>
            <div>
                <Row>
                <Col> 
                <FormGroup>
                <FormLabel>Product Models</FormLabel>            
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  defaultValue = {modelFilterStr!==''?modelFilterStr:''}
                  options={modelOptions}
                  label="Models"
                  isSearchable
                  styles={customStyles}
                  onChange={(e) => filterModel(e.value)}
                /> 
                </FormGroup>                 
                </Col>
                </Row>
                <Row>
                <Col>
                <FormGroup>
                  <FormLabel>Serial Numbers (Please seperate multiple serial numbers with new line)</FormLabel>
                  <FormControl
                    as="textarea"
                    onChange={(e) => {
                    var text = e.target.value.trim();
                    SetSerialNumbers(text);}}>
                </FormControl>             
                </FormGroup>
                <p style={{color:'#dfd99b', textAlign:'center'}}><small>Note: All makito units should be created through work order</small></p>
                </Col>
                </Row>  
                <Notification
                style={{ top: "1rem", right: "1rem" }}
                show={showNotification}
                onDismiss={()=>SetShowNotification(false)}
                message=
                  "Maktio Should be created via Work Order. Please contract helpdesk."
                
                variant="Error"
                autohide={false}
              ></Notification>            
              </div>        
              </ModalBody>
              <ModalFooter>
              <Button variant="primary" onClick={() => handleCreate()}>
                Create
              </Button>
              <Button onClick={() => handleHideModal(true)}>
                Cancel
              </Button>
            </ModalFooter>
              </Modal>
          </div>} 
          </div>

    );
}

export default HaivisionUUTSAdd;