import {
  Animation,
  Button,
  FormLabel,
  FormControl,
  List,
  ListHeader,
  TableRow,
  Icon,
  OverlayTrigger,
  FormGroup,
 ProgressBar, 
 Header,
 SelectOption} from "@hai/ui-react";
import React, { Component, Fragment } from "react";
import JellyHeader from "../../common/Utils/JellyHeader";
import axios from "../../../infra/axios";
import { Row, Col, Collapse } from "reactstrap";
import LicenseRequestItem from "./haivision-license-request-Item";

class HaivisionLicenseRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      licenseRequests: null,
      filteredLicenseRequest: null,
      showAssetIdList: [],
    };
    this.handleViewAssets = this.handleViewAssets.bind(this);
  }

  componentDidMount() {
    this.getLicenseRequests();
  }

  async getLicenseRequests() {
    axios
      .get(
        process.env.REACT_APP_LIC_API_BASE_URL + "/LicenseRequests?status=New"
      )
      .then((response) => {
        this.setState((prev) => {
          prev.licenseRequests = response.data;
          prev.filteredLicenseRequest = prev.licenseRequests.filter(
            (x) => x.status.indexOf("Closed") == -1
          );
          prev.loading = false;
          return prev;
        });
      });
  }

  filterLicenseRequest(value) {
    if (value == "") {
      this.setState((prev) => {
        prev.filteredLicenseRequest = prev.licenseRequests;
        return prev;
      });
    } else {
      if (value.length >= 2) {
        let filtered = this.state.licenseRequests.filter((c) => {
          let valueUp = value.toUpperCase();
          if (c.name?.toUpperCase().includes(valueUp)) return true;
        });
        this.setState((prev) => {
          prev.filteredLicenseRequest = filtered;
          return prev;
        });
      }
    }
  }

  filterLicenseRequestByStatus(value) {
    if (value == "") {
      this.setState((prev) => {
        prev.filteredLicenseRequest = prev.licenseRequests;
        return prev;
      });
    } else {
      if (value.length >= 2) {
        let filtered = this.state.licenseRequests.filter((c) => {
          let valueUp = value.toUpperCase();
          if (c.status?.toUpperCase().includes(valueUp)) return true;
        });
        this.setState((prev) => {
          prev.filteredLicenseRequest = filtered;
          return prev;
        });
      }
    }
  }

  handleViewAssets(l) {
    this.setState((prev) => {
      if (prev.showAssetIdList.indexOf(l.id) != -1) {
        prev.showAssetIdList.splice(prev.showAssetIdList.indexOf(l.id), 1);
      } else {
        prev.showAssetIdList.push(l.id);
      }

      return prev;
    });
  }

  render() {
    return (
      <div>
        <Header title="Haivision License Requests" subtitle="Salesforce" />
        <hr />
          <Row>
            <Col md="6" sm="6">
              <FormLabel>Search Request Number</FormLabel>
              <FormControl
                placeholder="Search Request Number"
                onChange={(e) => this.filterLicenseRequest(e.target.value)}
          
              />
            </Col>
            <Col md="6" sm="6">
              <FormLabel>Status</FormLabel>
              <FormControl
                as="select"
                onChange={(e) =>
                  this.filterLicenseRequestByStatus(e.target.value)
                }
              >
                <SelectOption key="New">New</SelectOption>
                <SelectOption key="In Progress">In Progress</SelectOption>
                <SelectOption key="Closed">Closed</SelectOption>
              </FormControl>
            </Col>
          </Row>
        <hr />
        {/* <Button>Button</Button> */}
        <hr />
        <List>
          <ListHeader
checkbox={false}
            style={{
              gridTemplateColumns: "0.1fr 1fr 1fr 1fr 1fr 1fr 1fr",
            }}
          >
            <div></div>
            <div>Request Number</div>
            <div>Partner Account</div>
            <div>End User Account</div>
            <div>Status</div>
            <div>Assets</div>
            <div>Actions</div>
          </ListHeader>
          {this.state.filteredLicenseRequest &&
            this.state.filteredLicenseRequest.map((l) => {
              return (
                <Fragment key={l.id}>
                  <TableRow
checkbox={false}
                    key={l.id}
                    style={{
                      gridTemplateColumns: "0.1fr 1fr 1fr 1fr 1fr 1fr 1fr",
                    }}
                  >
                    <div></div>
                    <div>
                      <a
                        href={`https://haivision.my.salesforce.com/${l.id}`}
                        style={{ color: "#007bff" }}
                      >
                        {l.name}
                      </a>
                    </div>
                    <div>{l.partnerAccountName}</div>
                    <div>{l.endUserAccountName}</div>
                    <div>{l.status}</div>
                    <div>
                      {" "}
                      <OverlayTrigger
                        popoverContent={"View Assets"}
                        style={{ cursor: "pointer", paddingRight: "15px" }}
                        onClick={() => this.handleViewAssets(l)}
                      >
                        <Icon
                          style={{ fill: "#007bff" }}
                          
                          iconname="Devices"
                        />
                      </OverlayTrigger>
                    </div>
                    <div></div>
                  </TableRow>

                  <Collapse
                    isOpen={this.state.showAssetIdList.indexOf(l.id) != -1}
                  >
                    {this.state.showAssetIdList.indexOf(l.id) != -1 && (
                      <LicenseRequestItem licenseRequestId={l.id} />
                    )}
                  </Collapse>
                </Fragment>
              );
            })}
        </List>
        {this.state.loading && <ProgressBar
  leftLabel="Loading: "
  barColors={{
    background: '#599bff',
    right: '#599bff',
    left: '#2fccd3',
  }}
  hideRight={true}
  animate={1}
/>}
      </div>
    );
  }
}

export default HaivisionLicenseRequest;
