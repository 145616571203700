import React, {useState, useEffect} from 'react'
import { Header, 
   Button,
  Icon,
  List,
  ListHeader,
  TableRow,
  FormGroup,
  FormLabel,
  FormControl, 
  ProgressBar,
  Pagination,
  Spinner} from "@hai/ui-react";
  import { Col, Row } from "reactstrap";
  import axios from "../../../infra/axios";
  import { useNavigate, useParams } from "react-router-dom";

const HaivisionUUTDetail = (props) =>{

    let {uutId, sn} = useParams();
    const navigate = useNavigate();

    const [snParam, setSNParam] = useState(props.sn || sn);
    const [uutIdParam, setUUTIdParam] = useState(props.uutId || uutId);
    const [assets, SetAssets] = useState([]);
    const [licenses, SetLicenses] = useState([]);
    const [unit, SetUnit] = useState(null);
    const pageCount = 5;
    const [currentPage, SetCurrentPage] = useState(1);
    const [totalPage, SetTotalPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [loadingLic, setLoadingLic] = useState(false);
    const [loadingAsset, setLoadingAsset] = useState(false);
    const [testResultPage, SetTestResultPage] = useState([]);


    useEffect(() => {
      getAssetsBySerialNumber();
      getLicensesBySerialNumber();
    }, [snParam]);

    useEffect(() => {
      getUnitById();
    }, [uutIdParam]);


  const getLicensesBySerialNumber = () => {

    setLoadingLic(true);
    axios
    .get(
    process.env.REACT_APP_LIC_API_BASE_URL +
        `/Licenses/SerialNumber/${snParam}`
    )
    .then((response) => {
        if(response.data){
            const result = response.data;
            SetLicenses(result);
        }
        setLoadingLic(false);
    });    
};

const getAssetsBySerialNumber = () => {
  setLoadingAsset(true);
  axios
  .get(
  process.env.REACT_APP_PRODUCTION_API_BASE_URL +
      `/Legacy/ProductSalesforce/makitoActiveAsset/serialNumber/${snParam}`
  )
  .then((response) => {
      if(response.data){
          const result = response.data;
          SetAssets(result);
      }
      setLoadingAsset(false);
  }).finally(() => {
    setLoadingAsset(false);
  }); 
}

const handleDownloadLicense = () => {
  setLoading(true);
  axios
    .get(
      process.env.REACT_APP_LIC_API_BASE_URL +
        `/LicenseText/SerialNumber/${snParam}/merge`
    )
    .then((response) => {
      const yourDate = new Date();
      var datastr = yourDate.toISOString().split("T")[0];
      var fileName = snParam + "_" + datastr + ".lic";

      const type = response.headers["content-type"];
      const blob = new Blob([response.data], {
        type: type,
        encoding: "UTF-8",
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
      
      getLicensesBySerialNumber();
    }).finally(() => {
      setLoading(false);
    });
}

const getUnitById = () => {
  setLoading(true);
  axios
  .get(
  process.env.REACT_APP_PRODUCTION_API_BASE_URL +
      `/Legacy/units/${uutIdParam}`
  )
  .then((response) => {
      if(response.data){
          let result = response.data !== null? response.data : null;
          SetUnit(result);

        const orderedTestResult = result?.unitTestResults?.sort((a, b) => {
            return new Date(b.dateTimeTested)-new Date(a.dateTimeTested);
          })

        SetTestResultPage (orderedTestResult?.slice(0, pageCount));
        let total = orderedTestResult?.length % pageCount === 0? Math.floor(orderedTestResult?.length / pageCount) : Math.floor(orderedTestResult?.length / pageCount) + 1;          
        SetTotalPage(total);
      }
      setLoading(false); 
  });  
}

const checkColor = (description) => {
    switch (description) {
      case "PASSED":
        return "#4ed01a";
      default:
        return "red";
    }};
    
    return (
      <div>
      { loading && <ProgressBar
          leftLabel="Loading: "
          barColors={{
              background: '#599bff',
              right: '#599bff',
              left: '#2fccd3',
          }}
          hideRight={true}
          animate={1}
      />}
     <div>
      <Header title="Unit" />
          <Row>
            <Col>      
              <FormGroup>
                <FormLabel>Model</FormLabel>
                <FormControl disabled placeholder={unit?.model} />
              </FormGroup>
           </Col> 
          <Col>  
            <FormGroup>
              <FormLabel>Serial Number</FormLabel>
              <FormControl disabled value={unit?.serialNumber}/>
            </FormGroup>
        </Col> 
        <Col>      
              <FormGroup>
                <FormLabel>Mac Address</FormLabel>
                <FormControl disabled placeholder={unit?.macAddress} />
              </FormGroup>
           </Col> 
           <Col>      
            <FormGroup>
              <FormLabel>Created Date</FormLabel>
              <FormControl disabled value={unit?.dateCreated}/>
            </FormGroup>
           </Col> 
        </Row>
        <Row>
            <Col>      
              <FormGroup>
                <FormLabel>Dpkg Version</FormLabel>
                <FormControl disabled placeholder={unit?.dpkgVersion} />
              </FormGroup>
           </Col> 
          <Col>  
            <FormGroup>
              <FormLabel>Stage Type</FormLabel>
              <FormControl disabled value={unit?.stageType}/>
            </FormGroup>
        </Col> 
          <Col>  
            <FormGroup>
              <FormLabel>Shipped Date</FormLabel>
              <FormControl disabled value={unit?.dateShipped}/>
            </FormGroup>
        </Col> 
        <Col>  
            <FormGroup>
              <FormLabel>Extra Info</FormLabel>
              <FormControl disabled value={unit?.extraInfo}/>
            </FormGroup>
          </Col> 
        </Row>
      </div> 
      <br/>
      <Button variant="primary" onClick={() => handleDownloadLicense()}>DownLoad merged License</Button>
      <Button onClick={() => navigate("/products/uut")}> Back</Button>
      <br/>
      <div>
        <Header title={`Testing results`} />
        <br/>
        <List>
          <ListHeader
            checkbox={false}
            style={{
              gridTemplateColumns:
                "0.5fr 0.7fr 3fr 1fr 0.5fr",
            }}
          >             
            <div>Station Name</div>
            <div>Staging Name</div>
            <div>Full Info</div>
            <div>Tested Time</div>
            <div>Tested Result</div>
          </ListHeader>
          {testResultPage &&
            testResultPage.map((result) => {
              return (
                <div key={result.id}>
                  <TableRow
                  checkbox={false}
                    style={{
                      gridTemplateColumns:
                        "0.5fr 0.7fr 3fr 1fr 0.5fr",
                    }}
                  >
                  <div>{result?.stationName}</div>
                  <div style={{color:'#0e88f5'}}><b>{result?.stageName}</b></div>
                  <div>{result.fullInfoResult}</div>
                  <div>{new Date(result.dateTimeTested).toUTCString()}</div>
                  <div style={{ color: `${checkColor(result?.testResultName)}` }}><b>{result?.testResultName}</b></div>
                  </TableRow>
                </div>
              );
            })}
        </List>
      </div>
        <br/>
        <Pagination
        pageCount={totalPage}
        currentPage={currentPage}
        onPageChange={(newPage, previousPage) => {
          //alert(`New page: ${newPage}  Previous Page: ${previousPage}`);
          SetTestResultPage (unit.unitTestResults.slice((newPage - 1) * pageCount, newPage * pageCount));
          SetCurrentPage(newPage);
          }
        }
      />
     <br/>
        <Row>
            <Col>      
            <div>
            { loadingLic && <ProgressBar
              leftLabel="Loading: "
              barColors={{
                  background: '#599bff',
                  right: '#599bff',
                  left: '#2fccd3',
              }}
              hideRight={true}
              animate={1}
            />}
              <Header title='Licenses' />
              <br/>
              <List>
                <ListHeader
                  checkbox={false}
                  style={{
                    gridTemplateColumns:
                      "1fr 1fr 1fr 2fr",
                  }}
                >           
                  <div>SKU</div>
                  <div>Expire Date</div>
                  <div>Current Version</div>
                  <div>Mac Addresses</div>
                </ListHeader>
                  {licenses &&
                    licenses.map((result) => {
                      return (
                        <div key={result.id}>
                          <TableRow
                          checkbox={false}
                            style={{
                              gridTemplateColumns:
                                "1fr 1fr 1fr 2fr",
                            }}
                          >
                          <div>{result?.sku}</div>
                          <div>{result?.licenseOptionDatas?.find(x=>x.item==='expdate')?.value}</div>
                          <div>{result?.productVersionNumber}</div>
                          <div>{result?.macAddresses.map((item) => { return item['value'];})?.join(', ')}</div>                        
                          </TableRow>
                        </div>
                      );
                    })}
                </List>
              </div>
           </Col> 
           <Col>      
            <div>
            { loadingAsset && <ProgressBar
              leftLabel="Loading: "
              barColors={{
                  background: '#599bff',
                  right: '#599bff',
                  left: '#2fccd3',
              }}
              hideRight={true}
              animate={1}
            />}
              <Header title='Shipped Active Makito Assets' />
              <br/>
              <List>
                <ListHeader
                  checkbox={false}
                  style={{
                    gridTemplateColumns:
                      "1fr 1fr 1fr 2fr 1fr",
                  }}
                >           
                  <div>Name</div>
                  <div>Expire Date</div>
                  <div>Current Version</div>
                  <div>Mac Addresses</div>
                  <div>Shipped Date</div>
                </ListHeader>
                  {assets &&
                    assets.map((result) => {
                      return (
                        <div key={result.id}>
                          <TableRow
                          checkbox={false}
                            style={{
                              gridTemplateColumns:
                                "1fr 1fr 1fr 2fr 1fr",
                            }}
                          >
                          <div>{result?.name}</div>
                          <div>{result?.expiredDate}</div>
                          <div>{result?.currentVersion}</div>
                          <div>{result?.macAddresses}</div>
                          <div>{result?.dateShipped}</div>
                          </TableRow>
                        </div>
                      );
                    })}
                </List>
              </div>
           </Col> 
        </Row>
        </div>       
      );

}

export default HaivisionUUTDetail;