import React, { useState } from "react";
import { useFetch } from "../common/useFetch";
import { getAccounts } from "./webbingApi";
import { Button, Header, List, Modal, ModalBody, ModalHeader, Pagination, ProgressBar } from "@hai/ui-react";
import { useParams } from "react-router-dom";
import cogoToast from "cogo-toast";
import { Col, Row } from "reactstrap";
import WebbingCreateAccount from "./WebbingCreateAccount";

const WebbingAccounts = () => {
  var params = useParams();
  const { data: accounts, loading, error } = useFetch(() => getAccounts(`?pageNumber=1&pageSize=10`));
  const [pageData, setPageData] = useState({
    currentPage: params?.pageNum ? parseInt(params?.pageNum) : 1,
    pageSize: 10
  });
  const [showCreate, setShowCreate] = useState(false);

  if (loading) {
    return (
      <ProgressBar
        leftLabel="Loading: "
        barColors={{
          background: "#599bff",
          right: "#599bff",
          left: "#2fccd3",
        }}
        hideRight={true}
        animate={1}
      />
    );
  }

  if (error) {
    return <div>An error has occurred: {error.message}</div>;
  }

  return (
    <>
      <Header title="Webbing Accounts" style={{ marginTop: "1%" }} />
      <br />
      <div>
        <Row>
          <Col md='4'>
            <Button onClick={() => setShowCreate(true)}>Create</Button>
          </Col>
        </Row>
        {accounts &&
          accounts.data.map((a, index) => (
            <List key={index}>
              <List.Panel
                panelColor={
                  a.statusID === 1 ? "haiui-green-01" : "haiui-yellow-01"
                }
              >
                <List.PanelTitle title={a.name ?? "No name"} />
                <List.PanelDetail>
                  <List.PanelDetailItem
                    icon="Information"
                    text="Webbing Account"
                    divider="vertical"
                  />
                  <List.PanelDetailItem
                    icon="Information"
                    text={`Status: ${a.statusID}`}
                    divider="vertical"
                    label="Status"
                  />
                  <List.PanelDetailItem
                    icon="HubNodes"
                    text={`Email: ${a.email}`}
                    label="email"
                  />
                </List.PanelDetail>
              </List.Panel>
            </List>
          ))}
      </div>

      <Pagination
        pageCount={accounts?.totalPages}
        currentPage={pageData.currentPage}
        onPageChange={(newPage, previousPage) => {
          if (accounts.hasNextPage || newPage <= accounts?.totalPages) {
            setPageData({ currentPage: newPage, pageSize: pageData.pageSize });
            getAccounts(`?pageNumber=${newPage}&pageSize=${pageData.pageSize}`);
          } else {
            cogoToast.warn("No next page", { position: 'top-right' });
          }

        }}
      />

      <Modal show={showCreate} size="lg">
        <ModalHeader>Create Account</ModalHeader>
        <ModalBody><WebbingCreateAccount close={() => setShowCreate(false)}>
        </WebbingCreateAccount>
        </ModalBody>
      </Modal>

    </>
  );
};

export default WebbingAccounts;
