const { default: cogoToast } = require("cogo-toast")

export const handleError = (error) => {
    if(!error) {
        cogoToast.error("Error on processing", {position: 'top-right'})
    }
    else if(error.errors && error.errors.length > 0) {
        error.errors.forEach(msg => {
            cogoToast.error(error.message + ': ' + msg, {position: 'top-right'})
        });
    } else {
        cogoToast.error(error.message, {position: 'top-right'})
    }
    
}
