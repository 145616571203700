import React, { useState, useEffect } from 'react'
import {
  Header,
  Button,
  Icon,
  List,
  ListHeader,
  TableRow,
  Pagination,
  FormLabel,
  FormControl,
  ProgressBar,
  OverlayTrigger,
  Spinner
} from "@hai/ui-react";
import { Col, Row } from "reactstrap";
import axios from "../../../infra/axios";
import ProductModelUpdate from "./haivision-model-update";
import { useParams } from "react-router-dom";
import cogoToast from "cogo-toast";

const HaivisionProductionModel = () => {
    var params = useParams();
  const [loading, setLoading] = useState(false);
  const [models, SetModels] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [updateModel, setUpdateModel] = useState(null);
  const [filterParams, setFilterParams] = useState([]);
  const [pageData, setPageData] = useState({
    currentPage: params?.pageNum ? parseInt(params?.pageNum) : 1,
    pageSize: 10
  });

  useEffect(() => {
    getModels();

  }, [pageData]);

  const getModels = () => {

    setLoading(true);
    axios
      .get(
        process.env.REACT_APP_PRODUCTION_API_BASE_URL +
        `/Legacy/Models?PageNumber=${pageData.currentPage}&PageSize=${pageData.pageSize}&ColumnFilters=${JSON.stringify(filterParams)}`
      )
      .then((response) => {
        if (response.data) {
          const result = response.data;
          SetModels(result);
        }
        setLoading(false);
      }).finally(() => {
        setLoading(false);
      })
  };

  const OnUpdateAddModel = (bshow) => {       
        setUpdateModel(null);
        setShowAddModal(bshow);
  }

  const OnUpdateUpdateModel = (bshow) => {
    setShowUpdateModal(bshow);
  }

  const handleDetails = (item) => {      
        setUpdateModel(item);
        OnUpdateUpdateModel(true);
  }

  return (
    <div>
      {loading && <ProgressBar
        leftLabel="Loading: "
        barColors={{
          background: '#599bff',
          right: '#599bff',
          left: '#2fccd3',
        }}
        hideRight={true}
        animate={1}
      />}
      <Header title="Production Models" />
      <Row style={{ alignItems: "flex-end" }}>
          <Col>
            <FormLabel>Filter by description</FormLabel>
            <FormControl
              placeholder="Filter by description"
              onChange={(e) => {
                let tempSet = filterParams.filter(f => f.id !== "description");
                if (e.target.value !== "") {
                  tempSet.push({
                    id: 'description',
                    Value: e.target.value,
                    Comparator: 'Contains'
                  });
                  setFilterParams(tempSet);
                } else {
                  setFilterParams(tempSet);
                }
              }
              }
            />
          </Col>
          <Col>
            <Button onClick={() => getModels()}>Search</Button>
          </Col>
        </Row>
        <br />
        <Row>
            <Col>
            <Button
              variant="primary"
              onClick={()=>OnUpdateAddModel(true)}>
              Add Model
            </Button>
            </Col>
        </Row>
        <List>
            <ListHeader
              checkbox={false}
              style={{
                gridTemplateColumns:
                  "1fr 1fr 2fr 1fr",
              }}
            >
              <div>Model Id</div>
              <div>Description</div>             
              <div>Mac Count</div>
              <div>Action</div>
            </ListHeader>
            {models.data &&
              models.data.map((item) => {
                return (
                  <div key={item.id}>
                    <TableRow
                      checkbox={false}
                      style={{
                        gridTemplateColumns:
                          "1fr 1fr 2fr 1fr",
                      }}
                    >
                      <div>{item.id}</div>
                      <div>{item.description}</div>                     
                      <div>{item.macCount}</div>
                      <div style={{ display: "inline-flex" }}>
                        <OverlayTrigger
                          popoverContent={"Edit the detail of model"}
                          style={{ cursor: "pointer", paddingRight: "10px" }}
                          onClick={() => handleDetails(item)}
                        >
                        <Icon
                        iconname="Edit"
                        style={{ fill: "#007bff" }}
                        />
                        </OverlayTrigger>
                      </div>
                    </TableRow>
                  </div>
                );
              })}
          </List>
          <Pagination
            pageCount={models?.totalPages}
            currentPage={pageData.currentPage}
            onPageChange={(newPage, previousPage) => {
              if (models.hasNextPage || newPage <= models?.totalPages) {
                setPageData({ currentPage: newPage, pageSize: pageData.pageSize });
                //getModels(newPage, pageData.pageSize);
              } else {
                cogoToast.warn("No next page", { position: 'top-right' });
              }

            }}
          />
          <ProductModelUpdate
		  model = {updateModel}
          title = "Add Model"
          show={showAddModal}
          onHide={() => OnUpdateAddModel(false)}
          onLoadModels={() => getModels()}
        />
        <ProductModelUpdate
          title = "Update Model"
          model = {updateModel}
          show={showUpdateModal}
          onHide={() => OnUpdateUpdateModel(false)}
          onLoadModels={() => getModels()}
        />
    </div>
  );
}

export default HaivisionProductionModel;