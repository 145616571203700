import React from "react";
import { Col, Row, Spinner } from "reactstrap";
import { List, ListHeader, Animation, TableRow , ProgressBar } from "@hai/ui-react";
import axios from "axios";

class SubscriptionTable extends React.Component {
  state = {
    subscriptions: [],
    loading: true,
  };

  async getSubscriptions() {
    await axios
      .get(
        process.env.REACT_APP_HUB_API_BASE_URL +
          "/Operations/client-subscription"
      )
      .then((response) => {
        this.setState({ subscriptions: response.data, loading: false });
      });
  }

  componentDidMount() {
    this.getSubscriptions();
  }

  render() {
    if (this.state.loading) {
      return (
        <Row>
          <Col sm="12" md={{ size: 6, offset: 6 }}>
            {" "}
            {this.state.loading && (
              <ProgressBar
  leftLabel="Loading: "
  barColors={{
    background: '#599bff',
    right: '#599bff',
    left: '#2fccd3',
  }}
  hideRight={true}
  animate={1}
/>
            )}
          </Col>
        </Row>
      );
    } else {
      return (
        <div>
          <List>
            <ListHeader
checkbox={false}
              style={{ gridTemplateColumns: "1.3fr 2fr 2fr 2fr" }}
              alternateRowColors="true"
            >
              <div></div>
              <div>Subscription Name</div>
              <div>Sub ID</div>
              <div>Status</div>
            </ListHeader>

            {this.state.subscriptions.map((sub, index) => (
              <TableRow
checkbox={false}
                key={sub.subscriptionId}
                alternateRowColors={index % 2 == 0}
                style={{
                  gridTemplateColumns: "1.3fr 2fr 2fr 2fr",
                  color: "white",
                }}
              >
                <div> </div>
                <div> {sub.displayName} </div>
                <div> {sub.subscriptionId} </div>
                <div> {sub.state} </div>
              </TableRow>
            ))}
          </List>
        </div>
      );
    }
  }
}

export default SubscriptionTable;
