import React from "react";
import { Container } from "reactstrap";
import { UserContext } from "../../common/userContext";
import ContractsTable from "./ContractsTable";

class Contracts extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    if (!sessionStorage.getItem("user.authState")) {
      return <div className="white-text">auth required...</div>;
    } else {
      return (
        <UserContext.Consumer>
          {(user) => {
            return (
              <Container className="themed-container" fluid={true}>
                <ContractsTable history={this.props.history}></ContractsTable>
              </Container>
            );
          }}
        </UserContext.Consumer>
      );
    }
  }
}

export default Contracts;
