import {
  Animation,
  Button,
  Dialog,
  List,
  ListHeader,
 ProgressBar, 
 SelectOption} from "@hai/ui-react";
import cogoToast from "cogo-toast";
import React, { useState, useEffect } from "react";
import axios from "../../../infra/axios";
import PropTypes from "prop-types";
import ContractNewProvisionDialog from "./ContractNewProvisionDialog";
import Select from "react-select";
import ContractProvisionSuccessModal from "./ContractProvisionSuccessModal";
import AssetsRow from "./AssetsRow";

const AssetsTable = (props) => {
  const [assetsMapped, setAssetsMapped] = useState(null);
  const [loadingSubs, setLoadingSubs] = useState(true);
  const [showInfo, setShowInfo] = useState(false);
  const [showProvisionDialog, setShowProvisionDialog] = useState(false);
  const [showNewSubDialog, setShowNewSubDialog] = useState(false);

  //new hub sub
  const [postingNewSub, setPostingNewSub] = useState(false);
  const [selectedSub, setSelectedSub] = useState(null);
  const [options, setOptions] = useState(null);
  const [createdNewSub, setCreatedNewSub] = useState(false);
  const [provision, setProvision] = useState(null);

  useEffect(() => {
    querySubscriptions(props.contract.hubOrgId);
    mountSubSelect();
  }, []);

  const mountSubSelect = () => {
    let options = props.contract.assets
      .filter((a) => {
        if (props.specificSub) {
          return a.subscriptionId === props.specificSub;
        }
        return a;
      })
      .map((a) => {
        return (<SelectOption value={a.azureSubscriptionId}>a.azureSubscriptionName + " - " + a.azureSubscriptionId</SelectOption>);
      });
    setOptions(options);
  };

  const querySubscriptions = (orgId) => {
    if (orgId) {
      setLoadingSubs(true);
      axios
        .get(
          process.env.REACT_APP_HUB_API_BASE_URL +
            `/SrtHub/organizations/${orgId}/subscriptions`
        )
        .then(async (response) => {
          // We need all of this because we merge subscriptions from 2 contract and from hub.
          let hubSubscriptions = response.data;

          let mergedAssets = props.contract.assets.map((sfAsset) => {
            sfAsset.orchestrator = "Loading...";

            let index = hubSubscriptions.findIndex(
              (sub) => sub.subscriptionId == sfAsset.subscriptionId
            );

            if (index != -1) {
              let newAsset = Object.assign(hubSubscriptions[index], sfAsset);
              hubSubscriptions.splice(index, 1);
              return newAsset;
            } else {
              return sfAsset;
            }
          }); // merge the asset property into hub sub

          hubSubscriptions.forEach((s, index) => {
            mergedAssets.push({
              id: "XXXXXXXXXXXXXXX" + index,
              subscriptionId: s.subscriptionId,
              isDisabled: s.isDisabled,
              azureSubscriptionName: s.subscriptionName,
              azureSubscriptionId: "00000-00000-00000-00000",
              orchestrator: "Loading...",
            });
          });

          mergedAssets = mergedAssets.filter((a) => {
            if (props.specificSub) {
              return a.subscriptionId === props.specificSub;
            }
            return a;
          });
          setLoadingSubs(false);
          setAssetsMapped(mergedAssets.sort());
        })
        .catch((error) => {
          setLoadingSubs(false);
        });
    } else {
      setShowInfo(true);
      setAssetsMapped(props.contract.assets);
      setLoadingSubs(false);
    }
  };

  const toggleProvisionDialog = () => setShowProvisionDialog(true);
  const toggleNewSubDialog = () => setShowNewSubDialog(true);

  const generateNewClaimCode = () => {
    if (selectedSub?.value) {
      setPostingNewSub(true);
      axios
        .post(
          process.env.REACT_APP_HUB_API_BASE_URL +
            `/Operations/client-subscription/srthub/claim-code/generate/${selectedSub.value}`
        )
        .then((response) => {
          setProvision(response.data);
          setPostingNewSub(false);
          setCreatedNewSub(true);
          setSelectedSub(null);
        })
        .catch((err) => setPostingNewSub(false));
    } else {
      cogoToast.warn("Select subscription first", { position: "top-right" });
    }
  };

  return (
    <div
      style={{
        padding: "1%",
        border: "1px #007bff groove",
        borderRadius: "5px",
        marginTop: "5px",
      }}
    >
      {showInfo && (
        <small>Showing only assets because org id is not valid.</small>
      )}
      <Button onClick={toggleProvisionDialog}>New Provision</Button>
      <Button onClick={toggleNewSubDialog} disabled={postingNewSub}>
        New Hub Subscription
      </Button>
      <small>Hub Org Id: {props.contract.hubOrgId}</small>
      <List style={{ marginTop: "1%" }}>
        <ListHeader
checkbox={false}
          style={{
            gridTemplateColumns: "0.3fr 2.5fr 2.5fr 1.5fr 0.3fr 0.3fr",
            color: "white",
            textalign: "center",
          }}
          alternateRowColors="true"
        >
          <div></div>
          <div>Hub Subscription</div>
        <div>Azure Subscription</div>
        <div>Hub Subscription Status</div>
        </ListHeader>
        {loadingSubs && <ProgressBar
  leftLabel="Loading: "
  barColors={{
    background: '#599bff',
    right: '#599bff',
    left: '#2fccd3',
  }}
  hideRight={true}
  animate={1}
/>}
        {assetsMapped &&
          assetsMapped.map((asset, index) => (
            <AssetsRow
              key={asset.id}
              index={index}
              asset={asset}
              orgId={props.contract.hubOrgId}
              customerName={props.contract.accountName}
            ></AssetsRow>
          ))}
      </List>

      <ContractNewProvisionDialog
        show={showProvisionDialog}
        contract={props.contract}
        close={() => setShowProvisionDialog(false)}
      ></ContractNewProvisionDialog>

      <Dialog
        show={showNewSubDialog}
        onClose={() => {
          setShowNewSubDialog(false);
          setSelectedSub(null);
        }}
        headline="Do you wish to create a new Hub Subscrpition for the selected sub?"
        content={
          <>
            <Select
              options={options}
              isSearchable
              onChange={(sub) => setSelectedSub(sub)}
            >
              {" "}
            </Select>
            {selectedSub && (
              <p style={{ marginTop: "1%" }}>
                By clicking YES you are going to create a new Hub
                Subscription/Claim Code using the selected Azure Subscription:{" "}
                {selectedSub.label}
              </p>
            )}
          </>
        }
        buttons={[
          {
            label: "Yes",
            primary: true,
            disabled: postingNewSub,
            onClick: () => generateNewClaimCode(),
          },
          {
            label: "No",
            onClick: () => {
              setShowNewSubDialog(false);
              setSelectedSub(null);
            },
          },
        ]}
      ></Dialog>

      {createdNewSub && (
        <ContractProvisionSuccessModal
          show={createdNewSub}
          contract={{
            contractName: props.contract.name,
            accountName: props.contract.accountName,
          }}
          provision={provision}
          hide={() => setCreatedNewSub(false)}
        ></ContractProvisionSuccessModal>
      )}
    </div>
  );
};

AssetsTable.propTypes = {
  contract: PropTypes.object.isRequired,
  specificSub: PropTypes.string,
};

export default AssetsTable;
