import React from "react";
import { Col, Row, Spinner } from "reactstrap";
import {
  List,
  ListHeader,
  Notification,
  TableRow,
  Animation, ProgressBar } from "@hai/ui-react";
import axios from "../../../infra/axios";
import moment from "moment";
import ProvisionDetailModal from "./ProvisionDetailModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCopyright,
  faEye,
  faPen,
  faSearch,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import ProvisionChangeModal from "./ProvisionChangeModal";
import ProvisoinDeleteModal from "./ProvisionDeleteModal";
import ProvisionStatusVerify from "./ProvisionStatusVerify";

class ProvisionsTable extends React.Component {
  state = {
    provisions: [],
    loading: true,
    detailModal: false,
    detailModalItem: null,
    changeModal: false,
    changeModalItem: null,
    deleteModal: false,
    deleteModalItem: null,
    successNotification: false,
    statusModal: false,
  };

  async getProvisions() {
    await axios
      .get(process.env.REACT_APP_HUB_API_BASE_URL + "/Provisions")
      .then((response) => {
        var values = [];
        for (var i in response.data) {
          values.push(response.data[i]);
        }
        this.setState({
          provisions: values,
          loading: false,
        });
      });
  }

  componentDidMount() {
    if (this.props.provisions) {
      this.setState({
        provisions: this.props.provisions,
        loading: false,
      });
    } else {
      this.getProvisions();
    }
  }

  modalComponent() {
    return (
      <div>
        {this.state.detailModal && (
          <ProvisionDetailModal
            show={this.state.detailModal}
            provision={this.state.detailModalItem}
            hide={() => this.hideDetailodal()}
          />
        )}
        {this.state.changeModal && (
          <ProvisionChangeModal
            show={this.state.changeModal}
            provision={this.state.changeModalItem}
            hide={() => this.hideChangeModal()}
            successNotification={this.showSuccessNotification}
          />
        )}
        {this.state.deleteModal && (
          <ProvisoinDeleteModal
            show={this.state.deleteModal}
            provision={this.state.deleteModalItem}
            hide={(id) => this.hideDeleteModal(id)}
            successNotification={this.showSuccessNotification}
          />
        )}
        {this.state.showStatusVerifyModal && (
          <ProvisionStatusVerify
            show={this.state.showStatusVerifyModal}
            claimCode={this.state.claimCode}
          />
        )}
      </div>
    );
  }

  showDeleteModal = (item) =>
    this.setState({ deleteModal: true, deleteModalItem: item });

  showDetailModal = (item) =>
    this.setState({ detailModal: true, detailModalItem: item });

  showChangeModal = (item) =>
    this.setState({ changeModal: true, changeModalItem: item });

  showStatusVerifyModal = (claimCode) => {
    this.setState({
      showStatusVerifyModal: true,
      claimCode: claimCode,
    });
  };

  hideDetailodal = () =>
    this.setState({ detailModal: false, detailModalItem: null });

  hideDeleteModal = (itemDeleted) => {
    if (itemDeleted) {
      let provisions = [...this.state.provisions].filter(
        (p) => p.id !== itemDeleted
      );
      this.setState({
        provisions: provisions,
        deleteModal: false,
        deleteModalItem: null,
      });
    } else {
      this.setState({ deleteModal: false, deleteModalItem: null });
    }
  };

  hideChangeModal = () =>
    this.setState({ changeModal: false, changeModalItem: null });

  notificationComponent() {
    return (
      <div>
        {this.state.successNotification && (
          <Notification
            style={{ top: "1rem", right: "1rem" }}
            show={this.state.successNotification}
            description="Success."
            message="Update succeeded."
            autohide={true}
            variant="Success"
            onDismiss={this.hideSuccessNotification}
          />
        )}
      </div>
    );
  }
  showSuccessNotification = () => this.setState({ successNotification: true });
  hideSuccessNotification = () => this.setState({ successNotification: false });

  render() {
    if (this.state.provisions == null || this.state.provisions.length == 0) {
      return (
        <div
          style={{
            padding: "1%",
            border: "1px #F6FF33 groove",
            borderRadius: "5px",
            marginTop: "5px",
          }}
        >
          <Row>
            <Col>
              <span style={{ color: `white` }}>No data...</span>
            </Col>
          </Row>
          <Row>
            <Col sm="12" md="12">
              {" "}
              {this.state.loading && (
                <ProgressBar
  leftLabel="Loading: "
  barColors={{
    background: '#599bff',
    right: '#599bff',
    left: '#2fccd3',
  }}
  hideRight={true}
  animate={1}
/>
              )}
            </Col>
          </Row>
        </div>
      );
    } else {
      return (
        <div
          style={{
            padding: "1%",
            border: "1px #F6FF33 groove",
            borderRadius: "5px",
            marginTop: "5px",
          }}
        >
          {this.notificationComponent()}
          <List>
            <ListHeader
checkbox={false}
              style={{
                gridTemplateColumns:
                  "2fr 2.5fr 1fr 1.5fr 1.5fr 0.3fr 0.3fr 0.3fr 0.3fr 0.3fr 0.3fr",
              }}
              alternateRowColors="true"
            >
              <div>Domain</div>
              <div>Claim Code</div>
              <div>Created Date (UTC)</div>
              <div>Claimed Date (UTC)</div>
              <div>Contract Id</div>

              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </ListHeader>

            {this.state.provisions
              .sort((a, b) =>
                moment(a.createdDate) < moment(b.createdDate) ? 1 : -1
              )
              .map((prov, index) => (
                <TableRow
checkbox={false}
                  key={index}
                  alternateRowColors={index % 2 == 0}
                  className="table-item"
                  style={{
                    gridTemplateColumns:
                      "2fr 2.5fr 1fr 1.5fr 1.5fr 0.3fr 0.3fr 0.3fr 0.3fr 0.3fr 0.3fr",
                    color: "white",
                  }}
                >
                  <div>{prov.domain} </div>
                  <div> {prov.claimCode}</div>
                  <div>
                    {" "}
                    {prov.createdDate &&
                      moment(prov.createdDate).format("MM/DD/YYYY HH:mm")}{" "}
                  </div>
                  <div>
                    {" "}
                    {prov.claimedDate &&
                      moment(prov.claimedDate).format("MM/DD/YYYY HH:mm")}{" "}
                  </div>
                  <div> {prov.contractId} </div>
                  <div>{prov.subscriptionName?.startsWith("CONNECT-") && <FontAwesomeIcon icon={faCopyright} title='Connect provision'></FontAwesomeIcon>}</div>
                  <div>
                    <FontAwesomeIcon
                      icon={faSearch}
                      style={{ cursor: "pointer" }}
                      onClick={() => this.showStatusVerifyModal(prov.claimCode)}
                    ></FontAwesomeIcon>
                  </div>
                  <div>
                    <FontAwesomeIcon
                      icon={faPen}
                      style={{ cursor: "pointer" }}
                      onClick={() => this.showChangeModal(prov)}
                    />
                  </div>
                  <div>
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{ cursor: "pointer" }}
                      onClick={() => this.showDetailModal(prov)}
                    />
                  </div>
                  <div>
                    <FontAwesomeIcon
                      icon={faTrash}
                      style={{ cursor: "pointer" }}
                      onClick={() => this.showDeleteModal(prov)}
                    />
                  </div>
                </TableRow>
              ))}
          </List>
          {this.modalComponent()}
        </div>
      );
    }
  }
}

export default ProvisionsTable;
