import React from "react";
import SubscriptionTable from "./SubscriptionTable";
import { Row, Col, Container } from "reactstrap";
import JellyHeader from "../../common/Utils/JellyHeader";

class Subscription extends React.Component {
  constructor(props) {
    super(props);
    console.log("subs env", process.env.REACT_APP_HUB_API_BASE_URL);
  }

  state = {
    subscriptions: [],
  };

  render() {
    if (!sessionStorage.getItem("user.authState")) {
      return <div className="white-text">auth required...</div>;
    } else {
      return (
        <Container className="themed-container" fluid={true}>
          <JellyHeader title="Subscriptions"></JellyHeader>
          <SubscriptionTable></SubscriptionTable>
        </Container>
      );
    }
  }
}

export default Subscription;
