import { MsalAuthProvider, LoginType } from "react-aad-msal";

export const authProvider = new MsalAuthProvider(
  {
    auth: {
      authority:
        "https://login.microsoftonline.com/a88156c4-f3f7-4104-8fad-43b93f27493d",
      clientId: "f44a5e32-2260-4e09-abc9-58bef7e3af6d", //Application (client) ID from Overview blade in App Registration
      redirectUri: window.location.protocol + "//" + window.location.host + "/",
      postLogoutRedirectUri: process.env.REACT_APP_PORTAL_BASE_URL,
    },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: false,
    },
  },
  {
    scopes: ["997264bd-4645-4171-964b-675d213b1597/access_as_user"],
  },

  LoginType.Redirect
);
