
   export const sortByVersionDesc = (arrayToSort) => {
        arrayToSort.sort((version1, version2) => {
            if (version1.majorVersion === version2.majorVersion) {
              if (version1.minorVersion === version2.minorVersion) {
                if (version1.patchVersion === version2.patchVersion) {
                  // two same version, impossible
                } else if (version1.patchVersion > version2.patchVersion) {
                  return -1;
                } else {
                  return 1;
                }
              } else if (version1.minorVersion > version2.minorVersion) {
                return -1;
              } else {
                return 1;
              }
            } else if (version1.majorVersion > version2.majorVersion) {
              return -1;
            } else {
              return 1;
            }
          });
    }