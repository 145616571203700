import React, { Component } from "react";
import { Fragment } from "react";
import {
  List,
  ListHeader,
  TableRow,
  Modal,
  ModalBody,
  ModalHeader,
  ModalTitle,
  Button } from "@hai/ui-react";
import moment from "moment";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactJson from "react-json-view";

class AuditLogTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logs: this.props.logs,
      logDetail: null,
      logDetailShow: false,
      logFilter: this.props.logFilter,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ logs: nextProps.logs });
  }

  showLogDetail(log) {
    console.log(log);
    this.setState((prev) => {
      prev.logDetail = log;
      prev.logDetailShow = true;
      return prev;
    });
  }

  hideLogDetail() {
    this.setState((prev) => {
      prev.logDetail = null;
      prev.logDetailShow = false;
      return prev;
    });
  }

  render() {
    return (
      <div>
        <div>
          <List>
            <ListHeader
checkbox={false} alternateRowColors={false}
              style={{
                gridTemplateColumns:
                  "0.1fr 1fr 2fr 1.3fr 2fr 1.5fr 0.5fr 0.5fr",
              }}
            >
              <div></div>
              <div>App</div>
              <div>Event Type</div>
              <div>Date</div>
              <div>User</div>
              <div>Result</div>
            </ListHeader>
            {this.state.logs.map((log, index) => {
              return (
                <Fragment key={log.id}>
                  <TableRow
checkbox={false}
                    key={log.id}
                    style={{
                      gridTemplateColumns:
                        "0.1fr 1fr 2fr 1.3fr 2fr 1.5fr 0.5fr 0.5fr",
                      color: "white",
                    }}
                    alternateRowColors={index % 2 === 0}
                  >
                    <div></div>
                    <div>{log.action.appModule}</div>
                    <div>{log.eventType}</div>
                    <div>
                      {moment(log.startDate).format("YYYY-MM-DD HH:mm:ss")}
                    </div>
                    <div>
                      {log.action.userName
                        ? log.action.userName
                        : log.action.appId}
                    </div>
                    <div>
                      {log.action.ResponseStatusCode}-
                      {log.action.ResponseStatus}
                    </div>
                    <div>
                      <FontAwesomeIcon
                        icon={faFile}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          this.showLogDetail(log);
                        }}
                      />
                    </div>
                    <div></div>
                  </TableRow>
                </Fragment>
              );
            })}
          </List>
        </div>
        <Modal
          show={this.state.logDetailShow}
          size="xl"
          onHide={() => this.hideLogDetail()}
        >
          <ModalHeader>
            <ModalTitle>Event Log Entry</ModalTitle>
            <Button
              style={{ float: "right" }}
              onClick={() => this.hideLogDetail()}
            >
              Close
            </Button>
          </ModalHeader>
          <ModalBody>
            <ReactJson src={this.state.logDetail} theme="ocean" />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default AuditLogTable;
