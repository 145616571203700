import React, { Component } from "react";
import axios from "../../../infra/axios";
import {
  FormLabel,
  FormControl,
  Button,
  Modal,
  ModalHeader,
  ModalTitle,
  ModalFooter,
  FormGroup,
  Dialog,
  Notification } from "@hai/ui-react";
import { Row, Col, ModalBody } from "reactstrap";

class HaivisionProductTypeAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showNotice:false,
      showWarningdlg: false,
      showProductTypeAdd: false,
      selectedProductType : props.selectedProductType,
      title : props.selectedProductType === null?'Add Product Type':'Update Product Type',
      newProductType: {
        name: props.selectedProductType === null?'':props.selectedProductType.name,
        salesforceLibraryName: props.selectedProductType=== null?'':props.selectedProductType.salesforceLibraryName,
        salesforceLibrarySubfolder: props.selectedProductType=== null?'':props.selectedProductType.salesforceLibrarySubfolder,
        active: 'true',
      },
    };
  }


  componentWillReceiveProps(nextProps) {
    if(nextProps.selectedProductType !== this.props.selectedProductType)
    {
      this.setState((prev) => {
        prev.selectedProductType = nextProps.selectedProductType;
        prev.newProductType.name = nextProps.selectedProductType===null?'':nextProps.selectedProductType.name;
        prev.newProductType.salesforceLibraryName = nextProps.selectedProductType===null?'':nextProps.selectedProductType.salesforceLibraryName;
        prev.newProductType.salesforceLibrarySubfolder = nextProps.selectedProductType===null?'':nextProps.selectedProductType.salesforceLibrarySubfolder;
        prev.title = nextProps.selectedProductType===null?'Add Product Type':'Update Product Type';
        return prev;
      });
    }

    this.setState((prev) => {
      prev.showProductTypeAdd = nextProps.show;
      return prev;
    });
  }

  onHideHaivisionProductTypeAdd(){
    this.setState((prev) => {
      prev.selectedProductType = this.props.selectedProductType;
      prev.newProductType.name = this.props.selectedProductType===null?'':this.props.selectedProductType.name;
      prev.newProductType.salesforceLibraryName = this.props.selectedProductType===null?'':this.props.selectedProductType.salesforceLibraryName;
      prev.newProductType.salesforceLibrarySubfolder = this.props.selectedProductType===null?'':this.props.selectedProductType.salesforceLibrarySubfolder;
      prev.title = this.props.selectedProductType===null?'Add Product Type':'Update Product Type';
      prev.showNotice = false;
      prev.showWarningdlg = false;
      prev.showProductTypeAdd = false;
      return prev;
    });

    this.props.handleAddProductType();
  }

  SetShowWarningdlg (isShow) {
    this.setState((prev) => {
      prev.showWarningdlg = isShow;
      return prev;
    });   
  }

  SetShowNotice (isShow) {
    this.setState((prev) => {
      prev.showNotice = isShow;
      return prev;
    });
  }

  handleCloseNotice(){
    this.SetShowNotice(false);
  }

  onCancelDlg(){
    this.SetShowWarningdlg(false);
  }

  onConfirmDlg(){
    this.handleUpdate();  
  }

  handleUpdate(){
    var updateDto = {
      salesforceLibraryName:this.state.newProductType.salesforceLibraryName,
      salesforceLibrarySubfolder:this.state.newProductType.salesforceLibrarySubfolder,       
    }
    axios
    .put(
      process.env.REACT_APP_LIC_API_BASE_URL + "/Products/ProductTypes/" + 
      this.state.selectedProductType.id, updateDto
    )
    .then((response) => {
      this.SetShowNotice(true);
      this.props.handleAddProductType();
      this.props.getProductTypes();
      this.setState((prev) => {
        prev.newProductType = {
          name: "",
          salesforceLibraryName:'',
          salesforceLibrarySubfolder:'',
          active: "true",
        };
        return prev;
      });
    });   
  }

  submitProductTypeAdd() {
    if(this.state.selectedProductType===null){
        axios
          .post(
            process.env.REACT_APP_LIC_API_BASE_URL + "/Products/ProductTypes",
            this.state.newProductType
          )
          .then((response) => {
            this.props.handleAddProductType();
            this.props.getProductTypes();
            this.setState((prev) => {
              prev.newProductType = {
                name: "",
                salesforceLibraryName:'',
                salesforceLibrarySubfolder:'',
                active: "true",
              };
              return prev;
            });
          });
    }
    else{
      this.SetShowWarningdlg(true);
    }
  }

  render() {
    return (
      <div>
      <Modal show={this.state.showProductTypeAdd}>
        <ModalHeader>
          <ModalTitle>{this.state.title}</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <FormLabel>Product Type</FormLabel>
            <FormControl
              value={this.state.newProductType?.name}
              disabled={this.state.selectedProductType===null?false:true}
              onChange={(e) => {
                var text = e.target.value;
                this.setState((prev) => {
                  prev.newProductType.name = text;
                  return prev;
                });
              }}
            />
          </FormGroup>
          <FormGroup>
            <FormLabel>Salesforce Library Name</FormLabel>
            <FormControl
              value={this.state.newProductType?.salesforceLibraryName}
              onChange={(e) => {
                var text = e.target.value;
                this.setState((prev) => {
                  prev.newProductType.salesforceLibraryName = text;
                  return prev;
                });
              }}
            />
          </FormGroup>
          <FormGroup>
            <FormLabel>Salesforce Library Subfolder</FormLabel>
            <FormControl
              value={this.state.newProductType?.salesforceLibrarySubfolder}
              onChange={(e) => {
                var text = e.target.value;
                this.setState((prev) => {
                  prev.newProductType.salesforceLibrarySubfolder = text;
                  return prev;
                });
              }}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button variant="primary" onClick={() => this.submitProductTypeAdd()}>
            Submit
          </Button>
          <Button onClick={() => this.onHideHaivisionProductTypeAdd()}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Dialog
      dynamicResizing={{ minWidth: 100, maxWidth: 500, margin: 100 }}
      headline={'Warning'}
      bodyText={['This operation only update salesforce Library Name or salesforce subfolder name in Metadata,',
                  'if you want create Library or folder in salesforce download center, need to click Create Salesforce Download link',
                  'but it will not remove the library and subforder with the old name.',
                  'Are you sure you want to update it?']}
      onClose={()=>this.SetShowWarningdlg(false)}
      show={this.state.showWarningdlg}
      buttons={[
        { label: 'CANCEL',onClick: () => this.onCancelDlg() },
        { label: 'YES', variant: 'danger' ,onClick: () => this.onConfirmDlg()},
      ]}
      />
      <Notification
      delay={3000}
      position="upper-right"
      show={this.state.showNotice}
      description="Success"
      onDismiss={()=>this.handleCloseNotice()}
      message={'Update Successful.'}
      variant="Success"
    />
      </div>
    );
  }
}

export default HaivisionProductTypeAdd;
