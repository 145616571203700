import React from "react";
import {
  Button,
  Dialog,
  FormControl,
  FormGroup,
  FormLabel,
  List,
  ListHeader,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Notification,
  Switch,
  TableRow,
 ProgressBar } from "@hai/ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faMinusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Col, Collapse, Row } from "reactstrap";
import { useState } from "react";
import cogoToast from "cogo-toast";
import {
  enableBySubHttp,
  disableBySubHttp,
  disableGloballyHttp,
  enableGloballyHttp,
} from "./FeatureFlagService";
import FeatureFlagSubRow from "./FeatureFlagSubRow";
import SubscriptionSelect from "../subscriptions/SubscriptionSelect";

const FeatureFlagTableRow = (props) => {
  const ff = props.featureflag;
  const index = props.index;

  const [collapse, setCollapse] = useState(false);
  const toggleCollapse = () => setCollapse(!collapse);

  //Dialogs
  const [showGlobalDialog, setShowGlobalDialog] = useState(false);
  const [showSubDialog, setShowSubDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogGlobalTitle, setDialogGlobalTitle] = useState("");

  //add sub modal
  const [showAddSubModal, setShowAddSubModal] = useState(false);
  const [addSubModalTitle, setAddSubModalTitle] = useState("");
  const [checkedValue, setCheckedValue] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [subId, setSubId] = useState(null);

  //clicked item
  const [selectedSub, setSelectedSub] = useState(null);
  const [selectedFF, setSelectedFF] = useState(null);

  const getFeatureFlags = props.getFeatureFlags;

  const enableGlobally = () => {
    enableGloballyHttp(selectedFF.id, (response) => {
      cogoToast.success(selectedFF.id + " enabled globally.", {
        position: "top-right",
      });
      getFeatureFlags();
    });
  };

  const disableGlobally = () => {
    disableGloballyHttp(selectedFF.id, (response) => {
      cogoToast.success(selectedFF.id + " disabled globally.", {
        position: "top-right",
      });
      getFeatureFlags();
    });
  };

  const enableBySub = (ffId, subId) => {
    enableBySubHttp(ffId, subId, (response) => {
      setShowNotification(true);
      getFeatureFlags();
    });
  };

  const disableBySub = (ffId, subId) => {
    disableBySubHttp(ffId, subId, (response) => {
      setShowNotification(true);
      getFeatureFlags();
    });
  };

  const openSubDialog = (sub, ff) => {
    setSelectedFF(ff);
    setSelectedSub(sub);
    if (sub.flag) {
      setDialogTitle("Are you sure you want to disable the feature flag?");
    } else {
      setDialogTitle("Are you sure you want to enable the feature flag?");
    }
    setShowSubDialog(true);
  };

  const openGlobalDialog = (ff) => {
    setSelectedFF(ff);
    if (ff.flag) {
      setDialogGlobalTitle(
        "Are you sure you want to disable globally the feature flag?"
      );
    } else {
      setDialogGlobalTitle(
        "Are you sure you want to enable globally the feature flag?"
      );
    }
    setShowGlobalDialog(true);
  };

  const enableOrDisableGlobally = () => {
    if (selectedFF.flag) {
      disableGlobally();
    } else {
      enableGlobally();
    }
    setShowGlobalDialog(false);
  };
  const enableOrDisablebySub = () => {
    if (selectedSub.flag) {
      disableBySub(selectedFF.id, selectedSub.id);
    } else {
      enableBySub(selectedFF.id, selectedSub.id);
    }
    setShowSubDialog(false);
  };

  const showAddSub = (feature) => {
    setAddSubModalTitle("Add " + feature.id + " for Subscription");
    setSelectedFF(feature);
    setSubId(null);
    setSelectedSub(null);
    setCheckedValue(false);
    setShowAddSubModal(true);
  };
  const hideAddSub = () => {
    setAddSubModalTitle("");
    setSelectedFF("");
    setSelectedSub(null);
    setSubId(null);
    setCheckedValue(false);
    setShowAddSubModal(false);
  };

  const addSubFlag = () => {
    setSelectedSub({ id: subId, flag: checkedValue });
    if(subId) {

      if (checkedValue) {
        enableBySub(selectedFF.id, subId);
      } else {
        disableBySub(selectedFF.id, subId);
      }
    }
    hideAddSub();
  };

  return (
    <div>
      <TableRow
checkbox={false}
        style={{
          gridTemplateColumns: "0.3fr 2fr 2fr 0.5fr 0.5fr 0.5fr 0.2fr 0.2fr",
          color: "white",
          textalign: "center",
        }}
        alternateRowColors={index % 2 == 0}
      >
        <div></div>
        <div>{ff.id}</div>
        <div>{ff.description}</div>
        <div>
          <label>
            <FontAwesomeIcon
              style={{ cursor: "pointer" }}
              icon={ff.flag ? faCheckCircle : faMinusCircle}
              color={ff.flag ? "green" : "red"}
              onClick={() => openGlobalDialog(ff)}
            />
          </label>
        </div>
        <div>
          <label onClick={toggleCollapse} style={{ cursor: "pointer" }}>
            {ff.subscriptions ? ff.subscriptions.length : 0}
          </label>
        </div>
        <div>{ff.organizations ? ff.organizations.length : 0}</div>
      </TableRow>
      <Collapse isOpen={collapse}>
        <div
          style={{
            padding: "1%",
            border: "1px #007bff groove",
            borderRadius: "5px",
            marginTop: "5px",
          }}
        >
          <label>
            Subscriptions (Total:
            {ff.subscriptions ? ff.subscriptions.length : 0})
          </label>
          <Button variant="primary" onClick={() => showAddSub(ff)}>
            Add Subscription
          </Button>
          <hr />
          <List>
            <ListHeader
checkbox={false}
              style={{
                gridTemplateColumns: "0.3fr 2fr 2fr 2fr 0.5fr",
                color: "white",
                textalign: "center",
              }}
            >
              <div></div>
              <div>Sub Id</div>
              <div>Sub Name</div>
              <div>Flag</div>
              <div></div>
              </ListHeader>
            {ff.subscriptions
              ? ff.subscriptions.map((sub, sub_index) => {
                  return (
                    <FeatureFlagSubRow
                      sub={sub}
                      key={sub_index}
                      ff={ff}
                      openSubDialog={openSubDialog}
                    ></FeatureFlagSubRow>
                  );
                })
              : null}
          </List>
        </div>
      </Collapse>

      <Dialog
        headline={dialogTitle}
        show={showSubDialog}
        buttons={[
          {
            label: "Yes",
            variant: 'primary',
            onClick: () => enableOrDisablebySub(),
          },
          { label: "No", onClick: () => setShowSubDialog(false) },
        ]}
      />

      <Dialog
        headline={dialogGlobalTitle}
        show={showGlobalDialog}
        buttons={[
          {
            label: "Yes",
            variant: 'primary',
            onClick: () => enableOrDisableGlobally(),
          },
          { label: "No", onClick: () => setShowGlobalDialog(false) },
        ]}
      />

      <Modal show={showAddSubModal} onHide={() => hideAddSub()} size="xl">
        <ModalHeader>
          <ModalTitle>{addSubModalTitle}</ModalTitle>
        </ModalHeader>
        <ModalBody style={{ textalign: "center" }}>
          <FormGroup>
            <Row>
              <Col>
                <FormLabel>Subscription Id</FormLabel>
                <SubscriptionSelect
                  onChange={(sub) => setSubId(sub.value)}
                  placeholder="Select Subscription"
                ></SubscriptionSelect>
              </Col>
              <Col>
                <FormLabel>Flag</FormLabel>
                <Switch
                  className={FormControl}
                  onChange={() => setCheckedValue(!checkedValue)}
                  checked={checkedValue}
                />
              </Col>
            </Row>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button variant="primary" onClick={() => addSubFlag()}>
            Submit
          </Button>
          <Button variant="secondary" onClick={() => hideAddSub()}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Notification
        style={{ top: "1rem", right: "1rem" }}
        show={showNotification}
        description="Success."
        message="Update succeeded."
        autohide={true}
        variant="Success"
        onDismiss={() => setShowNotification(false)}
      />
    </div>
  );
};

export default FeatureFlagTableRow;
