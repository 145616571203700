import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { CreateHaiThemeContext, ThemeNames , ProgressBar } from "@hai/ui-react";
import { ThemeProvider } from "styled-components";
import "./App.scss";
import Layout from "./components/common/Layout";
import { UserProvider } from "./components/common/userContext";
import { AuthenticationState } from "react-aad-msal";
import { authProvider } from "./infra/authProvider";
import axios from "axios";
import NeedAuthenticate_Shark from "./components/common/Exceptions/NeedAuthenticate_Shark";
import JellyfishLogin from "./components/JellyfishLogin";

const ThemeContext = CreateHaiThemeContext(ThemeNames.ARGON);

const App = (props) => {
  const haiThemeContextState = useContext(ThemeContext);

  const [accountInfo, setAccountInfo] = useState({
    userName: "",
    name: "",
    authState: AuthenticationState.Unauthenticated,
    accessToken: "",
    roles: [],
  });

  useEffect(() => {
    if (authProvider.getAccount()) {
      setAccountInfo({
        userName: authProvider.getAccount().userName,
        name: authProvider.getAccount().name,
        authState: "InProgress",
        accessToken: "",
        roles: [],
      });
      // get user auth info
      authProvider.getAccessToken().then((result) => {
        axios
          .get(
            process.env.REACT_APP_JELLYFISH_API_BASE_URL + "/User/current-user",
            {
              headers: {
                "Cache-Control": "no-cache",
              },
            }
          )
          .then((response) => {
            if (!response.data.roles || response.data.roles.length === 0) {
              return <NeedAuthenticate_Shark />;
            } else {
              setAccountInfo({
                userName: authProvider.getAccount().userName,
                name: authProvider.getAccount().name,
                authState: AuthenticationState.Authenticated,
                accessToken: result.accessToken,
                roles: response.data.roles,
              });
            }
          });
      });
    }
  }, []);

  return (<div>
    {accountInfo.authState == AuthenticationState.Unauthenticated && 
    <JellyfishLogin></JellyfishLogin>} 
    {accountInfo.authState == AuthenticationState.Authenticated && 
    <ThemeProvider theme={haiThemeContextState}>
      <ThemeContext.Provider value={"argon"}>
        <UserProvider
          value={{
            username: accountInfo.userName,
            roles: accountInfo.roles,
            displayName: accountInfo.name,
            authState: accountInfo.authState,
            accessToken: accountInfo.accessToken,
          }}
        >
          <Layout></Layout>
        </UserProvider>
      </ThemeContext.Provider>
    </ThemeProvider>}
    </div>);
};

export default App;
