import { Button, Header, List, Modal, ModalBody, ModalFooter, ModalHeader, Pagination, ProgressBar } from "@hai/ui-react";
import React, { useState, useEffect, useCallback } from "react";
import axios from "../../../infra/axios";
import { useParams } from "react-router-dom";
import moment from "moment";
import cogoToast from "cogo-toast";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import SelectUUIDBox from "./SelectUUIDBox";
import { Col, Row } from "reactstrap";
import CreateAllProducts from "./CreateAllProducts";

const AWBenchProducts = (props) => {
  var params = useParams();
  const [products, setProducts] = useState(null);
  const [loading, setLoading] = useState(true);
  const [visibleData, setVisibleData] = useState(null);
  const [pageData, setPageData] = useState({
    currentPage: params?.pageNum ? parseInt(params?.pageNum) : 1,
    pageSize: 12
  });
  const [openCreateModal, setOpenCreateModal] = useState(false);

  useEffect(() => {
    getAwProducts(pageData.currentPage, pageData.pageSize);
  }, []);

  const getAwProducts = (currentPage, size) => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_PRODUCTION_API_BASE_URL}/Legacy/AwProducts/page/${currentPage}/pageSize/${size}`)
      .then((res) => {
        setProducts(res.data);
        setVisibleData(res.data.data);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  };

  return (
    <>
      <Header title="AwProducts" style={{ marginTop: '1%' }} />
      <br />
      {loading && (
        <ProgressBar
          leftLabel="Loading: "
          barColors={{
            background: "#599bff",
            right: "#599bff",
            left: "#2fccd3",
          }}
          hideRight={true}
          animate={1}
        />
      )}


      {/* CREATE BUTTON */}
      <Row><Col><Button onClick={() => setOpenCreateModal(true)}>Create</Button></Col></Row>
      <SelectUUIDBox label={'Search'} onChange={i => window.open(`${window.location.origin}/products/awbench/uuid/${i[0].uuid}`, '_blank')}></SelectUUIDBox>

      <div>

        {/* LIST VIEW */}
        {!loading && visibleData &&
          visibleData.map((a) => {
            return (
              <List key={a.id}>
                <List.Panel panelColor="haiui-green-01">
                  <List.PanelTitle title={a.serial ?? 'No serial'} />
                  <List.PanelDetail>
                    <List.PanelDetailItem icon="Information" text={"Test: " + moment(a.dateTest).format('MM/DD/YYYY HH:mm:sss')} divider="vertical" label="Test date"></List.PanelDetailItem>
                    <List.PanelDetailItem icon="HubNodes" text={"UUDI: " + a.uuid} divider="vertical" label="UUID"></List.PanelDetailItem>
                  </List.PanelDetail>

                  <List.Actions>
                    <List.ActionItem isDivider />
                    <List.ActionItem eventKey={"showDevices"} icon='Expand' title="More"
                      onSelect={(eventKey, expanded) => window.open(`${window.location.origin}/products/awbench/uuid/${a.uuid}`, '_blank')}></List.ActionItem>
                  </List.Actions>
                </List.Panel>
              </List>
            )
          })
        }
      </div>

      <Pagination
        pageCount={products?.totalPages}
        currentPage={pageData.currentPage}
        onPageChange={(newPage, previousPage) => {
          if (products.hasNextPage || newPage <= products?.totalPages) {
            setPageData({ currentPage: newPage, pageSize: pageData.pageSize });
            getAwProducts(newPage, pageData.pageSize);
          } else {
            cogoToast.warn("No next page", { position: 'top-right' });
          }

        }}
      />
      <Modal show={openCreateModal} size="lg">
        <ModalHeader>Create Product</ModalHeader>
        <ModalBody>
          <CreateAllProducts></CreateAllProducts>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="primary"
            onClick={() => setOpenCreateModal(false)}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AWBenchProducts;
