import React, {useState, useEffect} from 'react'
import { Header, 
   Button,
  Icon,
  List,
  ListHeader,
  TableRow,
  FormGroup,
  FormLabel,
  FormControl, 
  ProgressBar,
  Pagination,
  Spinner} from "@hai/ui-react";
  import { Col, Row } from "reactstrap";
  import axios from "../../../infra/axios";
  import { useNavigate, useParams } from "react-router-dom";

const HaivisionWorkOrderDetail = (props) =>{

  let {workOrderId} = useParams();
  const navigate = useNavigate();
    const [workOrderNumber, setWorkOrderNumber] = useState(props.workOrderId || workOrderId);
    const [units, SetUnits] = useState([]);
    const [workOrder, SetWorkOrder] = useState(null);
    const [btnVisible, setBtnVisible] = useState(true);
    const [loading, setLoading] = useState(false);
    const [currentPage, SetCurrentPage] = useState(1);
    const [totalPage, SetTotalPage] = useState(0);
    const [unitPage, SetUnitPage] = useState([]);
    const pageCount = 10;


    useEffect(() => {
      getWorkOrderByWorkOrderNumber();
  }, []);


  const getWorkOrderByWorkOrderNumber = () => {

    setLoading(true);
    axios
    .get(
    process.env.REACT_APP_PRODUCTION_API_BASE_URL +
        "/Legacy/WorkOrders/workOrderNumber/" + workOrderNumber
    )
    .then((response) => {
        if(response.data){
            const wo = response.data;
            SetWorkOrder(wo);
            getUnitsOfWorkOrders();
        }
    });    
};


const getUnitsOfWorkOrders = () => {

      setLoading(true);
      axios
      .get(
      process.env.REACT_APP_PRODUCTION_API_BASE_URL +
          "/Legacy/workOrders/workOrderNumber/" + workOrderNumber + "/Units"
      )
      .then((response) => {
          if(response.data){
              const units = response.data;
              SetUnits(units);

              SetUnitPage (units?.slice(0, pageCount));
              let total = units?.length % pageCount === 0? Math.floor(units?.length / pageCount) : Math.floor(units?.length / pageCount) + 1;          
              SetTotalPage(total);

              var lackOfMac = units.filter((unit)=>unit.macAddress === '');
              if(lackOfMac.length === units.length){
                setBtnVisible(false);
              } 
              else{
                setBtnVisible(true);
              }                        
          }
          setLoading(false);
      });    
  };

  const AssignMacAddressToUnits= () =>{
    setLoading(true);
    axios
    .post(
    process.env.REACT_APP_PRODUCTION_API_BASE_URL +
        "/Legacy/workOrders/workOrderNumber/" + workOrderNumber + "/Units/AssignMacAddress"
    )
    .then((response) => {
        if(response.data){
            const units = response.data;
            SetUnits(units);
            var lackOfMac = units.filter((unit)=>unit.macAddress === '');
            if(lackOfMac.length === units.length){
              setBtnVisible(false);
            }   
            else{
              setBtnVisible(true);
            }        
        }
        setLoading(false);
    }); 
  }

    return (
      <div>
      { loading && <ProgressBar
          leftLabel="Loading: "
          barColors={{
              background: '#599bff',
              right: '#599bff',
              left: '#2fccd3',
          }}
          hideRight={true}
          animate={1}
      />}
      {!loading && <div style={{marginTop: "1%" }}>
      <Button onClick={() => navigate("/products/workorder")}> Back</Button>
      <Header title="Work Order" />
          <Row>
              <Col>
                <FormLabel>Work Order Number</FormLabel>
                <FormControl
                  defaultValue={workOrder?.workOrderNumber}
                  disabled={true}
                />               
              </Col>
              <Col>
                <FormLabel>Work Order Factory Name</FormLabel>
                <FormControl
                  defaultValue={workOrder?.workOrderFactoryName}
                  disabled={true}
                />               
              </Col>
          </Row>
          <Row>
              <Col>
                <FormLabel>Serial Number First</FormLabel>
                <FormControl
                  defaultValue={workOrder?.workOrderFirstSerialNumber}
                  disabled={true}
                />           
              </Col>
              <Col>
                <FormLabel>Serial Number Last</FormLabel>
                <FormControl
                  defaultValue={workOrder?.workOrderLastSerialNumber}
                  disabled={true}
                />               
              </Col>
          </Row>
          <Row>
              <Col>
                <FormLabel>Comments</FormLabel>
                <FormControl
                  as="textarea"
                  defaultValue={workOrder?.comments}
                  disabled={true}
                />           
              </Col>
          </Row>
          <br/>
          <br/>
          <Header title="Units of Work Orders" />
          <br/>
          <List>
            <ListHeader
              checkbox={false}
              style={{
                gridTemplateColumns:
                  "1fr 1fr 2fr 1fr",
              }}
            >
              <div>Model</div>
              <div>Serial Number</div>
              <div>Mac Address</div>
              <div>Date Created</div>
            </ListHeader>
            {unitPage &&
              unitPage.map((unit) => {
                return (
                  <div key={unit.id}>
                    <TableRow
                    checkbox={false}
                      style={{
                        gridTemplateColumns:
                          "1fr 1fr 2fr 1fr",
                      }}
                    >
                    <div>{unit.model}</div>
                    <div>{unit.serialNumber}</div>
                    <div>{unit.macAddress}</div>
                    <div>{new Date(unit.dateCreated).toUTCString()}</div>
                    </TableRow>
                  </div>
                );
              })}
          </List>
          <br/>
{/*           <Button variant="primary"  hidden={btnVisible} onClick={() => AssignMacAddressToUnits()}>
            Assign Mac Address to units
          </Button>     
          <br/> */}
        
          <Pagination
          pageCount={totalPage}
          currentPage={currentPage}
          onPageChange={(newPage, previousPage) => {
            //alert(`New page: ${newPage}  Previous Page: ${previousPage}`);
            SetUnitPage (units.slice((newPage - 1) * pageCount, newPage * pageCount));
            SetCurrentPage(newPage);
            }
          }
        />
          <br/>
        </div>}
        </div>
        
      );

}

export default HaivisionWorkOrderDetail;