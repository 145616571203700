import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";

const SlotsAvailable = (props) => {
  const [slotsAvailable, setSlotsAvailable] = useState(null);

  useEffect(() => {
    getSlotsAvailable();
  }, []);

  const getSlotsAvailable = async () => {
    let url;
    if (props.app === "connect") {
      url =
        process.env.REACT_APP_CONNECT_API_BASE_URL +
        "/ConnectOperation/client-subscription/slots";
    } else {
      url =
        process.env.REACT_APP_HUB_API_BASE_URL +
        "/Operations/client-subscription/slots";
    }
    await axios.get(url).then((response) => {
      setSlotsAvailable(response.data);
    });
  };

  return (
    slotsAvailable != null && (
      <div>
        <p style={{ color: "#007bff", margin: "auto" }}>
          Slots Available: {slotsAvailable}. Add more{" "}
          <a
            style={{ color: "#007bff" }}
            href="https://haivision.jira.com/wiki/spaces/JEL/pages/2818900952/Creating+Subscription+Slots"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
          .
        </p>
      </div>
    )
  );
};

SlotsAvailable.propTypes = {
  app: PropTypes.string.isRequired,
};

export default SlotsAvailable;
