import React, { Component } from "react";
import axios from "../../../infra/axios";
import {
  OverlayTrigger,
  List,
  ListHeader,
  TableRow,
  Animation,
  Icon,
  FormLabel,
  FormControl,
  Button,
  Modal,
  ModalHeader,
  ModalTitle,
  ModalFooter,
  FormGroup,
 ProgressBar } from "@hai/ui-react";
import { Row, Col, ModalBody } from "reactstrap";

class MakitoProductLicenseOptionUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showProductLicenseOptionUpdate: false,
      newProduceLicenseOption: this.props.productLicenseOption,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState((prev) => {
      prev.showProductLicenseOptionUpdate = nextProps.show;
      prev.newProduceLicenseOption = nextProps.productLicenseOption;
      return prev;
    });
  }

  submitProductLicenseOptionUpdate() {
    axios
      .put(
        process.env.REACT_APP_LIC_API_BASE_URL +
          "/Products/ProductVersions/ProductLicenseOptions/" +
          this.state.newProduceLicenseOption.id,
        this.state.newProduceLicenseOption
      )
      .then((response) => {
        this.props.handleProductLicenseOptionUpdate();
        this.props.getVersion();
        this.setState((prev) => {
          prev.newProduceLicenseOption = {
            licenseSection: "",
            licenseSectionItem: "",
            description: "",
            valueName: "",
            defaultValue: "",
            availableValues: "",
          };
          return prev;
        });
      });
  }

  render() {
    return (
      <>
        {this.state.newProduceLicenseOption && (
          <Modal show={this.state.showProductLicenseOptionUpdate}>
            <ModalHeader>
              <ModalTitle>Update Product Licnese Option</ModalTitle>
            </ModalHeader>
            <ModalBody>
              <FormGroup>
                <FormLabel>License Section</FormLabel>
                <FormControl
                  defaultValue={
                    this.state.newProduceLicenseOption.licenseSection
                  }
                  onChange={(e) => {
                    var text = e.target.value;
                    this.setState((prev) => {
                      prev.newProduceLicenseOption.licenseSection = text;
                      return prev;
                    });
                  }}
                />
                <FormLabel>License Section Item</FormLabel>
                <FormControl
                  defaultValue={
                    this.state.newProduceLicenseOption.licenseSectionItem
                  }
                  onChange={(e) => {
                    var text = e.target.value.trim();
                    this.setState((prev) => {
                      prev.newProduceLicenseOption.licenseSectionItem = text;
                      return prev;
                    });
                  }}
                />
                <FormLabel>Description</FormLabel>
                <FormControl
                  defaultValue={this.state.newProduceLicenseOption.description}
                  onChange={(e) => {
                    var text = e.target.value.trim();
                    this.setState((prev) => {
                      prev.newProduceLicenseOption.description = text;
                      return prev;
                    });
                  }}
                />
                <FormLabel>Display Name</FormLabel>
                <FormControl
                  defaultValue={this.state.newProduceLicenseOption.valueName}
                  onChange={(e) => {
                    var text = e.target.value.trim();
                    this.setState((prev) => {
                      prev.newProduceLicenseOption.valueName = text;
                      return prev;
                    });
                  }}
                />
                <FormLabel>Available Values</FormLabel>
                <FormControl
                  defaultValue={
                    this.state.newProduceLicenseOption.availableValues
                  }
                  onChange={(e) => {
                    var text = e.target.value.trim();
                    this.setState((prev) => {
                      prev.newProduceLicenseOption.availableValues = text;
                      return prev;
                    });
                  }}
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button
                variant="primary"
                onClick={() => this.submitProductLicenseOptionUpdate()}
              >
                Submit
              </Button>
              <Button
                onClick={() => this.props.handleProductLicenseOptionUpdate()}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </>
    );
  }
}

export default MakitoProductLicenseOptionUpdate;
