import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, FormControl, FormGroup, Icon, IconCard , ProgressBar } from "@hai/ui-react";
import axios from "axios";
import React, { Component } from "react";

class RevealKeyvaultSecrets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recordId: this.props.recordId,
      secretValue: "",
      buttonShow: true,
    };
  }

  componentDidMount() {}

  async retrieveSecret() {
    await axios
      .get(
        process.env.REACT_APP_HUB_API_BASE_URL +
          "/HubApiCredentials/secrets/" +
          this.state.recordId
      )
      .then((response) => {
        this.setState((prev) => {
          prev.secretValue = response.data;
          return prev;
        });
      })
      .catch((err) => {
        this.setState((prev) => {
          prev.secretValue = err.data;
          return prev;
        });
      });
  }

  render() {
    if (this.state.buttonShow) {
      return (
        <div>
          <Button
            onClick={() => {
              this.retrieveSecret();
              this.setState((prev) => {
                prev.buttonShow = false;
                return prev;
              });
            }}
          >
            Click to Reveal
          </Button>
        </div>
      );
    } else {
      return <>{this.state.secretValue}</>;
    }
  }
}

export default RevealKeyvaultSecrets;
