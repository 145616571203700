import {
  Animation,
  FormControl,
  FormGroup,
  FormLabel,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  SelectOption,
  Spinner,
  ModalTitle,
  ProgressBar
} from "@hai/ui-react";
import axios from "../../../infra/axios";
import React, { Component } from "react";

class HaivisionSetProductDefaultValueByProductType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: props.show,
      loading: false,
      productTypeList: props.productTypeList,
      versionList: null,
      productList: props.productList,
      productOptionDatas: [],
      selectedProductType: null,
      selectedVersion: null,
      selectedProductOptionData: null,
      changeGeneratdLicenses: false,
      showOption: false,
      defaultValue: "",
      newDefaultLicenseOption: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState((prev) => {
      prev.show = nextProps.show;
      prev.selectedProductType = prev.productTypeList[0];
      return prev;
    });
    this.setMaxVersion(this.state.productTypeList[0].id);
  }

  // getProductTypes = () => {
  //   this.setState((prev) => {
  //     prev.loading = true;
  //     return prev;
  //   });
  //   const requestProducts = axios.get(
  //     process.env.REACT_APP_LIC_API_BASE_URL + "/Products"
  //   );
  //   const requestProductTypes = axios.get(
  //     process.env.REACT_APP_LIC_API_BASE_URL + "/Products/ProductTypes"
  //   );
  //   axios.all([requestProducts, requestProductTypes]).then(
  //     axios.spread((...responses) => {
  //       this.setState(
  //         (prev) => {
  //           prev.productList = responses[0].data;
  //           prev.productTypeList = responses[1].data;
  //           prev.selectedProductType = prev.productTypeList[0];
  //           prev.loading = false;
  //           return prev;
  //         },
  //         () => {
  //           this.setMaxVersion(this.state.productTypeList[0].id);
  //         }
  //       );
  //     })
  //   );
  // };

  composeSubmitData() {
    var products = this.state.productList.filter(
      (x) => x.productTypeId == this.state.selectedProductType.id
    );

    products.forEach((product) => {
      var productDefaultOptions = product.productDefaultOptions.find(
        (x) =>
          x.productLicenseOptionId ===
          this.state.selectedProductOptionData.id &&
          x.productVersionId === this.state.selectedVersion.id
      );
      if (productDefaultOptions != null && productDefaultOptions != undefined) {
        this.state.newDefaultLicenseOption.push({
          item: this.state.selectedProductOptionData.licenseSectionItem,
          defaultValue: this.state.defaultValue,
          ProductVersionId: this.state.selectedVersion.id,
          productLicenseOptionId: this.state.selectedProductOptionData.id,
          id: productDefaultOptions.id,
        });
      }
    });
  }

  submit() {
    this.composeSubmitData();

    axios
      .post(
        process.env.REACT_APP_LIC_API_BASE_URL +
        "/Products/ProductDefaultOptions/bulkUpdateProductDefaultOptions",
        this.state.newDefaultLicenseOption
      )
      .then((reponse) => {
        this.clearData();
        this.props.handleAddProduct();
      });
  }

  clearData() {
    this.setState((prev) => {
      prev.show = false;
      prev.productTypeList = null;
      prev.versionList = null;
      prev.productList = null;
      prev.productOptionDatas = [];
      prev.selectedProductType = null;
      prev.selectedProductOptionData = null;
      prev.selectedVersion = null;
      prev.showOption = false;
      prev.defaultValue = "";
      prev.newDefaultLicenseOption = [];
      return prev;
    });
  }

  setProductOptionDatas(productOptionDatas) {
    this.setState(
      (prev) => {
        prev.productOptionDatas = productOptionDatas || [];
        return prev;
      },
      () => {
        this.setSelectProductOptionData(this.state.productOptionDatas[0]);
      }
    );
  }

  setSelectProductOptionData(productOptionData) {
    this.setState((prev) => {
      prev.selectedProductOptionData = productOptionData;
      return prev;
    });
  }

  onChangeProductType(e) {
    var productTypeId = e.target.key;
    this.setState(
      (prev) => {
        prev.selectedProductType = productTypeId;
        return prev;
      },
      () => {
        this.setMaxVersion(productTypeId);
      }
    );
  }

  setMaxVersion(productTypeId) {
    if (productTypeId === undefined || productTypeId === "") {
      return;
    }
    var productType = this.state.productTypeList.find(
      (x) => x.id === productTypeId
    );
    var versionList = productType.productVersions.sort((version1, version2) => {
      if (version1.majorVersion === version2.majorVersion) {
        if (version1.minorVersion === version2.minorVersion) {
          if (version1.patchVersion === version2.patchVersion) {
            // two same version, impossible
          } else if (version1.patchVersion > version2.patchVersion) {
            return -1;
          } else {
            return 1;
          }
        } else if (version1.minorVersion > version2.minorVersion) {
          return -1;
        } else {
          return 1;
        }
      } else if (version1.majorVersion > version2.majorVersion) {
        return -1;
      } else {
        return 1;
      }
    });
    this.setState(
      (prev) => {
        prev.versionList = versionList;
        prev.selectedVersion = versionList[0];
        prev.productOptionDatas = versionList[0].productLicenseOptions || [];
        prev.selectedProductOption = versionList[0].productLicenseOptions[0];
        return prev;
      },
      () => {
        this.onSelectOption(this.state.selectedProductOption.description);
      }
    );
  }

  submitProductLicenseOptionUpdate() {
    if (this.state.formError !== "") {
      return;
    }

    axios
      .post(
        process.env.REACT_APP_LIC_API_BASE_URL +
        "/Products/" +
        this.state.newDefaultLicenseOption.productId +
        "/ProductDefaultOptions/" +
        this.state.newDefaultLicenseOption.productLicenseOptionId,
        this.state.newDefaultLicenseOption
      )
      .then((response) => {
        this.props.getProduct();
        this.setState((prev) => {
          prev.newProduceLicenseOption = {
            licenseSection: "",
            licenseSectionItem: "",
            description: "",
            valueName: "",
            defaultValue: "",
            availableValues: "",
          };

          prev.newDefaultLicenseOption = {
            item: "",
            defaultValue: "",
            productId: "",
            productLicenseOptionId: "",
          };
          prev.formError = "";
          return prev;
        });
      });
  }

  isShowOption(availableValues) {
    if (
      availableValues.substring(0, 1) === "^" &&
      availableValues.substring(availableValues.length - 1) === "$"
    ) {
      return false;
    } else {
      return true;
    }
  }

  onSelectOption(desc) {
    if (
      desc === undefined ||
      desc === null ||
      this.state.productOptionDatas === undefined ||
      this.state.productOptionDatas === null
    ) {
      return;
    }

    var selectedProductOption = Array.isArray(this.state.productOptionDatas)
      ? this.state.productOptionDatas.find(
        (x) => x.description.toLowerCase() === desc.toLowerCase()
      )
      : null;

    if (selectedProductOption === null) {
      return;
    }

    var showOption = this.isShowOption(selectedProductOption?.availableValues);

    this.setState((prev) => {
      prev.selectedProductOptionData = selectedProductOption;
      prev.showOption = showOption;
      return prev;
    });
  }

  showValueComponent = () => {
    return (
      <div>
        {!this.state.showOption && (
          <FormControl
            value={this.state.selectedProductOptionData?.value}
            onChange={(e) => {
              var text = e.target.value;
              this.setState((prev) => {
                prev.defaultValue.value = text;
                return prev;
              });
            }}
          ></FormControl>
        )}
        {this.state.showOption && (
          <FormControl
            as="select"
            value={this.state.selectedProductOptionData?.value}
            onChange={(e) => {
              var text = e.target.value;
              this.setState((prev) => {
                prev.defaultValue = text;
                return prev;
              });
            }}
          >
            {this.state.selectedProductOptionData.availableValues
              .split(";")
              .map((o) => {
                var value = o.split("!")[0];
                return <SelectOption key={o.id}>{value}</SelectOption>;
              })}
          </FormControl>
        )}
      </div>
    );
  };

  render() {
    if (this.state.show && this.state.loading) {
      return (
        <Modal show={this.state.show}>
          <ModalHeader>
            <ModalTitle>Set Product default value by product type</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <ProgressBar
              leftLabel="Loading: "
              barColors={{
                background: '#599bff',
                right: '#599bff',
                left: '#2fccd3',
              }}
              hideRight={true}
              animate={1}
            />
          </ModalBody>
        </Modal>
      );
    } else {
      return (
        <Modal show={this.state.show}>
          <ModalHeader>
            <ModalTitle>Set Product default value by product type</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <FormLabel>Product Type</FormLabel>
              <FormControl
                as="select"
                value={this.state.selectedProductType?.name}
                onChange={(e) => {
                  var text = e.target.value;
                  this.setState(
                    (prev) => {
                      prev.selectedProductType =
                        this.state.productTypeList.find((x) => x.name === text);
                      return prev;
                    },
                    () => {
                      this.setMaxVersion(this.state.selectedProductType.id);
                    }
                  );
                }}
              >
                {this.state.productTypeList &&
                  this.state.productTypeList.map((p) => {
                    return <SelectOption key={p.id}>{p.name}</SelectOption>;
                  })}
              </FormControl>
              <FormLabel>Product Version</FormLabel>
              <FormControl
                as="select"
                defaultValue={
                  this.state.selectedVersion?.majorVersion.majorVersion +
                  "." +
                  this.state.selectedVersion?.majorVersion.minorVersion +
                  "." +
                  this.state.selectedVersion?.majorVersion.patchVersion
                }
                onChange={(e) => {
                  var text = e.target.value;
                  var selectedVersionTmp = this.state.versionList.find(
                    (x) =>
                      text ===
                      x.majorVersion +
                      "." +
                      x.minorVersion +
                      "." +
                      x.patchVersion
                  );
                  this.setState(
                    (prev) => {
                      prev.selectedVersion = selectedVersionTmp;
                      return prev;
                    },
                    () => {
                      this.setProductOptionDatas(
                        this.state.selectedVersion.productLicenseOptions
                      );
                    }
                  );
                }}
              >
                {this.state.versionList &&
                  this.state.versionList.map((p) => {
                    return (
                      <SelectOption key={p.id}>
                        {p.majorVersion +
                          "." +
                          p.minorVersion +
                          "." +
                          p.patchVersion}
                      </SelectOption>
                    );
                  })}
              </FormControl>
              <FormLabel>Product option data</FormLabel>
              <FormControl
                as="select"
                value={this.state.selectedProductOptionData?.description}
                onChange={(e) => {
                  var text = e.target.value;
                  this.onSelectOption(text);
                }}
              >
                {this.state.productOptionDatas &&
                  this.state.productOptionDatas?.length > 0 &&
                  this.state.productOptionDatas.map((p) => {
                    return <SelectOption key={p.id}>{p.description}</SelectOption>;
                  })}
              </FormControl>
              {this.showValueComponent()}
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button variant="primary" onClick={() => this.submit()}>
              Submit
            </Button>
            <Button onClick={() => this.props.handleAddProduct()}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      );
    }
  }
}

export default HaivisionSetProductDefaultValueByProductType;
