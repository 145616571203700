import React, { useEffect, useState } from "react";
import {
  FormGroup,
  FormLabel,
  Form,
  LabelValue,
 ProgressBar, 
 Header} from "@hai/ui-react";
import { Row, Col, Spinner, Container } from "reactstrap";
import axios from "../../../infra/axios";
import AssetsTable from "../contracts/AssetsTable";
import ContractItemsTable from "../contracts/ContractItemsTable";
import moment from "moment";
import { useParams } from "react-router-dom";

const ContractDetails = (props) => {
  const [contract, setContract] = useState(null);
  const [loading, setLoading] = useState(true);
  const [organization, setOrganization] = useState(null);
  const {idParam} = useParams();
  const [error, setError] = useState(null);
  

  useEffect(() => {
    getContracts();
  }, []);

  const getOrg = async (orgId) => {
    axios
      .get(process.env.REACT_APP_HUB_API_BASE_URL + `/SrtHub/organizations`)
      .then((response) => {
        let org = response.data.find(
          (org) => org.organizationId == orgId
        );
        if (org) {
          setOrganization(org)
        }
      });
  }

  const getContracts = async() => {
    axios
      .get(
        process.env.REACT_APP_HUB_API_BASE_URL +
          `/HubServiceContract/${idParam}`
      )
      .then((response) => {
        setContract(response.data);
        getOrg(response.data?.hubOrgId);
        setLoading(false);
      })
      .then(() => {
        axios
          .get(
            process.env.REACT_APP_HUB_API_BASE_URL +
              `/HubServiceContract/${idParam}/HubAssets`
          )
          .then((response_asset) => {
            setContract((prevState) => ({
              ...prevState,
              assets: response_asset.data}));
          });
        axios
          .get(
            process.env.REACT_APP_HUB_API_BASE_URL +
              `/HubServiceContract/${idParam}/HubContractLineItems`
          )
          .then((response_cli) => {
            setContract((prevState) => ({
              ...prevState,
              contractLineItems: response_cli.data}));
          });
      })
      .catch((error) => {
        setLoading(false);
        setError(true);
      });
  }

    if (loading && contract === null) {
      return (
        <div>
          <Row>
            <Col sm="12">
              {" "}
              {loading && (
                <ProgressBar
                leftLabel="Loading: "
                barColors={{
                  background: '#599bff',
                  right: '#599bff',
                  left: '#2fccd3',
                }}
                hideRight={true}
                animate={1}
              />
              )}
            </Col>
          </Row>
        </div>
      );
    } else if (loading === false && contract === null) {
      return (
        <div>
          <h4>Invalid contract or not found. Please, go back.</h4>
        </div>
      );
    } else {
      return (
        <>
          <Row>
            <Col md="12">
              {" "}
              <Header
                title={"Contract " + contract?.name}
              ></Header>
            </Col>
          </Row>

          <Form >
            <Row>
              <Col>
                <FormLabel style={{ color: "#007bff" }}>Organization</FormLabel>
                {organization && (
                  <LabelValue>{organization.name}</LabelValue>
                )}
              </Col>
              <Col>
                <FormGroup>
                  <FormLabel style={{ color: "#007bff" }}>
                    Contract Name
                  </FormLabel>
                  <LabelValue>{contract?.name}</LabelValue>
                </FormGroup>
              </Col>

              <Col>
                <FormGroup>
                  <FormLabel style={{ color: "#007bff" }}>
                    Company Name
                  </FormLabel>
                  <LabelValue>{contract?.accountName}</LabelValue>
                </FormGroup>
              </Col>

              <Col>
                <FormGroup>
                  <FormLabel style={{ color: "#007bff" }}>Domain</FormLabel>
                  <LabelValue>{contract?.orgDomain}</LabelValue>
                </FormGroup>
              </Col>

              <Col>
                <FormGroup>
                  <FormLabel style={{ color: "#007bff" }}>Status</FormLabel>
                  <LabelValue>{contract?.status}</LabelValue>
                </FormGroup>
              </Col>

              <Col>
                <FormGroup>
                  <FormLabel style={{ color: "#007bff" }}>Start date</FormLabel>
                  <LabelValue>
                    {moment(contract?.startDate).format("MM/DD/YYYY")}
                  </LabelValue>
                </FormGroup>
              </Col>

              <Col>
                <FormGroup>
                  <FormLabel style={{ color: "#007bff" }}>End date</FormLabel>
                  <LabelValue>
                    {moment(contract?.endDate).format("MM/DD/YYYY")}
                  </LabelValue>
                </FormGroup>
              </Col>
            </Row>

            <hr className="hr-divider" />

            <Row>
              <Col>
                <FormLabel style={{ color: "#007bff" }}>Assets</FormLabel>
                {contract?.assets && (
                  <AssetsTable contract={contract}></AssetsTable>
                )}
              </Col>
            </Row>

            <hr className="hr-divider" />

            <Row>
              <Col>
                <FormLabel style={{ color: "#007bff" }}>Items</FormLabel>
                {contract?.contractLineItems && (
                  <ContractItemsTable
                    contractItems={contract?.contractLineItems}
                    isDetails={true}
                  ></ContractItemsTable>
                )}
              </Col>
            </Row>

            <hr className="hr-divider" />

            <Row>
              <FormLabel style={{ color: "#007bff" }}>Invoices</FormLabel>
            </Row>
          </Form>
        </>
      );
    }
}

export default ContractDetails;
