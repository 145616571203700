import React, { Component, Fragment } from "react";
import axios from "../../../infra/axios";
import {
  FormControl,
  FormLabel,
  Button,
  Pagination,
  List,
  ListHeader,
  TableRow,
  OverlayTrigger,
  Icon,
  SelectOption,
  ProgressBar,
  Header
} from "@hai/ui-react";
import { Col, Row } from "reactstrap";
import HaivisionLicenseAdd from "./haivision-license-add";
import HaivisionLicenseDelete from "./haivision-license-delete";
import { useParams } from "react-router-dom";
import cogoToast from "cogo-toast";


function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}


class HaivisionLicense extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      licenses: null,
      productTypes: null,
      showAdd: false,
      showDelete: false,
      deleteLicense: null,
      isLoadingLs: [],
      filterType: 'Serial Number',
      filterParam: null,
      filterParams: [],
      pageData: {
        currentPage: this.params?.pageNum ? parseInt(this.params?.pageNum) : 1,
        pageSize: 12
      },
      totalPages: 1
    };
    this.GetLicenses = this.GetLicenses.bind(this);
    this.handleCreateShow = this.handleCreateShow.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidMount() {
    this.GetLicenses();
  }

  async GetLicenses() {
    this.setState((prev) => {
      prev.loading = true;
      return prev;
    });

    const requestProducts = this.getLicensesPaginated(1, 12);
    const requestProductTypes = axios.get(
      process.env.REACT_APP_LIC_API_BASE_URL + "/Products/ProductTypes"
    );
    axios.all([requestProducts, requestProductTypes]).then(
      axios.spread((...responses) => {
        this.setState((prev) => {
          prev.licenses = responses[0].data?.data;
          prev.totalPages = responses[0].data?.totalPages;
          prev.productTypes = responses[1].data;
          prev.productTypes.unshift({ id: "All", name: "All" });
          prev.loading = false;
          return prev;
        });
      })
    );
  }

  getLicensesPaginated = (page, pageSize) => {
    const OrderBy = JSON.stringify([{'Id':'LastModifiedDate', 'Desc':true}]);
    return axios.get(
      process.env.REACT_APP_LIC_API_BASE_URL + `/Licenses/all?pageNumber=${page}&pageSize=${pageSize}&columnFilters=${JSON.stringify(this.state.filterParams)}&OrderBy=${OrderBy}`
    );
  }

  searchBySn = () => {
    this.setState({
      loading: true
    })
    const requestVersion = axios.get(
      process.env.REACT_APP_LIC_API_BASE_URL +
        "/Licenses/SerialNumber/" +
        this.state.filterParam
    ).then(response=>{
      let totalPageTmp = Math.ceil(response.data?.length / this.state.pageData.pageSize);
      if(totalPageTmp <= 0){
        totalPageTmp = 1;
      }
      this.setState({
        licenses: response.data,
        totalPages : totalPageTmp,
        loading: false,
        pageData: { currentPage: 1, pageSize: this.state.pageData.pageSize }
      })
    })
  }

  searchByMacAddress = () => {
    this.setState({
      loading: true
    })
    const requestVersion = axios.get(
      process.env.REACT_APP_LIC_API_BASE_URL +
        "/Licenses/macAddress/" +
        this.state.filterParam
    ).then(response=>{
      let totalPageTmp = Math.ceil(response.data?.length / this.state.pageData.pageSize);
      if(totalPageTmp <= 0){
        totalPageTmp = 1;
      }
      this.setState({
        licenses: response.data,
        totalPages : totalPageTmp,
        loading: false,
        pageData: { currentPage: 1, pageSize: this.state.pageData.pageSize }
      })
    })   
  }

  searchByFilter = () => {

    if(this.state.filterParam === "" || this.state.filterParam == undefined){
      this.GetLicenses();
      return;
    }

    if(this.state.filterType === "Serial Number"){
      this.searchBySn();
    }
    else{
      this.searchByMacAddress();
    }
  }

  setIsloadingLs(key, value) {
    this.setState((prev) => {
      prev.isLoadingLs[key] = value;
      return prev;
    });
  }

  handleCreateShow() {
    this.setState((prev) => {
      prev.showAdd = !prev.showAdd;
      return prev;
    });
  }

  handleDownload(lic) {
    this.setIsloadingLs(lic.id, true);
    axios
      .get(
        process.env.REACT_APP_LIC_API_BASE_URL +
        "/LicenseText/Licenses/" +
        lic.id
      )
      .then((response) => {
        const yourDate = new Date();
        var datastr = yourDate.toISOString().split("T")[0];
        var fileName = lic.serialNumber + "_" + datastr + ".lic";

        const type = response.headers["content-type"];
        const blob = new Blob([response.data], {
          type: type,
          encoding: "UTF-8",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
        this.setIsloadingLs(lic.id, false);
      });
  }

  handleMergeDownload(lic){
    this.setIsloadingLs(lic.id, true);
    axios
      .get(
        process.env.REACT_APP_LIC_API_BASE_URL +
        "/LicenseText/SerialNumber/" +
        lic.serialNumber + '/merge'
      )
      .then((response) => {
        const yourDate = new Date();
        var datastr = yourDate.toISOString().split("T")[0];
        var fileName = lic.serialNumber + "_" + datastr + "_merge.lic";

        const type = response.headers["content-type"];
        const blob = new Blob([response.data], {
          type: type,
          encoding: "UTF-8",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
        this.setIsloadingLs(lic.id, false);
      });
  }

  handleDetail(lic) {
    window.location = window.location + "/" + lic.id;
  }

  handleDelete(lic) {
    this.setState((prev) => {
      prev.showDelete = !prev.showDelete;
      prev.deleteLicense = prev.deleteLicense == null ? lic : null;
      return prev;
    });
  }

  render() {
    var SearchValueArray = ['Serial Number', 'Mac Addresse'];
    return (
      <Fragment>
        <br />
        <Header title="Haivision Licenses" />
        <hr />
        {this.state.loading && <ProgressBar
          leftLabel="Loading: "
          barColors={{
            background: '#599bff',
            right: '#599bff',
            left: '#2fccd3',
          }}
          hideRight={true}
          animate={1}
        />}
        {!this.state.loading && <div style={{ marginTop: "1%" }}>
          <Row style={{ alignItems: "flex-end" }}>
          <Col>
          <FormLabel>Filter by</FormLabel>
              <FormControl
                as="select"
                defaultValue={this.state.filterType}
                
                onChange={(e) => {
                  var text = e.target.value;
                  this.setState((prev) => {
                    prev.filterType = text;
                    return prev;
                  });
                }}
              >
                {SearchValueArray.map((p) => {
                  return <SelectOption value={p} key={p}>{p}</SelectOption>;
                })}
             </FormControl>
          </Col>
            <Col>
              <FormControl
                placeholder="Filter value"
                defaultValue={ this.state.filterParam}
                onChange={(e) => {
                  var text = e.target.value?.trim();
                  this.setState({ filterParam: text });
                }
                }
              />
            </Col>
            {/*  <Col>
              <FormLabel>Filter by Product Type</FormLabel>
              <FormControl
                as="select"
                placeholder="Filter by type"
                onChange={(e) => {
                  let tempSet = this.state.filterParams.filter(f => f.id !== "product.productType.name");;
                  if (e.target.value !== "All") {
                    tempSet.push({
                      id: 'product.productType.name',
                      Value: e.target.value,
                      Comparator: 'Equal'
                    });
                    this.setState({ filterParams: tempSet });
                  } else {
                    this.setState({ filterParams: tempSet });
                  }
                }
                }
              >
                {this.state.productTypes &&
                  this.state.productTypes.map((t) => {
                    return <SelectOption key={t.id}>{t.name}</SelectOption>;
                  })}
              </FormControl>
            </Col> 
                */}
            <Col>
              <Button onClick={() => this.searchByFilter()}>Search</Button>
            </Col>
            <Col>
              <Button variant="primary" onClick={this.handleCreateShow}>
                Create License
              </Button>
            </Col>
          </Row>

          <hr />
          <Row>
            <List>
              <ListHeader
                checkbox={false}
                style={{
                  gridTemplateColumns:
                    "1fr 0.5fr 1fr 1fr 1fr 1fr 1fr 1fr 1.5fr",
                }}
              >
                <div>SKU</div>
                <div>ProductType</div>
                <div>Serial Number</div>
                <div>Mac Address</div>
                <div>Instance UUID</div>
                <div>CPU ID</div>
                <div>Resource ID</div>
                <div>License Request Number</div>
                <div>Actions</div>
              </ListHeader>
              {this.state.licenses &&
                this.state.licenses.map((lic) => {
                  return (
                    <div key={lic.id}>
                      <TableRow
                        checkbox={false}
                        style={{
                          gridTemplateColumns:
                            "1fr 0.5fr 1fr 1fr 1fr 1fr 1fr 1fr 1.5fr",
                        }}
                      >
                        <div>{lic.sku}</div>
                        <div>{lic.product.productType.name}</div>
                        <div>{lic.serialNumber}</div>
                        <div>
                          {lic.macAddresses?.map((m) => {
                            return <p key={m.value}>{m.value}</p>;
                          })}
                        </div>
                        <div>{lic.instanceUuid}</div>
                        <div>{lic.cpuId}</div>
                        <div>{lic.resourceId}</div>
                        <div>{lic.licenseRequestNumber}</div>
                        <div style={{ display: "inline-flex" }}>
                          {this.state.isLoadingLs[lic.id] && (
                            <div>
                              <ProgressBar
                                leftLabel="Loading: "
                                barColors={{
                                  background: '#599bff',
                                  right: '#599bff',
                                  left: '#2fccd3',
                                }}
                                hideRight={true}
                                animate={1}
                              />
                            </div>
                          )}
                          {!this.state.isLoadingLs[lic.id] && (
                            <div style={{ display: "inline-flex" }}>
                              <OverlayTrigger
                              popoverContent="Merge and download license with the same serial number"
                              style={{
                                cursor: "pointer",
                                paddingRight: "15px",
                              }}
                              onClick={() => this.handleMergeDownload(lic)}
                            >
                                <Icon
                                  style={{ fill: "#29a329" }}

                                  iconname="Import"
                                />
                            </OverlayTrigger>

                              <OverlayTrigger
                                popoverContent={"Download license"}
                                style={{
                                  cursor: "pointer",
                                  paddingRight: "15px",
                                }}
                                onClick={() => this.handleDownload(lic)}
                              >
                                <Icon
                                  style={{ fill: "#007bff" }}

                                  iconname="Download"
                                />
                              </OverlayTrigger>
                              <OverlayTrigger
                                popoverContent={"Details"}
                                style={{
                                  cursor: "pointer",
                                  paddingRight: "15px",
                                }}
                                onClick={() => this.handleDetail(lic)}
                              >
                                <Icon
                                  style={{ fill: "#b3b300" }}

                                  iconname="View"
                                />
                              </OverlayTrigger>
                              <OverlayTrigger
                                popoverContent={"Delete"}
                                onClick={() => this.handleDelete(lic)}
                                style={{
                                  cursor: "pointer",
                                  paddingRight: "15px",
                                }}
                              >
                                <Icon
                                  style={{ fill: "#dc3545" }}

                                  iconname="TrashCan"
                                />
                              </OverlayTrigger>
                            </div>
                          )}
                        </div>
                      </TableRow>
                    </div>
                  );
                })}
            </List>
            {this.state.showAdd &&
              <HaivisionLicenseAdd
                show={this.state.showAdd}
                onHide={this.handleCreateShow}
                refresh={this.GetLicenses}
              />
            }
            {
              this.state.showDelete &&
              <HaivisionLicenseDelete
                show={this.state.showDelete}
                license={this.state.deleteLicense}
                onHide={this.handleDelete}
                refresh={this.GetLicenses}
              />
            }

          </Row>
          <Row>
          <Pagination
              pageCount={this.state.totalPages}
              currentPage={this.state.pageData.currentPage}
              onPageChange={(newPage, previousPage) => {
                if (newPage <= this.state.totalPages) {
                  this.setState({ ...this.state, pageData: { currentPage: newPage, pageSize: this.state.pageData.pageSize }, loading: true });
                  this.getLicensesPaginated(newPage, this.state.pageData.pageSize).then(a => {
                    this.setState({
                      totalPages: a.data?.totalPages,
                      licenses: a.data?.data,
                      loading: false
                    })
                  });
                } else {
                  cogoToast.warn("No next page", { position: 'top-right' });
                }
              }}
            />
          </Row>
        </div>}

      </Fragment>);
  }
}

export default withParams(HaivisionLicense);
