import React, { useCallback, useState } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import axios from "../../../infra/axios";
import { useParams } from "react-router-dom";
import propTypes from 'prop-types'
import { FormGroup } from "@hai/ui-react";
import { FormLabel } from "react-bootstrap";

const SelectUUIDBox = props => {
    var params = useParams();
    const [userFilter, setUserFilter] = useState(params?.id);
    const [loadingTypeahead, setLoadingTypeAhead] = useState(false);
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null)
    const PER_PAGE = 30;
    const CACHE = {};

    const handlePagination = (e, shownResults) => {
        const cachedQuery = CACHE[userFilter];

        // Don't make another request if:
        // - the cached results exceed the shown results
        // - we've already fetched all possible results
        if (
            cachedQuery.options.length > shownResults ||
            cachedQuery.options.length === cachedQuery.total_count
        ) {
            return;
        }

        setLoadingTypeAhead(true);

        const page = cachedQuery.page + 1;

        makeAndHandleRequest(userFilter, page).then((resp) => {
            const options = cachedQuery.options.concat(resp.options);
            CACHE[userFilter] = { ...cachedQuery, options, page };

            setLoadingTypeAhead(false);
            setOptions(options);
        });
    };

    const handleInputChange = (q) => {
        setUserFilter(q);
        const { onInputChange } = props;
        if (onInputChange) {
            onInputChange(q);
        }
    }

    const makeAndHandleRequest = (filter, page = 1) => {
        return axios.get(`${process.env.REACT_APP_PRODUCTION_API_BASE_URL}/Legacy/AwProducts/page/${page}/pageSize/${PER_PAGE}/filters/${filter}`)
            .then((resp) => resp.data)
            .then(({ data, totalCount }) => {
                const options = data.map((i) => ({
                    serial: i?.serial,
                    id: i.id,
                    uuid: i.uuid,
                }));
                return { options, totalCount };
            }).catch(e => setLoadingTypeAhead(false));
    }

    const handleSearch = useCallback((q) => {
        if (CACHE[q]) {
            setOptions(CACHE[q].options);
            return;
        }
        setLoadingTypeAhead(true);
        makeAndHandleRequest(q).then((resp) => {
            CACHE[q] = { ...resp, page: 1 };
            setLoadingTypeAhead(false);
            setOptions(resp.options);
        });
    }, []);

    return (<>
        <FormGroup>
            {props.label && <FormLabel>
                {props.label}
            </FormLabel>}
            <AsyncTypeahead
                useCache={false}
                id="search-typeahead-product"
                labelKey={o => `UUID: ${o.uuid} | SN: ${o.serial}`}
                isLoading={loadingTypeahead}
                maxResults={PER_PAGE - 1}
                onInputChange={handleInputChange}
                onChange={props.onChange}
                onPaginate={handlePagination}
                minLength={4}
                onSearch={handleSearch}
                options={options}
                filterBy={['uuid', 'serial']}
                paginate
                placeholder="Search for UUID or type new"
                renderMenuItemChildren={(option) => (
                    <div key={option.uuid}>
                        <span>uuid: {option.uuid} | serial: {option.serial}</span>
                    </div>
                )}
                style={{ marginBottom: '1rem' }}
                onBlur={props?.onBlur}
            />
        </FormGroup>
    </>)
}

SelectUUIDBox.propTypes = {
    onChange: propTypes.func.isRequired,
    label: propTypes.string,
    onInputChange: propTypes.func,
    onBlur: propTypes.func
}

export default SelectUUIDBox;