import React, { useEffect, useState } from "react"
import axios from "../../../infra/axios"
import { Button, Dialog, FormControl, Header, List, ProgressBar } from "@hai/ui-react"
import { useParams } from "react-router-dom";
import { FormLabel } from "react-bootstrap";
import DeviceByAccountTable from "./DeviceByAccountTable";
import EmsHubFlags from "./EmsHubFlags";
import cogoToast from 'cogo-toast';
import NavigationButtons from "../../common/Utils/NavigationButtons";

const ListAllEmsAccounts = (props) => {
    const params = useParams();
    const [accounts, setAccounts] = useState(null);
    const [loading, setLoading] = useState(true);
    const [expandContent, setExpandContent] = useState(null);
    const [visibleData, setVisibleData] = useState(null);
    const [pageData, setPageData] = useState({
        currentPage: params?.pageNum ? parseInt(params?.pageNum) : 1,
        pageSize: 10
    });
    const [userFilter, setUserFilter] = useState(params?.id);
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    useEffect(() => {
        //get all accounts
        axios.get(process.env.REACT_APP_HUB_API_BASE_URL + `/EmsApi/accounts`).then(res => {
            setAccounts(res.data);
            let start = params?.pageNum != null ? ((params?.pageNum - 1) * pageData.pageSize) : 0;
            setVisibleData(res.data.slice(start, pageData.currentPage * pageData.pageSize));
            if (res.data.length < pageData.currentPage * pageData.pageSize) {
                console.log('invalid page');
                setVisibleData(res.data.slice(0, 1 * pageData.pageSize));
            }

            setLoading(false);
        });
    }, []);

    useEffect(() => {
        search();
    }, [accounts])

    const search = () => {
        if (userFilter === '*') {
            setVisibleData(accounts);
        } else if (userFilter) {
            var data = accounts?.filter(a => a.id === userFilter || a.name.includes(userFilter));
            setVisibleData(data);
        } 
        else if (!userFilter && !params.pageNum) {
            setVisibleData(accounts?.slice(0, 10));
            setPageData({...pageData, currentPage: 1});
            window.history.replaceState('','', '/ems/accounts/page/1')
        } else if (params?.pageNum) {
            setVisibleDataFromPageNum(accounts);
        }
    }

    const deleteAccount = () => {
        axios.delete(process.env.REACT_APP_HUB_API_BASE_URL + `/EmsApi/accounts/${selectedAccount}`)
            .then(() => {
                cogoToast.success("Account deleted", {position: "top-right"})
                setUserFilter(null);
                setAccounts(accounts.filter(a => a.id !== selectedAccount))
                setVisibleData(visibleData.filter(a => a.id !== selectedAccount));
                setSelectedAccount(null)
            })
    }

    const setVisibleDataFromPageNum = (data) => {
        if (data) {
            let start = params?.pageNum != null ? ((params?.pageNum - 1) * pageData.pageSize) : 0;
            setVisibleData(data.slice(start, pageData.currentPage * pageData.pageSize));
            if (data.length < pageData.currentPage * pageData.pageSize) {
                console.log('invalid page');
                setVisibleData(data.slice(0, 1 * pageData.pageSize));
            }
        }
    }

    return (<>
    <Header title="Ems Accounts"></Header>
    <br/>
    <div style={{display: 'flex', alignItems: 'baseline'}}>
        <FormLabel style={{marginRight: '1%'}}>Ems Id/Name</FormLabel>
        <FormControl onChange={(e) => setUserFilter(e.target.value)} defaultValue={params?.id}></FormControl>
        <Button onClick={search}>Search</Button>
    </div>
    <br/>
    {loading && <ProgressBar leftLabel="Loading: "
                              barColors={{
                                background: '#599bff',
                                right: '#599bff',
                                left: '#2fccd3',
                              }}
                              hideRight={true}
                              animate={1} />}
        {
            visibleData && visibleData.map(a => {
                return (
                    <List key={a.id}>
                        <List.Panel panelColor={a.hub != null ? 'haiui-green-01' : 'haiui-purple-01'}>
                            <List.PanelTitle title={a.name}/>
                            <List.PanelDetail>
                                <List.PanelDetailItem icon="Information" text={a.id} divider="vertical"></List.PanelDetailItem>
                                <List.PanelDetailItem icon="HubNodes" text={a.hub?.organizationId} divider="vertical"></List.PanelDetailItem>
                            </List.PanelDetail>
                            <List.Actions>
                                <List.ActionItem expandButton eventKey={"showDevices"} icon='Devices' title="Devices" 
                                onSelect={(eventKey, expanded) => {
                                    setExpandContent(expanded ? eventKey : '')
                                    setSelectedAccount(a.id);
                                }} />
                                <List.ActionItem expandButton eventKey={"showFlags"} icon='HubNodes' title="Hub Flags"
                                onSelect={(eventKey, expanded) => {
                                    setExpandContent(expanded ? eventKey : '')
                                    setSelectedAccount(a.id);
                                }} />
                                <List.ActionItem isDivider />
                                <List.ActionItem icon='TrashCan' eventKey="deleteAccount" title="Delete" onSelect={(e, expanded) => {
                                    setSelectedAccount(a.id);
                                    setShowDeleteDialog(true);
                                }} />
                            </List.Actions>
                            <List.ExpandedPanel>
                                <div>
                                    {expandContent == 'showDevices' && 
                                    <DeviceByAccountTable account={a} selectedAccount={selectedAccount}/>}
                                    {expandContent == 'showFlags' && 
                                    <EmsHubFlags account={a} selectedAccount={selectedAccount} />}
                                </div>
                            </List.ExpandedPanel>
                        </List.Panel>
                    </List>
                )
            })
        }

        {accounts &&
        <NavigationButtons
            baseUrl="/ems/accounts"
            setPageData={setPageData}
            pageData={pageData}
            setVisibleData={setVisibleData}
            visibleData={visibleData}
            data={accounts}
        ></NavigationButtons>
        
        }

<Dialog show={showDeleteDialog}
                onClose={() => setShowDeleteDialog(false)}
                headline="Delete account?"
                bodyText={`This action will delete the account ${selectedAccount}.`}
                buttons={[
                    { label: 'CANCEL' },
                    { label: 'DELETE', variant: 'danger', onClick: () => deleteAccount() },
                  ]}
            ></Dialog>
    </>)
    }

export default ListAllEmsAccounts;