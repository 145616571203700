import React, { Component, Fragment } from "react";
import axios from "../../../infra/axios";
import {
  OverlayTrigger,
  List,
  ListHeader,
  TableRow,
  Animation,
  Icon,
  Button,
  ProgressBar,
  Header,
  Pagination
} from "@hai/ui-react";
import HaivisionProductTypeAdd from "./haivision-producttype-add";
import { useParams } from "react-router-dom";
import cogoToast from "cogo-toast";

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

class HaivisionProductType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      productTypes: null,
      showProductTypeAdd: false,
      selectedProductType:null,
    };

    this.GetProductTypes = this.GetProductTypes.bind(this);
    this.handleAddProductType = this.handleAddProductType.bind(this);
    this.handleUpdateProductType = this.handleUpdateProductType.bind(this);
    this.handleHideProductType = this.handleHideProductType.bind(this);
  }

  componentDidMount() {
    this.GetProductTypes();
  }

  GetProductTypes() {
    this.setState({ loading: true })
    axios
      .get(process.env.REACT_APP_LIC_API_BASE_URL + `/Products/ProductTypes`, {
        headers: {
          'Cache-Control': 'max-stale=30',
        }
      })
      .then((response) => {
        this.setState((prev) => {
          prev.productTypes = response.data;
          prev.loading = false;
          return prev;
        });
      });
  }

  handleDetails(id) {
    window.location = window.location + "/" + id;
  }
 
  handleUpdateProductType(id){
    if(this.state.productTypes === null || this.state.productTypes === undefined){
      return;
    }
    var selected = this.state.productTypes.filter(x=>x.id === id)[0];
    this.setState((prev) => {
      prev.showProductTypeAdd = !prev.showProductTypeAdd;
      prev.selectedProductType = selected;
      return prev;
    });
  }

  handleAddProductType() {
    this.setState((prev) => {
      prev.showProductTypeAdd = !prev.showProductTypeAdd;
      prev.selectedProductType = null;
      return prev;
    });
  }

  handleHideProductType (){
    this.setState((prev) => {
      prev.showProductTypeAdd = false;
      prev.selectedProductType = null;
      return prev;
    });   
  }

  render() {
    if (this.state.loading) {
      return (
        <Fragment>
          <br />
          <Header title="Haivision Product Types" />
          <hr />
          <ProgressBar
            leftLabel="Loading: "
            barColors={{
              background: '#599bff',
              right: '#599bff',
              left: '#2fccd3',
            }}
            hideRight={true}
            animate={1}
          />
        </Fragment>);
    } else {
      return (
        <div>
          <br />
          <Header title="Haivision Product Types" />
          <hr />
          <div style={{ display: "inline-flex" }}>
            <Button
              variant="primary"
              onClick={() => this.handleAddProductType()}
            >
              Add Product Type
            </Button>
          </div>
          <hr />
          <List>
            <ListHeader
              checkbox={false}
              alternateRowColors="true"
              style={{
                gridTemplateColumns: "0.1fr 1fr 1fr",
              }}
            >
              <div></div>
              <div>Product Type</div>
              <div>Actions</div>
            </ListHeader>
            {this.state.productTypes &&
              this.state.productTypes.map((p) => {
                return (
                  <div key={p.id}>
                    <TableRow
                      checkbox={false}
                      style={{
                        gridTemplateColumns: "0.1fr 1fr 1fr",
                      }}
                    >
                      <div></div>
                      <div>{p.name}</div>
                      <div style={{ display: "inline-flex" }}>
                        <OverlayTrigger
                          popoverContent={"View Details"}
                          style={{ cursor: "pointer", paddingRight: "10px" }}
                          onClick={() => this.handleDetails(p.id)}
                        >
                          <Icon

                            iconname="View"
                            style={{ fill: "#007bff" }}
                          />
                        </OverlayTrigger>
                      </div>
                    </TableRow>
                  </div>
                );
              })}
          </List>
          <HaivisionProductTypeAdd
            selectedProductType={this.state.selectedProductType}
            show={this.state.showProductTypeAdd}
            handleAddProductType={this.handleHideProductType}
            getProductTypes={this.GetProductTypes}
          />

          {/* <Pagination
            pageCount={this.state.productTypes?.totalPages}
            currentPage={this.state.pageData.currentPage}
            onPageChange={(newPage, previousPage) => {
              if (this.state.productTypes.hasNextPage || newPage <= this.state.productTypes?.totalPages) {
                this.setState({ ...this.state, pageData: { currentPage: newPage, pageSize: this.state.pageData.pageSize } });
                this.GetProductTypes(newPage, this.state.pageData.pageSize);
              } else {
                cogoToast.warn("No next page", { position: 'top-right' });
              }

            }}
          /> */}
        </div>
      );
    }
  }
}

export default withParams(HaivisionProductType);
