import { Button, Form, FormControl, FormGroup, FormLabel } from "@hai/ui-react";
import React, { useState } from "react";
import axios from "../../../infra/axios";
import SelectUUIDBox from "./SelectUUIDBox";
import cogoToast from "cogo-toast";
import { Col, Row } from "reactstrap";

const CreateProProduct = props => {
    const [isNewProduct, setIsNewProduct] = useState(true)
    const [form, setForm] = useState({
        serialNumber: null,
        uuid: null,
        macAddress1: null,
        macAddress2: null,
        productName: null,
        rosedoVersion: null,
        version: null,
        idBrehat: null,
        idTresco: null,
        idOuessant: null,
        idMercury: null,
    });

    const getProductBySerial = () => {
        if (form.serialNumber) {
            axios.get(`${process.env.REACT_APP_PRODUCTION_API_BASE_URL}/Legacy/AwProducts/SerialNumber/${form.serialNumber}`)
                .then(a => setIsNewProduct(false))
                .catch(e => setIsNewProduct(true));
        }
    }

    const createProProduct = () => {
        if (form.uuid && form.serialNumber) {
            axios.post(`${process.env.REACT_APP_PRODUCTION_API_BASE_URL}/Legacy/AwProducts/Uuid/${form.uuid}/ProSeries`, form)
                .then(a => {
                    setForm({
                        serialNumber: null,
                        uuid: null,
                        macAddress1: null,
                        macAddress2: null,
                        productName: null,
                        rosedoVersion: null,
                        version: null,
                        idBrehat: null,
                        idTresco: null,
                        idOuessant: null,
                        idMercury: null,
                    });
                    cogoToast.success('Product created', { position: 'top-right' });
                })
                .catch(e => cogoToast.error("Failed to create Pro Product.", { position: 'top-right' }));
        } else {
            cogoToast.error('Fill required fields', { position: 'top-right' });
        }
    }


    const onChangeHandler = evt => {
        const { name, value } = evt.target
        setForm({
            ...form,
            [name]: value,
        })
    }

    return (<>
        <Form>
            <Row>
                <Col>
                    <SelectUUIDBox label={'UUID'} onChange={a => {
                        if (a && a[0]) {
                            setForm({ ...form, uuid: a[0].uuid, serialNumber: a[0].serial });
                        }
                    }}
                        onInputChange={i => {
                            setForm({ ...form, uuid: i });
                        }}
                        onBlur={getProductBySerial}
                    ></SelectUUIDBox>
                </Col>
                {isNewProduct && <Col md='3'>
                    <FormGroup>
                        <FormLabel required>Serial Number</FormLabel>
                        <FormControl placeholder="AW..." name="serialNumber" onChange={onChangeHandler} defaultValue={form.serialNumber} />
                    </FormGroup>
                </Col>}
                <Col md='3'>
                    <FormGroup >
                        <FormLabel>MAC Address</FormLabel>
                        <FormControl placeholder="AB:CD:EF..." name="macAddress1" onChange={onChangeHandler} defaultValue={form.macAddress1} />
                    </FormGroup>
                </Col>
                <Col md='3'>
                    <FormGroup >
                        <FormLabel>MAC Address 2</FormLabel>
                        <FormControl placeholder="AB:CD:EF..." name="macAddress2" onChange={onChangeHandler} defaultValue={form.macAddress2} />
                    </FormGroup>

                </Col>
            </Row>

            <Row>
                <Col md='4'>
                    <FormGroup>
                        <FormLabel>Product name</FormLabel>
                        <FormControl placeholder="Product name" name="productName" onChange={onChangeHandler} defaultValue={form.productName} />
                    </FormGroup>

                </Col>
                <Col md='4'>
                    <FormGroup>
                        <FormLabel>Rosedo Version</FormLabel>
                        <FormControl placeholder="1.9" name="rosedoVersion" onChange={onChangeHandler} defaultValue={form.rosedoVersion} />
                    </FormGroup>
                </Col>
                <Col md='4'>
                    <FormGroup>
                        <FormLabel>Version</FormLabel>
                        <FormControl placeholder="1.0.0_BX" name="version" onChange={onChangeHandler} defaultValue={form.version} />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md='3'>
                    <FormGroup>
                        <FormLabel>Brehat Id</FormLabel>
                        <FormControl placeholder="1" name="idBrehat" onChange={onChangeHandler} defaultValue={form.idBrehat} />
                    </FormGroup>
                </Col>
                <Col md='3'>
                    <FormGroup>
                        <FormLabel>Tresco Id</FormLabel>
                        <FormControl placeholder="1" name="idTresco" onChange={onChangeHandler} defaultValue={form.idTresco} />
                    </FormGroup>
                </Col>
                <Col md='3'>
                    <FormGroup>
                        <FormLabel>Ouessant Id</FormLabel>
                        <FormControl placeholder="1" name="idOuessant" onChange={onChangeHandler} defaultValue={form.idOuessant} />
                    </FormGroup>
                </Col>
                <Col md='3'>
                    <FormGroup>
                        <FormLabel>Mercury Id</FormLabel>
                        <FormControl placeholder="1" name="idMercury" onChange={onChangeHandler} defaultValue={form.idMercury} />
                    </FormGroup>
                </Col>
            </Row>
            <Button onClick={() => createProProduct()} type="primary">Create</Button>

        </Form>
    </>)
}

export default CreateProProduct;