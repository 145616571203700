import { Animation, Icon } from '@hai/ui-react';
import React from 'react';
import { Row } from 'reactstrap';
import PropTypes from 'prop-types'

const StepStatusRow = props => {
    
    let noLoop = !(props.type === "StatusError") && !props.type.includes("OK");
    return (
        <Row
        style={{
          alignItems: "center",
          justifyContent: "center",
          marginTop: "1.5%",
        }}
      >
        <div>
          <Icon iconname={(props.isFailed && props.type == "StatusStarting") ? "StatusError" : props.type} ></Icon>
        </div>
        &nbsp;
        <span>{props.text}</span>
      </Row>
    )
}

StepStatusRow.propTypes = {
    type: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    isFailed: PropTypes.bool
}

export default StepStatusRow;