import { Icon, InfoFooter, Layout, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle, OverlayTrigger, Sidebar } from "@hai/ui-react"
import React, { useEffect, useState } from 'react';
import JellyfishLogo from '../../../static/jellyfish.svg'
import { authProvider } from "../../../infra/authProvider";
import { useNavigate } from "react-router-dom";
import background from '../../../static/jellyfishbg.jpeg';
import packageJson from "../../../../package.json";
import './HaiSideBar.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartArea } from "@fortawesome/free-solid-svg-icons";


const HaiSideBar = (props) => {
  const navigate = useNavigate();
  const [showGrafana, setShowGrafana] = useState(false);

  const getGrafanaLink = () => {
    let env = null;
    if (packageJson.version.includes("dev")) {
      env = "dev";
    } else if (packageJson.version.includes("qa")) {
      env = "qa";
    } else {
      env = "prod";
    }
    return `https://jellyfish-grafana.haivision.com/d/D5jjzflGz/jellyfish-monitor?orgId=1&refresh=1m&var-env=jellyfish-${env}`;
  };

  const SidebarSections = [
    {
      key: 'Jellyfish',
      title: 'Jellyfish',
      iconName: 'Connection',
      to: '/home',
      defaultPath: '/home',
      menuItems: [
        {
          caption: 'Users',
          key: 'user',
          to: '/system/user',
        },
        {
          caption: 'Audit Log',
          key: 'auditlog',
          to: '/system/audit-log',
        },
        {
          caption: 'Service Principal',
          key: 'servicepricipal',
          to: '/system/service-principal',
        },
      ],
    },
    {
      key: 'license',
      title: 'Licenses',
      iconName: 'License',
      to: '/license/requests',
      defaultPath: '/license/requests',
      menuItems: [
        {
          caption: 'Requests',
          key: 'licenseRequests',
          to: '/license/requests',
        },
        {
          caption: 'Product Types',
          key: 'licenseProductsType',
          to: '/license/producttypes',
        },
        {
          caption: 'Products',
          key: 'licenseProducts',
          to: '/license/products',
        },
        {
          caption: 'Licenses',
          key: 'licenses',
          to: '/license/licenses',
        },
        {
          caption: 'Command360',
          key: 'Command360License',
          to: '/license/command360license',
        }
      ]
    },
    {
      key: 'hub',
      title: 'Hub',
      iconName: 'HubNodes',
      to: '/hub/contracts',
      defaultPath: '/hub/organizations',
      menuItems: [
        {
          caption: 'Organizations',
          key: 'huborganizations',
          to: '/hub/organizations',
        },
        {
          caption: 'Contracts',
          key: 'hubcontracts',
          to: '/hub/contracts',
        },
        {
          caption: 'Ems Accounts',
          key: 'emsaccounts',
          to: '/ems/accounts',
        },
        {
          caption: 'Users',
          key: 'hubusers',
          to: '/hub/users',
        },
        {
          caption: 'Provisions',
          key: 'hubprovisions',
          to: '/hub/provisions',
        },
        {
          caption: 'Routes',
          key: 'routes',
          to: '/hub/support',
        },
        {
          caption: 'Features',
          key: 'hubfeatures',
          to: '/hub/feature-flags',
        },
      ],
    },
    {
      key: 'products',
      title: 'Manufacturing Database',
      iconName: 'Devices',
      to: '/products/awbench',
      defaultPath: '/products/awbench',
      menuItems: [
        {
          caption: 'Aviwest Bench Database',
          key: 'awbench',
          to: '/products/awbench',
        },
        {
          caption: 'Work Order',
          key: 'workorder',
          to: '/products/workorder',
        },
        {
          caption: 'Units',
          key: 'uut',
          to: '/products/uut',
        },
        {
          caption: 'Model',
          key: 'model',
          to: '/products/model',
        }
      ]
    },
    {
      key: 'torii',
      title: 'Torii',
      iconName: 'HubNodes',
      to: '/torii/organizations',
      defaultPath: '/torii/organizations',
      menuItems: [
        {
          caption: 'Organizations',
          key: 'toriiorganizations',
          to: '/torii/organizations',
        },
        {
          caption: 'Subscriptions',
          key: 'toriisubscriptions',
          to: '/torii/subscriptions',
        },
        {
          caption: 'Users',
          key: 'toriiusers',
          to: '/torii/users',
        }
      ],
    },
    {
      key: 'webbing',
      title: 'Webbing',
      iconName: 'HubNodes',
      to: '/webbing/accounts',
      defaultPath: '/webbing/accounts',
      menuItems: [
        {
          caption: 'Accounts',
          key: 'webbingAccounts',
          to: '/webbing/accounts',
        },
        {
          caption: 'Branches',
          key: 'webbingBranches',
          to: '/webbing/branches',
        },
        {
          caption: 'Devices',
          key: 'webbingDevices',
          to: '/webbing/devices',
        },
        // {
        //   caption: 'Usage',
        //   key: 'webbingUsage',
        //   to: '/webbing/usage',
        // }
      ],
    }
  ]
  const [userName, setUserName] = useState('');
  const [selectedItem, setSelectedItem] = React.useState(SidebarSections[0])

  useEffect(() => {
    setUserName(sessionStorage.getItem("user.name"));
  }, [])

  const handleOnSelect = item => {
    navigate(item);
  }

  const InfoStyle = {
    alignItems: 'center',
    backgroundColor: '#33333c',
    display: "grid",
    gap: "5px",
    padding: "20px 40px",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
  }

  const versionUI = (<p style={{ fontSize: "13px" }}>
    {packageJson.version.includes("prod") && (
      <OverlayTrigger
        placement="bottom"
        triggerByHover={false}
        popoverContent={
          <p>
            View recent releases notes{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://haivision.jira.com/wiki/spaces/JEL/pages/1862894613/Jellyfish+Releases"
            >
              here.
            </a>
          </p>
        }
      >
        <p style={{ cursor: "pointer", color: "#007bff" }}>
          {packageJson.version}
        </p>
      </OverlayTrigger>
    )}
    {!packageJson.version.includes("prod") &&
      "Version: " + packageJson.version}
  </p>)


  return (<>
    <Sidebar
      style={{
        height: "100vh",
      }}
      collapsible={true}
      productName="Jellyfish"
      logoSrc={JellyfishLogo}
      productNameCollapsed="JF"
      menuContent={SidebarSections}
      onSelect={handleOnSelect}
      defaultActive={`/jellyfish/users`}
      userName={userName}
      onSignOut={authProvider.logout}
      backgroundImage={background}
      footer={<InfoFooter><div style={InfoStyle}>
        {versionUI}
        <small>
          <a
            style={{
              cursor: "pointer",
              color: "#1e6ff1",
              fontSize: "13px",
            }}
            target="_blank"
            rel="noopener noreferrer"
            href={getGrafanaLink()}
          >
            Jellyfish Monitor{" "}
            <FontAwesomeIcon icon={faChartArea} onClick={() => setShowGrafana(true)}></FontAwesomeIcon>
          </a>
        </small>
      </div></InfoFooter>}

    />
  </>
  )
}

export default HaiSideBar;