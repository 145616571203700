import {
  FormControl,
  Header,
  List,
  ListHeader,
  ProgressBar,
  Spinner, } from "@hai/ui-react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { UserContext } from "../../common/userContext";
import JellyHeader from "../../common/Utils/JellyHeader";
import HubUsersRow from "./HubUsersRow";

const HubUsers = (props) => {
  const [allUsers, setAllUsers] = useState(null);
  const [filteredUsers, setFilteredUsers] = useState(null);
  const [loadingUsers, setLoadingUsers] = useState(false);

  useEffect(() => {
    getAllUsers();
  }, []);

  const getAllUsers = async () => {
    setLoadingUsers(true);
    await axios
      .get(process.env.REACT_APP_HUB_API_BASE_URL + "/Support/hub/users", {
        headers: {
          "Cache-Control": "no-cache",
        },
      })
      .then((response) => {
        setAllUsers(response.data);
        setFilteredUsers(response.data);
        setLoadingUsers(false);
      })
      .catch((err) => setLoadingUsers(false));
  };

  const filterUsers = (value) => {
    if (value == null || value === "") {
      setFilteredUsers(allUsers);
    } else {
      let filtered = allUsers?.filter((user) => {
        let valueUp = value.toUpperCase();
        if (user.userName?.toUpperCase().includes(valueUp)) return true;
        if (user.email?.toUpperCase().includes(valueUp)) return true;
        if (user.id?.toUpperCase().includes(valueUp)) return true;
      });
      setFilteredUsers(filtered);
    }
  };

  return (
    <>
      {/* <JellyHeader title="Hub Users"></JellyHeader> */}
      <Header title="Hub Users"></Header>
<br/>
      <UserContext.Consumer>
        {(user) => {
          if (
            user.roles
              ?.map((r) => r.role)
              .some(
                (r) =>
                  r === "SYS_ADMIN" ||
                  r === "HUB_ADMIN" ||
                  r === "HUB_READONLY" ||
                  r === "HUB_OPERATOR"
              )
          ) {
            return (
              <>
                <FormControl
                  name="filter"
                  placeholder="Search by Name"
                  onChange={(e) => filterUsers(e.target.value)}
                  style={{ maxWidth: "300px" }}
                />
                <hr />
                <List>
                  <ListHeader
checkbox={false}
                    style={{
                      gridTemplateColumns: "0.2fr 3fr 3fr 3fr 1fr  0.3fr 0.3fr",
                    }}
                  >
                    <div></div>
                    <div>Display name</div>
                    <div>Email</div>
                    <div>Type</div> 
                    <div>Status</div>
                    <div></div>
                  </ListHeader>
                  {loadingUsers && <ProgressBar leftLabel="Loading: "
                              barColors={{
                                background: '#599bff',
                                right: '#599bff',
                                left: '#2fccd3',
                              }}
                              hideRight={true}
                              animate={1} />}
                  {filteredUsers &&
                    filteredUsers
                      .sort((a, b) => (a.userName > b.userName ? 1 : -1))
                      .map((u, index) => {
                        return (
                          <HubUsersRow
                            key={u.id}
                            user={u}
                            index={index}
                          ></HubUsersRow>
                        );
                      })}
                </List>
              </>
            );
          } else {
            return <p>You don't have permission on this page</p>;
          }
        }}
      </UserContext.Consumer>
    </>
  );
};

export default HubUsers;
