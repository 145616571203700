import { List, ListHeader , ProgressBar } from "@hai/ui-react";
import React, { useState } from "react";
import SecretsRow from "./SecretsRow";
import PropTypes from "prop-types";

const SecretsTable = (props) => {
  const [secrets, setSecrets] = useState(props.secrets);

  return (
    <div
      style={{
        padding: "1%",
        border: "1px #5a98ef groove",
        borderRadius: "5px",
        marginTop: "5px",
      }}
    >
      <List>
        <ListHeader
checkbox={false}
          style={{
            gridTemplateColumns:
              "0.2fr 2fr 1fr 1fr 1fr 1fr 1fr 0.2fr 0.2fr 1fr",
          }}
        >
          <div></div>
          <div>Secret Id</div>
          <div>Start Date</div>
          <div>Expiration Date</div>
          <div>Renewed Date</div>
          <div>Secret Value</div>
          <div>Type</div>
          <div></div>
        </ListHeader>
        {secrets &&
          secrets.map((secret, index) => (
            <SecretsRow
              secret={secret}
              index={index}
              key={secret.secretId}
              spId={props.spId}
              getSecrets={props.getSecrets}
            ></SecretsRow>
          ))}
      </List>
    </div>
  );
};

SecretsTable.propTypes = {
  secrets: PropTypes.array.isRequired,
  spId: PropTypes.string.isRequired,
};

export default SecretsTable;
