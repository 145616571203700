import React, { useState, useEffect } from 'react'
import {
  Header,
  FormControl,
  FormGroup,
  FormLabel,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Button,
  SelectOption,
  ProgressBar,
  List,
  ListHeader,
  TableRow,
} from "@hai/ui-react";

import { Col, Row } from "reactstrap";
import axios from "../../../infra/axios";
import Select from "react-select";

const ProductModelUpdate = (props) => {

    const [model, setModel] = useState(props.model);
    const [loading, setLoading] = useState(false);
    const [action, SetAction] = useState('Create');
 
    useEffect(() => {
        SetAction(props.title.includes('Add')===true?'Create':'Update'); 
      }, [props.title]);

      useEffect(() => {
        setModel(props.model); 
      }, [props.model]);

      const handleCreate = (action) =>{
        if(model.macCount < 0){
            alert('Mac Count should greater than 0!');
            return;
        }

        var modelDto = {
            description: model?.description,
            macCount: (model?.macCount===null || model?.macCount===undefined)?0:model?.macCount
          };

        if(action  === 'Update')
        {
            setLoading(true);
            axios
            .put(
            process.env.REACT_APP_PRODUCTION_API_BASE_URL + "/Legacy/Models/modelId/" + model.id,
            modelDto
            )
            .then((reponse) => {
            handleHideModal(false);
            }).finally(()=>{
                setLoading(false);
            })     
        }
        else{
            setLoading(true);
            axios
            .post(
              process.env.REACT_APP_PRODUCTION_API_BASE_URL + "/Legacy/Models",
              modelDto
            )
            .then((reponse) => {
              handleHideModal(false);
            }).finally(()=>{
                setLoading(false);
            }) 
        }
      }

      const handleHideModal = (bCancel) => {
        props.onHide();
        if(!bCancel){
            props.onLoadModels();
        }
        setModel(null);     
      }

      const inputStyle = {color: "white", background: "#33333c", height : "40px"};

    return (
        <div>      
          {
            <div>
              <Modal show={props.show} size="xl">
              {loading && <ProgressBar
                leftLabel="Loading: "
                barColors={{
                background: '#599bff',
                right: '#599bff',
                left: '#2fccd3',
                }}
                hideRight={true}
                animate={1}
            />} 
            <ModalHeader>
            <ModalTitle>{props.title}</ModalTitle>
            </ModalHeader>
            <ModalBody>
            <div>
                <Row>
                <Col>
                <FormGroup>
                  <FormLabel>Description</FormLabel>
                  <FormControl
                    value={model===null?'':model.description}
                    onChange={(e) => {
                    var text = e.target.value.trim();
                    setModel((prev) => ({ ...prev, description: text}))
                    }}>
                </FormControl>             
                </FormGroup>
                </Col>
                </Row> 
                <Row>
                    <Col>
                    <FormGroup>
                    <FormLabel>Mac Count</FormLabel>
                    <input  style = {inputStyle} type="number" name="macCount" step="1" value = {model===null?0:model.macCount} onChange={(e) => setModel((prev) => ({ ...prev, macCount: e.target.value }))}></input>
                    </FormGroup>
                  </Col>
                </Row>       
            </div>
             </ModalBody> 
             <ModalFooter>
              <Button variant="primary" onClick={() => handleCreate(action)}>
                {action}
              </Button>
              <Button onClick={() => handleHideModal(true)}>
                Cancel
              </Button>
            </ModalFooter>        
          </Modal>
          </div>
          }
          </div>);

}

export default ProductModelUpdate;