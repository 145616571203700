import { Animation, Button, Header, IconCard , ProgressBar } from "@hai/ui-react";
import React, { Component, useEffect, useState } from "react";
import axios from "../../../infra/axios";
import HubRouteDiagram from "./routeDiagram";
import JellyHeader from "../../common/Utils/JellyHeader";
import { Row } from "reactstrap";
import Col from "reactstrap/lib/Col";
import { useNavigate, useParams } from "react-router-dom";

const HubRouteDetail = (props) => {
  let navigate = useNavigate();
  const params = useParams();
  const [routeId, setRouteId] = useState(props.routeId ?? params.routeId)
  const [subscriptionId, setSubscriptionId] = useState(props.subscriptionId ?? params.subscriptionId)
  const [route, setRoute] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    refreshRouteDetail();
  }, []);

  const backToRoutes = () => {
    navigate(-1);
  }

  const refreshRouteDetail = async () => {
    await axios
      .get(
        process.env.REACT_APP_HUB_API_BASE_URL +
          "/Support/subscriptions/" +
          subscriptionId +
          "/routes/" +
          routeId
      )
      .then((response) => {
        setRoute(response.data);
        setLoading(false);
      });
  }


    if (loading) {
      return (
        <div>
          <ProgressBar
  leftLabel="Loading: "
  barColors={{
    background: '#599bff',
    right: '#599bff',
    left: '#2fccd3',
  }}
  hideRight={true}
  animate={1}
/>
        </div>
      );
    } else {
      return (
        <>
          <Row style={{ justifyContent: "space-between" }}>
            <JellyHeader
              style={{ marginBottom: "1%", color: "#007bff" }}
              title={
                "Route: " +
                route.name +
                " - " +
                route.status
              }
            ></JellyHeader>
            <Button variant="secondary" onClick={() => backToRoutes()}>
              Back
            </Button>
          </Row>
          <Row style={{ marginTop: "-0.6%" }}>
            <Col>
              <small color="#f6426e">Route ID: {route.id}</small>
            </Col>
          </Row>

          <hr />

          <HubRouteDiagram route={route} />
        </>
      );
    }
}

export default HubRouteDetail;
