import React from "react";
import { Header, Icon , ProgressBar } from "@hai/ui-react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";

const JellyHeader = (props) => {
  return (
    <Col
      style={{ paddingLeft: "0px" }}
      onClick={() => {
        if (props.onClick) {
          props.onClick();
        }
      }}
    >
      <Header
        title={props.title}
        style={{
          color: props.color == undefined ? "#1e6ff1" : props.color,
          borderBottom: 0,
        }}
        type="page-header"
        children={props.children}
      />
      <Header
        title={props.subtitle}
        style={{
          color: props.color == undefined ? "#1e6ff1" : props.color,
          fontSize: "1rem",
          borderBottom: 0,
        }}
        type="sub-header"
      />
    </Col>
  );
};

JellyHeader.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.any,
};

export default JellyHeader;
