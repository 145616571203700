import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import axios from "../../infra/axios";
import { Header, List, ProgressBar } from "@hai/ui-react";

const WebbingUsages = (props) => {
  var params = useParams();
  const [loading, setLoading] = useState(true);
  const [usages, setUsages] = useState();
  const getUsages = (iccid) => {
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_WEBBING_API_BASE_URL}/Usages/DeviceUsage/${iccid}?startDate=04/01/2024&endDate=04/30/2024`
      )
      .then((res) => {
        setUsages(res.data);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  };
  useEffect(() => {
    getUsages("89852351022200022702");
  }, []);
  return (
    <>
      <Header title="Webbing Usages" style={{ marginTop: "1%" }} />
      <br />
      {loading && (
        <ProgressBar
          leftLabel="Loading: "
          barColors={{
            background: "#599bff",
            right: "#599bff",
            left: "#2fccd3",
          }}
          hideRight={true}
          animate={1}
        />
      )}
      <div>
        {!loading &&
          usages &&
          usages.map((a) => {
            return (
              <>
                <List key={a.id}>
                  <List.Panel panelColor="haiui-green-01">
                    <List.PanelTitle title={a.date ?? "No name"} />
                    <List.PanelDetail>
                      <List.PanelDetailItem
                        icon="Information"
                        text={"Country: " + a.countryName}
                        divider="vertical"
                        label="Status"
                      ></List.PanelDetailItem>
                      <List.PanelDetailItem
                        icon="HubNodes"
                        text={"totalUage: " + a.totalUsage + "MB"}
                        divider="vertical"
                        label="email"
                      ></List.PanelDetailItem>
                    </List.PanelDetail>
                  </List.Panel>
                </List>
              </>
            );
          })}
      </div>
    </>
  );
};
export default WebbingUsages;
