import React from "react";
import {
  Modal,
  ModalTitle,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  FormLabel,
  FormControl,
 ProgressBar } from "@hai/ui-react";
import { Row, Col } from "reactstrap";
import moment from "moment";
import axios from "axios";
import { Fragment } from "react";
import cogoToast from "cogo-toast";

class ProvisionChangeModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: this.props.provision,
      notificationShow: false,
      submitting: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  enableOrDisable() {
    this.setState({ submitting: true });
    if (this.state.item.status === "new") {
      // disable
      axios
        .put(
          process.env.REACT_APP_HUB_API_BASE_URL +
            "/Provisions/disable/" +
            this.state.item.claimCode
        )
        .then((response) => {
          cogoToast.success("Disabled sucessfully", { position: "top-right" });
          let item = this.state.item;
          item.status = "disabled";
          this.setState({ submitting: false });
        });
    } else {
      // enable
      axios
        .put(
          process.env.REACT_APP_HUB_API_BASE_URL +
            "/Provisions/enable/" +
            this.state.item.claimCode
        )
        .then((response) => {
          cogoToast.success("Enabled sucessfully", { position: "top-right" });
          let item = this.state.item;
          item.status = "new";
          this.setState({ submitting: false });
        });
    }
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    var temp = this.state.item;
    temp[name] = value;
    this.setState({
      item: temp,
    });
  }

  handleSubmit(event) {
    this.setState({ submitting: true });
    axios
      .put(
        process.env.REACT_APP_HUB_API_BASE_URL + "/Provisions/update",
        JSON.stringify(this.state.item)
      )
      .then((response) => {
        if (response.status == 200) {
          this.setState({ submitting: false });
          this.props.successNotification();
          this.props.hide();
        }
      })
      .catch((e) => this.setState({ submitting: false }));

    event.preventDefault();
  }

  render = () => {
    return (
      <Fragment>
        <Modal
          size="lg"
          show={this.props.show}
          onHide={this.props.hide}
          centered
          style={{ font: "0.8rem" }}
        >
          <ModalHeader>
            <ModalTitle>Edit</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                <FormGroup>
                  <FormLabel text="Claim Code" />
                  <p>{this.state.item.claimCode}</p>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <FormLabel text="Status" />
                  <p>{this.state.item.status ?? ""} </p>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <FormLabel text="Domain" />
                  <FormControl
                    name="domain"
                    value={this.state.item.domain}
                    onChange={this.handleChange}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <FormLabel text="Claimed Date" />
                  <FormControl
                    name="claimedDate"
                    value={
                      (this.state.item.claimedDate &&
                        moment(this.state.item.claimedDate).format(
                          "MM/DD/YYYY"
                        )) ||
                      ""
                    }
                    onChange={this.handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <FormLabel text="Claimed By" />
                  <FormControl
                    name="claimedUserEmail"
                    value={this.state.item.claimedUserEmail || ""}
                    onChange={this.handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <FormLabel text="Hub Org Id" />
                  <FormControl
                    name="srtHubOrganizationId"
                    value={this.state.item.srtHubOrganizationId || ""}
                    onChange={this.handleChange}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <FormLabel text="Hub Sub Id" />
                  <FormControl
                    name="srtHubSubscriptionId"
                    value={this.state.item.srtHubSubscriptionId || ""}
                    onChange={this.handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Row>
              <Col>
                <Button
                  variant="primary"
                  disabled={this.state.submitting}
                  onClick={this.handleSubmit}
                >
                  Save Change
                </Button>
              </Col>
              <Col>
                <Button
                  variant="secondary"
                  disabled={
                    this.state.item.status == "active" || this.state.submitting
                  }
                  onClick={() => this.enableOrDisable()}
                >
                  {this.state.item.status === "new"
                    ? "Disable Code"
                    : "Enable Code"}
                </Button>
              </Col>
              <Col>
                <Button variant="secondary" onClick={this.props.hide}>
                  Close
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  };
}
export default ProvisionChangeModal;
