import React from "react";
import { Container, Row } from "reactstrap";
import { Routes, Route, Outlet } from "react-router-dom";
import Subscription from "../../haivision-hub/subscriptions/Subscriptions";
import Contracts from "../../haivision-hub/contracts/Contracts";
import ContractDetails from "../../haivision-hub/contracts-details/ContractDetails";
import Provision from "../../haivision-hub/provisions/Provisions";
import Home from "../../Home";
import TopologySupport from "../../haivision-hub/support/TopologySupport";
import ContainerSupport from "../../haivision-hub/support/ContainerSupport";
import Organization from "../../haivision-hub/organizations/Organizations";
import SubscriptionPage from "../../haivision-hub/subscriptions/SubscriptionPage";
import FeatuerFlag from "../../haivision-hub/feature-flags/FeatureFlags";
import Users from "../../jellyfish/users/Users";
import UserEdit from "../../jellyfish/users/UserEdit";
import HubUsers from "../../haivision-hub/users/HubUsers";
import AuditLog from "../../jellyfish/audit-log/AuditLog";
import HubRoute from "../../haivision-hub/route/route";
import HubRouteDetail from "../../haivision-hub/route/routeDetails";
import NotFound_Shark from "../Exceptions/NotFound_Shark";
import ServicePrincipal from "../../jellyfish/servicePrincipals/ServicePrincipals";
import NotAuthorized_Shark from "../Exceptions/NotAuthorized_Shark";
import HaivisionProduct from "../../haivision-license/product/haivision-product";
import HaivisionLicense from "../../haivision-license/license/haivision-license";
import HaivisionCommand360License from "../../haivision-license/license/haivision-command360-license-add";
import HaivisionProductDetail from "../../haivision-license/product/haivision-product-detail";
import HaivisionLicenseDetail from "../../haivision-license/license/haivision-license-detail";
import HaivisionLicenseRequest from "../../haivision-license/request/haivision-license-request";
import HaivisionProductType from "../../haivision-license/producttype/haivision-producttype";
import HaivisionProductTypeDetail from "../../haivision-license/producttype/haivision-producttype-detail";
import KickerCaller from "../../haivision-license/kicker-caller";
import backgroundImage from "../../../static/jellyfishbg.jpeg";
import ListAllEmsAccounts from "../../haivision-hub/ems/ListAllEmsAccounts";
import ToriiOrganizations from "../../haivision-torii/ToriiOrganizations";
import ToriiSubscriptions from "../../haivision-torii/ToriiSubscriptions";
import ToriiUsers from "../../haivision-torii/ToriiUsers";
import AWBenchProducts from "../../haivision-production/awbench/AWBenchProducts";
import AwProductDetails from "../../haivision-production/awbench/AwProductDetails.jsx";
import HaivisionWorkOrderDetail from "../../haivision-production/WorkOrder/haivision-Work-order-detail";
import HaivisionWorkOrder from "../../haivision-production/WorkOrder/haivision-Work-order";
import CreateAwProduct from "../../haivision-production/awbench/CreateAwProduct.jsx";
import CreateAllProducts from "../../haivision-production/awbench/CreateAllProducts.jsx";
import HaivisionUUT from "../../haivision-production/uut/haivision-uut";
import HaivisionUUTDetail from "../../haivision-production/uut/haivision-uut-detail";
import HaivisionProductionModel from "../../haivision-production/model/haivision-model";
import WebbingAccounts from "../../haivision-webbing/WebbingAccounts.jsx";
import WebbingBranches from "../../haivision-webbing/WebbingBranches.jsx";
import WebbingUsages from "../../haivision-webbing/WebbingUsages.jsx";
import WebbingDevices from "../../haivision-webbing/WebbingDevices.jsx";

const Content = (props) => {
  return (
    <Container
      fluid
      className={"content"}
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: "repeat",
        marginTop: "1rem",
      }}
    >
      <Routes>
        {/* Jellyfish Root Namespace*/}
        <Route exact path="/" element={<Home />} />
        <Route exact path="/home" element={<Home />} />
        <Route exact path="/system/user" element={<Users />} />
        <Route exact path="/system/user/edit/:idParam" element={<UserEdit />} />
        <Route exact path="/user-disabled" element={<NotAuthorized_Shark />} />
        <Route exact path="/system/audit-log" element={<AuditLog />} />
        <Route
          exact
          path="/system/service-principal"
          element={<ServicePrincipal />}
        />

        {/* Torii namespace*/}
        <Route
          exact
          path="/torii/organizations"
          element={<ToriiOrganizations />}
        />
        <Route
          exact
          path="/torii/organizations/:id"
          element={<ToriiOrganizations />}
        />
        <Route
          exact
          path="/torii/organizations/page/:pageNum"
          element={<ToriiOrganizations />}
        />
        <Route
          exact
          path="/torii/organizations/:orgId/subscriptions"
          element={<ToriiSubscriptions />}
        />
        <Route
          exact
          path="/torii/subscriptions"
          element={<ToriiSubscriptions />}
        />
        <Route
          exact
          path="/torii/subscriptions/page/:pageNum"
          element={<ToriiSubscriptions />}
        />

        <Route exact path="/torii/users" element={<ToriiUsers />} />
        <Route exact path="/torii/users/:id" element={<ToriiUsers />} />
        <Route
          exact
          path="/torii/users/page/:pageNum"
          element={<ToriiUsers />}
        />

        {/* Hub Namespace*/}
        <Route exact path="/hub/subscriptions" element={<Subscription />} />
        <Route exact path="/hub/contracts" element={<Contracts />} />
        <Route
          exact
          path="/hub/contracts/:idParam"
          element={<ContractDetails />}
        />
        <Route exact path="/hub/provisions" element={<Provision />} />
        <Route exact path="/hub/users" element={<HubUsers />} />

        <Route exact path="/hub/support/" element={<TopologySupport />} />
        <Route
          exact
          path="/hub/support/routes/route/:routeId"
          element={<TopologySupport />}
        />
        <Route
          exact
          path="/hub/support/routes/topology/:topologyId"
          element={<TopologySupport />}
        />
        <Route
          exact
          path="/hub/support/routes/subscription/:subscriptionId"
          element={<TopologySupport />}
        />
        <Route
          exact
          path="/hub/support/routes/container/:topologyId"
          element={<ContainerSupport />}
        />

        <Route path="/hub/organizations" element={<Organization />} />
        <Route
          exact
          path="/hub/organizations/:orgId/subscriptions/:subId"
          element={<SubscriptionPage />}
        />
        <Route exact path="/hub/feature-flags" element={<FeatuerFlag />} />
        <Route
          exact
          path="/hub/subscriptions/:subscriptionIdParam/routes"
          element={<HubRoute />}
        />
        <Route
          exact
          path="/hub/subscriptions/:subscriptionId/routes/:routeId"
          element={<HubRouteDetail />}
        />

        {/* Connect Namespace
      <Route exact path="/connect/customers" element={Customer} />
      <Route
        exact
        path="/connect/customers/:customerId"
        element={CustomerDetails}
      />
      <Route
        exact
        path="/connect/organizations/:organizationId/features"
        element={ConnectFeature}
      />
      <Route exact path="/connect/users" element={ConnectUsers} />
      <Route exact path="/connect/provisions" element={ConnectUsers} />

      <Route
        exact
        path="/connect/contracts"
        element={ConnectServiceContract}
      />
      <Route
        exact
        path="/connect/service-contracts/invoices/:sfInvoiceId"
        element={ConnectInvoiceDetail}
      />
      */}

        {/* P2P Namespace*/}
        {/* Haivision License Namespace */}
        <Route
          exact
          path="/license/producttypes"
          element={<HaivisionProductType />}
        />
        <Route
          exact
          path="/license/producttypes/:productTypeIdParam"
          element={<HaivisionProductTypeDetail />}
        />
        {/* Haivision License Namespace */}
        <Route exact path="/license/products" element={<HaivisionProduct />} />
        <Route
          exact
          path="/license/products/:productIdParam"
          element={<HaivisionProductDetail />}
        />
        <Route exact path="/license/licenses" element={<HaivisionLicense />} />
        <Route
          exact
          path="/license/licenses/:licenseId"
          element={<HaivisionLicenseDetail />}
        />
        <Route
          exact
          path="/license/command360license"
          element={<HaivisionCommand360License />}
        />
        <Route
          exact
          path="/license/requests"
          element={<HaivisionLicenseRequest />}
        />
        <Route exact path="/license/kicker" element={<KickerCaller />} />

        {/* Products */}
        <Route exact path="/products/awbench" element={<AWBenchProducts />} />
        <Route
          exact
          path="/products/awbench/uuid/:uuid"
          element={<AwProductDetails />}
        />
        <Route
          exact
          path="/products/WorkOrder"
          element={<HaivisionWorkOrder />}
        />
        <Route
          exact
          path="/products/WorkOrder/:workOrderId"
          element={<HaivisionWorkOrderDetail />}
        />
        <Route
          exact
          path="/products/awbench/create"
          element={<CreateAllProducts />}
        />
        <Route exact path="/products/uut" element={<HaivisionUUT />} />
        <Route
          exact
          path="/products/uut/:uutId/:sn"
          element={<HaivisionUUTDetail />}
        />
        <Route
          exact
          path="/products/model"
          element={<HaivisionProductionModel />}
        />

        {/* EMS */}
        <Route exact path="/ems/accounts" element={<ListAllEmsAccounts />} />
        <Route
          exact
          path="/ems/accounts/:id"
          element={<ListAllEmsAccounts />}
        />
        <Route
          exact
          path="/ems/accounts/page/:pageNum"
          element={<ListAllEmsAccounts />}
        />

        {/*Webbing */}
        <Route exact path="/webbing/accounts" element={<WebbingAccounts />} />
        <Route exact path="/webbing/branches" element={<WebbingBranches />} />
        <Route exact path="/webbing/devices" element={<WebbingDevices />} />
        <Route exact path="/webbing/usage" element={<WebbingUsages />} />

        <Route element={<NotFound_Shark />} />
      </Routes>

      <Outlet></Outlet>
    </Container>
  );
};

export default Content;
