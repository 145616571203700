import {
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  ModalFooter,
  ModalTitle,
 ProgressBar } from "@hai/ui-react";
import React, { Component } from "react";
import axios from "../../../infra/axios";

class HaivisionProductDelete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      product: this.props.product,
      show: this.props.show,
    };
  }

  componentDidMount() {}

  componentWillReceiveProps(nextprops) {
    this.setState((prev) => {
      prev.loading = false;
      prev.product = nextprops.product;
      prev.show = nextprops.show;
      return prev;
    });
  }

  handleConfirmDelete() {
    axios
      .patch(
        process.env.REACT_APP_LIC_API_BASE_URL +
          "/Products/" +
          this.state.product.id + '/disable'
      )
      .then((reponse) => {
        this.handleHide();
        this.props.refresh();
      });
  }

  handleHide() {
    this.setState({ loading: true, product: this.props.product, show: false });
    this.props.onHide();
  }

  render() {
    if (!this.state.product) {
      return <></>;
    } else {
      return (
        <div>
          <Modal
            show={this.state.show}
            onHide={() => this.handleHide()}
            size="lg"
          >
            <ModalHeader>
              <ModalTitle>Confirm</ModalTitle>
            </ModalHeader>
            <ModalBody style={{ textAlign: "center" }}>
              You are about to delete Product for {this.state.product.sku}{" "}
              <br />
              Are you sure?
            </ModalBody>
            <ModalFooter>
              <Button
                variant="primary"
                onClick={() => this.handleConfirmDelete()}
              >
                Confirm
              </Button>
              <Button variant="secondary" onClick={() => this.handleHide()}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      );
    }
  }
}

export default HaivisionProductDelete;
