import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "../../../infra/axios";
import { DataTable, Header } from "@hai/ui-react";
import AwModemsDataTable from "./AwModemsDataTable";
import AwFunctionalTestsDataTable from "./AwFunctionalTestsDataTable";
import AwAirSeriesDataTable from "./AwAirSeriesDataTable";
import AwProSeriesDataTable from "./AwProSeriesDataTable";
import AwPro4SeriesDataTable from "./AwPro4SeriesDataTable";

const AwProductDetails = props => {
    const params = useParams();
    const [loadingTests, setLoadingTests] = useState(true)
    const [loadingPro, setLoadingPro] = useState(true)
    const [loadingPro4, setLoadingPro4] = useState(true)
    const [loadingModems, setLoadingModems] = useState(true)
    const [loadingAir, setLoadingAir] = useState(true)
    const [modems, setModems] = useState([])
    const [tests, setTests] = useState([])
    const [pro4, setPro4] = useState({})
    const [pro, setPro] = useState({})
    const [air, setAir] = useState({})

    useEffect(() => {
        if (params?.uuid) {
            getFunctionalTests();
            getModems();
            getPro4();
            getPro();
            getAir();
        }
    }, []);

    const getFunctionalTests = () => {
        axios
            .get(`${process.env.REACT_APP_PRODUCTION_API_BASE_URL}/Legacy/AwProducts/Uuid/${params?.uuid}/FunctionalTests`)
            .then((res) => {
                setTests(res.data);
                setLoadingTests(false);
            })
            .catch((e) => setLoadingTests(false));
    };

    const getModems = () => {
        axios
            .get(`${process.env.REACT_APP_PRODUCTION_API_BASE_URL}/Legacy/AwProducts/Uuid/${params?.uuid}/Modem`)
            .then((res) => {
                setModems(res.data);
                setLoadingModems(false);
            })
            .catch((e) => setLoadingModems(false));
    };

    const getPro4 = () => {
        axios
            .get(`${process.env.REACT_APP_PRODUCTION_API_BASE_URL}/Legacy/AwProducts/Uuid/${params?.uuid}/Pro4Series`)
            .then((res) => {
                setPro4(res.data);
                setLoadingPro4(false);
            })
            .catch((e) => setLoadingPro4(false));
    };


    const getPro = () => {
        axios
            .get(`${process.env.REACT_APP_PRODUCTION_API_BASE_URL}/Legacy/AwProducts/Uuid/${params?.uuid}/ProSeries`)
            .then((res) => {
                setPro(res.data);
                setLoadingPro(false);
            })
            .catch((e) => setLoadingPro(false));
    };

    const getAir = () => {
        axios
            .get(`${process.env.REACT_APP_PRODUCTION_API_BASE_URL}/Legacy/AwProducts/Uuid/${params?.uuid}/AirSeries`)
            .then((res) => {
                setAir(res.data);
                setLoadingAir(false);
            })
            .catch((e) => setLoadingAir(false));
    };


    return (<>
        <Header title={"Aw Product: " + params?.uuid}></Header>

        <div style={{ marginBottom: '1rem' }}>
            {!loadingAir && Object.keys(air).length !== 0 &&
                <AwAirSeriesDataTable uuid={params?.uuid} air={air}></AwAirSeriesDataTable>}
        </div>
        <div style={{ marginBottom: '1rem' }}>
            {!loadingPro && Object.keys(pro).length !== 0 &&
                <AwProSeriesDataTable uuid={params?.uuid} pro={pro}></AwProSeriesDataTable>}
        </div>
        <div style={{ marginBottom: '1rem' }}>
            {!loadingPro && Object.keys(pro4).length !== 0 &&
                <AwPro4SeriesDataTable uuid={params?.uuid} pro4={pro4}></AwPro4SeriesDataTable>}
        </div>
        <div style={{ marginBottom: '1rem' }}>
            {!loadingModems &&
                <AwModemsDataTable uuid={params?.uuid} modems={modems}></AwModemsDataTable>}
        </div>
        <div style={{ marginBottom: '1rem' }}>
            {!loadingTests &&
                <AwFunctionalTestsDataTable uuid={params?.uuid} functionalTests={tests}></AwFunctionalTestsDataTable>}
        </div>

    </>)
}

export default AwProductDetails;