import React, { useEffect, useState } from "react";
import { Button, Animation , ProgressBar } from "@hai/ui-react";
import axios from "../../infra/axios";
import PropTypes from "prop-types";

function SecretRevealButton(props) {
  const [showSecret, setShowSecret] = useState(props.showSecret);
  const [secret, setSecret] = useState(null);
  const [jellySp, setJellySp] = useState(null);
  const [loading, setLoading] = useState(false);

  const getSecret = async (spId, secretId) => {
    setLoading(true);
    await axios
      .get(
        process.env.REACT_APP_JELLYFISH_API_BASE_URL +
          `/azure-resource/service-principals/${spId}/secrets/${secretId}/with-secret`
      )
      .then((res) => {
        setSecret(res.data);
        setLoading(false);
      })
      .catch((e) => setLoading(false));
  };

  const getJellySpData = async () => {
    if (props.jellyfishDbId != null) {
      setLoading(true);
      await axios
        .get(
          process.env.REACT_APP_JELLYFISH_API_BASE_URL +
            `/azure-resource/service-principals/by-db/${props.jellyfishDbId}`
        )
        .then((res) => {
          setJellySp(res.data);
          setLoading(false);
          getSecret(res.data.applicationId, res.data.secrets?.[0]?.secretId);
        })
        .catch((e) => setLoading(false));
    }
  };

  if (!showSecret) {
    return (
      <div>
        <Button
          onClick={() => {
            setShowSecret(true);
            getJellySpData();
          }}
        >
          Click to Reveal
        </Button>
      </div>
    );
  } else {
    return (
      <>
        {loading && <ProgressBar
  leftLabel="Loading: "
  barColors={{
    background: '#599bff',
    right: '#599bff',
    left: '#2fccd3',
  }}
  hideRight={true}
  animate={1}
/>}
        {!loading && <div>{props.secretValue ?? secret?.secretValue}</div>}
      </>
    );
  }
}

SecretRevealButton.propTypes = {
  jellyfishDbId: PropTypes.string,
  secretValue: PropTypes.string,
  showSecret: PropTypes.bool
}

SecretRevealButton.defaultProps = {
  showSecret: false
}


export default SecretRevealButton;
