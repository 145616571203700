import { Dialog, ProgressBar } from "@hai/ui-react";
import React, { Component } from "react";
import { AuthenticationState } from "react-aad-msal";
import { UserContext, UserProvider } from "./common/userContext";
import LastestProvisionsTable from "./haivision-hub/provisions/LastestProvisionsTable";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      showNoPermissionDialog: false,
    };
  }

  componentDidMount() {
    this.setState((prev) => {
      prev.isLogedIn =
        sessionStorage.getItem("user.authState") === "Authenticated"
          ? true
          : false;
      return prev;
    });
  }

  componentDidUpdate() { }

  render() {
    if (sessionStorage.getItem("user.authState") === "Authenticated") {
      return (
        <div style={{ textAlign: "center" }} className="container-column">
          <div className="content-column">
            <p style={{ color: "#ffffff" }}>
              Hello {sessionStorage.getItem("user.name")}
            </p>
            <p style={{ color: "#ffffff" }}>
              Welcome to Haivision Control Center{" "}
            </p>
            <p style={{ color: "#ffffff" }}>
              Navigate on the side menu to explore.
            </p>
          </div>

          {/* <LastestProvisionsTable></LastestProvisionsTable> */}


          <UserContext.Consumer>
            {(user) => {
              if (
                user.authState === AuthenticationState.Authenticated &&
                user.roles.length == 0
              ) {
                this.setState({ showNoPermissionDialog: true });
              }
            }}
          </UserContext.Consumer>
          <Dialog
            show={this.state.showNoPermissionDialog}
            title="Warning"
            content={
              <div style={{ display: "flex", justifyContent: "center" }}>
                <p>
                  You have no roles assgined. Please contact Jellyfish Admin.
                </p>
              </div>
            }
            buttons={[
              {
                label: "OK",
                primary: true,
                onClick: () => this.setState({ showNoPermissionDialog: false }),
              },
            ]}
          />
        </div>
      );
    } else {
      return <h2>Please Login First</h2>;
    }
  }
}

export default Home;
