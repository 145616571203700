import {
  Button,
  LabelValue,
  ModalHeader,
  FormGroup,
  FormLabel,
  ModalBody,
  ModalFooter,
  Form,
  Modal,
  ProgressBar } from "@hai/ui-react";
import React from "react";

import PropTypes from "prop-types";
import moment from "moment";

const SecretModal = (props) => {
  return (
    <Modal show={props.show}>
      <ModalHeader></ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <FormLabel>Application Id</FormLabel>
            <LabelValue>{props.sp?.applicationId}</LabelValue>
          </FormGroup>

          <FormGroup>
            <FormLabel>Secret Id</FormLabel>
            <LabelValue>{props.secret?.secretId}</LabelValue>
          </FormGroup>

          <FormGroup>
            <FormLabel>Created Date</FormLabel>
            <LabelValue>
              {moment(props.secret?.startDate).format("MM/DD/YYYY HH:MM")}
            </LabelValue>
          </FormGroup>

          <FormGroup>
            <FormLabel>Expire Date</FormLabel>
            <LabelValue>
              {moment(props.secret?.expireDate).format("MM/DD/YYYY HH:MM")}
            </LabelValue>
          </FormGroup>

          <FormGroup>
            <FormLabel>Secret Value</FormLabel>
            <LabelValue>{props.secret?.secretValue}</LabelValue>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button onClick={props.onClose}>Close</Button>
      </ModalFooter>
    </Modal>
  );
};

SecretModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  secret: PropTypes.object.isRequired,
};

export default SecretModal;
