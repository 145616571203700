import React, { Component } from "react";
import axios from "../../../infra/axios";
import {
  Modal,
  ModalHeader,
  ModalBody,
  List,
  ListHeader,
  TableRow,
  TagInput,
  Animation,
  FormGroup,
  FormLabel,
  FormControl,
  LabelValue,
  ModalFooter,
  Button,
  ProgressBar } from "@hai/ui-react";

class ProvisionStatusVerify extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: props.show,
      claimCode: props.claimCode,
      hubLoading: true,
      jellyfishLoading: true,
      hubProvision: null,
      jellyfishProvision: null,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState((prev) => {
      prev.showModal = nextProps.show;
      prev.claimCode = nextProps.claimCode;
      return prev;
    });
    this.loadClaimCodeStatusFromHub(nextProps.claimCode);
    this.loadClaimCodeStatusFromJellyfish(nextProps.claimCode);
  }

  componentDidMount() {
    this.loadClaimCodeStatusFromHub(this.state.claimCode);
    this.loadClaimCodeStatusFromJellyfish(this.state.claimCode);
  }

  handleHide() {
    this.setState({
      showModal: false,
      claimCode: null,
      hubLoading: true,
      jellyfishLoading: true,
      hubProvision: null,
      jellyfishProvision: null,
    });
  }

  async loadClaimCodeStatusFromHub(claimCode) {
    await axios
      .get(
        process.env.REACT_APP_HUB_API_BASE_URL +
          "/Provisions/from-hub/" +
          claimCode
      )
      .then((response) => {
        this.setState((prev) => {
          prev.hubProvision = response.data;
          prev.hubLoading = false;
          return prev;
        });
      });
  }

  async loadClaimCodeStatusFromJellyfish(claimCode) {
    await axios
      .get(
        process.env.REACT_APP_HUB_API_BASE_URL +
          "/Provisions/by-claim-code/" +
          claimCode
      )
      .then((response) => {
        this.setState((prev) => {
          prev.jellyfishProvision = response.data;
          prev.jellyfishLoading = false;
          return prev;
        });
        console.log(this.state.jellyfishProvision);
      });
  }

  render() {
    return (
      <div>
        <Modal
          size="xl"
          show={this.state.showModal}
          onHide={() => this.handleHide()}
        >
          <ModalHeader style={{ color: "white" }}>
            Claim Code - {this.state.claimCode}
          </ModalHeader>
          <ModalBody>
            {this.state.hubLoading && this.state.jellyfishLoading && (
              <ProgressBar
  leftLabel="Loading: "
  barColors={{
    background: '#599bff',
    right: '#599bff',
    left: '#2fccd3',
  }}
  hideRight={true}
  animate={1}
/>
            )}
            {!this.state.hubLoading && !this.state.jellyfishLoading && (
              <div>
                <List>
                  <ListHeader
checkbox={false}
                    style={{
                      gridTemplateColumns: "2fr 3fr 3fr 1fr",
                    }}
                  >
                    <div></div>
                    <div>Hub Value</div>
                    <div>Jellyfish Value</div>
                    <div></div>
                  </ListHeader>
                  <TableRow
checkbox={false}
                    style={{
                      gridTemplateColumns: "2fr 3fr 3fr 1fr",
                    }}
                  >
                    <div>
                      <FormLabel>Created Date</FormLabel>
                    </div>
                    <div>{this.state.hubProvision.createdAt}</div>
                    <div>{this.state.jellyfishProvision.createdDate}</div>
                    <div></div>
                  </TableRow>
                  <TableRow
checkbox={false}
                    style={{
                      gridTemplateColumns: "2fr 3fr 3fr 1fr",
                    }}
                  >
                    <div>
                      <FormLabel>Claimed Date</FormLabel>
                    </div>
                    <div>{this.state.hubProvision.claimCodeReedemedAt}</div>
                    <div>{this.state.jellyfishProvision.claimedDate}</div>
                    <div></div>
                  </TableRow>
                </List>
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => this.handleHide()}>Close</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default ProvisionStatusVerify;
