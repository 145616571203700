import React from "react";
import { List, ListHeader, FormControl, Animation, ProgressBar, Header } from "@hai/ui-react";
import "./ContractsTable.scss";
import axios from "../../../infra/axios";
import { Col, Row } from "reactstrap";
import SlotsAvailable from "../../common/Utils/SlotsAvailable";
import JellyHeader from "../../common/Utils/JellyHeader";
import ContractRow from "./ContractRow";
import { Fragment } from "react";

class ContractsTable extends React.Component {
  state = {
    contracts: [],
    subscriptions: null,
    fullContracts: null,
    showModal: false,
    activeContract: null,
    loading: true,
  };

  async getContracts() {
    await axios
      .get(process.env.REACT_APP_HUB_API_BASE_URL + "/HubServiceContract")
      .then((resposne_sf) => {
        var contracts = resposne_sf.data.sort((a, b) =>
          a.name > b.name ? 1 : -1
        );
        this.setState((prev) => {
          prev.fullContracts = contracts;
          prev.contracts = contracts;
          prev.loading = false;
          return prev;
        });
      });
  }

  componentDidMount() {
    this.getContracts();
  }

  showModalClick(contract) {
    this.setState({ showModal: true, activeContract: contract });
  }

  hideModal() {
    this.setState({ showModal: false, activeContract: null });
  }

  filterForm = () => {
    return (
      <Row>
        <Col>
          <FormControl
            name="filter"
            placeholder="Company name/Hub Organization Id/Contract Name or Id"
            onChange={(e) => this.filterContracts(e.target.value)}
          ></FormControl>
        </Col>
        <Col>
          <FormControl
            name="subFilter"
            placeholder="Hub Subscription Id"
            onChange={(e) => this.filterSubs(e.target.value)}
          ></FormControl>
        </Col>
      </Row>
    );
  };

  contractTable = () => {
    return (
      <List>
        <ListHeader
checkbox={false}
          style={{
            gridTemplateColumns:
              "0.2fr 1.5fr 3fr 3fr 1.4fr 2fr 1fr 0.5fr 0.5fr 0.5fr 0.5fr",
          }}
          alternateRowColors="true"
        >
          <div></div>
          <div>Name</div>
          <div>Company Name</div>
          <div>Domain / Org Id</div>
          <div>Start / End Date</div>
          <div>Claim Code / Activation Date</div>
          <div>Status</div>
          <div>Assets</div>
          <div>Items</div>
          <div>Claim Codes</div>
        </ListHeader>

        {this.state.contracts.map((contract, index) => (
          <ContractRow
            contract={contract}
            key={contract.id}
            index={index}
            history={this.props.history}
          ></ContractRow>
        ))}
      </List>
    );
  };

  loadingData = () => {
    return (
      <div>
        <Row>
          <Col>
            <span style={{ color: `white` }}>No data...</span>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            {" "}
            {this.state.loading && (
              <ProgressBar
  leftLabel="Loading: "
  barColors={{
    background: '#599bff',
    right: '#599bff',
    left: '#2fccd3',
  }}
  hideRight={true}
  animate={1}
/>
            )}
          </Col>
        </Row>
      </div>
    );
  };

  filterContracts(value) {
    if (value == "") {
      this.setState((prev) => {
        prev.contracts = prev.fullContracts;
        return prev;
      });
    } else {
      if (value.length >= 2) {
        let filtered = this.state.fullContracts.filter((c) => {
          let valueUp = value.toUpperCase();
          if (c.accountName?.toUpperCase().includes(valueUp)) return true;
          if (c.name?.toUpperCase().includes(valueUp)) return true;
          if (c.hubOrgId?.toUpperCase().includes(valueUp)) return true;
          if (c.id?.toUpperCase().includes(valueUp)) return true;
        });
        this.setState((prev) => {
          prev.contracts = filtered;
          return prev;
        });
      }
    }
  }

  async filterSubs(value) {
    if (value == "") {
      this.setState((prev) => {
        prev.contracts = prev.fullContracts;
        return prev;
      });
    } else if (value.length == 36) {
      await axios
        .get(
          process.env.REACT_APP_HUB_API_BASE_URL +
            "/HubServiceContract/HubSubscription/" +
            value
        )
        .then((resposne_sf) => {
          this.setState((prev) => {
            prev.contracts = new Array(resposne_sf.data);
            prev.loading = false;
            return prev;
          });
        });
    }
  }

  render() {
    if (this.state.contracts === null || this.state.contracts.length === 0) {
      return (
        <div>
          {this.PageTitle()}
          {this.filterForm()}
          <hr className="hr-divider" />
          {this.loadingData()}
        </div>
      );
    } else {
      return (
        <div>
          {this.PageTitle()}
          {this.filterForm()}
          <hr className="hr-divider" />
          {this.contractTable()}
        </div>
      );
    }
  }

  PageTitle() {
    return (
      <Fragment>
          <Header title="Contracts"></Header>
          <br/>
          <SlotsAvailable app="hub"></SlotsAvailable>
        <hr></hr>
      </Fragment>
    );
  }
}

export default ContractsTable;
